<div *ngIf="!coverageTable && !uploadFileInput" class="row mt-4">
	<form id="file-upload-form" class="uploader">
		<input id="file-upload" type="file" name="fileUpload" accept=".xlsx" (change)="onFileChange($event)"
			[disabled]="uploadFileInput">

		<label for="file-upload" id="file-drag">
			<img id="file-image" src="#" alt="Preview" class="hidden">
			<div id="start">
				<i class="fa fa-download" aria-hidden="true"></i>
				<div>Select a Coverage file or drag here</div>
				<div id="notimage" class="hidden">Please select an Coverage Excel File</div>
				<span id="file-upload-btn" class="btn btn-primary">Select a Coverage file</span>
			</div>
			<div id="response" class="hidden">
				<div id="messages"></div>
				<progress class="progress" id="file-progress" value="0">
					<span>0</span>%
				</progress>
			</div>
		</label>
	</form>
</div>

<div *ngIf="uploadFileInput" class="d-flex justify-content-center">
	<div class="spinner-border" role="status">
		<span class="visually-hidden"></span>
	</div>
</div>

<div *ngIf="coverageTable">
	<div class="row mb-2  d-flex justify-content-between align-items-center">
		<div class="col-3">
			<h5 class="text-size pl-3 text-info mb-0">Coverage Data:-</h5>
		</div>
		<div class="col-3 ">
			<p>File Name: {{fileName}}</p>
		</div>
		<div class="col-1 ">
			<button (click)="fileInput.click()" type="button" class="btn bg-softblue text-white">
				<input style="display: none" type="file" accept=".xlsx" (change)="onFileChange($event)" #fileInput
					[disabled]="uploadFileInput" />
				Replace
			</button>
		</div>
		<div class="col mr-4">
			<!--  -->
			<div class="switch_panel pt-2" *ngIf="spinDiv">
				<div class="switch_main ml-auto mr-5">
					<span class="switch_off">Show All</span>
					<span class="switch_on">Selected Rows</span>
					<label class="switch">
						<input type="checkbox" (click)="switchToggle($event)">
						<span class="slider round"></span>
					</label>
				</div>
			</div>
			<!--  -->
		</div>
	</div>
	<div class="p-4 bg-white cust_shadow mx-3 rounded filter-csv_table">
		<table class="table ">
			<thead class="thead-light">
				<tr>
					<th scope="col">
              <input class="form-check-input" type="checkbox"
                (click)="seletedAll($event,reportId)"
                [checked]="isAllChecked">Select All
          </th>
					<th scope="col">GENE</th>
					<th scope="col">Percent (%)</th>
					<th *ngIf="switchToggleButton">Actions</th>
				</tr>
			</thead>
			<div *ngIf="coverageListSpinner">
				<div class="spinner-border" role="status">
					<span></span>
				</div>
			</div>
			<tbody>
				<ng-container *ngFor="let coverageVariant of coverageVariants; let i = index">
					<tr>
						<td>
							<div class="form-check cust_form-ckeck">
								<input class="form-check-input" type="checkbox"
									(click)="seletedRow($event,coverageVariant)"
									[checked]="coverageVariant.selectedForReport">
							</div>
						</td>
						<td>{{coverageVariant.geneName}}</td>
						<td>{{coverageVariant.coveragePercent}}</td>
						<td *ngIf="switchToggleButton">
							<select class="form-control" name="significance" [(ngModel)]="coverageVariant.significance"
								(change)="selectSignificance(coverageVariant.id,coverageVariant.significance)">
								<option [ngValue]="null" selected disabled>Select</option>
								<option *ngFor="let significance of clinicalSiginificanceList"
									[selected]="coverageVariant.significance == significance" value={{significance}}>
									{{significance}}</option>
							</select>
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>
</div>
