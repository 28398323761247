<div class="container-fluid px-0" style="position: absolute;">

    <div class="row  py-3  mx-0">
        <div class="col pl-0">
          <h5 class="mb-0 d-inline-block  text_color pt-2">Hotspot Drugs List</h5>
        </div>
        <div class="col pr-0">
          <div class="text-right ml-auto d-table">
            <div class="add-new">
              <a class="add_post mr-2" [routerLink]="[ '/super_admin/add-hotspot-clinical-trail' ]">Add Hotspot <i class="zmdi zmdi-plus"></i></a>
            </div>
          </div>
        </div>

        </div>
        <div class="row ml-5 mr-4 reports_info align-items-left">
          <label class="mt-2">Search: </label>
          <input
            class="col-2 form-control"
            type="text"
            name="search"
            [(ngModel)]="searchText"
            autocomplete="off"
            placeholder="Key"
          />
      </div>
      <div *ngIf="hotspotDrugListSpinner" class="row data-spinner-parent">
        <div class="spinner-border data-spinner-child" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
      <div *ngIf="!hotspotDrugListSpinner" class="bg-white cust_shadow p-4 rounded ">
        <div class="tableFixHead">
          <table class="care_companion table ">
            <thead class="thead-light" >
              <tr >
                <th>Sl.No</th>
                <th>Gene</th>
                <th>Drug</th>
                <th>ASN</th>
                <th>P Position</th>
                <th>Cancer Type</th>
                <th>LOE</th>
                <th style="width: 115px!important;">Actions</th>
              </tr>

            </thead>
            <tbody >
              <tr *ngFor="let ClinicalTrial of ClinicalTrialList | filter : searchText; let i = index">
                <td>{{i+1}}</td>
                <td>{{ClinicalTrial.gene.name}}</td>
                <td><p *ngFor="let drug of ClinicalTrial.drugs, let i = index">{{drug.name}}{{(i+1 != ClinicalTrial.drugs.length) ? ',' : ''}}</p></td>
                <td *ngIf="ClinicalTrial.association=='RESPONSIVE'">S</td>
                <td *ngIf="ClinicalTrial.association=='RESISTANCE'">R</td>
                <td *ngIf="ClinicalTrial.association=='ONGOING'">O</td>
                <td *ngIf="ClinicalTrial.association==''||ClinicalTrial.association==null">-</td>
                <td style="width: 200px; display: block;"><span style="  word-wrap: break-word;">{{ClinicalTrial.pposition || "-"}}</span></td>

                <td >{{ClinicalTrial.cancerType.name}}</td>
                <td *ngIf="ClinicalTrial.evidence=='LEVEL_1'">1</td>
                <td *ngIf="ClinicalTrial.evidence=='LEVEL_2'">2</td>
                <td *ngIf="ClinicalTrial.evidence==null">-</td>
                <td class="mr-4">
                  <ul class="list-unstyled mb-0 d-flex actions">
                    <li><a class="mr-1 delete_clr" [routerLink]="[ '/super_admin/add-hotspot-clinical-trail', ClinicalTrial.id ]"> Edit </a></li>
                    <li><a class="delete_clr" data-toggle="modal" data-target="#deleteClinicalModal"
                      (click)="assignClinicalTrail(ClinicalTrial)">| Delete</a></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        </div>

        <!-- The delete Modal -->
        <div class="modal fade" id="deleteClinicalModal">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content surgery-modal">

              <div class="modal-header px-4 border-bottom-0 bg-light">
                <h5 class="modal-title text-softblue">Delete</h5>
                <a class="text-softblue" data-dismiss="modal">
                    <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
                </a>
              </div>

              <div class="modal-body p-4">
                      <h6 class=" mb-4">Are you sure want to delete ?</h6>
                    <div class="mx-0 ml-auto d-table">
                        <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                        data-dismiss="modal" (click)="deleteClinicalTrail()">Yes</button>
                        <button type="button" class="btn bg-softblue text-white px-5"
                        data-dismiss="modal">No</button>
                    </div>
              </div>

            </div>
          </div>
        </div>

</div>
