<div class="container-fluid px-0">


    <div class="row  py-3 mx-0">
        <div class="col pl-0">
          <h5 class="mb-0 d-inline-block text_color pt-2">Prognostic Biomarker List</h5>
        </div>
        <div class="col pr-0">
          <div class="text-right ml-auto d-table">
            <div class="add-new">
              <a class="add_post mr-2" style="width: 220px;" [routerLink]="[ '/super_admin/add-prognasticbiomarker']">Add Prognostic Biomarker <i class="zmdi zmdi-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="prognasticListSpinner" class="row data-spinner-parent">
        <div class="spinner-border data-spinner-child" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
      <div *ngIf="!prognasticListSpinner" class="bg-white cust_shadow p-4 rounded">
        <!-- <div class="row col " style="padding: 0; margin: 0;">
          <div class="reports_info d-flex ml-auto">
            <label  class="mr-2"> Type:</label>
              <select
                class="bg-white form-control  dropdown-toggle"
                (change)="filterByType($event)"
              >
                <option selected disabled>Select</option>
                <option value="All">All</option>
                <option *ngFor="let type of typeList" [value]="type">{{type}}</option>
              </select>
          </div>
        </div> -->
        <div class="tableFixHead mt-2">
          <table class="care_companion table ">
            <thead class="thead-light">
              <tr>
                <th>Sl.No</th>
                <th>Tag</th>
                <th>Actions</th>
              </tr>

            </thead>
            <tbody>
              <tr *ngFor="let prognastic of prognasticList; let i = index">
                <td>{{i+1}}</td>
                <td>{{prognastic.prognasticTag}}</td>
                <td>
                  <ul class="list-unstyled mb-0 d-flex actions">
                    <li><a class="mr-1 delete_clr" (click)="prognasticBiomarkerDetails(prognastic)"
                      data-toggle="modal" data-target="#viewPrognasticModal"> View </a></li>
                    <li><a class="mr-1 delete_clr" [routerLink]="['/super_admin/add-prognasticbiomarker', prognastic.id]">| Edit </a></li>
                    <li><a class="delete_clr" (click)="assignPrognasticBiomarker(prognastic.id)" data-toggle="modal" data-target="#deletePrognasticModal">| Delete</a></li>

                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        </div>


</div>

<!-- view Prognastic Modal -->
 <div class="modal fade" id="viewPrognasticModal">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 1100px;">
    <div class="modal-content surgery-modal">

      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Prognostic Marker Details</h5>
        <a class="text-softblue" data-dismiss="modal" (click)="closeModal()">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4" style="text-align: left;">
        <div class="row">
          <div class="col">
            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right">Prognostic Tag :</label>
              <div class="col-sm-8">
                <label>{{prognastic.prognasticTag}}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right">Prognostic Significance  :</label>
              <div class="col-sm-8">
                <label>{{prognastic.prognsticSignificance}}</label>
              </div>
            </div>

            <div class="form-group row mb-0" *ngFor="let geneVariant of prognastic.geneVariants, let i= index">
              <label class="col-sm-4 control-label text-right">Gene, Variant {{i+1}}:</label>
              <div class="col-sm-8">
                <label>{{geneVariant.gene?.name}}, {{geneVariant.variant}}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 control-label text-right">Description :</label>
              <div class="col-sm-8">
                <label style="text-align: justify!important;" [innerHtml]='prognastic.description'></label>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>

      <!-- The delete Modal -->
     <div class="modal fade" id="deletePrognasticModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content surgery-modal">

            <div class="modal-header px-4 border-bottom-0 bg-light">
              <h5 class="modal-title text-softblue">Delete</h5>
              <a class="text-softblue" data-dismiss="modal">
                  <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
              </a>
            </div>

            <div class="modal-body p-4">
                    <h6 class=" mb-4">Are you sure want to delete ?</h6>
                  <div class="mx-0 ml-auto d-table">
                      <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                      (click)="deletePrognastic()" data-dismiss="modal">Yes</button>
                      <button type="button" class="btn bg-softblue text-white px-5"
                      data-dismiss="modal">No</button>
                  </div>
            </div>

          </div>
        </div>
      </div>
