import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CNAVariants } from 'src/app/_interfaces.ts/excel';
import { ReportRes } from 'src/app/_interfaces.ts/reports';
import { CnaService } from 'src/app/_services/cna.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-cna-excel',
  templateUrl: './cna-excel.component.html',
  styleUrls: ['./cna-excel.component.scss']
})
export class CnaExcelComponent implements OnInit {

  reportId!: number;
  report = {} as ReportRes;
  cnaVariants: CNAVariants[] = [];
  selectedForReport!: boolean;
  selectedStarForReport!: boolean;

  cnaTable = false;
  uploadFileInput = false;
  cnaListSpinner = true;
  switchToggleButton = false;
  spinDiv=false;

  fileName!: string;

  constructor(
    private cnaService: CnaService,
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
  ) { }
  organisationName!: string;

  ngOnInit(): void {
    this.organisationName = localStorage.getItem('organisationName') || '';

    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }

  autoUploadCnaFile(){
    console.log("autoUploadCnaFile");
    this.cnaService
      .autoUploadCnaFile(this.reportId)
      .subscribe((response: any) => {
        this.cnaTable = true;
        this.uploadFileInput = false;
        this.cnaListSpinner = false;
        console.log("CNA data ==>", response);
        this.cnaVariants = response['payLoad'];
      });


  }

  onFileChange(event: any) {
    this.uploadFileInput = true;
    const files = event.target.files;
    const file = files[0];
    // this.fileName = file.name;
    this.uploadFile(file);
  }

  uploadFile(file: any) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.cnaService.uploadCNAExcel(formdata, this.reportId).subscribe(
      (response: any) => {
        this.cnaTable = true;
        this.uploadFileInput = false;
        this.cnaListSpinner = false;
        console.log("CNA data ==>", response);
        this.cnaVariants = response['payLoad'];
      }
    );
  }

  seletedRow(event: any, cnaVariant: CNAVariants) {
    this.selectedForReport = event['target']['checked'];

    if (this.selectedForReport) {
      this.cnaService.cnaVariantStatus(cnaVariant.id, true).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getCNAVariants();
          }
        }
      );
    } else if (!this.selectedForReport) {
      if(confirm("Are you sure you want unselect..!")) {
      this.cnaService.cnaVariantStatus(cnaVariant.id, false).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getCNAVariants();

          }
        }
      );
      }
      else{
        this.getCNAVariants();
      }
    }

  }

  seletedStarRow(event: any, cnaVariant: CNAVariants) {
    this.selectedStarForReport = event['target']['checked'];
    console.log(this.selectedStarForReport);
    this.cnaService.cnaStarVariantStatus(cnaVariant.id, this.selectedStarForReport).subscribe(
      (response: any) => {
        console.log(response);
      }
    );
  }

  priorityOrder(orderNumber: number, cnaVariantId: number){
    console.log(orderNumber, cnaVariantId);
    if(orderNumber != null && orderNumber > 0){
      this.cnaService.setPriorityOrder(cnaVariantId, orderNumber).subscribe(
        (response: any) => {
          console.log("setPriorityOrder",response);
        }
      );
    }

  }


  switchToggle(event: any) {
    this.cnaListSpinner = true;
    this.switchToggleButton = event['target']['checked'];
    console.log("switchToggleButton", this.switchToggleButton);
    if (this.switchToggleButton) {
      this.getSelectedCNAVariants();
    } else if (!this.switchToggleButton) {
      this.getCNAVariants();
    }

  }

  getReport() {
    this.uploadFileInput = true;
    this.reportService.getReport(this.reportId).subscribe(
      (response: any) => {
        console.log("cna component", response);
        this.report = response['payLoad'];
        this.uploadFileInput = false;

        if(this.report.cnaExcelFile == null && this.organisationName != null && this.organisationName.length > 0){
          this.autoUploadCnaFile();
        }

        if (this.report.cnaExcelFile == null) {
          this.cnaTable = false;
        } else if (this.report.cnaExcelFile != null) {
          this.cnaTable = true;
          this.fileName = this.report.cnaExcelFile.split('/')[5].substring(14);
          this.getCNAVariants();
        }
      });
  }

  getCNAVariants() {
    this.cnaListSpinner = true;
    this.cnaService.getAllCNAVariantsByReportId(this.reportId).subscribe(
      (response: any) => {
        this.cnaListSpinner = false;
        this.spinDiv=true;
        console.log("CNA data ==>", response);
        this.cnaVariants = response['payLoad'];
        if (response['success'] == true) {
          if (this.switchToggleButton) {
            this.cnaListSpinner = true;
            this.getSelectedCNAVariants();
          }
        }
      }
    );
  }

  getSelectedCNAVariants() {

    function isSelectedForReport(element: CNAVariants, index: any, array: any) {
      return (element.selectedForReport == true);
    }
    this.cnaVariants = this.cnaVariants.filter(isSelectedForReport);
    this.cnaListSpinner = false;
  }

  accordionClick(cna: CNAVariants){
    console.log("cna",cna);
    cna.show = !cna.show
    this.getComparisionList(cna.comparison);
  }

  comparisionList : String[] = [] ;
  getComparisionList(comparision : string){
    console.log("getComparisionList");
    this.comparisionList =  comparision.split(";");
  }
}
