<div class="container-fluid px-0">
  <h5 class="mb-0 d-inline-block text_color mb-3 mt-4">Add Pathway</h5>
  <div class="mx-0 p-4 bg-white cust_shadow rounded w-75">
      <!-- <div class="col">
        <div class="text-right ml-auto d-table">
          <div class="add-new">
            <a class="add_post mr-2" [routerLink]="[ '/super_admin/add-pathway']">Add Pathway <i
                class="zmdi zmdi-plus"></i></a>
          </div>
        </div>
      </div> -->


  <div class="row">
    <div class="col mr-auto">
      <form action="" class="mt-5 ">
        <div class="form-group row w-75">
          <label class="col-sm-4 control-label text-right">Name :</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" name="name" [(ngModel)]="add_pathway.name">
          </div>
        </div>

        <div class="form-group row mb-3 w-75">
          <label class="col-sm-4 control-label text-right">Associated Genes :</label>
          <div class="col-sm-8">
            <ng-multiselect-dropdown name="id" [data]="geneList" [settings]="dropdownSettings"
              [(ngModel)]="selectedGenes">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="form-group row w-75">
          <label class="col-sm-4 control-label text-right">Upload Image:</label>
          <div class="col-sm-8">
          <input type="file" class="form-control" (change)="uploadImage($event)">
        </div>
        </div>

        <div class="form-group row w-75">
          <div class="col-sm-8 offset-4">
          <img [src]=imageUrl style="width: 300px;">
        </div>
        </div>



        <div class="form-group row w-75">
          <label class="col-sm-4 control-label">Description </label>
         <div class="col-sm-8">
          <ckeditor [editor]="editor" name="description" [(ngModel)]="add_pathway.description"></ckeditor>
         </div>
        </div>
        <div class="w-75 mr-5 pr-4">
          <button type="button" [disabled]="addPathwaySpinner" class="btn px-5 ml-auto d-block save_btn"
            (click)="addPathway()">
            <span *ngIf="!addPathwaySpinner" > Save </span>
            <div *ngIf="addPathwaySpinner" class="spinner-border text-dark">
              <span class="sr-only"></span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
  </div>

</div>
