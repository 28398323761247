import { CancerType } from './../../_interfaces.ts/admin';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { AddClinicalTrial, Drug, Gene, Therapy } from 'src/app/_interfaces.ts/admin';

@Component({
  selector: 'app-add-clinical',
  templateUrl: './add-clinical.component.html',
  styleUrls: ['./add-clinical.component.css']
})
export class AddClinicalComponent implements OnInit {

  editor = ClassicEditor;
  add_clinical = {} as AddClinicalTrial;
  submitSpinner = false;
  cancerTypeList!: CancerType[];
  geneList!: Gene[];
  therapyList!: Therapy[];
  statusMsg!: string;

  clinicalTrailId!: number;
  drugsDropdownSettings = {};
  cancerTypeDropdownSettings = {};
  geneDropdownSettings = {};

  drugs = [];
  // genes=[];
  // cancerTypes=[];
  drugIds: number[] = [];
  selectedDrugs: Drug[] = [];
  selectedcancertype: CancerType[] = [];
  selectedGene: Gene[] = [];
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute
  ) { }


  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {

    this.add_clinical.summary = "";
    this.clinicalTrailId = +this.routerAct.snapshot.params['id'];
    if(this.clinicalTrailId){
      this.getClinicalTrail();
    }

    this.getCancerTypes();
    this.getGenes();
    this.getTherspyList();

    this.getDrugs();

    this.drugsDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.cancerTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.geneDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


  onSubmit(){
    this.submitSpinner = true;

    if(this.selectedDrugs.length > 0){
      for (let i = 0; i < this.selectedDrugs.length; i++) {
        const id = this.selectedDrugs[i]['id'];
        this.drugIds.push(id);
      }
    }

    if(this.selectedGene.length == 1){
      this.add_clinical.geneId = this.selectedGene[0]['id'];
  }

    if(this.selectedcancertype.length == 1){
      this.add_clinical.cancerTypeId = this.selectedcancertype[0]['id'];
    }

    this.add_clinical.drugIds = this.drugIds;
    this.drugIds = [];

    console.log("add_clinical",this.add_clinical);
    this.dataAdminService.addClinicalTrail(this.add_clinical).subscribe(
      (response: any) => {
        this.submitSpinner = false;
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );
  }


  getSelectedGene: Gene[] = [];
  getSelectedCancerType: CancerType[] = [];

  getClinicalTrail(){
    this.dataAdminService.getClinicalTrail(this.clinicalTrailId).subscribe(
      (response:any) => {
        console.log("clinicalTrailId ==>",response);
        this.add_clinical = response['payLoad'];
        this.selectedDrugs = response['payLoad']['drugs'];

        if(response['payLoad']['gene'] != null){
        this.getSelectedGene.push(response['payLoad']['gene']);
        this.selectedGene = this.getSelectedGene;
        }

        if(response['payLoad']['cancerType'] != null){
        this.getSelectedCancerType.push(response['payLoad']['cancerType']);
        this.selectedcancertype = this.getSelectedCancerType;
        }

        console.log(this.selectedGene);
        
      }
    );
  }

  getDrugs() {
    this.dataAdminService.getDrugs().subscribe(
      (response: any) => {
        this.drugs = response['payLoad'];
      }
    );
  }

  getCancerTypes(){
    this.dataAdminService.cancerTypeList().subscribe(
      (response: any) => {
        this.cancerTypeList = response['payLoad'];
      }
    );
  }

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  getTherspyList(){
    this.dataAdminService.getTherapyList().subscribe(
      (response: any) => {
        this.therapyList = response['payLoad'];
      }
    );
  }

  selectTrailType(levelType: string){
    if(levelType == 'LEVEL_1'){
      this.add_clinical.clinicalTrailType = 'FDA';
    } else if(levelType == 'LEVEL_2'){
      this.add_clinical.clinicalTrailType = 'NCCN';
    } else if(levelType == 'LEVEL_3'){
      this.add_clinical.clinicalTrailType = 'Clinical trials';
    } else if(levelType == 'LEVEL_4'){
      this.add_clinical.clinicalTrailType = 'Pre clinical trial';
    }
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/clinical-trails']);
    });
  }

}
