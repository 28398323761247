import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-first72-solid-hcg',
  templateUrl: './first72-solid-hcg.component.html',
  styleUrls: ['./first72-solid-hcg.component.css']
})
export class First72SolidHCGComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
