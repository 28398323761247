import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CoverageFile } from 'src/app/_interfaces.ts/excel';
import { HRRSomaticReportPdfRes, RelevantData, ReportRes } from 'src/app/_interfaces.ts/reports';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-hrr-somatic-report',
  templateUrl: './hrr-somatic-report.component.html',
  styleUrls: ['./hrr-somatic-report.component.css']
})
export class HrrSomaticReportComponent implements OnInit {
  // footerDiscription = "All the 4baseCare test samples are processed at 4basecare Offshore Development Centre (ODC) - Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15189:2012 for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO. 69P,71/4P,78/8AP,134P,76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification"
  footerDiscription ="All the 4baseCare test samples are processed at 4baseCare Offshore Development Centre (ODC) - "+
                "Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15109:2012 "+
                "for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO 69P,71/4P,70/BAP,134P, "+
                "76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, "+
                "INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification."
  footerDiscription2 = "4baseCare (Genomics Tarang ODC), SJP2-S1-1F-C wing, Wipro Limited, SEZ, Sarjapur 2, SyNo.69 (P), Doddakannelli, "+
                "Sarjapura Road, Bengaluru 560035, KA, India"


  reportId!:number
  statusMsg!:string
  reportPdfRes = {} as HRRSomaticReportPdfRes;
  report = {} as ReportRes;
  downloadSpinner = false;
  snvSameRelevantData: RelevantData[] = [];
  snvOtherRelevantData: RelevantData[] = [];

  cnaSameRelevantData: RelevantData[] = [];
  firstTableFlag=false;
  cnaOtherRelevantData: RelevantData[] = [];

  fusionSameRelevantData: RelevantData[] = [];
  fusionOtherRelevantData: RelevantData[] = [];
  newsnvHotspotTrailResList:any=[]
  todayDate = Date();
  reportData=false
  sampleSource!:string;
  coverageMiddleIndex!: number;
  allcoverageFileList: CoverageFile[] = [];
  firstHalfCoverageFileList: CoverageFile[] = [];
  secondHalfCoverageFileList: CoverageFile[] = [];
  constructor( private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("view report");
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  showFail() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 10000 }
    );
  }
  downloadReport() {
    this.downloadSpinner = true;
    this.print()
}
print(){
  // this.isReportDownload=true
 setTimeout(()=>{
  this.downloadSpinner = false;
  document.title="tarGT_Hrr_somatic" + "-" + this.report?.patient?.name?.replace(/\./g, ' ')
  window.print()
  // this.isReportDownload=false
 },3000)
}
referringClinician!:string;
is4basecare!:boolean;
labHospital!:string;

  getReport() {
    this.reportService.somaticReportPdfResponse(this.reportId).subscribe(
      (response: any) => {
        console.log(response);
        this.reportData = true;
        this.statusMsg = response.message;
        this.showSuccess();
        this.reportPdfRes = response['payLoad'];
        this.report = response['payLoad']['report'];
        this.report = response['payLoad']['report'];
        if(this.report.patient.organisationName != null){
          if(this.report.patient.organisationName.includes("4baseCare") || this.report.patient.organisationName.includes("4basecare")){
            this.is4basecare = true;
          }
        }
        if(this.is4basecare==true && this.report?.labDetails?.hospital.includes("Cytecare")){
          this.referringClinician="Cytecare Hospitals";
          this.labHospital="Cytecare Hospitals"
        }else{
          this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
          this.labHospital=this.report?.labDetails?.hospital;
        }
        this.allcoverageFileList = this.reportPdfRes.coverageFileList;
        this.coverageMiddleIndex = Math.ceil(this.allcoverageFileList.length / 2);
        this.firstHalfCoverageFileList = this.allcoverageFileList.splice(0, this.coverageMiddleIndex);
        this.secondHalfCoverageFileList = this.allcoverageFileList.splice(-this.coverageMiddleIndex);


        // this.tableCount= this.reportPdfRes.snvHotspotTrailResList.length + this.reportPdfRes.cnaHotspotTrailResList.length + this.reportPdfRes.fusionHotspotTrailResList.length;
        this.setPage1Table(this.reportPdfRes);
        console.log("additional noted before-->", this.report.addNotes)
        if (this.report.addNotes == null || this.report.addNotes == undefined || this.report.addNotes == '') {
          this.report.addNotes = '<p></p>'
          console.log("additional noted Not requried-->", this.report.addNotes)
        }
        if (this.report.addNotes != null || this.report.addNotes == '<p></p>') {

          // console.log("actual Addnotes",this.report.addNotes)
          this.report.addNotes = this.report.addNotes.replace(/<p>/g, '<div>');
          this.report.addNotes = this.report.addNotes.replace(/<\/p>/g, '</div>');
          this.report.addNotes = this.report.addNotes.replace(/{/g, '<sup>');
          this.report.addNotes = this.report.addNotes.replace(/}/g, '</sup>');
          this.report.addNotes = this.report.addNotes.replace(/~/g, '<sub>');
          this.report.addNotes = this.report.addNotes.replace(/!/g, '</sub>');
          console.log("additional noted after-->", this.report.addNotes)
        }
        if (this.report.labDetails != null) {
          // console.log("sampleScorce-->",this.report.labDetails.sampleSource)
          this.sampleSource = this.report.labDetails?.sampleSource.replace(/FFPE BlockId:/g, '');
          // this.sampleSource=this.sampleSource.replace(/\//g, '');
        }
        // if (this.reportPdfRes.cnaReportedVariantList.length > 0) {
        //   this.tableShow = true;
        // }
        this.getExonNumbers();
        let geneLength:number=0;
        let associationListLenght:number=0;
        let drugListLength:number=0;
        if(this.reportPdfRes.snvHotspotTrailResList && this.reportPdfRes.snvHotspotTrailResList.length>0){
          geneLength=geneLength+this.reportPdfRes.snvHotspotTrailResList.length;
          this.reportPdfRes.snvHotspotTrailResList.forEach(element => {
            let snvData:any=element
            let maxSameassociationListLenght:number=0
            let maxSamedrugListLength:number=0
            let maxOtherassociationListLenght:number=0
            let maxOtherdrugListLength:number=0
         if(snvData['sameCancerType']!=null){
          maxSameassociationListLenght=associationListLenght+snvData['sameCancerType'].associationList.length
          maxSameassociationListLenght=drugListLength+snvData['sameCancerType'].drugList.length
         }
         else{
          maxSameassociationListLenght=associationListLenght
          maxSamedrugListLength=drugListLength
         }
          if(snvData['otherCancerType']!=null){
            maxOtherassociationListLenght=associationListLenght+snvData['otherCancerType'].associationList.length
            maxOtherdrugListLength=drugListLength+snvData['otherCancerType'].drugList.length
         }else{
          maxOtherassociationListLenght=associationListLenght
          maxOtherdrugListLength=drugListLength
         }

         if((maxSameassociationListLenght+maxSamedrugListLength)>(maxOtherassociationListLenght+maxOtherdrugListLength)){
          associationListLenght=maxSameassociationListLenght
          drugListLength=maxSamedrugListLength
         }else{
          associationListLenght=maxOtherassociationListLenght
          drugListLength=maxOtherdrugListLength
         }
          });

        }
        if(this.reportPdfRes.fusionHotspotTrailResList && this.reportPdfRes.fusionHotspotTrailResList.length>0){
          geneLength=geneLength+this.reportPdfRes.fusionHotspotTrailResList.length
          this.reportPdfRes.fusionHotspotTrailResList.forEach(element => {
            let fusionData:any=element
          if(fusionData['sameCancerType']){
            associationListLenght=associationListLenght+fusionData['sameCancerType'].associationList.length
            drugListLength=drugListLength+fusionData['sameCancerType'].drugList.length
          }

          });

        }
        console.log("size",associationListLenght," ",drugListLength)
        let length=this.reportPdfRes.snvHotspotTrailResList.length+this.reportPdfRes.cnaHotspotTrailResList.length+this.reportPdfRes.fusionHotspotTrailResList.length
        let length1= this.reportPdfRes.snvReportedVariantList.length+this.reportPdfRes.cnaReportedVariantList.length+this.reportPdfRes.fusionReportedVariantList.length
        if(length>3 && length1>5){
          this.firstTableFlag=true
        }
        // if(associationListLenght>=7 || drugListLength>=7 || geneLength>6){
        //   this.firstTableFlag=true
        // }
        // if(this.reportId==2976){
        //   // if(this.reportPdfRes.snvHotspotTrailResList.length>1){
        //     this.reportPdfRes.snvHotspotTrailResList.forEach((element:any)=>{
        //       if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
        //         this.newsnvHotspotTrailResList.push(element)
        //        let index= this.reportPdfRes.snvHotspotTrailResList.indexOf(element)
        //        this.reportPdfRes.snvHotspotTrailResList.splice(index,1)
        //       }
        //     })
        //   // }
        //   if(this.reportPdfRes.cnaHotspotTrailResList.length>1){
        //     this.reportPdfRes.cnaHotspotTrailResList.forEach((element:any)=>{
        //       if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
        //         this.newsnvHotspotTrailResList.push(element)
        //        let index= this.reportPdfRes.cnaHotspotTrailResList.indexOf(element)
        //        this.reportPdfRes.cnaHotspotTrailResList.splice(index,1)
        //       }

        //     })
        //   }
        //   // if(this.newsnvHotspotTrailResList.length>0){
        //   //   this.geneFlag=true
        //   // }
        // }else{
        //     if(this.reportPdfRes.snvHotspotTrailResList.length>1){
        //   this.reportPdfRes.snvHotspotTrailResList.forEach((element:any)=>{
        //     if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
        //       this.newsnvHotspotTrailResList.push(element)
        //      let index= this.reportPdfRes.snvHotspotTrailResList.indexOf(element)
        //      this.reportPdfRes.snvHotspotTrailResList.splice(index,1)
        //     }
        //   })
        // }
        // if(this.reportPdfRes.cnaHotspotTrailResList.length>1){
        //   this.reportPdfRes.cnaHotspotTrailResList.forEach((element:any)=>{
        //     if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
        //       this.newsnvHotspotTrailResList.push(element)
        //      let index= this.reportPdfRes.cnaHotspotTrailResList.indexOf(element)
        //      this.reportPdfRes.cnaHotspotTrailResList.splice(index,1)
        //     }

        //   })
        // }
        // if(this.newsnvHotspotTrailResList.length>0){
        //   // this.geneFlag=true
        // }
        // }

      },
      error => {
        console.log('oops...!', error)
        this.statusMsg = error.error.error;
        this.showFail();
      });
  }

  setPage1Table(reportPdfRes: HRRSomaticReportPdfRes) {
    // SNV //
    if (reportPdfRes.snvHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.snvHotspotTrailResList.length; i++) {
        // Different Cancer type
        if (reportPdfRes.snvHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.snvHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.snvHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.snvHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.snvHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.snvOtherRelevantData.push(relevantData);
          }
          console.log("this.snvOtherRelevantData", this.snvOtherRelevantData);
          reportPdfRes.snvHotspotTrailResList[i].otherTypeRelevantData = this.snvOtherRelevantData;
          this.snvOtherRelevantData = [];
        }

        // Same Cancer Type
        if (reportPdfRes.snvHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.snvHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.snvHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.snvHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.snvSameRelevantData.push(relevantData);
          }
          console.log("this.snvSameRelevantData", this.snvSameRelevantData);
          reportPdfRes.snvHotspotTrailResList[i].sameTypeRelevantData = this.snvSameRelevantData;
          this.snvSameRelevantData = [];
        }

      }
    }

    // CNA //
    if (reportPdfRes.cnaHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.cnaHotspotTrailResList.length; i++) {

        // Different Cancer type
        if (reportPdfRes.cnaHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.cnaOtherRelevantData.push(relevantData);
          }
        }
        console.log("this.cnaOtherRelevantData", this.cnaOtherRelevantData);
        reportPdfRes.cnaHotspotTrailResList[i].otherTypeRelevantData = this.cnaOtherRelevantData;

        // Same Cancer Type
        if (reportPdfRes.cnaHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.cnaSameRelevantData.push(relevantData);
          }
        }
        console.log("this.cnaSameRelevantData", this.cnaSameRelevantData);
        reportPdfRes.cnaHotspotTrailResList[i].sameTypeRelevantData = this.cnaSameRelevantData;

      }
    }

    // Fusion //
    if (reportPdfRes.fusionHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.fusionHotspotTrailResList.length; i++) {

        // Different Cancer type
        if (reportPdfRes.fusionHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.fusionOtherRelevantData.push(relevantData);
          }
        }
        console.log("this.fusionOtherRelevantData", this.fusionOtherRelevantData);
        reportPdfRes.fusionHotspotTrailResList[i].otherTypeRelevantData = this.fusionOtherRelevantData;

        // Same Cancer Type
        if (reportPdfRes.fusionHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.fusionSameRelevantData.push(relevantData);
          }
        }
        console.log("this.fusionSameRelevantData", this.fusionSameRelevantData);
        reportPdfRes.fusionHotspotTrailResList[i].sameTypeRelevantData = this.fusionSameRelevantData;

      }
    }

  }
  count=0;
  countOther=0
  WTcountSNV=false
  otherCountSNV=false
  getExonNumbers() {

    this.reportPdfRes.snvReportedVariantList.forEach(reportedVariant => {
      reportedVariant.aachangeknownGeneList.forEach((aachangeknownGene, index) => {
        if(reportedVariant.selectedAAChangeknownGene && reportedVariant.selectedAAChangeknownGene.length>0){
          if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {

            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }
        }else{
          if (aachangeknownGene == 'UNKNOWN') {
            // console.log("UNKNOWN");
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene UNKNOWN", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene == '.') {
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene dot", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3];
            reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ aachangeknownGene.split(":")[4];
            console.log('AAAAAa',reportedVariant.selectedAAChangeknownGene)
            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }

          // console.log("reportedVariant.selectedAAChangeknownGene", reportedVariant.selectedAAChangeknownGene);


          if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
            // console.log("reportedVariant.selectedAAChangeknownGene index", reportedVariant.selectedAAChangeknownGene);

            if (reportedVariant.selectedAAChangeknownGene == null) {
              let selectedAA:any= reportedVariant.aachangeknownGeneList[0];
              reportedVariant.selectedAAChangeknownGene = selectedAA.split(":")[3];
              reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ selectedAA.split(":")[4];// reportedVariant.selectedAAChangeknownGene = reportedVariant.aachangeknownGeneList[0];
              reportedVariant.exonNumber = reportedVariant.aachangeknownGeneList[0].split(":")[2].substring(4);
              // console.log("reportedVariant.selectedAAChangeknownGene null", reportedVariant.selectedAAChangeknownGene);
            }
          }
        }
        // console.log("==>", aachangeknownGene, reportedVariant.ensembleValue);


      });
      console.log("JJJJ",reportedVariant)
      if (reportedVariant.significance == 'NA' || reportedVariant.significance =='Not Classified') {
        this.count++;
      }
      if (reportedVariant.significance != "NA" && reportedVariant.significance !='Not Classified') {
        this.countOther++;
      }

      if (this.count == 0) {
        this.WTcountSNV = true;
      }
      if (this.countOther > 0) {
        this.otherCountSNV = true;
      }
    });
    // console.log('NA.count: ', this.WTcountSNV, '\n Other.count: ', this.otherCountSNV);
  }

}
