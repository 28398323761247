<div class="mx-3">
  <div class="variant_view p-4 rounded cust_shadow my-3 d-inline-block w-100 bg-white ">
    <div class="variant_title d-flex justify-content-between  align-items-center ">
      <h5 class="font-weight-bold mb-0">{{reportedVariant.report?.patient?.ecrfId}}
         <span >[{{reportedVariant?.refGeneX}}
          <span *ngIf="reportedVariant.selectedAAChangeknownGene != null" >
            ({{reportedVariant.selectedAAChangeknownGene.split(",")[1]}})</span>]</span></h5>
        <button (click)="saveVariant()" *ngIf="btnShow" type="button" class="btn sm btn-primary text-white ml-5">
            Save Variant
        </button>
      <!-- <span class="back_icon"><a [routerLink]="[ '/dash-reports', reportId ]"  [state]="{ snvIsLinkActive: 0 }"><i
                    class="zmdi zmdi-chevron-left"></i></a>
        </span>
        <div>
            <ul class="list-unstyled d-flex">
                <li> Chrom </li>
                <li> Pos </li>
                <li> End </li>
            </ul>
        </div> -->
        <div class="ml-auto clinical_trail-title" *ngIf="!isGermline">
          <a *ngIf="this.nstudiesFound == 0 && reportedVariant.report.reportType.name != 'First' && reportedVariant.report.reportType.name !='First 72 Solid' && reportedVariant.report?.reportType?.name !='First 72 Liquid' && reportedVariant.report?.reportType?.name !='Lung Liquid Basic' &&  reportedVariant.report?.reportType?.name !='Lung Liquid Advanced' && reportedVariant.report.reportType.name != 'HRR Somatic' "
             [routerLink]="[ '/trail_list', reportedVariantId]">No Clinical Trials</a>
            <a *ngIf="this.nstudiesFound > 0 && reportedVariant.report.reportType.name != 'First' && reportedVariant.report.reportType.name !='First 72 Solid' && reportedVariant.report?.reportType?.name !='First 72 Liquid' && reportedVariant.report.reportType.name != 'HRR Somatic' && reportedVariant.report?.reportType?.name !='Lung Liquid Basic' &&  reportedVariant.report?.reportType?.name !='Lung Liquid Advanced' "
                 [routerLink]="[ '/trail_list', reportedVariantId]">Select
                ClinicalTrial </a>
            <a *ngIf="this.clinicalTrialResList.length > 0 && reportedVariant.report.reportType.name != 'First' && reportedVariant.report.reportType.name !='First 72 Solid' && reportedVariant.report?.reportType?.name !='First 72 Liquid' && reportedVariant.report.reportType.name != 'HRR Somatic' && reportedVariant.report?.reportType?.name !='Lung Liquid Basic' &&  reportedVariant.report?.reportType?.name !='Lung Liquid Advanced'" class="delete_clr "
                [routerLink]="[ '/fda_trail_list', reportedVariantId]">| Select FDA/NCCN Approved </a>
            <a *ngIf="(hotspotList.length > 0) && (reportedVariant.report.reportType.name == 'First' || reportedVariant.report.reportType.name =='First 72 Solid' || reportedVariant.report?.reportType?.name =='First 72 Liquid' || reportedVariant.report?.reportType?.name =='Lung Liquid Basic' ||  reportedVariant.report?.reportType?.name =='Lung Liquid Advanced')" class="delete_clr "
                [routerLink]="[ '/fda_trail_list', reportedVariantId]">Select Hotspot Drugs</a>
            <a *ngIf="(hotspotList.length <= 0) && (reportedVariant.report.reportType.name == 'First' || reportedVariant.report.reportType.name =='First 72 Solid' || reportedVariant.report?.reportType?.name =='First 72 Liquid' || reportedVariant.report?.reportType?.name =='Lung Liquid Basic' ||  reportedVariant.report?.reportType?.name =='Lung Liquid Advanced')"
                  [routerLink]="[ '/fda_trail_list', reportedVariantId]">No Clinical Trials</a>
            <a *ngIf="(reportedVariant.report.reportType.name == 'HRR Somatic')" class="delete_clr "
                [routerLink]="[ '/fda_trail_list', reportedVariantId]">Select Hotspot Drugs</a>
        </div>
    </div>

    <!-- Variant detail -->
    <div class="row mb-5  mt-3 mx-1 p-4  cust_shadow bg-white">
        <h5 class="mb-3 text_color ">Variant details</h5>
        <div class="w-100"></div>
        <div class="col-3 border p-4">
            <p>Chromosome No
              <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='chrom')" (click)="edit('chrom')"></i> <i *ngIf="varient=='chrom'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='chrom')">{{reportedVariant.chrom}}</h6>
            <span *ngIf="varient=='chrom'"><textarea type="text" class="form-control" name="chrom" [(ngModel)]="reportedVariant.chrom"></textarea></span>
        </div>
        <div class="col-3 border p-4">
            <p>Start position
              <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='pos')" (click)="edit('pos')"></i> <i *ngIf="varient=='pos'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='pos')">{{reportedVariant.pos}}</h6>
            <span *ngIf="varient=='pos'"><textarea type="text" class="form-control" name="pos" [(ngModel)]="reportedVariant.pos"></textarea></span>
        </div>
        <div class="col-3 border p-4">
            <p>End position
              <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='end')" (click)="edit('end')"></i> <i *ngIf="varient=='end'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='end')">{{reportedVariant.end}}</h6>
            <span *ngIf="varient=='end'"><textarea type="text" class="form-control" name="end" [(ngModel)]="reportedVariant.end"></textarea></span>
        </div>
        <div class="col-3 border p-4">
            <p>Reference allele
              <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='refx')" (click)="edit('refx')"></i> <i *ngIf="varient=='refx'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='refx')">{{reportedVariant.refX}}</h6>
            <span *ngIf="varient=='refx'"><textarea type="text" class="form-control" name="refx" [(ngModel)]="reportedVariant.refX"></textarea></span>
        </div>
        <div class="col-3 border p-4">
            <p>Altered allele
              <span style="float: right;" class="varient-edit" *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='altx')" (click)="edit('altx')"></i> <i *ngIf="varient=='altx'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='altx')">{{reportedVariant.altX}}</h6>
            <span *ngIf="varient=='altx'"><textarea type="text" class="form-control" name="altx" [(ngModel)]="reportedVariant.altX"></textarea></span>
        </div>
    </div>

    <!-- Gene -->
    <!-- <div class="row mb-5  mt-3 mx-1 p-4  cust_shadow bg-white">
        <h5 class="mb-3 text_color ">Gene</h5>
        <div class="w-100"></div>
        <div class="col-3 border p-4">
            <p>Ref.Gene_x  <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='refGeneX')" (click)="edit('refGeneX')"></i> <i *ngIf="varient=='refGeneX'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='refGeneX')">{{reportedVariant.refGeneX}}</h6>
            <span *ngIf="varient=='refGeneX'"><textarea type="text" class="form-control" name="refGeneX" [(ngModel)]="reportedVariant.refGeneX"></textarea></span>
        </div>
    </div> -->

    <!-- Consequences -->
    <div class="row mb-5 mt-3 mx-1 p-4  cust_shadow bg-white">
        <h5 class="mb-3 text_color">Consequences</h5>
        <div class="w-100"></div>
        <div class="col-3 border p-4 break-word">
            <p>Variant Consequences
              <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='funcknownGene')" (click)="edit('funcknownGene')"></i> <i *ngIf="varient=='funcknownGene'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='funcknownGene')">{{reportedVariant.funcknownGene}}</h6>
            <span *ngIf="varient=='funcknownGene'"><textarea type="text" class="form-control" name="funcknownGene" [(ngModel)]="reportedVariant.funcknownGene"></textarea></span>
        </div>
        <div class="col-3 border p-4 break-word">
            <p>Variant type
              <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='exonicFuncknownGene')" (click)="edit('exonicFuncknownGene')"></i> <i *ngIf="varient=='exonicFuncknownGene'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='exonicFuncknownGene')">{{reportedVariant.exonicFuncknownGene}}</h6>
            <span *ngIf="varient=='exonicFuncknownGene'"><textarea type="text" class="form-control" name="exonicFuncknownGene" [(ngModel)]="reportedVariant.exonicFuncknownGene"></textarea></span>
        </div>
        <div class="col-3 border p-4 break-word">
            <p>c. position and p. position
              <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='selectedAAChangeknownGene')" (click)="edit('selectedAAChangeknownGene')"></i> <i *ngIf="varient=='selectedAAChangeknownGene'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='selectedAAChangeknownGene')">{{reportedVariant.selectedAAChangeknownGene}}</h6>
            <span *ngIf="varient=='selectedAAChangeknownGene'"><textarea type="text" class="form-control" name="selectedAAChangeknownGene" [(ngModel)]="reportedVariant.selectedAAChangeknownGene"></textarea></span>
        </div>
        <!-- <div class="col-3 border p-4 break-word">
            <p>interproDomain</p>
            <h6>{{reportedVariant.interproDomain}}</h6>
        </div> -->
        <div class="col-3 border p-4 break-word">
            <p>Exon No.<span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='exonNumber')" (click)="edit('exonNumber')"></i> <i *ngIf="varient=='exonNumber'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='exonNumber')">{{reportedVariant.exonNumber}}</h6>
            <span *ngIf="varient=='exonNumber'"><textarea type="text" class="form-control" name="exonNumber" [(ngModel)]="reportedVariant.exonNumber"></textarea></span>
        </div>
         <div class="col-3 border p-4 break-word">
            <p>Gene role
            </p>
            <h6></h6>
        </div>
    </div>

    <!-- Clinical Significance -->
    <div class="row mb-5 mt-3 mx-1 p-4  cust_shadow bg-white">
        <h5 class="mb-3 text_color">Clinical Significance</h5>
        <div class="w-100"></div>
        <div class="col-3 border p-4 break-word">
            <p>Annovar
            </p>
            {{reportedVariant.avsnp150}}
        </div>
        <!-- <div class="col-3 border p-4 break-word">
            <p>clndn</p>
            {{reportedVariant.clndn}}
        </div>
        <div class="col-3 border p-4 break-word">
            <p>clndisdb</p>
            {{reportedVariant.clndisdb}}
        </div> -->
        <div class="col-3 border p-4 break-word">
            <p>clinvar</p>
            {{reportedVariant.clinvar}}
        </div>
        <div class="col-3 border p-4 break-word">
            <p>interVarAutomated</p>
            {{reportedVariant.interVarAutomated}}
        </div>
        <!-- <div class="col-3 border p-4 break-word">
            <p>intervarInhouse</p>
            {{reportedVariant.intervarInhouse}}
        </div> -->
        <div class="col-3 border p-4 break-word">
            <p>CancerVar</p>
            {{reportedVariant.cancerVar}}
        </div>
    </div>
     <!-- POPULATION FREQUENCY -->
     <div class="row mb-5 mt-3 mx-1 p-4  cust_shadow bg-white">
      <h5 class="mb-3 text_color ">POPULATION FREQUENCY</h5>
      <div class="w-100"></div>
      <div class="col-3 border p-4">
          <p>Exome Sequencing Project 6500 database (All population)
          </p>
          <h6>{{reportedVariant.esp6500siv2All}}</h6>
      </div>
      <div class="col-3 border p-4">
          <p>Exome Aggregation Consortium (All population)
          </p>
          <h6>{{reportedVariant.exAC_ALL}}</h6>
      </div>
      <div class="col-3 border p-4">
          <p>Exome Aggregation Consortium(South Asian Specific)
          </p>
          <h6>{{reportedVariant.exAC_SAS}}</h6>
      </div>
      <div class="col-3 border p-4">
          <p>Allele Frequency (All population)
          </p>
          <h6>{{reportedVariant.af}}</h6>
      </div>
      <div class="col-3 border p-4">
          <p>Allele Frequency (South Asian Specific)
          </p>
          <h6>{{reportedVariant.afSas}}</h6>
      </div>
      <div class="col-3 border p-4">
          <p>1000 Genomes Project 2015 (All population)
          </p>
          <h6>{{reportedVariant.kg2015AugAll}}</h6>
      </div>
      <div class="col-3 border p-4">
          <p>1000 Genomes Project 2015 (South Asian Specific)
          </p>
          <h6>{{reportedVariant.kg2015AugSAS}}</h6>
      </div>
      <div class="col-3 border p-4">
        <p>Genome Aggregation database (All population)</p>
        <h6></h6>
    </div>
  </div>
   <!-- INSILICO PREDICTION -->
   <div class="row mb-5 mt-3 mx-1 p-4  cust_shadow bg-white">
    <h5 class="mb-3 text_color">INSILICO PREDICTION</h5>
    <div class="w-100"></div>
    <div class="col-3 border p-4">
        <p>CADD score</p>
        <h6>{{reportedVariant.cadd13Phred}}</h6>
    </div>
    <div class="col-3 border p-4">
        <div><span>SIFT</span>
          <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
            <span class="tooltiptext">
              <ul class="mb-0 list-unstyled">
                <li>Deleterious - D</li>
                <li>Tolerated - T</li>
                <li>Unknown - </li>
              </ul>

            </span>
          </div>
        </div>
        <h6>{{reportedVariant.siftPred}}</h6>
    </div>
    <div class="col-3 border p-4">

        <div><span>Polyphen</span>
          <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
            <span class="tooltiptext">
              <ul class="mb-0 list-unstyled">
                <li>Probably Damaging - B</li>
                <li>Possibly Damaging - D</li>
                <li>Benign -  P  </li>
                <li>Unknown - </li>
              </ul>

            </span>
          </div>
        </div>
        <h6>{{reportedVariant.polyphen2HvarPred}}</h6>
    </div>
    <div class="col-3 border p-4">
        <div><span>Mutation taster</span>
          <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
            <span class="tooltiptext">
              <ul class="mb-0 list-unstyled">
                <li>disease_causing_automatic - A</li>
                <li>disease_causing  - D</li>
                <li>polymorphism -  N  </li>
                <li>polymorphism_automatic - P</li>
                <li>Unknown - </li>
              </ul>

            </span>
          </div>
        </div>
        <h6>{{reportedVariant.mutationTasterPred}}</h6>
    </div>
    <div class="col-3 border p-4">
        <div><span>FATHMM</span>
          <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
            <span class="tooltiptext">
              <ul class="mb-0 list-unstyled">
                <li>Deleterious - D</li>
                <li>Tolerated - T</li>
                <li>Unknown - </li>
              </ul>

            </span>
          </div>
        </div>
        <h6>{{reportedVariant.fathmmPred}}</h6>
    </div>
    <div class="col-3 border p-4">
        <div><span>MetaSVM</span>
          <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
            <span class="tooltiptext">
              <ul class="mb-0 list-unstyled">
                <li>Deleterious - D</li>
                <li>Tolerated - T</li>
                <li>Unknown - </li>
              </ul>

            </span>
          </div>
        </div>
        <h6>{{reportedVariant.metaSvmPred}}</h6>
    </div>
    <div class="col-3 border p-4">
        <div><span>MetaLR</span>
          <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
            <span class="tooltiptext">
              <ul class="mb-0 list-unstyled">
                <li>Deleterious - D</li>
                <li>Tolerated - T</li>
                <li>Unknown - </li>
              </ul>

            </span>
          </div>
        </div>
        <h6>{{reportedVariant.metaLrPred}}</h6>
    </div>



</div>

    <!-- Other information -->
    <div class="row mb-5 mt-3 mx-1 p-4  cust_shadow bg-white">
        <h5 class="mb-3 text_color">Other information</h5>
        <div class="w-100"></div>
        <!-- <div class="col-3 border p-4">
            <p>omim</p>
            <h6>{{reportedVariant.omim}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>pathway</p>
            <h6>{{reportedVariant.pathway}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>therapList</p>
            <h6>{{reportedVariant.therapList}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>diagList</p>
            <h6>{{reportedVariant.diagList}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>progList</p>
            <h6>{{reportedVariant.progList}}</h6>
        </div> -->
        <div class="col-3 border p-4">
            <p>Ensemble Value <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='ensembleValue')" (click)="edit('ensembleValue')"></i> <i *ngIf="varient=='ensembleValue'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='ensembleValue')">{{reportedVariant.ensembleValue}}</h6>
            <span *ngIf="varient=='ensembleValue'"><textarea type="text" class="form-control" name="altx" [(ngModel)]="reportedVariant.ensembleValue"></textarea></span>
        </div>
        <div class="col-3 border p-4">
            <p>IGV Link <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='igvLink')" (click)="edit('igvLink')"></i> <i *ngIf="varient=='igvLink'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='igvLink')">{{reportedVariant.igvLink}}</h6>
            <span *ngIf="varient=='igvLink'"><textarea type="text" class="form-control" name="altx" [(ngModel)]="reportedVariant.igvLink"></textarea></span>
        </div>
        <div class="col-3 border p-4">
            <p>Genomic Alteration <span style="float: right;" class="varient-edit"  *ngIf="showEdit"><i class="zmdi zmdi-edit" *ngIf="!(varient=='genomicAlteration')" (click)="edit('genomicAlteration')"></i> <i *ngIf="varient=='genomicAlteration'" (click)="close()" class="zmdi zmdi-close"></i></span></p>
            <h6 *ngIf="!(varient=='genomicAlteration')" style="overflow-wrap: break-word;">{{reportedVariant.genomicAlteration}}</h6>
            <span *ngIf="varient=='genomicAlteration'"><textarea type="text" class="form-control" name="altx" [(ngModel)]="reportedVariant.genomicAlteration"></textarea></span>
        </div>
    </div>





    <!-- Variant quality -->
    <!-- <div class="row mb-5 mt-3 mx-1 p-4  cust_shadow bg-white">
        <h5 class="mb-3 text_color">Variant quality</h5>
        <div class="w-100"></div>
        <div class="col-3 border p-4">
            <p>infoMq</p>
            <h6>{{reportedVariant.infoMq}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeAd</p>
            <h6>{{reportedVariant.tIeAd}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeAf</p>
            <h6>{{reportedVariant.tIeAf}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeDp</p>
            <h6>{{reportedVariant.tIeDp}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeFt</p>
            <h6>{{reportedVariant.f1r2}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeGt</p>
            <h6>{{reportedVariant.tIeGt}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeMb</p>
            <h6>{{reportedVariant.tIeMb}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeObam</p>
            <h6>{{reportedVariant.tIeObam}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeObamrc</p>
            <h6>{{reportedVariant.tIeObamrc}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeObf</p>
            <h6>{{reportedVariant.tIeObf}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeObp</p>
            <h6>{{reportedVariant.tIeObp}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeObq</p>
            <h6>{{reportedVariant.tIeObq}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeObqrc</p>
            <h6>{{reportedVariant.tIeObqrc}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIePs</p>
            <h6>{{reportedVariant.tIePs}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeSb</p>
            <h6>{{reportedVariant.tIeSb}}</h6>
        </div>
        <div class="col-3 border p-4">
            <p>tIeSq</p>
            <h6>{{reportedVariant.tIeSq}}</h6>
        </div>

    </div> -->

    <!--drop downs   -->
    <div class="row ml-2 mb-4">
        <div class="col-3">
            <h5 class=" text_color ">*Clinical Siginificance</h5>
            <select class="form-control" name="significance" [(ngModel)]="reportedVariant.significance" (click)="getGeneDescription()" >
                <option value="Benign">Benign</option>
                <option value="Pathogenic">Pathogenic</option>
                <option value="Likely Pathogenic">Likely Pathogenic</option>
                <option value="VUS">VUS</option>
                <option value="Not Classified">Not Classified</option>
            </select>
        </div>

        <div class="col-3" *ngIf="reportedVariant.significance!='NA'" >
            <h5 class="text_color ">Reference</h5>
            <input type="text" class="form-control" name="reference" [(ngModel)]="reportedVariant.reference">
        </div>

        <div class="col-3" *ngIf="reportedVariant.significance!='NA'" >
            <h5 class="text_color ">Alternative Allele Depth</h5>
            <input type="text" class="form-control" name="reference" [(ngModel)]="reportedVariant.mutantAlt">
        </div>

        <div class="col-2" *ngIf="reportedVariant.significance!='NA'" >
            <h5 class="text_color ">Total Depth</h5>
            <input type="text" class="form-control" name="reference" [(ngModel)]="reportedVariant.mutantRef">
        </div>

        <div *ngIf="isGermline" class="col-3 mt-2">
            <h5 class=" text_color ">Zygosity</h5>
            <select class="form-control" name="significance" [(ngModel)]="reportedVariant.zygosity">
                <option value="Homozygous">Homozygous</option>
                <option value="Heterozygous">Heterozygous</option>
                <option value="Compound Heterozygous">Compound Heterozygous</option>
                <option value="Hemizygous">Hemizygous</option>
            </select>
        </div>

        <div *ngIf="isGermline" class="col-3 mt-2">
            <h5 class=" text_color ">Mode of Inheritance</h5>
            <select class="form-control" name="significance" [(ngModel)]="reportedVariant.inheritanceMode">
                <option value="Autosomal Dominant">Autosomal Dominant</option>
                <option value="Autosomal Recessive">Autosomal Recessive</option>
                <option value="X-linked">X-linked</option>
                <option value="Autosomal Dominant/ Autosomal Recessive">Autosomal Dominant/ Autosomal Recessive</option>
                <option value="Others">Others</option>
            </select>
        </div>

        <div *ngIf="isGermline" class="col-3 mt-2">
            <h5 class=" text_color ">Cancer Type</h5>
            <input class="form-control" name="cancerType" [(ngModel)]="reportedVariant.cancerType">
        </div>


    </div>

    <div class="row ml-2 mb-4" *ngIf="reportedVariant?.report?.reportType?.name != 'First'">
        <div class="col">
            <h5 class=" text_color ">Gene Description ({{gene?.name}})</h5>
           <p> {{gene?.description}}</p>
        </div>
    </div>

    <div class="row ml-2 mb-4" *ngIf="reportedVariant.report?.reportType?.name != 'First'">
        <div class="col">
           <div class="d-flex justify-content-between align-items-center  my-1">
            <h5 class="text_color">Add Gene Description</h5>
            <!-- <textarea type="text" class="form-control" style="height: 200px;" name="addGeneDescription"
                [(ngModel)]="reportedVariant.addGeneDescription"></textarea> -->
                <div  *ngIf="reportedVariant.addGeneDescription!=null ||reportedVariant.addGeneDescription!=undefined" >
                  <P *ngIf="reportedVariant.addGeneDescription.length"><strong>[{{editorText.length}}/2000]</strong></P>
                </div>
           </div>
                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                name="addGeneDescription" [(ngModel)]="reportedVariant.addGeneDescription" (keyup)="textcount('addGeneDescription-editor')"></ckeditor>
                <div class="addGeneDescription-editor" hidden="true" [innerHtml]="reportedVariant.addGeneDescription"  ></div>
                <p class="pt-2"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
        </div>
    </div>
    <div class="row ml-2 mb-4" *ngIf="reportedVariant.report?.reportType?.name == 'First'">
      <div class="col">
          <h5 class="text_color ">Additional Notes</h5>
              <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
              name="addNotes" [(ngModel)]="reportedVariant.addNotes"></ckeditor>
      </div>
  </div>

    <div class="form-group row ml-2 mb-4" *ngIf="reportedVariant.report?.reportType?.name != 'First'">
      <div class="col">
          <h5>PMIDS:</h5>
          <input *ngIf="isFirst72" type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
              [(ngModel)]="reportedVariant.variantPmIds">
          <input *ngIf="!isFirst72" type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
              [(ngModel)]="pmIds">

      </div>
  </div>

  <div class="row ml-2 mb-4" *ngIf="isFirst72">
    <div class="col-3">
        <h5 class=" text_color ">*Prognostic Siginificance</h5>

        <select class="form-control" name="significance" [(ngModel)]="reportedVariant.prognasticSignificance" (ngModelChange)="getPrognasticTag($event)" >
            <option value="Poor">Poor</option>
            <option value="Good">Good</option>
            <option value="NA">NA</option>

        </select>
    </div>

    <div class="col-3" >
        <h5 class=" text_color ">*Prognostic Tag</h5>
        <ng-multiselect-dropdown [settings]="prognasticBiomarkerDropdownSettings" [data]="prognasticTagList"  [(ngModel)]="selectedPrognasticBiomarker" (ngModelChange)="selectPrognasticBiomarker($event)" name="prognasticTag"
        >
        </ng-multiselect-dropdown>
    </div>
    <div class="col">
    </div>
</div>


<div class="row ml-2 mb-4" *ngIf="isFirst72">
    <div class="col">
       <div class="d-flex justify-content-between align-items-center  my-1">
        <h5 class="text_color">Add Prognostic Description</h5>
        <!-- <textarea type="text" class="form-control" style="height: 200px;" name="addGeneDescription"
            [(ngModel)]="reportedVariant.addGeneDescription"></textarea> -->
            <div  *ngIf="reportedVariant.prognasticDescription!=null ||reportedVariant.prognasticDescription!=undefined" >
              <P *ngIf="reportedVariant.prognasticDescription.length"><strong>[{{editorText.length}}/2000]</strong></P>
            </div>
       </div>
            <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
            name="addPrognasticDescription" [(ngModel)]="reportedVariant.prognasticDescription" (keyup)="textcount('addPrognasticDescription-editor')"></ckeditor>
            <div class="addPrognasticDescription-editor" hidden="true" [innerHtml]="reportedVariant.prognasticDescription"  ></div>
            <p class="pt-2"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
    </div>
</div>
    <div class="row">
        <div class="col">
            <button type="button" [disabled]="isSubmited" class="btn save_btn" style="float:right"
                (click)="submitDetails()">
                <span *ngIf="!isSubmited"> Save </span>
                <div *ngIf="isSubmited" class="spinner-border text-dark">
                    <span class="sr-only"></span>
                </div>
            </button>
        </div>
    </div>


</div>

</div>
