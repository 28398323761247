import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gene, TruncationDetails } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-truncation-details',
  templateUrl: './truncation-details.component.html',
  styleUrls: ['./truncation-details.component.css']
})
export class TruncationDetailsComponent implements OnInit {

  editor = ClassicEditor;

  truncationDetailsSpinner:boolean=true;
  addTruncationDetails = {} as TruncationDetails;
  truncationDetailsList: TruncationDetails[] | undefined;
  statusMsg: string | undefined;
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) { }
  geneDropdownSettings = {};

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }
  showError(msg:any) {
    this.toastr.error(msg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {
    this.getTruncationDetails();
    this.getGenes();
    this.geneDropdownSettings = {
      singleSelection: true,
      idField: 'name',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  addTruncationDetail(){
    console.log("Add Truncation Details :  ",this.addTruncationDetails);
    this.dataAdminService.addTruncationDetails(this.addTruncationDetails).subscribe(
      (response: any) => {
        console.log("response add",response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
        (error) =>{
          console.log(error);
            this.showError(error.error.message);
        });
  }
  geneList:Gene[] | any|undefined;

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  deleteTruncationDetails(){
    console.log("edit Truncation Details =>",this.addTruncationDetails);
    this.dataAdminService.deleteTruncationDetails(this.addTruncationDetails.id).subscribe(
      (response: any) => {
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }
  uploadFileInput=false;
  onFileChanges(event: any) {
    const files = event.target.files;
    const file = files[0];
    if(file){
      this.uploadFileInput = true;
    }
    // this.fileName = file.name;
    this.uploadFile(file);
  }
  uploadFile(file: any) {
    console.log("evt=>",file)
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.dataAdminService
      .uploadAndGetTruncationDetailsDataFromExcel(formdata)
      .subscribe((response: any) => {
        console.log("response=>\n",response)
        console.log("list of not uploaded=>\n",response['payLoad'])
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
}

  assignTruncationDetails(truncationDetails: TruncationDetails){
    this.addTruncationDetails = truncationDetails;
    console.log("edit Truncation Details =>",this.addTruncationDetails);
  }

  getTruncationDetails(){
    this.dataAdminService.getTruncationDetailsList().subscribe(
      (response: any) => {
        this.truncationDetailsSpinner=false
        this.truncationDetailsList = response['payLoad'];
        console.log("list Truncation Details =>",this.truncationDetailsList);
      }
    );
  }

  closeModal(){
    console.log("close modal")
    this.addTruncationDetails = {} as  TruncationDetails;
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/truncation_details']);
    });
  }

}
