import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RecurrenceVariantReqDto } from '../_interfaces.ts/admin';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

@Injectable({
  providedIn: 'root'
})
export class RecurrenceVariantSerciceService {

  constructor(
    private httpClient: HttpClient
  ) { }

  addRecurrenceVariant(body:RecurrenceVariantReqDto){
    return this.httpClient.post('/admin/rv/add_recurrence_variant',body);
  }

  getRecurrenceVariantsList(){
    return this.httpClient.get('/admin/rv/get_recurrence_variant_list');
  }

  getRecurrenceVariantById(id:number){
    return this.httpClient.get('/admin/rv/'+id+'/get_recurrence_variant');
  }

  deleteRecurrenceVariant(id:number){
    return this.httpClient.get('/admin/rv/'+id+'/delete_recurrence_variant');
  }
  uploadAndGetRecurrenceVariantFromExcel(params: any){
    return this.httpClient.post('/admin/rv/get_recurrence_variant_upload', params);
  }

  async generateExcel() {
    const header = [
      'Sample ID',
      'Type of Test',
      'Panel',
      'Cancer Type Name',
      'Gene Name',
      'Refernce Link',
      'Refernce PMIDs',
      'c.Position',
      'p.Position',
      'Genimic Coordinates',
      'ENST Id',
      'DB SNP ID',
      'Clinical Significance',
      'Prognostic Significance',
    ];

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sharing Data');

    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, number) => {
      headerRow.font = {
        name: 'Corbel',
        family: 4,
        size: 12,
        bold: true,
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });


    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);


    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, 'RecurrenceVaiantFormatExcel.xlsx');
    });
  }
}
