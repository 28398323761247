<div class="container-fluid px-0">
    <h5 class="mb-0 d-inline-block text_color mb-3 mt-4">Add Clinical Trial</h5>
   <div class="mx-0 p-4 bg-white cust_shadow rounded w-75">
    <div class="row mx-3">
        <div class="col">
            <form class="form_list" (ngSubmit)="onSubmit()">
                <div class="row mt-5">
                    <div class="col-10">
                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Tag :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="interpretationTag"
                                [(ngModel)]="add_interpretation.interpretationTag">
                            </div>
                        </div>
                        
                        <div class="form-group row">
                          <label class="col-sm-4 control-label">Type of Test :</label>
                          <div class="col-sm-8">
                            <select type="text" class="form-control" name="testType"
                            [(ngModel)]="add_interpretation.testType">
                            <option value="SOMATIC">SOMATIC</option>
                            <option value="GERMLINE">GERMLINE</option>
                        </select>
                          </div>
                      </div>
                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Cancer Type :</label>
                            <div class="col-sm-8">
                                <ng-multiselect-dropdown [data]="cancerTypeList" [(ngModel)]="selectedcancertype" name="cancerType"
                                    [settings]="cancerTypeDropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 control-label">Add PMIDS:</label>
                          <div class="col-sm-8">
                              <input type="text" class="form-control" name="interpretationTag"
                              [(ngModel)]="add_interpretation.referencePmids">
                          </div>
                      </div>
                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Reference :</label>
                            <div class="col-sm-8">
                              <textarea type="text" class="form-control" cols="15" rows="3"  name="reference"
                                [(ngModel)]="add_interpretation.reference"></textarea>
                            </div>
                        </div>
                        <!-- <div class="form-group row"> 
                          <label class="col-sm-4 control-label">Add PMIDS :</label>
                          <div class="col-sm-8">
                              <input type="text" class="form-control" name="pmids"
                              [(ngModel)]="add_interpretation.referencePmids">
                          </div>
                      </div> -->

                        <div class="form-group row add_interpretation">
                          <label class="col-sm-4 control-label"> <a (click)="addGeneVariant()" class="font_14">Add Gene Variant <i class="zmdi zmdi-plus ml-2"></i></a> :</label>

                            <div class="col-sm-8">
                              <div *ngFor="let add_geneVariant of addGeneVarinats.addGeneVarinat; let i = index ">
                            <!-- <div class="form-group row">
                              <label for="" class="col-sm-1 col-form-label">Gene: </label>
                              <div class="col-sm position-relative">
                                <select type="text" class="form-control" name="tumorWidth_{{ i }}"
                                  [(ngModel)]="add_geneVariant.geneId">
                                  <option *ngFor="let gene of geneList" value="{{ gene.id }}">
                                    {{ gene.name }}
                                  </option>
                                </select>
                              </div>
                              <label class="col-sm col-form-label">Variant :</label>
                              <div class="col-sm position-relative">
                                <input type="text" name="tumorLength_{{ i }}" class="form-control"
                                  [(ngModel)]="add_geneVariant.variant" />
                              </div>
                              <div class="col-sm-1 position-relative">
                                <a (click)="removeGeneVariant(i)"><i class="zmdi zmdi-close"></i></a>
                              </div>
                            </div> -->
                            <div class="form-group row py-2 mx-0 pr-0" style="border: 1px solid #d5dadf ;padding:5px;border-radius: 5px;">
                              <label for="" class="col-sm-3 col-form-label">Gene :</label>
                              <div class="col-sm-3 position-relative mb-1 pr-0">
                                <select type="text" class="form-control" name="tumorWidth_{{ i }}" [(ngModel)]="add_geneVariant.geneId">
                                  <option *ngFor="let gene of geneList" value="{{ gene.id }}">
                                    {{ gene.name }}
                                  </option>
                                </select>
                              </div>
                              <label class="col-sm-3 col-form-label mb-1">Variant :</label>
                              <div class="col-sm-3 position-relative pl-0">
                                <input type="text" name="tumorLength_{{ i }}" class="form-control" [(ngModel)]="add_geneVariant.variant" />
                              </div>
                              <div class="w-100"></div>
                              <label for="" class="col-sm-3 col-form-label">Add PMIDS :</label>
                              <div class="col-sm position-relative" style="margin-bottom:4px;">
                                <input type="text" name="tumorLength_Pmids_{{ i }}" class="form-control" [(ngModel)]="add_geneVariant.referencePmids" />
                              </div>
                              <div class="w-100"></div>
                              <label for="" class="col-sm-3 col-form-label">Reference :</label>
                              <div class="col-sm position-relative">
                                <textarea type="text" class="form-control" cols="15" rows="5" name="tumorLength_reference_{{ i }}"
                                [(ngModel)]="add_geneVariant.reference"> </textarea>
                                <!-- <input type="text" name="tumorLength_{{ i }}" class="form-control" [(ngModel)]="add_geneVariant.reference" /> -->
                              </div>
                              <!-- btn close -->
                              <div class="btn_close" (click)="removeGeneVariant(i)">
                                <a ><i class="zmdi zmdi-close"></i></a>
                              </div>


                            </div>

                            </div>
                            </div>

                        </div>




                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Description :</label>
                            <div class="col-sm-8 ">
                              <div style="text-align: right;" *ngIf="add_interpretation.description!=null || add_interpretation.description!=undefined" >
                                <P *ngIf="add_interpretation.description.length"><strong>[{{editorText.length}}/11000]</strong></P>
                              </div>
                                <ckeditor [editor]="editor" name="description" [(ngModel)]="add_interpretation.description" (keyup)="textcount('description-editor')"></ckeditor>
                                <div class="description-editor" hidden="true" [innerHtml]="add_interpretation.description"  ></div>
                            </div>
                        </div>





                        <div class="add-emp  mb-5">
                            <div class="col-sm-8 offset-sm-4 px-0 text-right">
                                <button type="submit" class="btn save_btn text-uppercase bg-softblue ml-2"
                                    [disabled]="submitSpinner">
                                    <span *ngIf="!submitSpinner"> Submit </span>
                                    <div *ngIf="submitSpinner" class="spinner-border text-dark">
                                        <span class="sr-only"></span>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </form>
        </div>
    </div>
   </div>
</div>
