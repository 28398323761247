import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-forpdf',
  templateUrl: './demo-forpdf.component.html',
  styleUrls: ['./demo-forpdf.component.css']
})
export class DemoForpdfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
