<div class="row bg-white py-3 px-4 mx-0 cust_shadow top_bar">
  <div class="col">
    <div class="text-decoration-none">
      <h4 class="mb-0 font-weight-bold pt-1" style="color: #001a56">
        Work bench
      </h4>
    </div>
  </div>
  <div class="col">
    <div class="head-name text-right">
      <!-- <div class="add-new">
             <a href="/general_info" class="btn btn-outline-light">Add Patient</a>
         </div> -->
      <div class="dropdown">
        <button
          class="btn position-relative font-weight-bold pr-0"
          type="button"
          data-toggle="dropdown"
        >
          {{ appUser.fullName }}
          <i class="zmdi zmdi-account-circle admin-icon ml-1"></i>
          <!-- <i class="zmdi zmdi-caret-down ml-2"></i> -->
          <!-- <span class="caret"></span> -->
        </button>
        <ul class="dropdown-menu cust-drop text-decoration-none px-1">
          <li><a href="/reports">Reports</a></li>
          <!-- <li><a href="#">New Reports</a></li> -->
          <li *ngIf="appUser.role == 'ROLE_SUPER_ADMIN'">
            <a [routerLink]="['/super_admin']">Data Management</a>
          </li>
          <li>
            <a (click)="logout()"
              ><i class="zmdi zmdi-power mr-1 font-weight-bold"></i> Logout</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-5 pt-5">
  <router-outlet></router-outlet>
</div>
