import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoverageService {

  constructor(
    private httpClient: HttpClient
  ) { }

  uploadCoverageExcel(params: any,reportId: Number) {
    return this.httpClient.post('/coverage/' + reportId + '/upload_excel_file', params);
  }

  getAllCoverageListByReportId(reportId: number){
    return this.httpClient.get('/coverage/' + reportId + '/get_coverage_variants');
  }

  coverageVariantStatus(coverageVariantId: number, aBoolean: boolean){
    return this.httpClient.post('/coverage/' + coverageVariantId + '/variant_status' ,aBoolean);
  }

  setAllCoverageVariantStatus(reportId: number, aBoolean: boolean){
    return this.httpClient.post('/coverage/' + reportId + '/all_variant_status' ,aBoolean);
  }

  addCoverageSignificance(coverageVariantId: number, significance: string){
    return this.httpClient.post('/coverage/' + coverageVariantId + '/add_significance' ,significance);
  }
}
