import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CancerType, Gene, RecurrenceVariantReqDto, RecurrenceVariant,  } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { RecurrenceVariantSerciceService } from 'src/app/_services/recurrence-variant-sercice.service';

@Component({
  selector: 'app-add-recurrence-variant',
  templateUrl: './add-recurrence-variant.component.html',
  styleUrls: ['./add-recurrence-variant.component.css']
})
export class AddRecurrenceVariantComponent implements OnInit {
  variantId:number|any
  cancerTypeList!: CancerType[];
  statusMsg!:string;
  recurrenceVariance={} as RecurrenceVariantReqDto;
  geneList!: Gene[];
  submitSpinner = false;
  constructor(private dataAdminService: DataAdminService,
    private recurrenceVariantSercice:RecurrenceVariantSerciceService,

    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute) { }

  ngOnInit(): void {
    this.variantId = +this.routerAct.snapshot.params['id'];
    this.recurrenceVariance={} as RecurrenceVariantReqDto
    if(this.variantId){
      this.getRecurrenceVariantById()
    }
      this.getCancerTypes();
      this.getGenes();
  }

  getRecurrenceVariantById(){
    this.recurrenceVariantSercice.getRecurrenceVariantById(this.variantId).subscribe((res:any)=>{
      this.recurrenceVariance=res['payLoad'];
      console.log("this.recurrenceVariance=>",this.recurrenceVariance)
      let data:RecurrenceVariant=res['payLoad'];
      this.recurrenceVariance.cancerTypeId=data?.cancerType?.id
      this.recurrenceVariance.geneId=data?.gene?.id;
    })
  }

  onSubmit(){
    if(this.variantId){
      this.recurrenceVariance.id=this.variantId;
      this.recurrenceVariantSercice.addRecurrenceVariant(this.recurrenceVariance).subscribe((res:any)=>{
        console.log("Res",res)
        this.statusMsg=res['message'];
        this.showSuccess();
        this.reload()
       },error => {
        console.log('oops...!', error)
        this.statusMsg = error.error.message;
        this.showFail();
      }
    );

    }else{
     this.recurrenceVariantSercice.addRecurrenceVariant(this.recurrenceVariance).subscribe((res:any)=>{
      console.log("Res",res)
      this.statusMsg=res['message'];
        this.showSuccess();
      this.reload()
     },error => {
      console.log('oops...!', error)
      this.statusMsg = error.error.message;
      this.showFail();
    });
    }
  }

  showFail() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 1000 }
    );
  }

  getCancerTypes(){
    this.dataAdminService.cancerTypeList().subscribe(
      (response: any) => {
        this.cancerTypeList = response['payLoad'];
      }
    );
  }

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/recurrenceVarient']);
    });
  }

}
