<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div class="spinner-border" style="width: 8rem; height: 8rem" role="status"></div>
</div>

<div *ngIf="reportData" style="height: 1600px; padding: 10px; margin: 0 auto" class="main_wrapper">
  <button [disabled]="downloadSpinner" type="button" class="btn btn-primary m-2 float-right download-btn"
    (click)="downloadReport()">
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i>
      Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>



  <!-- For 4basecare Template 1-->
  <div>
    <div id="page" class="container position-relative page" style="overflow-y: clip; background-color: white">
      <div class="row header">
        <div class="w-100 overflow-hidden">
          <div class="col">
            <div class="cover_page">
              <img src="../../../assets/tarGT_FirstSolid-Cover.png" alt="" class="img-fluid w-100" />
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <!-- Page 1 -->
    <div id="page1" class="container position-relative page" style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                SCOPE OF THE TEST
              </p>
              <p>SNVs, InDels, CNAs, Gene Fusions status</p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-100">
              <div class="w-100" style="min-height: 80px">
                <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                  <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                </p>

                <p *ngIf="report.clinicalSummary != null">
                  <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                    }}</span>
                </p>
              </div>
              <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'txt-skyblue'">
            Report Details
          </h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Name : </span>
              <span class="text-black">{{
                report.patient?.name | uppercase
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
              <span class="text-black">{{
                report.patient?.gender | titlecase
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
              <span class="text-black">{{
                report.patient?.dateOfBirth
                ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                : report.patient?.age + " Years"
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Reporting Date :
              </span>
              <span class="text-black">{{ date | date : "dd/MM/yyyy" }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Prescribing Clinician :
              </span>
              <span class="text-black">{{referringClinician}}</span>
            </li>

          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled pt-4 mt-3">
            <li *ngIf="report.cancerSubType != null" class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Cancer Celltype :
              </span>
              <span class="text-black">{{ report.cancerSubType }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Sample Source :
              </span>
              <span class="text-black">{{ sampleSource }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age Of Block :
              </span>
              <span class="text-black"
                *ngIf="report.labDetails.dateOfHistopathologyReport!=null">{{ageOfBlock}}<span *ngIf="ageOfBlock!=null"> days</span></span>
              <span class="text-black" *ngIf="report.labDetails.dateOfHistopathologyReport==null"></span>

            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Tumour Content Assessment :
              </span>
              <span class="text-black" *ngIf="report.labDetails.tumourContentAssessment!=null">{{report.labDetails.tumourContentAssessment}}%</span>
              <span class="text-black" *ngIf="report.labDetails.tumourContentAssessment==null"></span>

            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Hospital :
              </span>
              <span class="text-black">{{ labHospital }}</span>
            </li>


          </ul>
        </div>
      </div>
      <!--  -->
      <div class="mt-3" *ngIf="report.resultValue!=null">
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
          Results
        </h6>
      </div>
      <div>
        {{report.resultValue}}
      </div>

      <!--  -->
      <div *ngIf="geneMutationlist != null && isStatusDisplay">
        <div class="mt-3">
          <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
            Status of variants in cancer related Biomarkers
          </h6>
        </div>

        <table class="staus_gene bg_skyblue" *ngIf="geneMutationlist != null" [ngClass]="{
                                biomarker_table:
                                  geneMutationlist.length >= 4 &&
                                  geneMutationlist.length < 8
                              }">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                                    let geneMutation of geneMutationlist
                                      | slice : 0 : 8
                                  ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                                    let geneMutation of geneMutationlist
                                      | slice : 0 : 8
                                  ">
              <span *ngIf="
                                      geneMutation.status == 'Pathogenic' ||
                                      geneMutation.status == 'Likely Pathogenic' ||
                                      geneMutation.status == 'Detected'
                                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                                      geneMutation.status == 'Not Detected' ||
                                      geneMutation.status == 'Benige'
                                    ">{{ geneMutation.status }}</span>
            </td>
          </tr>
          <br />
        </table>
        <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                                geneMutationlist != null &&
                                geneMutationlist.length > 8
                              ">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                                    let geneMutation of geneMutationlist
                                      | slice : 8 : 17
                                  ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                                    let geneMutation of geneMutationlist
                                      | slice : 8 : 17
                                  ">
              <span *ngIf="
                                      geneMutation.status == 'Pathogenic' ||
                                      geneMutation.status == 'Likely Pathogenic' ||
                                      geneMutation.status == 'Detected'
                                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                                      geneMutation.status == 'Not Detected' ||
                                      geneMutation.status == 'Benige'
                                    ">{{ geneMutation.status }}</span>
            </td>
          </tr>
          <br />
        </table>
        <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                geneMutationlist != null &&
                geneMutationlist.length > 17
              ">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 17 : 25
                  ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                    let geneMutation of geneMutationlist
                      | slice : 17 : 25
                  ">
              <span *ngIf="
                      geneMutation.status == 'Pathogenic' ||
                      geneMutation.status == 'Likely Pathogenic' ||
                      geneMutation.status == 'Detected'
                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                      geneMutation.status == 'Not Detected' ||
                      geneMutation.status == 'Benige'
                    ">{{ geneMutation.status }}</span>
            </td>
          </tr>
          <br />
        </table>
        <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
                  geneMutationlist != null &&
                  geneMutationlist.length > 25
                ">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 25 : 33
                    ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                      let geneMutation of geneMutationlist
                        | slice : 25 : 33
                    ">
              <span *ngIf="
                        geneMutation.status == 'Pathogenic' ||
                        geneMutation.status == 'Likely Pathogenic' ||
                        geneMutation.status == 'Detected'
                      " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                        geneMutation.status == 'Not Detected' ||
                        geneMutation.status == 'Benige'
                      ">{{ geneMutation.status }}</span>
            </td>
          </tr>
          <br />
        </table>
        <table class="staus_gene" [ngClass]="'bg_skyblue'" *ngIf="
              geneMutationlist != null &&
              geneMutationlist.length > 33
            ">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 33 : 41
                ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                  let geneMutation of geneMutationlist
                    | slice : 33 : 41
                ">
              <span *ngIf="
                    geneMutation.status == 'Pathogenic' ||
                    geneMutation.status == 'Likely Pathogenic' ||
                    geneMutation.status == 'Detected'
                  " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                    geneMutation.status == 'Not Detected' ||
                    geneMutation.status == 'Benige'
                  ">{{ geneMutation.status }}</span>
            </td>
          </tr>
          <br />
        </table>
        <h6 *ngIf="
                                (fusionGeneMutationList != null &&
                                fusionGeneMutationList.length > 0)
                              " class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
          Gene Fusion
        </h6>
        <table class="staus_gene" *ngIf="
                                (fusionGeneMutationList != null &&
                                fusionGeneMutationList.length > 0)
                              " [ngClass]="'bg_skyblue'">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                                    let geneMutation of fusionGeneMutationList
                                      | slice : 0 : 8
                                  ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                                    let geneMutation of fusionGeneMutationList
                                      | slice : 0 : 8
                                  ">
              <span *ngIf="
                                      geneMutation.status == 'Pathogenic' ||
                                      geneMutation.status == 'Likely Pathogenic' ||
                                      geneMutation.status == 'Detected'
                                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                                      geneMutation.status == 'Not Detected' ||
                                      geneMutation.status == 'Benige'
                                    ">{{ geneMutation.status }}</span>
            </td>
          </tr>
          <br />
        </table>
        <table class="staus_gene" *ngIf="
                                (fusionGeneMutationList != null &&
                                fusionGeneMutationList.length > 8)
                              " [ngClass]="'bg_skyblue'">
          <tr style="background: #daedf7">
            <td style="font-size: 18px !important; padding-left: 10px !important">
              Gene
            </td>
            <td *ngFor="
                                    let geneMutation of fusionGeneMutationList
                                      | slice : 8 : 17
                                  ">
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td style="font-size: 18px !important; padding-left: 10px !important">
              <b>Status</b>
            </td>
            <td *ngFor="
                                    let geneMutation of fusionGeneMutationList
                                      | slice : 8 : 17
                                  ">
              <span *ngIf="
                                      geneMutation.status == 'Pathogenic' ||
                                      geneMutation.status == 'Likely Pathogenic' ||
                                      geneMutation.status == 'Detected'
                                    " style="color: red; font-weight: 600px"><b>{{ geneMutation.status }}</b></span>
              <span *ngIf="geneMutation.status == 'VUS'" style="color: black; font-weight: 600px"><b>{{
                  geneMutation.status }}</b></span>
              <span *ngIf="
                                      geneMutation.status == 'Not Detected' ||
                                      geneMutation.status == 'Benige'
                                    ">{{ geneMutation.status }}</span>
            </td>
          </tr>
        </table>
      </div>
      <!--  -->
      <div *ngIf="(tableCount>0) && prognasticCount==0">
        <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
      </div>
      <div class="row" *ngIf="(tableCount>0 )&&
            prognasticCount==0">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
            [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Genomic Alteration</th>
                <th>Prognostic Significance</th>

              </tr>
            </thead>
            <ng-container>
              <tr>
                <td colspan="2" style="text-align:center">
                  Clinically relevant genomic alterations associated with prognostic significance were not detected.
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
      <!-- Prognostic Biomarkers -->
      <div *ngIf="prognasticCount>0">
        <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
      </div>

      <div class="row" *ngIf="prognasticCount>0">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
            [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Genomic Alteration</th>
                <th>Prognostic Significance</th>

              </tr>
            </thead>
            <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
              <tr>
                <td style="padding-left: 3px !important">
                  <i>{{ prog.gene }}&nbsp; </i>
                  <span [innerHtml]="prog.geneDetails"></span>
                </td>
                <td style="padding-left: 3px !important;">{{prog.statement}}
                </td>
              </tr>
            </ng-container>


          </table>
        </div>
      </div>
      <!--  -->





      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 2 -->
    <div id="page2" *ngIf="
                          otherCountSNV == true ||
                          reportPdfRes.allVariantsList.length > 0 || (report.addNotes != '<div></div>')
                          " class="container position-relative page" style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }} </span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>


      <!--  -->

      <div  *ngIf="otherCountSNV == true ||
                              reportPdfRes.allVariantsList.length != 0
                            ">
        <h6 class="py-2 h6_bg">
        Variant Details
          </h6>
      </div>

      <div class="row" *ngIf="
                              otherCountSNV == true ||
                              reportPdfRes.allVariantsList.length != 0
                            ">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant consequence and Type</th>
                <th>Clinical Significance and Reference</th>
                <th>Relevant Therapies (in Same Cancer Type)</th>
                <th>Relevant Therapies (in Different Cancer Type)</th>
              </tr>
            </thead>
            <tr *ngFor="let variant of reportPdfRes.allVariantsList">
              <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <i>{{ variant.gene }}</i>
              </td>
              <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant?.variantType=='snv'">
                  <span *ngIf="variant.gene.includes('Wild type')">NA</span>
                  <span *ngIf="!variant.gene.includes('Wild type')">
                  {{ variant?.genomicAlteration }},<br />
                  {{ variant?.ensembleValue }},<br />
                  Exon {{ variant?.exonNumber }}</span>
                </span>
                <span *ngIf="variant?.variantType=='cna'">
                  chr{{ variant.sample.split(":")[0] }}:g.{{
                  variant.sample
                  .split(":")[1]
                  .replace("-", "_")
                  .split("|")[0]
                  }}
                </span>
                <span *ngIf="variant.variantType=='fusion'">
                  {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                </span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant.variantType=='snv'">
                  <span *ngIf="variant.gene.includes('Wild type')">NA</span>
                  <span *ngIf="!variant.gene.includes('Wild type')">
                  <span *ngIf="variant?.selectedAAChangeknownGene">{{
                    variant.selectedAAChangeknownGene|splitComma }}</span> ,
                  {{variant.mutantBurdenPercent}}<br/>{{ variant?.exonicFuncknownGene }}</span>
                </span>
                <span *ngIf="variant.variantType=='cna'">NA<br/>
                  Copy Number
                  {{ variant.sample.split(":")[2] | titlecase }}
                  ({{ variant.sample.split("|")[1].split(":")[0] }})</span>
                <span *ngIf="variant.variantType=='fusion'">NA<br/>Gene Fusions</span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant.gene.includes('Wild type')">NA</span>
                  <span *ngIf="!variant.gene.includes('Wild type')">
                {{ variant?.significance }}
                <br/>{{ variant?.reference }}</span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                {{variant?.sameCancerType || "NA"}}

              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                {{variant?.differentCancerType || "NA"}}

              </td>
            </tr>
          </table>
          <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>



      <!--  -->
      <div class="row mt-5" *ngIf="
          !variantFlag &&
          report.addNotes != null && report.addNotes != '<div></div>'
        ">
        <div class="col">
          <h6 class="py-2 h6_bg">Additional Notes</h6>
          <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3 -->
    <div id="page3" *ngIf="reportPdfRes.drugsResList.length > 0" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 custom-table" [ngClass]="'bg_cyan'">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;  let i = index ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_1 -->
    <div id="page3_1" *ngIf="reportPdfRes.drugsResList.length > 4" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor="let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                                                                        let i = index
                                                                      ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','') }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_2 -->
    <div id="page3_2" *ngIf="reportPdfRes.drugsResList.length > 8" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor="
                                                                                            let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                                                                                            let i = index
                                                                                          ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_3 -->
    <div id="page3_3" *ngIf="reportPdfRes.drugsResList.length > 12" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor="
                                                                                                                  let drugsRes of reportPdfRes.drugsResList | slice : 12 : 16;
                                                                                                                  let i = index
                                                                                                                ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_4 -->
    <div id="page3_4" *ngIf="reportPdfRes.drugsResList.length > 16" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor="
                                                                                                        let drugsRes of reportPdfRes.drugsResList | slice : 16 : 20;
                                                                                                        let i = index
                                                                                                      ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_5 -->
    <div id="page3_5" *ngIf="reportPdfRes.drugsResList.length > 20" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor="
                    let drugsRes of reportPdfRes.drugsResList | slice : 20 : 24; let i = index ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_6 -->
    <div id="page3_6" *ngIf="reportPdfRes.drugsResList.length > 24" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 24 : 28; let i = index ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_7 -->
    <div id="page3_7" *ngIf="reportPdfRes.drugsResList.length > 28" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                                                                                                                                                                        let drugsRes of reportPdfRes.drugsResList | slice : 28 : 32;
                                                                                                                                                                        let i = index
                                                                                                                                                                      ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_8 -->
    <div id="page3_8" *ngIf="reportPdfRes.drugsResList.length > 32" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 32 : 36; let i = index ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3_9 -->
    <div id="page3_9" *ngIf="reportPdfRes.drugsResList.length > 36" class="container position-relative page"
      style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Alterations With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Alterations) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr *ngFor=" let drugsRes of reportPdfRes.drugsResList | slice : 36 : 40; let i = index ">
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene.replace("Fusion","") }}</i><span *ngIf="drugsRes.gene.includes('Fusion')">Fusion</span> {{ drugsRes.pposition?.replace(' ','')}} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 4 -->
    <div id="page4" *ngIf="prognasticCount>0" class="container position-relative page" style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div *ngIf="prognasticCount>0">
        <div>
          <h6 class="py-2 h6_bg"> Alterations associated with prognostic significance</h6>

        </div>

        <div class="row">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3 varient_details prognastictable"
              [ngClass]="'table_variant-blue'">
              <thead>
                <tr>
                  <th>Gene (Alterations)</th>
                  <th>Prognostic Significance</th>
                  <th>Summary</th>

                </tr>
              </thead>

              <ng-container *ngFor=" let prog of reportPdfRes.prognosticAlterations ">
                <tr>
                  <td style="padding-left: 3px !important">
                    <i>{{ prog.gene }}&nbsp;</i> <span [innerHtml]="prog.pOrcDot"></span>
                  </td>
                  <td style="padding-left: 3px !important">
                    {{prog.prognosticSignificance}}
                  </td>
                  <td style="padding-left: 3px !important">
                    <span [innerHtml]="prog.description"></span>
                  </td>

                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>


      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>


    <!-- page4_1 -->
    <div id="page4_1" *ngIf="report.keyInformation != ' ' && report.keyInformation != null " class="container position-relative page" style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">Key Clinical Information</h6>
      <!--  -->
      <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.keyInformation"></div>


      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Page 5 -->
    <div id="page5" class="container position-relative page" style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="disc">
            <div class="ref_data mt-4">
              <ul class="break-word" style="text-align: justify">
                <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                        text-align: justify;
                                        /* white-space:pre-wrap; */
                                        word-break: break-word;
                                      ">
                  {{ referenceArticle }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="img_signs above-footer">
        <!-- <div class="col" *ngIf="isReportDownload">
          <img [src]="imgsings" alt="" class="img-fluid w-90" />
        </div>
        <div class="row" id="imgsings" *ngIf="!isReportDownload">
          <div class="col"> -->
        <div class="row" id="imgsings" >
          <div class="col">

            <p style="
                  text-align: center;

                  font-size: 15px !important;
                ">(Electronically signed by)
            </p>
            <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
              Vidya H Veldore, PhD<br />Vice President - Clinical Operations
            </p>
          </div>
          <div class="col">
            <p style="text-align: center; font-size: 15px !important;">
              (Electronically signed by)</p>
              <p style="
              text-align: center;
              font-weight: bolder;
              font-size: 15px !important;
            ">
            Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
          </div>
          <div class="col">
            <p style="
              text-align: center;

              font-size: 15px !important;
            ">(Electronically signed by)
            </p>
            <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
              Vyomesh Javle<br />Head - Clinical Bioinformatics
            </p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Page 6 -->
    <div id="page6" class="container position-relative page" style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
        <p class="mb-2" style="text-align:justify">
          <span class="font-weight-normal">TAR</span><b>GT</b>
          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
          is a Next Generation Sequencing based test which identifies
          genetic alterations in a comprehensive panel of well curated 72 genes which are having an impact response to
          approved
          therapy for a particular cancer type. Some of the alterations
          detected may have bearing on prognosis and/or therapeutic options
          and may provide relevant information that allows
          oncologists/clinicians to consider various lines of
          targeted treatment for the patient.
        </p>
      </div>

      <!--  -->
      <div class="row">
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
            <p>
              <span class="font-weight-normal">TAR</span><b>GT</b>
              <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
              detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
              Gene Fusions and splice variants in the 72 genes:
            </p>
          </div>
          <!--  -->
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>SNVs and SHORT INDELS
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                      <span>IDH2</span>
                    </div>
                    <div class="gene_list">
                      <span>JAK2</span><span>KIT</span><span>KRAS</span>
                      <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MLH3</span>
                      <span>MSH2</span>
                    </div>
                    <div class="gene_list">
                      <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                      <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                      <span>POLD1</span>
                    </div>
                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>

              </div>
            </div>





            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>Gene Fusions/Splicing Variations</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                      <span>MET</span><span>NRG1</span><span>NRG2</span>
                      <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                    </div>
                    <div class="gene_list">
                      <span>ROS1</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
      <!--  -->
      <div class="row mt-3">
        <div class="col">
          <h6 class="font-weight-bold">
            Sample preparation and Library preparation :
          </h6>
          <p style="text-align: justify;">
            DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture
            using
            a custom capture kit. The libraries were sequenced to mean
            >350X coverage on Illumina sequencing platform.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h6 class="font-weight-bold">
            Bioinformatics Analysis and Reporting :
          </h6>
          <p style="text-align: justify;">
            The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was
            performed
            using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
            consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
            result in any change
            in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
            published variants in literature and a set of databases – ClinVar, COSMIC
            and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP,
            gnomAD,
            etc. In the absence of a clinically significant reported
            known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools: CADD,
            SIFT,
            PolyPhen-2, Condel and Mutation taster and prioritized for clinical
            correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the
            patient and interpreted according to ACMG/AMP guidelines.
          </p>
          <p class="mb-2 d-block" style="text-align: justify;">
            Somatic variants are classified into three tiers based on their level of clinical significance in cancer
            diagnosis, prognosis, and/or therapeutics as per international guidelines:
            ACMG, ASCO, AMP, CAP, NCCN and ESMO.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>

    </div>

    <!-- Page 7 -->
    <div id="page7" class="container position-relative page" style="background-color: white">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->


      <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
        LIMITATIONS AND DISCLAIMER
      </h6>

      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4" style="text-align: justify;">
              <li>
                TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd
                and
                has not been cleared or approved by the FDA.
              </li>
              <li>
                DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                should be realized that there are possible sources of error. Errors can result
                from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                recent scientific developments, and alternative classification systems. This
                test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                treatment plan.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting which is usually the longest coding
                transcript with strong/multiple supporting evidence. However, in rare cases,
                clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
              </li>
              <li>
                The contents of this test should be carefully assessed by the treating physician and further interpreted
                along with clinical, histopathological findings, contraindications and
                guidelines before deciding the course of therapy.
              </li>
              <li>
                The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical
                management decisions. </li>
              <li>
                Most recent block is recommended for testing as the mutation profile may change in response to treatment
                and hence differ at different sampling points.
              </li>
              <li>
                The identified pathogenic variant will be correlated with observed phenotypic features of the patient
                and interpreted according to AMP guidelines.
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                rule out the possibility that the tested tumor sample carries
                mutations not previously associated with cancer and hence not included in the panel.
              </li>
              <li>
                Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In
                addition
                to this, sequencing quality and coverage is dependent on many factors
                such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                detection.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
