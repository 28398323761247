import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RecurrenceVariant } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { RecurrenceVariantSerciceService } from 'src/app/_services/recurrence-variant-sercice.service';

@Component({
  selector: 'app-recurrence-variant',
  templateUrl: './recurrence-variant.component.html',
  styleUrls: ['./recurrence-variant.component.css']
})
export class RecurrenceVariantComponent implements OnInit {

  recurrenceVariantList:RecurrenceVariant[]=[]
  recurrenceVariantId!:number
  statusMsg!:string
  recurrenceVariantListSpinner:boolean=true
  constructor(private dataAdminService: DataAdminService,
    private recurrenceVariantSercice:RecurrenceVariantSerciceService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAllRecords()
  }

  getAllRecords(){
    this.recurrenceVariantSercice.getRecurrenceVariantsList().subscribe((res:any)=>{
      this.recurrenceVariantList=res['payLoad']
      this.recurrenceVariantListSpinner=false
    })
  }

  assignRecurrenceVariant(data:any){
    this.recurrenceVariantId=data.id
  }

  deleteRecurrenceVariant(){
    this.recurrenceVariantSercice.deleteRecurrenceVariant(this.recurrenceVariantId).subscribe((response:any)=>{
      this.statusMsg = response['message'];
        this.showSuccess();
        this.reload()
    })
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/recurrenceVarient']);
    });
  }
  uploadFileInput=false;

  onFileChanges(event: any) {
    const files = event.target.files;
    const file = files[0];
    if(file){
      this.uploadFileInput = true;
    }
    // this.fileName = file.name;
    this.uploadFile(file);
  }
  uploadFile(file: any) {
    console.log("evt=>",file)
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.recurrenceVariantSercice
      .uploadAndGetRecurrenceVariantFromExcel(formdata)
      .subscribe((response: any) => {
        this.recurrenceVariantList=response['payLoad']

        console.log("response=>\n",response)
        console.log("list of not uploaded=>\n",response['payLoad'])
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
}
generateExcel() {
   this.recurrenceVariantSercice.generateExcel();
 }
}

