import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-cancer-sitegene',
  templateUrl: './cancer-sitegene.component.html',
  styleUrls: ['./cancer-sitegene.component.css']
})
export class CancerSitegeneComponent implements OnInit {
  cancerListSpinner:boolean=false
  cancerSiteGeneList:any=[]
  cancerSiteGeneId!:number
  statusMsg=''
  constructor(private dataAdminService:DataAdminService,private toastr: ToastrService,
    private router: Router) { }
  

  ngOnInit(): void {
    this.getCancerSiteGeneList()
  }


  getCancerSiteGeneList(){
    this.dataAdminService.getCancerSiteGeneList().subscribe((res:any)=>{
      this.cancerSiteGeneList=res['payLoad']
      console.log("DATAT ",this.cancerSiteGeneList)
    })
  }

  assignCancerSiteGene(cancerSiteGenesId:number){
    this.cancerSiteGeneId=cancerSiteGenesId
  }

  
  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/cancer_site_gene']);
    });
  }

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  closeModal(){
    this.cancerSiteGeneId=0
  }
  deleteCancerSiteGene(){

    this.dataAdminService.deleteCancerSiteGene(this.cancerSiteGeneId).subscribe((response:any)=>{
      this.statusMsg = response['message'];
      this.showSuccess();
      this.reload();
    })
    
  }
}
