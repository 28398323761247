import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CancerSiteGene } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-add-cancersitegene',
  templateUrl: './add-cancersitegene.component.html',
  styleUrls: ['./add-cancersitegene.component.css']
})
export class AddCancersitegeneComponent implements OnInit {

  cancerSiteGeneId!:number;
  cancerSiteDropdownSettings = {};
  geneDropdownSettings = {};
  submitSpinner:boolean=false;
  statusMsg:string=''
  cancerSiteList:any=[]
  cancerSite:any
  gene:any
  geneList:any=[]
  constructor(  
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute) { }

  ngOnInit(): void {
    this.cancerSiteGeneId = +this.routerAct.snapshot.params['id'];
    this.getGenes()
    this.getCancerSites()
    this.cancerSiteDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.geneDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    if(this.cancerSiteGeneId){
      this.getCancerSiteGene()
    }
  }
getCancerSiteGene(){
  this.dataAdminService.getCancerSiteGeneById(this.cancerSiteGeneId).subscribe((res:any)=>{
    let data= res['payLoad'];
    this.cancerSite=[]
    this.gene=[]
    if(data){
      this.cancerSite.push({id:data.cancerSite.id,name:data.cancerSite.name})
      data.genes.forEach((element:any)=>{
        this.gene.push({
          id:element.id,name:element.name
        })
      })

    }
    console.log("data ",data)
  })
}
  
  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  getCancerSites(){
    this.dataAdminService.getCancerSiteList().subscribe(
      (response: any) => {
        this.cancerSiteList = response['payLoad'];
      }
    );
  }
  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  onSubmit(){
    let cancerSiteId!:number;
    let geneIds:any=[]
    if(this.cancerSite && this.cancerSite.length>0){
      cancerSiteId=this.cancerSite[0].id
     
    }
   if(this.gene && this.gene.length>0){
    this.gene.forEach((element:any) => {
      geneIds.push(element.id)
    });
   }

  let cancerSiteGene:CancerSiteGene
  
  if(this.cancerSiteGeneId){
    cancerSiteGene={
      id:this.cancerSiteGeneId ,
      cancerSiteId: cancerSiteId,
      geneId:geneIds
    }
  }else{
    cancerSiteGene={
      id:null ,
      cancerSiteId: cancerSiteId,
      geneId:geneIds
    }
  }

  this.dataAdminService.addCancerSiteGene(cancerSiteGene).subscribe((res:any)=>{
    this.statusMsg = res['message'];
    this.showSuccess();
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      
      this.router.navigate(['/super_admin/cancer_site_gene']);
    });
  })

  }
}
