import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PaginationReqInterface } from 'src/app/_interfaces.ts/pagination';
import { Patient } from 'src/app/_interfaces.ts/patient';
import { ImmunoDetails, ReportRes } from 'src/app/_interfaces.ts/reports';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-dash-reports',
  templateUrl: './dash-reports.component.html',
  styleUrls: ['./dash-reports.component.css']
})
export class DashReportsComponent implements OnInit {

  dataLoded:boolean=true
  patient = {} as Patient;
  report = {} as ReportRes;
  searchText!:any;
  searchDeliveredText!:any;
  searchNewText!:any;
  newReportsList: ReportRes[] = [];
  processingReportsList: ReportRes[] = [];
  deliveredReportsList: ReportRes[] = [];
  reportsList: ReportRes[] = [];
  processingReportsListBackup: ReportRes[] = [];
  processingReportsListMainBackup: ReportRes[] = [];
  newReportsView = true;
  processReportsView = false;
  deliveredReportsView = false;
  organisation:string[]=[]
  processingReportPaginationReq = {} as PaginationReqInterface;
  newReportPaginationReq= {} as PaginationReqInterface;
  reportedReportPaginationReq= {} as PaginationReqInterface;
  currentPage!:number;
  totalPages!:number;
  newCurrentPage!:number;
  newTotalPages!:number;
  deliveredCurrentPage!:number;
  deliveredTotalPages!:number;
  itemsPerPage!:number;
  processCount=0
  newCount=0
  deliveredCount=0
  pageSize=[10,25,50,100]

  organisationName!: string;

  constructor(
    private router: Router,
    private reportService: ReportService,
    private toastr: ToastrService,
    private routerAct: ActivatedRoute
  ) { }

  statusMsg!: string;
  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  showError() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }


  ngOnInit(): void {

    this.organisationName = this.routerAct.snapshot.params['organisationName'];
    if(localStorage.getItem('organisationName') != null){
       this.organisationName = localStorage.getItem('organisationName') || '';
    }

  this.processingReportPaginationReq.reqPageNo = 0;
  this.processingReportPaginationReq.pageSize = 10;
  this.processingReportPaginationReq.sortBy = 'lastModified';
  this.processingReportPaginationReq.sortOrder = 'desc';
  this.processingReportPaginationReq.searchKey = "";

  this.itemsPerPage=10

  this.newReportPaginationReq.reqPageNo = 0;
  this.newReportPaginationReq.pageSize = 10;
  this.newReportPaginationReq.sortBy = 'lastModified';
  this.newReportPaginationReq.sortOrder = 'desc';
  this.newReportPaginationReq.searchKey = "";

  this.reportedReportPaginationReq.reqPageNo = 0;
  this.reportedReportPaginationReq.pageSize = 10;
  this.reportedReportPaginationReq.sortBy = 'lastModified';
  this.reportedReportPaginationReq.sortOrder = 'desc';
  this.reportedReportPaginationReq.searchKey = "";

  if(this.organisationName != null && this.organisationName != '4basecare'){
    console.log("organisationName",this.organisationName);
    this.processingReportPaginationReq.organisationName = this.organisationName;
    this.newReportPaginationReq.organisationName = this.organisationName;
    this.reportedReportPaginationReq.organisationName = this.organisationName;
    this.getProcessingReports();
    this.getNewReports()
    this.getReportedReports()
  } else{
    this.getProcessingReports();
    this.getNewReports()
    this.getReportedReports()
    // this.pdfChecking();
  }

  }


  assignPatient(patient: Patient) {
    this.patient = patient;
    console.log("patient", this.patient);
  }

  changePageSize(event:any){
    let pageSize=event['target']['value']
    console.log("PageSize",pageSize)
    this.processingReportPaginationReq.pageSize=+pageSize
    this.newReportPaginationReq.pageSize=+pageSize
    this.reportedReportPaginationReq.pageSize=+pageSize
    this.getProcessingReports()
    this.getNewReports()
    this.getReportedReports()
  }

  searchNewData(searchstr:any){
    if(searchstr!=""){
      this.newReportPaginationReq.searchKey = searchstr
      this.newReportPaginationReq.reqPageNo = 0;
    }else{
      this.newReportPaginationReq.searchKey =''
      this.newReportPaginationReq.reqPageNo = 0;
    }
    this.getNewReports()
  }

  searchData(searchstr:any){
      if(searchstr!=""){
        this.processingReportPaginationReq.searchKey = searchstr
        this.processingReportPaginationReq.reqPageNo = 0;
      }else{
        this.processingReportPaginationReq.searchKey =''
        this.processingReportPaginationReq.reqPageNo = 0;
      }
      this.getProcessingReports()
  }

  searchDeliveredData(searchstr:any){
    if(searchstr!=""){
      this.reportedReportPaginationReq.searchKey = searchstr
      this.reportedReportPaginationReq.reqPageNo = 0;
    }else{
      this.reportedReportPaginationReq.searchKey =''
      this.reportedReportPaginationReq.reqPageNo = 0;
    }
    this.getReportedReports()
  }

  getProcessingReports() {
    this.processingReportsList=[]
    this.reportService.getReports(this.processingReportPaginationReq).subscribe(
      (response: any) => {
        this.processingReportsList  = response['payLoad']['reportList'];
        this.totalPages = response['payLoad']['totalPages'];
        if(this.processCount==0){
          this.processCount= response['payLoad']['totalRecordCount']
        }

        // this.totalPages=1;
        this.currentPage = response['payLoad']['currentPage'] + 1;
        console.log(this.processingReportsList);

        this.processingReportsListBackup=this.processingReportsList

        // this.processingReportsListMainBackup=this.processingReportsListBackup

        this.processingReportsListBackup.forEach((element:any)=>{
          if(this.organisation.length>0 && element.patient.organisationName!=null && !(this.organisation.includes(element.patient.organisationName)))
          {
            this.organisation.push(element.patient.organisationName)
          }else if(element.patient.organisationName!=null && this.organisation.length==0){
              this.organisation.push(element.patient.organisationName)
          }

        })
      },((error:any)=>{
      })
    );
  }

  getNewReports(){

    this.reportService.getNewReports(this.newReportPaginationReq).subscribe(
      (response: any) => {
        // this.newReportsList=[]
        this.newReportsList = response['payLoad']['reportList'];
        this.newTotalPages = response['payLoad']['totalPages'];
        this.newCurrentPage = response['payLoad']['currentPage'] + 1;
        if( this.newCount==0){
          this.newCount= response['payLoad']['totalRecordCount']
        }
      }
    );
  }

  getReportedReports(){
    this.deliveredReportsList=[]
    this.reportService.getReportedReports(this.reportedReportPaginationReq).subscribe(
      (response: any) => {

        this.deliveredReportsList = response['payLoad']['reportList'];
        this.deliveredTotalPages = response['payLoad']['totalPages'];
        this.deliveredCurrentPage = response['payLoad']['currentPage'] + 1;
        if(this.deliveredCount==0){
          this.deliveredCount= response['payLoad']['totalRecordCount']
        }

        console.log("reported",this.deliveredReportsList);
      }
    );
  }

  newReports() {
    this.newReportsView = true;
    this.processReportsView = false;
    this.deliveredReportsView = false;
  }

  processReports() {
    this.newReportsView = false;
    this.processReportsView = true;
    this.deliveredReportsView = false;
  }

  deliveredReports() {
    this.newReportsView = false;
    this.processReportsView = false;
    this.deliveredReportsView = true;
  }

  getReport(id: number, patient: Patient) {
    console.log("REPORT :" + id)
    this.patient = patient;
    this.reportService.getReport(id).subscribe(
      (response: any) => {
        console.log(response);
        this.report = response['payLoad'];
      });
  }

  getDocuments(ecrfId: string) {
    if (!ecrfId.includes("-423-")) {
      this.reportService.getDocuments(ecrfId).subscribe(
        (response: any) => {
          if (response['payLoad'].length > 0) {
            window.open(this.router.serializeUrl(this.router.createUrlTree(['/patient-documents/', ecrfId])));
          } else {
            this.statusMsg = "No Documents Found";
            this.showError();
          }
        },
        (error) =>{
          console.log(error);
          this.statusMsg = "No Documents Found";
            this.showError();
        });
    } else {
      this.statusMsg = "No Documents Found";
      this.showError();
    }
  }


  addReportType(event:any){
    console.log("evenet==>\n",event['target']['value']);
    if(event['target']['value']!='All'){
    // this.processingReportsList.filter(x =>x.reportType==event['target']['value']);
    this.processingReportsList = this.processingReportsListBackup.filter( (report:any)=> {
      if(report['reportType']){
        return report['reportType']['name'] == event['target']['value'];
      }
      return false
    });
    console.log("this.processingReportsList",this.processingReportsList)
  }else{
    this.processingReportsList = this.processingReportsListBackup;
  }
}


addOrganization(event:any){
  if(event['target']['value']!='All'){
  this.processingReportsList = this.processingReportsListMainBackup.filter(function (report) {
    return report['patient']['organisationName'] == event['target']['value'];
  });
  this.processingReportsListBackup=this.processingReportsList

}else{
  this.processingReportsListBackup= this.processingReportsListMainBackup;
  this.processingReportsList = this.processingReportsListMainBackup;
}
}
navigateToReport(report:string,id:number){
  switch(report){
    case "Absolute":
    case "Indiegene":
    case "Focus":
      this.router.navigate([]).then(result => {  window.open( `/view_report/${id}`, '_blank'); });
      break;
    case "Core":
      this.router.navigate([]).then(result => {  window.open( `/core_report/${id}`, '_blank'); });
      break;
    case "Germline+ V2":
    case "Germline+":
      this.router.navigate([]).then(result => {  window.open( `/germline_report/${id}`, '_blank'); });
      break;
    case "HRR Germline":
      this.router.navigate([]).then(result => {  window.open( `/hrr_germline_report/${id}`, '_blank'); });
      break;
    case "First":
      this.router.navigate([]).then(result => {  window.open( `/first_view_report/${id}`, '_blank'); });
      break;
    case "Family Testing":
      this.router.navigate([]).then(result => {  window.open( `/family_report/${id}`, '_blank'); });
      break;
    case "HRR Somatic":
      this.router.navigate([]).then(result => {  window.open( `/hrr_somatic/${id}`, '_blank'); });
      break;
    case "First 72 Solid":
      this.router.navigate([]).then(result => {  window.open( `/first72_view_report/${id}`, '_blank'); });
      break;
    case "First 72 Liquid":
      this.router.navigate([]).then(result => {  window.open( `/first72_liquid_view_report/${id}`, '_blank'); });
      break;
    case "Lung Liquid Advanced":
      this.router.navigate([]).then(result => {  window.open( `/lung_liquid_advanced_report/${id}`, '_blank'); });
      break;
    case "Lung Liquid Basic":
      this.router.navigate([]).then(result => {  window.open( `/lung_liquid_basic_report/${id}`, '_blank'); });
      break;
  }
}

changePage(event: number) {
  console.log('event', event);
  this.currentPage = event;
  this.processingReportPaginationReq.reqPageNo = this.currentPage - 1;
  this.getProcessingReports();
}
changeNewPage(event: number) {

    console.log('event', event);
    this.newCurrentPage = event;
    this.newReportPaginationReq.reqPageNo = this.newCurrentPage - 1;
    this.getNewReports();
}

changedeliveredPage(event: number) {

  console.log('event', event);
  this.deliveredCurrentPage = event;
  this.reportedReportPaginationReq.reqPageNo = this.deliveredCurrentPage - 1;
  this.getReportedReports();
}

@ViewChild('closeModal') private closeModal!: ElementRef;
changeReportStatus(event: any) {
  this.closeModal.nativeElement.click();
  if (event.value == "Yes") {
    this.reportService.changeReportStatus(this.report.id, "PROCESSING").subscribe(
      (response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.getProcessingReports();
        this.getReportedReports();
      });
  }

}


}
