<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div
    class="spinner-border"
    style="width: 8rem; height: 8rem"
    role="status"
  ></div>
</div>

<div
  *ngIf="reportData"
  style="height: 1600px; padding: 10px; margin: 0 auto"
  class="main_wrapper"
>
  <button
    [disabled]="downloadSpinner"
    type="button"
    class="btn btn-primary m-2 float-right download-btn"
    (click)="downloadReport()"
  >
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i>
      Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>
  <!-- For 4basecare -->
  <div *ngIf="is4basecare">
    <div
      id="page"
      class="container position-relative page"
      style="overflow-y: clip; background-color: white"
    >
      <div class="row header">
        <div class="w-100 overflow-hidden">
          <div class="col">
            <div class="cover_page">
              <img
                src="../../../assets/cover_first.jpeg"
                alt=""
                class="img-fluid w-100"
              />
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>

    <div
      id="page1"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                SCOPE OF THE TEST
              </p>
              <p>SNVs, InDels, CNAs, Gene Fusions status</p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-100">
              <!-- <p style="color: #208dcb;" class="py-1">
                      <span style="color: #000 !important"></span>
                    </p> -->
              <div class="w-100" style="min-height: 80px">
                <p
                  [ngClass]="'txt-skyblue'"
                  class="py-1 font-weight-bold"
                  *ngIf="report.clinicalSummary != null"
                >
                  <span *ngIf="report.clinicalSummary != ' '"
                    >CLINICAL INDICATION</span
                  >
                </p>

                <p *ngIf="report.clinicalSummary != null">
                  <span
                    style="color: #000 !important"
                    *ngIf="report.clinicalSummary != ' '"
                    >{{ report.clinicalSummary }}</span
                  >
                </p>
              </div>
              <!-- </div> -->
              <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6
            class="text-uppercase py-2 font-weight-bold"
            [ngClass]="'txt-skyblue'"
          >
            Report Details
          </h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Name : </span>
              <span class="text-black">{{
                report.patient?.name | uppercase
              }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
              <span class="text-black">{{
                report.patient?.gender | titlecase
              }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
              <span class="text-black">{{
                report.patient?.dateOfBirth
                  ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                  : report.patient?.age + " Years"
              }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                >Reporting Date :
              </span>
              <span class="text-black">{{ date | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled pt-4 mt-3">
            <li *ngIf="report.cancerSubType != null" class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                >Cancer Celltype :
              </span>
              <span class="text-black">{{ report.cancerSubType }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                >Sample Source :
              </span>
              <span class="text-black">{{ sampleSource }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                >Consulting Clinician :
              </span>
              <span class="text-black">{{referringClinician}}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'"
                >Hospital :
              </span>
              <span class="text-black">{{ report.labDetails?.hospital }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <div class="row mt-2 py-2">
        <div class="col">
          <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
        </div>
      </div>
      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Genomic findings from tumor profiling
      </h6>

      <!--  -->
      <div class="row">
        <div class="col-12" *ngIf="isReportDownload">
          <img [src]="firstPageTable" alt="" class="img-fluid w-90" />
        </div>
        <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
          <table
            class="page_1 cust_table w-100 mt-3 first_1"
            [ngClass]="'table_skyblue'"
          >
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr
              *ngIf="
                reportPdfRes.snvHotspotTrailResList.length == 0 &&
                reportPdfRes.cnaHotspotTrailResList.length == 0 &&
                reportPdfRes.fusionHotspotTrailResList.length == 0
              "
            >
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with
                therapeutic significance were not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container>
              <tr
                *ngFor="
                  let snvHotspotTrail of reportPdfRes.snvHotspotTrailResList
                "
              >
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  <span *ngIf="snvHotspotTrail.exonNumber != null">
                    Exon {{ snvHotspotTrail.exonNumber }}</span
                  >
                  <span *ngIf="snvHotspotTrail.pposition != null"
                    >({{ snvHotspotTrail.pposition }})</span
                  >
                  <p *ngIf="snvHotspotTrail.exonNumber != null">
                    Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                  </p>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                          <td class="w-50">
                            <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-50">
                            <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                          <td class="w-33 ">
                            <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-33">
                            <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                      <td class="w-33">
                        <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                        {{cancerType.name}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <!-- CNA -->
              <tr
                *ngFor="
                  let cnaHotspotTrail of reportPdfRes.cnaHotspotTrailResList
                "
              >
                <td>
                  <i>{{ cnaHotspotTrail.gene }}</i>
                  {{ cnaHotspotTrail.copyNumber }}
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-50">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="cnaHotspotTrail.sameCancerType != null">
                          <td class="w-50">
                            <div *ngFor="let drugs of cnaHotspotTrail.sameCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-50">
                            <div *ngFor="let association of cnaHotspotTrail.sameCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="cnaHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of cnaHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                      <tr *ngIf="cnaHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="cnaHotspotTrail.otherCancerType != null">
                          <td class="w-33 ">
                            <div *ngFor="let drugs of cnaHotspotTrail.otherCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-33">
                            <div *ngFor="let association of cnaHotspotTrail.otherCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                      <td class="w-33">
                        <div *ngFor="let cancerType of cnaHotspotTrail.otherCancerType.cancerTypeList">
                        {{cancerType.name}}
                        </div>
                      </td>
                        </tr> -->

                      <ng-container
                        *ngIf="cnaHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of cnaHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>

                      <tr *ngIf="cnaHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <!-- Fusion -->
              <tr
                *ngFor="
                  let fusionHotspotTrail of reportPdfRes.fusionHotspotTrailResList
                "
              >
                <td>
                  <i>{{ fusionHotspotTrail.gene }}</i>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="fusionHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <!-- <tr *ngIf="fusionHotspotTrail.sameCancerType != null">
                          <td class="w-50">
                            <div *ngFor="let drugs of fusionHotspotTrail.sameCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-50">
                            <div *ngFor="let association of fusionHotspotTrail.sameCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                        </tr> -->

                      <ng-container
                        *ngIf="fusionHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of fusionHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="fusionHotspotTrail.otherCancerType != null">
                          <td class="w-33 ">
                            <div *ngFor="let drugs of fusionHotspotTrail.otherCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-33">
                            <div *ngFor="let association of fusionHotspotTrail.otherCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                      <td class="w-33">
                        <div *ngFor="let cancerType of fusionHotspotTrail.otherCancerType.cancerTypeList">
                        {{cancerType.name}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="fusionHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of fusionHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="fusionHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </table>
          <p
            *ngIf="
              reportPdfRes.snvHotspotTrailResList.length != 0 ||
              reportPdfRes.cnaHotspotTrailResList.length != 0
            "
            style="font-size: 13px !important"
            class="mt-3"
          >
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->

      <!--  -->

      <!-- gene Data -->
      <div *ngIf="!geneFlag">
        <div *ngIf="reportPdfRes.geneMutationResList != null" class="mt-3">
          <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
            Status of variants in cancer related Biomarkers
          </h6>
        </div>

        <table
          class="staus_gene bg_skyblue"
          *ngIf="reportPdfRes.geneMutationResList != null"
          [ngClass]="{
            biomarker_table:
              reportPdfRes.geneMutationResList.length >= 4 &&
              reportPdfRes.geneMutationResList.length < 8
          }"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <table
          class="staus_gene"
          [ngClass]="'bg_skyblue'"
          *ngIf="
            reportPdfRes.geneMutationResList != null &&
            reportPdfRes.geneMutationResList.length > 8
          "
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <h6
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          class="font-weight-bold clr-cyan text-uppercase"
          [ngClass]="'txt-skyblue'"
        >
          Gene Fusion
        </h6>
        <table
          class="staus_gene"
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          [ngClass]="'bg_skyblue'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
      </div>
      <!-- <div *ngIf="reportPdfRes.geneMutationResList != null"  class="row">
         <div class="col">
           <div class="gene_name d-flex rounded">
             <h6 class="gene_title mb-0">GENE</h6>
             <span *ngFor= "let geneMutation of reportPdfRes.geneMutationResList | slice:0:8"><i>{{geneMutation.gene}}</i></span>

           </div>
           <div class="gene_status d-flex ">
            <h6 class="gene_title mb-0">STATUS</h6>
            <span *ngFor= "let geneMutation of reportPdfRes.geneMutationResList | slice:0:8" style="font-size :14pximportant;">
              <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
              <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
              <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
            </span>
            <!-- <span class="det_cyan">Detected</span> -->
      <!-- </div>
         </div>
       </div> -->

      <!-- <div *ngIf="reportPdfRes.geneMutationResList != null &&  reportPdfRes.geneMutationResList.length > 8"  class="row mt-4">
        <div class="col">
          <div class="gene_name d-flex rounded">
            <h6 class="gene_title mb-0">GENE</h6>
            <span *ngFor= "let geneMutation of reportPdfRes.geneMutationResList | slice:8:17"><i>{{geneMutation.gene}}</i></span>

          </div>
          <div class="gene_status d-flex ">
           <h6 class="gene_title mb-0">STATUS</h6>
           <span *ngFor= "let geneMutation of reportPdfRes.geneMutationResList | slice:8:17" style="font-size :14pximportant;">
            <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
            <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
            <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
          </span>
         </div>
        </div>
      </div> -->

      <div
        *ngIf="
          report.disclaimerNote != ' ' &&
          report.disclaimerNote != null &&
          tableCount <= 5
        "
      >
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div
          style="font-size: 14px !important"
          [innerHtml]="report.disclaimerNote"
        ></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>

      <!--  -->

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>

    </div>

    <div
      id="page1_2"
      *ngIf="newsnvHotspotTrailResList.length > 0"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Genomic findings from tumor profiling
      </h6>
      <div class="row">
        <!-- <div class="col-12"  *ngIf="isReportDownload">
          <img [src]="firstPageTable" alt="" class="img-fluid w-90">
        </div> -->
        <div class="col-12" id="firstPageTable">
          <table
            class="page_1 cust_table w-100 mt-3 first_1"
            [ngClass]="'table_skyblue'"
          >
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr *ngIf="newsnvHotspotTrailResList.length == 0">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with
                therapeutic significance were not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container>
              <tr *ngFor="let snvHotspotTrail of newsnvHotspotTrailResList">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  <span *ngIf="snvHotspotTrail.exonNumber != null">
                    Exon {{ snvHotspotTrail.exonNumber }} ({{
                      snvHotspotTrail.pposition
                    }})</span
                  >
                  <p *ngIf="snvHotspotTrail.exonNumber != null">
                    Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                  </p>
                </td>
                <td>
                  <table class="first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                          <td class="w-50">
                            <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-50">
                            <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                          <td class="w-33 ">
                            <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-33">
                            <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                      <td class="w-33">
                        <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                        {{cancerType.name}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </table>
          <p
            *ngIf="newsnvHotspotTrailResList.length != 0"
            style="font-size: 13px !important"
            class="mt-3"
          >
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page2"
      *ngIf="
        report.addNotes != '<div></div>' ||
        otherCountSNV == true ||
        reportPdfRes.cnaReportedVariantList.length > 0 ||
        reportPdfRes.fusionReportedVariantList.length > 0 ||
        (report.disclaimerNote != ' ' && report.disclaimerNote != null)
      "
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          report.disclaimerNote != ' ' &&
          report.disclaimerNote != null &&
          tableCount >= 6
        "
      >
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div
          style="font-size: 14px !important"
          [innerHtml]="report.disclaimerNote"
        ></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>
      <div *ngIf="geneFlag">
        <div *ngIf="reportPdfRes.geneMutationResList != null" class="mt-3">
          <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
            Status of variants in cancer related Biomarkers
          </h6>
        </div>
        <table
          class="staus_gene"
          *ngIf="reportPdfRes.geneMutationResList != null"
          [ngClass]="'bg_skyblue'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <table
          class="staus_gene"
          [ngClass]="'bg_skyblue'"
          *ngIf="
            reportPdfRes.geneMutationResList != null &&
            reportPdfRes.geneMutationResList.length > 8
          "
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <h6
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          class="font-weight-bold clr-cyan text-uppercase"
          [ngClass]="'txt-skyblue'"
        >
          Gene Fusion
        </h6>
        <table
          class="staus_gene"
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          [ngClass]="'bg_skyblue'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
      </div>

      <!--  -->

      <div
        class="row mt-3"
        *ngIf="
          otherCountSNV == true ||
          reportPdfRes.cnaReportedVariantList.length != 0 ||
          reportPdfRes.fusionReportedVariantList.length != 0
        "
      >
        <div class="col">
          <h6
            class="font-weight-bold clr-cyan text-uppercase"
            [ngClass]="'txt-skyblue'"
          >
            Variant Details:
          </h6>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          otherCountSNV == true ||
          reportPdfRes.cnaReportedVariantList.length != 0 ||
          reportPdfRes.fusionReportedVariantList.length != 0
        "
      >
        <div class="col-12">
          <table
            class="page_1 cust_table w-100 mt-3 varient_details"
            [ngClass]="'table_variant-blue'"
          >
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant Consequence</th>
                <th>Clinical Significance</th>
                <th>Variant Type</th>
                <th>Reference</th>
              </tr>
            </thead>

            <!-- SNV -->
            <tr
              *ngFor="
                let selectedReportedVariant of reportPdfRes.snvReportedVariantList
              "
            >
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ selectedReportedVariant.refGeneX }}</i>
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.genomicAlteration }},<br />
                {{ selectedReportedVariant.ensembleValue }},<br />
                Exon {{ selectedReportedVariant.exonNumber }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.selectedAAChangeknownGene }} ,

                {{
                  selectedReportedVariant.mutantBurdenPercent * 0.01 | percent
                }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.exonicFuncknownGene }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.reference }}
              </td>
            </tr>

            <!-- CNA -->
            <tr
              *ngFor="
                let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
              "
            >
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ cnaReportedVariant.gene }}</i>
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                  cnaReportedVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ cnaReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                Copy Number
                {{ cnaReportedVariant.sample.split(":")[2] | titlecase }}
                ({{ cnaReportedVariant.sample.split("|")[1].split(":")[0] }})
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ cnaReportedVariant.reference }}
              </td>
            </tr>
            <!-- Fusion -->
            <tr
              *ngFor="
                let fusionReportedVariant of reportPdfRes.fusionReportedVariantList
              "
            >
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ fusionReportedVariant.fusionGene }}</i>
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.leftBreakPoint }}<br />{{
                  fusionReportedVariant.rightBreakPoint
                }}
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                Gene Fusions
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.reference }}
              </td>
            </tr>
          </table>
          <p
            *ngIf="
              otherCountSNV == true ||
              reportPdfRes.cnaReportedVariantList.length > 0 ||
              reportPdfRes.fusionReportedVariantList.length > 0
            "
            style="font-size: 13px !important"
            class="mt-3"
          >
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <div
        class="row mt-5"
        *ngIf="
          !variantFlag &&
          report.disclaimer != '<p></p>' &&
          report.disclaimer != null
        "
      >
        <div class="col">
          <h6 class="py-2 h6_bg">Disclaimer</h6>
          <div
            class="py-2"
            [innerHtml]="report.disclaimer"
            style="text-align: justify; font-size: 14px !important"
          ></div>
        </div>
      </div>

      <!--  -->
      <div
        class="row mt-5"
        *ngIf="!variantFlag && report.addNotes != '<div></div>'"
      >
        <div class="col">
          <h6
            class="font-weight-bold clr-cyan text-uppercase"
            [ngClass]="'txt-skyblue'"
          >
            Additional Notes:
          </h6>
          <!-- <p>{{report.addNotes}}</p> -->
          <div
            class="py-2"
            [innerHtml]="report.addNotes"
            style="text-align: justify; font-size: 14px !important"
          ></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page2_1"
      *ngIf="
        variantFlag &&
        (report.addNotes != '<div></div>' ||
          otherCountSNV == true ||
          reportPdfRes.cnaReportedVariantList.length > 0 ||
          reportPdfRes.fusionReportedVariantList.length > 0 ||
          (report.disclaimerNote != ' ' && report.disclaimerNote != null))
      "
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>

      <!--  -->

      <div
        class="row mt-5"
        *ngIf="report.disclaimer != '<p></p>' && report.disclaimer != null"
      >
        <div class="col">
          <h6 class="py-2 h6_bg">Disclaimer</h6>
          <div
            class="py-2"
            [innerHtml]="report.disclaimer"
            style="text-align: justify; font-size: 14px !important"
          ></div>
        </div>
      </div>

      <!--  -->
      <div class="row mt-5" *ngIf="report.addNotes != '<div></div>'">
        <div class="col">
          <h6
            class="font-weight-bold clr-cyan text-uppercase"
            [ngClass]="'txt-skyblue'"
          >
            Additional Notes:
          </h6>
          <!-- <p>{{report.addNotes}}</p> -->
          <div
            class="py-2"
            [innerHtml]="report.addNotes"
            style="text-align: justify; font-size: 14px !important"
          ></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--  -->

    <div
      id="page3"
      *ngIf="reportPdfRes.drugsResList.length > 0"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Variants With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table
            class="page_1 cust_table w-100 mt-3 first_2 custom-table"
            [ngClass]="'bg_cyan'"
          >
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 0 : 5;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page3_1"
      *ngIf="reportPdfRes.drugsResList.length > 5"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Variants With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 5 : 10;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div
      id="page3_2"
      *ngIf="reportPdfRes.drugsResList.length > 10"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Variants With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 3rd_table">
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 10 : 15;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page4"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4">
              <li>
                Hampel, Heather, et al. "A practice guideline from the American
                College of Medical Genetics and Genomics and the National
                Society of Genetic Counselors: referral indications for cancer
                predisposition assessment." Genetics in Medicine 17.1 (2015):
                70.
              </li>
              <li>
                Hoffman-Andrews, Lily. "The known unknown: the challenges of
                genetic variants of uncertain significance in clinical
                practice." Journal of Law and the Biosciences 4.3 (2017): 648.
              </li>
              <li>
                Landrum M. J. et al., ClinVar: public archive of interpretations
                of clinically relevant variants. Nucleic Acids Res.,
                44(D1):D862-8, 2015.
              </li>
              <li>
                Li, Marilyn M., et al. "Standards and guidelines for the
                interpretation and reporting of sequence variants in cancer: a
                joint consensusrecommendation of the Association for Molecular
                Pathology, American Society of Clinical Oncology, and College of
                American Pathologists." The Journal of molecular diagnostics
                19.1 (2017): 4-23.
              </li>
              <li>
                Nykamp, K., Anderson, M., Powers, M., Garcia, J., Herrera, B.,
                Ho, Y. Y., Topper, S. (2017). Sherloc: a comprehensive
                refinement of the ACMG-AMP variant classification criteria.
                Genetics in medicine: official journal of the American College
                of Medical Genetics, 19(10), 1105–1117. doi:10.1038/gim.2017.37
              </li>
              <li>
                Richards S. et al. Standards and Guidelines for Interpretation
                of Sequence Variants: A joint consensus recommendation of the
                American College of Medical Genetics and Genomics and
                Association for Molecular Pathology. Genetics in Medicine
                (2015); 17: 405- 423
              </li>
              <li>
                Spratt, Daniel E., et al. "Racial/ethnic disparities in genomic
                sequencing." JAMA oncology 2.8 (2016): 1070-1074
              </li>
              <li>
                The AACR Project GENIE Consortium. AACR Project GENIE: powering
                precision medicine through an international consortium. Cancer
                Discovery. 2017;7(8):818-831.
              </li>
              <li>
                The UniProt Consortium. UniProt: a worldwide hub of protein
                knowledge. Nucleic Acids Research. 2019;47: D506-D515
              </li>
            </ul>
          </div>

          <!-- <div class="img_signs">
          <img src="../../../assets/signatures.png" alt="" class="img-fluid">
        </div> -->
          <!--  -->

          <!--  -->
          <!-- <div class="mt-4">
          <h6 class="mt-3 mb-4 py-2 h6_bg" >
            GENES EVALUATED </h6>
           <p><span class="font-weight-normal">taR</span><b>GT</b> <span class="clr-cyan pl-2">First</span> detects mutations (SNVs and Short Indels), Copy Number Variations (CNVs), gene fusions and splice variants in the 53 genes :</p>
        </div> -->

          <!-- <div class="row mx-0">
          <div class="col-7 px-0">
            <div class="genes_info">
              <div class="title py-2">
                <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>SNVs/InDels</b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
           <div class="gene_body">
            <div class="gene_list"><span>ABL1</span><span>ALK</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRAF</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span></div>
            <div class="gene_list"><span>CDK4</span><span>CDK6</span><span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span></div>
            <div class="gene_list"><span>FANCL</span><span>FGFR3</span><span>GAPDH</span><span>IDH1</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>KRAS</span><span>MAP2K1</span><span>MAP2K2</span></div>
            <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span><span>MLH3</span><span>MSH2</span><span>MSH6</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span></div>
            <div class="gene_list"><span>PIK3CA</span><span>PMS1</span><span>PMS2</span><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>RET</span><span>ROS1</span></div>
            <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span></div>
           </div>
              </div>
          </div>
          <div class="col">
            <div class="title py-2">
              <h6 class=" mb-0 d-inline-block border-bottom  py-2"><b>Gene Fusions </b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
          </div>


        </div> -->
        </div>
      </div>

      <div class="img_signs above-footer">
        <div class="row d-flex justify-content-between">
          <div class="col-4">

            <p style="
                  text-align: center;

                  font-size: 15px !important;
                ">(Electronically signed by)
            </p>
            <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
              Vidya H Veldore, PhD<br />Vice President - Clinical Operations
            </p>
          </div>
          <div class="col-4">
            <p style="text-align: center; font-size: 15px !important;">
              (Electronically signed by)</p>
              <p style="
              text-align: center;
              font-weight: bolder;
              font-size: 15px !important;
            ">
            Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
          </div>
          <div class="col-4">
            <p style="
              text-align: center;

              font-size: 15px !important;
            ">(Electronically signed by)
            </p>
            <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
              Vyomesh Javle<br />Head - Clinical Bioinformatics
            </p>
          </div>
        </div>
      </div>

      <!--  -->


      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page5"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
        <p class="mb-2">
          <span class="font-weight-normal">TAR</span><b>GT</b>
          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First</span> is
          a Next Generation Sequencing based test which identifies genetic
          alterations in a comprehensive panel of well curated 53 tumor genes
          which can impact response to approved therapy for a particular cancer
          type. Some of the alterations detected may have bearing on prognosis
          and/or therapeutic options and may provide relevant information that
          allows oncologists/clinicians to consider various lines of targeted
          treatment for the patient.
        </p>
      </div>
      <div class="row">
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
            <p>
              <span class="font-weight-normal">TAR</span><b>GT</b>
              <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First</span>
              detects mutations (SNVs and Short Indels), Copy Number Variations
              (CNVs), gene fusions and splice variants in the 53 genes :
            </p>
          </div>
          <!--  -->
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>SNVs/InDels</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>AR</span
                      ><span>ATM</span><span>BARD1</span><span>BRAF</span
                      ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                      ><span>CDK12</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                      ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                      ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                      ><span>EZH2</span>
                    </div>
                    <div class="gene_list">
                      <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                      ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                      ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                      ><span>MAP2K2</span>
                    </div>
                    <div class="gene_list">
                      <span>MDM2</span><span>MET</span><span>MLH1</span
                      ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                      ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                      ><span>PDGFRB</span>
                    </div>
                    <div class="gene_list">
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                      ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                      ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                      ><span>ROS1</span>
                    </div>
                    <div class="gene_list">
                      <span>STK11</span><span>TSC1</span><span>TSC2</span>
                    </div>
                  </i>
                </div>

                <div class="title py-2 mt-4">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>CNAs</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>AR</span><span>ATM</span
                      ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                      ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                      ><span>CDK6</span>
                    </div>
                    <div class="gene_list">
                      <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                      ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                      ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                      ><span>FGFR3</span>
                    </div>
                    <div class="gene_list">
                      <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                      ><span>KIT</span><span>MDM2</span><span>NRAS</span
                      ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                      ><span>PPP2R2A</span>
                    </div>
                    <div class="gene_list">
                      <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                      ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                      ><span>TSC2</span>
                    </div>
                  </i>
                </div>
                <div class="title py-2 mt-4">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>Gene Fusions </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'"
                      >First</span
                    >
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <div class="gene_list">
                    <i
                      ><span>ALK</span><span>MET</span><span>RET</span
                      ><span>ROS1</span></i
                    >
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      </div>
      <!--  -->
      <h6 class="py-2 h6_bg mt-4" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
      <!--  -->
      <div class="row mt-3">
        <div class="col">
          <h6 class="font-weight-bold">
            Sample preparation and Library preparation :
          </h6>
          <p>
            DNA isolated from FFPE, or any other fresh tumor tissue source was
            used to perform targeted gene capture using a custom capture kit.
            The libraries were sequenced to mean >350X coverage on Illumina
            sequencing platform.
          </p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <h6 class="font-weight-bold">
            Bioinformatics Analysis and Reporting :
          </h6>
          <p>
            The sequences obtained are aligned to human reference genome
            (GRCh37/hg19) and variant analysis was performed using set of
            Bioinformatics Pipeline. Only non-synonymous and splice site
            variants found in the panel consisting of specific set of genes were
            used for clinical interpretation. Silent variations that do not
            result in any change in amino acid in the coding region are not
            reported. Clinically relevant mutations were annotated using
            published variants in literature and a set of databases – ClinVar,
            COSMIC and dbSNP. Common variants are filtered based on allele
            frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
            absence of a clinically significant reported known variation(s),
            pathogenicity will be predicted based on in-silico gene
            prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
            taster and prioritized for clinical correlation. The identified
            pathogenic variant will be correlated with observed phenotypic
            features of the patient and interpreted according to American
            College of Medical Genetics (ACMG) guidelines.
          </p>
          <p class="mb-2 d-block">
            Somatic variants are classified into two tiers based on their level
            of clinical significance in cancer diagnosis, prognosis, and/or
            therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
            NCCN and ESMO
          </p>
        </div>
      </div>
<!--  -->
<div class="row footer">
  <div class="col-12">
    <div class="pb-2 pl-4 text">
      <div class="d-block">
        <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
      </div>
      <div class="footer_body" [ngClass]="'gene-bg_blue'">
        <div class="footer_list">
          <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
          <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
          <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
          <span>Ile - I</span>
        </div>
        <div class="footer_list">
          <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
          <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
          <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
          <span>Val - V</span>
        </div>
      </div>
    </div>
    <div class="col-12 pt-1 ">
      <p style="font-size: 8px!important; line-height: 14px!important;">
        {{footerDiscription}}<br/>{{footerDiscription2}}
      </p>
    </div>
  </div>
</div>
</div>


        <div
      id="page5_1"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Cancer Site<br />
                <span>{{ report.cancerType }}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
                <span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/oncoquest logo.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img
                src="../../../assets/pathlab.png"
                alt=""
                style="width: 100px"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->


      <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
        LIMITATIONS AND DISCLAIMER
      </h6>

      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4">
              <li>
                DNA studies do not constitute a definitive test for the selected
                condition(s) in all individuals. It should be realized that
                there are possible sources of error. Errors can result from
                trace contamination, rare technical errors, rare genetic
                variants that interfere with analysis, recent scientific
                developments, and alternative classification systems. This test
                should be one of the many aspects used by the healthcare
                provider to help with a diagnosis and treatment plan.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting
                which is usually the longest coding transcript with
                strong/multiple supporting evidence. However, in rare cases,
                clinically relevant variants annotated in alternate complete
                coding transcripts could also be reported.
              </li>
              <li>
                The contents of this test should be carefully assessed by the
                treating physician and further interpreted along with clinical,
                histopathological findings, contraindications and guidelines
                before deciding the course of therapy.
              </li>
              <li>
                The chromosomal aberrations like copy number variations and
                rearrangements may not be reliably detected with this assay and
                have to be confirmed by alternate method.
              </li>
              <li>
                The sensitivity of this assay to detect large
                deletions/duplications of more than 10 bp or copy number
                variations (CNV) is 70-75%. The CNVs detected have to be
                confirmed by alternate method.
              </li>
              <li>
                Most recent block is recommended for testing as the mutation
                profile may change in response to treatment and hence differ at
                different sampling points.
              </li>
              <li>
                TARGT FIRST test has been developed, validated and performed by
                4baseCare Genomics Pvt. Ltd and has not been cleared or approved
                by the FDA.
              </li>
              <li>
                The identified pathogenic variant will be correlated with
                observed phenotypic features of the patient and interpreted
                according to (ASCO) guidelines.
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations
                could be missed. A negative result cannot rule out the
                possibility that the tested tumor sample carries mutations not
                previously associated with cancer and hence not included in the
                panel.
              </li>
              <li>
                Our limit of detection for TARGT First is 5% for SNVs, 10% for InDels and CNV gain >10.
                In addition to this, sequencing quality and coverage is dependent on many factors such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant detection.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <!--For LifeCell -->
  <div *ngIf="!is4basecare && !isTMH">
    <!-- <div id="page" class="container position-relative" style="height: 1600px; overflow-y: clip; background-color: white;">
    <div class="row header">
      <div class="w-100 overflow-hidden">
        <div class="col ">
          <div class="cover_page">
            <img src="../../../assets/targ_first/lifecell_cover.png" alt="" class="img-fluid w-100">
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div> -->

    <div
      id="page1"
      class="container position-relative pagee"
      style="background-color: white"
    >
    <div class="row header header_height mt-1"></div>
      <!--  -->

      <div class="mar_top"></div>
      <!--  -->
      <div>
        <div class="col-3" style="padding-left: 0">
          <h5
            class="py-2"
            [ngClass]="'purple'"
            style="padding-left: 10px; border-radius: 5px"
          >
            Targeted 53 Genes Panel
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold" [ngClass]="'txt-purple'">
                SCOPE OF THE TEST
              </p>
              <p>SNVs, InDels, CNAs and Gene Fusion status</p>
              <!-- <p>{{report.subTestName}}</p> -->
            </div>
            <img
              src="../../../assets/targ_first/purple_divider.png"
              class="mr-2 w-75"
            />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-100">
              <!-- <p style="color: #208dcb;" class="py-1">
                  <span style="color: #000 !important"></span>
                </p> -->
              <div class="w-100" style="min-height: 80px">
                <p
                  [ngClass]="'txt-purple'"
                  class="py-1 font-weight-bold"
                  *ngIf="report.clinicalSummary != null"
                >
                  <span *ngIf="report.clinicalSummary != ' '"
                    >CLINICAL INDICATION</span
                  >
                </p>

                <p *ngIf="report.clinicalSummary != null">
                  <span
                    style="color: #000 !important"
                    *ngIf="report.clinicalSummary != ' '"
                    >{{ report.clinicalSummary }}</span
                  >
                </p>
              </div>
              <!-- </div> -->
              <img
                src="../../../assets/targ_first/purple_divider.png"
                class="mr-2 w-100"
              />

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6
            class="text-uppercase py-2 font-weight-bold"
            [ngClass]="'txt-purple'"
          >
            Report Details
          </h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-purple'">Name : </span
              ><span>{{ report.patient?.name }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-purple'">Gender : </span
              ><span>{{ report.patient?.gender }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-purple'">Age/DOB : </span
              ><span>{{
                report.patient?.dateOfBirth
                  ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                  : report.patient?.age
                  ? report.patient?.age + " Years"
                  : report.patient?.age
              }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-purple'"
                >Reporting Date :
              </span>
              <span class="text-black">{{ date | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled pt-4 mt-3">
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-purple'"
                >Sample Source :
              </span>
              <span class="text-black">{{ sampleSource }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-purple'"
                >Consulting Clinician :
              </span>
              <span>{{ referringClinician }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-purple'">Hospital : </span>
              <span>{{ report.labDetails?.hospital }}</span>
            </li>
            <li *ngIf="report.firstCancerType != null" class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-purple'"
                >Cancer Celltype :
              </span>
              <span class="text-black">{{ report.firstCancerType.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <div class="row mt-2 py-2">
        <div class="col">
          <h5 class="mb-0 text-uppercase f-weight_700">Results</h5>
        </div>
      </div>
      <h6 class="py-2 h6_bg" [ngClass]="'purple'">
        Genomic findings from tumor profiling
      </h6>

      <!--  -->
      <div class="row">
        <div class="col-12" *ngIf="isReportDownload">
          <img [src]="firstPageTable" alt="" class="img-fluid w-90" />
        </div>
        <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
          <table
            class="page_1 cust_table w-100 mt-3 first_1"
            [ngClass]="'table_purple'"
          >
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr
              *ngIf="
                reportPdfRes.snvHotspotTrailResList.length == 0 &&
                reportPdfRes.cnaHotspotTrailResList.length == 0 &&
                reportPdfRes.fusionHotspotTrailResList.length == 0
              "
            >
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with
                therapeutic significance were not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container>
              <tr
                *ngFor="
                  let snvHotspotTrail of reportPdfRes.snvHotspotTrailResList
                "
              >
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  <span *ngIf="snvHotspotTrail.exonNumber != null">
                    Exon {{ snvHotspotTrail.exonNumber }}</span
                  ><span *ngIf="snvHotspotTrail.pposition != null">
                    ({{ snvHotspotTrail.pposition }})</span
                  >
                  <p *ngIf="snvHotspotTrail.exonNumber != null">
                    Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                  </p>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                      <td class="w-50">
                        <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                        <span *ngFor="let drug of drugs, let i=index">
                            {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                        </span>
                    </div>
                  </td>
                    <td class="w-50">
                        <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                        {{association}}
                    </div>
                  </td>
                    </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                      <td class="w-33 ">
                        <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                        <span *ngFor="let drug of drugs, let i=index">
                            {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                        </span>
                    </div>
                  </td>
                    <td class="w-33">
                        <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                        {{association}}
                    </div>
                  </td>
                  <td class="w-33">
                    <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                    {{cancerType.name}}
                    </div>
                  </td>
                    </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <!-- CNA -->
              <tr
                *ngFor="
                  let cnaHotspotTrail of reportPdfRes.cnaHotspotTrailResList
                "
              >
                <td>
                  <i>{{ cnaHotspotTrail.gene }}</i>
                  {{ cnaHotspotTrail.copyNumber }}
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-50">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="cnaHotspotTrail.sameCancerType != null">
                      <td class="w-50">
                        <div *ngFor="let drugs of cnaHotspotTrail.sameCancerType.drugList">
                        <span *ngFor="let drug of drugs, let i=index">
                            {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                        </span>
                    </div>
                  </td>
                    <td class="w-50">
                        <div *ngFor="let association of cnaHotspotTrail.sameCancerType.associationList">
                        {{association}}
                    </div>
                  </td>
                    </tr> -->
                      <ng-container
                        *ngIf="cnaHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of cnaHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                      <tr *ngIf="cnaHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="cnaHotspotTrail.otherCancerType != null">
                      <td class="w-33 ">
                        <div *ngFor="let drugs of cnaHotspotTrail.otherCancerType.drugList">
                        <span *ngFor="let drug of drugs, let i=index">
                            {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                        </span>
                    </div>
                  </td>
                    <td class="w-33">
                        <div *ngFor="let association of cnaHotspotTrail.otherCancerType.associationList">
                        {{association}}
                    </div>
                  </td>
                  <td class="w-33">
                    <div *ngFor="let cancerType of cnaHotspotTrail.otherCancerType.cancerTypeList">
                    {{cancerType.name}}
                    </div>
                  </td>
                    </tr> -->

                      <ng-container
                        *ngIf="cnaHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of cnaHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>

                      <tr *ngIf="cnaHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <!-- Fusion -->
              <tr
                *ngFor="
                  let fusionHotspotTrail of reportPdfRes.fusionHotspotTrailResList
                "
              >
                <td>
                  <i>{{ fusionHotspotTrail.gene }}</i>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="fusionHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <!-- <tr *ngIf="fusionHotspotTrail.sameCancerType != null">
                      <td class="w-50">
                        <div *ngFor="let drugs of fusionHotspotTrail.sameCancerType.drugList">
                        <span *ngFor="let drug of drugs, let i=index">
                            {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                        </span>
                    </div>
                  </td>
                    <td class="w-50">
                        <div *ngFor="let association of fusionHotspotTrail.sameCancerType.associationList">
                        {{association}}
                    </div>
                  </td>
                    </tr> -->

                      <ng-container
                        *ngIf="fusionHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of fusionHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="fusionHotspotTrail.otherCancerType != null">
                      <td class="w-33 ">
                        <div *ngFor="let drugs of fusionHotspotTrail.otherCancerType.drugList">
                        <span *ngFor="let drug of drugs, let i=index">
                            {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                        </span>
                    </div>
                  </td>
                    <td class="w-33">
                        <div *ngFor="let association of fusionHotspotTrail.otherCancerType.associationList">
                        {{association}}
                    </div>
                  </td>
                  <td class="w-33">
                    <div *ngFor="let cancerType of fusionHotspotTrail.otherCancerType.cancerTypeList">
                    {{cancerType.name}}
                    </div>
                  </td>
                    </tr> -->
                      <ng-container
                        *ngIf="fusionHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of fusionHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="fusionHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </table>
          <p
            *ngIf="
              reportPdfRes.snvHotspotTrailResList.length != 0 ||
              reportPdfRes.cnaHotspotTrailResList.length != 0
            "
            style="font-size: 13px !important"
            class="mt-3"
          >
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->
      <div
        *ngIf="!firstTableFlag && reportPdfRes.geneMutationResList != null"
        class="mt-3"
      >
        <h6 class="py-2 h6_bg" [ngClass]="'purple'">
          Status of variants in cancer related Biomarkers
        </h6>
      </div>
      <!--  -->

      <!-- gene Data -->
      <div *ngIf="!geneFlag">
        <table
          class="staus_gene"
          *ngIf="reportPdfRes.geneMutationResList != null"
          [ngClass]="'bg_purple'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <table
          class="staus_gene"
          [ngClass]="'bg_purple'"
          *ngIf="
            reportPdfRes.geneMutationResList != null &&
            reportPdfRes.geneMutationResList.length > 8
          "
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <h6
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          class="font-weight-bold clr-cyan text-uppercase"
          [ngClass]="'txt-purple'"
        >
          Gene Fusion
        </h6>
        <table
          class="staus_gene"
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          [ngClass]="'bg_purple'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
      </div>
      <!-- <div *ngIf="reportPdfRes.geneMutationResList != null"  class="row">
     <div class="col">
       <div class="gene_name d-flex rounded">
         <h6 class="gene_title mb-0">GENE</h6>
         <span *ngFor= "let geneMutation of reportPdfRes.geneMutationResList | slice:0:8"><i>{{geneMutation.gene}}</i></span>

       </div>
       <div class="gene_status d-flex ">
        <h6 class="gene_title mb-0">STATUS</h6>
        <span *ngFor= "let geneMutation of reportPdfRes.geneMutationResList | slice:0:8" style="font-size :14pximportant;">
          <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
          <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
          <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
        </span>
        <!-- <span class="det_cyan">Detected</span> -->
      <!-- </div>
     </div>
   </div> -->

      <!-- <div *ngIf="reportPdfRes.geneMutationResList != null &&  reportPdfRes.geneMutationResList.length > 8"  class="row mt-4">
    <div class="col">
      <div class="gene_name d-flex rounded">
        <h6 class="gene_title mb-0">GENE</h6>
        <span *ngFor= "let geneMutation of reportPdfRes.geneMutationResList | slice:8:17"><i>{{geneMutation.gene}}</i></span>

      </div>
      <div class="gene_status d-flex ">
       <h6 class="gene_title mb-0">STATUS</h6>
       <span *ngFor= "let geneMutation of reportPdfRes.geneMutationResList | slice:8:17" style="font-size :14pximportant;">
        <span *ngIf="geneMutation.status=='Pathogenic' || geneMutation.status=='Likely Pathogenic'" style="color:red; font-weight:600px"><b>{{geneMutation.status}}</b></span>
        <span *ngIf="geneMutation.status=='VUS'" style="color:black; font-weight:600px"><b>{{geneMutation.status}}</b></span>
        <span *ngIf="geneMutation.status == 'Not Detected' || geneMutation.status =='Benige'">{{geneMutation.status}}</span>
      </span>
     </div>
    </div>
  </div> -->

      <!-- -->
      <div
        *ngIf="
          report.disclaimerNote != ' ' &&
          report.disclaimerNote != null &&
          tableCount <= 5
        "
      >
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div
          style="font-size: 14px !important"
          [innerHtml]="report.disclaimerNote"
        ></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <!--  -->
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>
    <div
      id="page1_2"
      *ngIf="newsnvHotspotTrailResList.length > 0"
      class="container position-relative pagee"
      style="background-color: white"
    >
    <div class="row header header_height mt-1"></div>
      <!-- -->
      <h6 class="py-2 h6_bg" [ngClass]="'purple'">
        Genomic findings from tumor profiling
      </h6>
      <div class="row">
        <div class="col-12" id="firstPageTable">
          <table
            class="page_1 cust_table w-100 mt-3 first_1"
            [ngClass]="'table_purple'"
          >
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr *ngIf="newsnvHotspotTrailResList.length == 0">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with
                therapeutic significance were not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container>
              <tr *ngFor="let snvHotspotTrail of newsnvHotspotTrailResList">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  <span *ngIf="snvHotspotTrail.exonNumber != null">
                    Exon {{ snvHotspotTrail.exonNumber }}</span
                  >
                  <span *ngIf="snvHotspotTrail.pposition != null">
                    ({{ snvHotspotTrail.pposition }})</span
                  >
                  <p *ngIf="snvHotspotTrail.exonNumber != null">
                    Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                  </p>
                </td>
                <td>
                  <table class="first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                    <td class="w-50">
                      <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                      <span *ngFor="let drug of drugs, let i=index">
                          {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                      </span>
                  </div>
                </td>
                  <td class="w-50">
                      <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                      {{association}}
                  </div>
                </td>
                  </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                    <td class="w-33 ">
                      <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                      <span *ngFor="let drug of drugs, let i=index">
                          {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                      </span>
                  </div>
                </td>
                  <td class="w-33">
                      <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                      {{association}}
                  </div>
                </td>
                <td class="w-33">
                  <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                  {{cancerType.name}}
                  </div>
                </td>
                  </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </table>
          <p
            *ngIf="newsnvHotspotTrailResList.length != 0"
            style="font-size: 13px !important"
            class="mt-3"
          >
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>
    <div
      id="page2"
      *ngIf="
        report.addNotes != '<div></div>' ||
        otherCountSNV == true ||
        reportPdfRes.cnaReportedVariantList.length > 0 ||
        reportPdfRes.fusionReportedVariantList.length > 0 ||
        (report.disclaimerNote != ' ' && report.disclaimerNote != null)
      "
      class="container position-relative pagee"
      style="background-color: white"
    >
    <div class="row header header_height mt-1"></div>
      <!-- -->
      <div
        *ngIf="
          report.disclaimerNote != ' ' &&
          report.disclaimerNote != null &&
          tableCount >= 6
        "
      >
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div
          style="font-size: 14px !important"
          [innerHtml]="report.disclaimerNote"
        ></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>
      <!-- -->
      <div *ngIf="geneFlag">
        <div *ngIf="reportPdfRes.geneMutationResList != null" class="mt-3">
          <h6 class="py-2 h6_bg" [ngClass]="'purple'">
            Status of variants in cancer related Biomarkers
          </h6>
        </div>
        <table
          class="staus_gene"
          *ngIf="reportPdfRes.geneMutationResList != null"
          [ngClass]="'bg_purple'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <table
          class="staus_gene"
          [ngClass]="'bg_purple'"
          *ngIf="
            reportPdfRes.geneMutationResList != null &&
            reportPdfRes.geneMutationResList.length > 8
          "
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <h6
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          class="font-weight-bold clr-cyan text-uppercase"
          [ngClass]="'txt-skyblue'"
        >
          Gene Fusion
        </h6>
        <table
          class="staus_gene"
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          [ngClass]="'bg_skyblue'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
      </div>
      <!--  -->

      <div
        class="row mt-5"
        *ngIf="
          otherCountSNV == true ||
          reportPdfRes.cnaReportedVariantList.length != 0 ||
          reportPdfRes.fusionReportedVariantList.length != 0
        "
      >
        <div class="col">
          <h6
            class="font-weight-bold clr-cyan text-uppercase"
            [ngClass]="'txt-purple'"
          >
            Variant Details:
          </h6>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          otherCountSNV == true ||
          reportPdfRes.cnaReportedVariantList.length != 0 ||
          reportPdfRes.fusionReportedVariantList.length != 0
        "
      >
        <div class="col-12">
          <table
            class="page_1 cust_table w-100 mt-3 varient_details"
            [ngClass]="'table_variant-purple'"
          >
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant Consequence</th>
                <th>Clinical Significance</th>
                <th>Variant Type</th>
                <th>Reference</th>
              </tr>
            </thead>

            <!-- SNV -->
            <tr
              *ngFor="
                let selectedReportedVariant of reportPdfRes.snvReportedVariantList
              "
            >
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ selectedReportedVariant.refGeneX }}</i>
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.genomicAlteration }},<br />
                {{ selectedReportedVariant.ensembleValue }},<br />
                Exon {{ selectedReportedVariant.exonNumber }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.selectedAAChangeknownGene }},
                {{
                  selectedReportedVariant.mutantBurdenPercent * 0.01 | percent
                }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.exonicFuncknownGene }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.reference }}
              </td>
            </tr>

            <!-- CNA -->
            <tr
              *ngFor="
                let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
              "
            >
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ cnaReportedVariant.gene }}</i>
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                  cnaReportedVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ cnaReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                Copy Number
                {{ cnaReportedVariant.sample.split(":")[2] | titlecase }}
                ({{ cnaReportedVariant.sample.split("|")[1].split(":")[0] }})
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ cnaReportedVariant.reference }}
              </td>
            </tr>
            <!-- Fusion -->
            <tr
              *ngFor="
                let fusionReportedVariant of reportPdfRes.fusionReportedVariantList
              "
            >
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ fusionReportedVariant.fusionGene }}</i>
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.leftBreakPoint }}<br />{{
                  fusionReportedVariant.rightBreakPoint
                }}
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                Gene Fusions
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.reference }}
              </td>
            </tr>
          </table>
          <p
            *ngIf="
              otherCountSNV == true ||
              reportPdfRes.cnaReportedVariantList.length > 0 ||
              reportPdfRes.fusionReportedVariantList.length > 0
            "
            style="font-size: 13px !important"
            class="mt-3"
          >
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <div
        class="row mt-5"
        *ngIf="report.disclaimer != '<p></p>' && report.disclaimer != null"
      >
        <div class="col">
          <h6 class="py-2 h6_bg" [ngClass]="'purple'">Disclaimer</h6>
          <div
            class="py-2"
            [innerHtml]="report.disclaimer"
            style="text-align: justify; font-size: 14px !important"
          ></div>
        </div>
      </div>

      <!--  -->
      <div class="row mt-5" *ngIf="report.addNotes != '<div></div>'">
        <div class="col">
          <h6
            class="font-weight-bold clr-cyan text-uppercase"
            [ngClass]="'txt-purple'"
          >
            Additional Notes:
          </h6>
          <!-- <p>{{report.addNotes}}</p> -->
          <div
            class="py-2"
            [innerHtml]="report.addNotes"
            style="text-align: justify; font-size: 14px !important"
          ></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>
    <!--  -->

    <div
      id="page3"
      *ngIf="reportPdfRes.drugsResList.length > 0"
      class="container position-relative pagee"
      style="background-color: white"
    >
    <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'purple'">
        Variants With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table
            class="page_1 cust_table w-100 mt-3 first_2 custom-table"
            [ngClass]="'bg_pink'"
          >
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 0 : 5;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page3_1"
      *ngIf="reportPdfRes.drugsResList.length > 5"
      class="container position-relative pagee"
      style="background-color: white"
    >
    <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'purple'">
        Variants With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table
            class="page_1 cust_table w-100 mt-3 first_2 2nd_table"
            [ngClass]="'bg_pink'"
          >
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 5 : 10;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!--  -->
    <div
      id="page3_2"
      *ngIf="reportPdfRes.drugsResList.length > 10"
      class="container position-relative pagee"
      style="background-color: white"
    >
    <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'purple'">
        Variants With Clinically Relevant Therapies
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table
            class="page_1 cust_table w-100 mt-3 first_2 3rd_table"
            [ngClass]="'bg_pink'"
          >
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 10 : 15;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page4"
      class="container position-relative pagee"
      style="background-color: white"
    >
    <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'purple'">References</h6>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4">
              <li>
                Hampel, Heather, et al. "A practice guideline from the American
                College of Medical Genetics and Genomics and the National
                Society of Genetic Counselors: referral indications for cancer
                predisposition assessment." Genetics in Medicine 17.1 (2015):
                70.
              </li>
              <li>
                Hoffman-Andrews, Lily. "The known unknown: the challenges of
                genetic variants of uncertain significance in clinical
                practice." Journal of Law and the Biosciences 4.3 (2017): 648.
              </li>
              <li>
                Landrum M. J. et al., ClinVar: public archive of interpretations
                of clinically relevant variants. Nucleic Acids Res.,
                44(D1):D862-8, 2015.
              </li>
              <li>
                Li, Marilyn M., et al. "Standards and guidelines for the
                interpretation and reporting of sequence variants in cancer: a
                joint consensusrecommendation of the Association for Molecular
                Pathology, American Society of Clinical Oncology, and College of
                American Pathologists." The Journal of molecular diagnostics
                19.1 (2017): 4-23.
              </li>
              <li>
                Nykamp, K., Anderson, M., Powers, M., Garcia, J., Herrera, B.,
                Ho, Y. Y., Topper, S. (2017). Sherloc: a comprehensive
                refinement of the ACMG-AMP variant classification criteria.
                Genetics in medicine: official journal of the American College
                of Medical Genetics, 19(10), 1105–1117. doi:10.1038/gim.2017.37
              </li>
              <li>
                Richards S. et al. Standards and Guidelines for Interpretation
                of Sequence Variants: A joint consensus recommendation of the
                American College of Medical Genetics and Genomics and
                Association for Molecular Pathology. Genetics in Medicine
                (2015); 17: 405- 423
              </li>
              <li>
                Spratt, Daniel E., et al. "Racial/ethnic disparities in genomic
                sequencing." JAMA oncology 2.8 (2016): 1070-1074
              </li>
              <li>
                The AACR Project GENIE Consortium. AACR Project GENIE: powering
                precision medicine through an international consortium. Cancer
                Discovery. 2017;7(8):818-831.
              </li>
              <li>
                The UniProt Consortium. UniProt: a worldwide hub of protein
                knowledge. Nucleic Acids Research. 2019;47: D506-D515
              </li>
            </ul>
          </div>
          <div class="img_signs">
            <div class="row">
              <div class="col-4">
                <img
                  src="../../../assets/targ_first/sign1.png"
                  alt=""
                  style="padding-left: 60px; padding-right: 60px; width: 60%"
                />
                <p
                  style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  "
                >
                  Dr. Chirayu Padhiar, DCP (G25442)<br /><span
                    class="font-weight-normal"
                    >Senior Medical Director</span
                  >
                </p>
              </div>
              <div class="col-4 offset-4 text-center">
                <img
                  src="../../../assets/targ_first/sign2.png"
                  alt=""
                  style="padding-left: 60px; padding-right: 60px; width: 60%"
                />
                <p
                  style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  "
                >
                  Dr. Pragya Gupta MBBS, MD(Pathology)<br /><span
                    class="font-weight-normal"
                    >PDF Molecular Genetics (TATA MEDICAL) Head -
                    Oncogenomics</span
                  >
                </p>
              </div>
            </div>
          </div>

          <!-- <div class="img_signs">
      <img src="../../../assets/signatures.png" alt="" class="img-fluid">
    </div> -->
          <!--  -->

          <!--  -->
          <!-- <div class="mt-4">
      <h6 class="mt-3 mb-4 py-2 h6_bg" >
        GENES EVALUATED </h6>
       <p><span class="font-weight-normal">taR</span><b>GT</b> <span class="clr-cyan pl-2">First</span> detects mutations (SNVs and Short Indels), Copy Number Variations (CNVs), gene fusions and splice variants in the 53 genes :</p>
    </div> -->

          <!-- <div class="row mx-0">
      <div class="col-7 px-0">
        <div class="genes_info">
          <div class="title py-2">
            <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>SNVs/InDels</b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
       <div class="gene_body">
        <div class="gene_list"><span>ABL1</span><span>ALK</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRAF</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span></div>
        <div class="gene_list"><span>CDK4</span><span>CDK6</span><span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span></div>
        <div class="gene_list"><span>FANCL</span><span>FGFR3</span><span>GAPDH</span><span>IDH1</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>KRAS</span><span>MAP2K1</span><span>MAP2K2</span></div>
        <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span><span>MLH3</span><span>MSH2</span><span>MSH6</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span></div>
        <div class="gene_list"><span>PIK3CA</span><span>PMS1</span><span>PMS2</span><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>RET</span><span>ROS1</span></div>
        <div class="gene_list"><span>MDM2</span><span>MET</span><span>MLH1</span></div>
       </div>
          </div>
      </div>
      <div class="col">
        <div class="title py-2">
          <h6 class=" mb-0 d-inline-block border-bottom  py-2"><b>Gene Fusions </b> Coverage in <span class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2">First</span></h6></div>
      </div>


    </div> -->
        </div>
      </div>

      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'purple'">TEST DESCRIPTION</h6>
        <p class="mb-2">
          <span class="font-weight-normal">Onco</span>
          <span
            class="clr-cyan pl-1"
            [ngClass]="'txt-purple'"
            style="font-weight: 600"
            >Life</span
          >
          Comprehensive Targeted 53 genes panel is a Next Generation Sequencing
          based test which identifies genetic alterations in a comprehensive
          panel of well curated 53 tumor genes which can impact response to
          approved therapy for a particular cancer type. Some of the alterations
          detected may have bearing on prognosis and/or therapeutic options and
          may provide relevant information that allows oncologists/clinicians to
          consider vanous lines of targeted treatment for the patient.
        </p>
      </div>
      <div class="">
        <div class="">
          <h6 class="py-2 h6_bg" [ngClass]="'purple'">GENES EVALUATED</h6>
          <p>
            <span class="font-weight-normal">Onco</span
            ><span
              class="clr-cyan pl-2"
              [ngClass]="'txt-purple'"
              style="font-weight: 600"
            >
              Life</span
            >
            Comprehensive Targeted 53 genes panel detects mutations (SNVs and
            Short Indels), Copy Number Variations (CNVs), gene fusions and
            splice variants in the 53 genes :
          </p>
        </div>
        <!--  -->
        <div class="row mx-0">
          <div class="col-12 px-0">
            <div class="genes_info">
              <div class="title py-2">
                <h6 class="mb-0 d-inline-block border-bottom py-2">
                  <b>SNVs/InDels</b> Covered in
                  <span class="font-weight-normal" style="font-weight: 500"
                    >Onco</span
                  >
                  <span
                    class="clr-cyan pl-2"
                    [ngClass]="'txt-purple'"
                    style="font-weight: 600"
                    >Life</span
                  >
                </h6>
              </div>
              <div class="gene_body" [ngClass]="'gene-bg_purple'">
                <i>
                  <div class="gene_list">
                    <span>ABL1</span><span>ALK</span><span>AR</span
                    ><span>ATM</span><span>BARD1</span><span>BRAF</span
                    ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                    ><span>CDK12</span>
                  </div>
                  <div class="gene_list bg-white">
                    <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                    ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                    ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                    ><span>EZH2</span>
                  </div>
                  <div class="gene_list">
                    <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                    ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                    ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                    ><span>MAP2K2</span>
                  </div>
                  <div class="gene_list">
                    <span>MDM2</span><span>MET</span><span>MLH1</span
                    ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                    ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                    ><span>PDGFRB</span>
                  </div>
                  <div class="gene_list">
                    <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                    ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                    ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                    ><span>ROS1</span>
                  </div>
                  <div class="gene_list">
                    <span>STK11</span><span>TSC1</span><span>TSC2</span>
                  </div>
                </i>
              </div>

              <div class="title py-2 mt-4">
                <h6 class="mb-0 d-inline-block border-bottom py-2">
                  <b>CNAs</b> Covered in
                  <span class="font-weight-normal" style="font-weight: 500"
                    >Onco</span
                  >
                  <span
                    class="clr-cyan pl-2"
                    [ngClass]="'txt-purple'"
                    style="font-weight: 600"
                    >Life</span
                  >
                </h6>
              </div>
              <div class="gene_body" [ngClass]="'gene-bg_purple'">
                <i>
                  <div class="gene_list">
                    <span>ABL1</span><span>AR</span><span>ATM</span
                    ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                    ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                    ><span>CDK6</span>
                  </div>
                  <div class="gene_list">
                    <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                    ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                    ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                    ><span>FGFR3</span>
                  </div>
                  <div class="gene_list">
                    <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                    ><span>KIT</span><span>MDM2</span><span>NRAS</span
                    ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                    ><span>PPP2R2A</span>
                  </div>
                  <div class="gene_list">
                    <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                    ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                    ><span>TSC2</span>
                  </div>
                </i>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="title py-2">
              <h6 class="mb-0 d-inline-block border-bottom py-2">
                <b>Gene Fusions </b> Covered in
                <span style="font-weight: 500">Onco</span
                ><span
                  class="clr-cyan pl-2"
                  [ngClass]="'txt-purple'"
                  style="font-weight: 600"
                  >Life</span
                >
              </h6>
            </div>
            <div class="gene_body" [ngClass]="'gene-bg_purple'">
              <div class="gene_list">
                <i
                  ><span>ALK</span><span>MET</span><span>RET</span
                  ><span>ROS1</span></i
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!-- <div id="page4_1" class="container position-relative page" style="background-color: white;">
      <div class="row header mt-1 py-4">
        <div>
          <div class="col-3">
            <img src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px">
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{report.patient?.ecrfId}}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{report.patient?.name}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mar_top"></div>

      <div class="row" *ngIf="report.subTestName!='Homologous recombination repair (HRR)'">
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg" [ngClass]="'purple'">
              GENES EVALUATED </h6>
            <p><span class="font-weight-normal">Onco</span><span class="clr-cyan pl-2" [ngClass]="'txt-purple'" style="font-weight: 600;">
              Life</span> e Comprehensive Targeted 53 genes panel detects mutations (SNVs and Short Indels), Copy Number Variations
                (CNVs), gene fusions and splice variants in the 53 genes :</p>
          </div>

          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>SNVs/InDels</b> Covered in <span
                      class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2"
                      [ngClass]="'txt-purple'">First</span></h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_purple'"><i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRAF</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>CDK4</span><span>CDK6</span><span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                    </div>
                    <div class="gene_list">
                      <span>FANCL</span><span>FGFR3</span><span>GAPDH</span><span>IDH1</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>KRAS</span><span>MAP2K1</span><span>MAP2K2</span>
                    </div>
                    <div class="gene_list">
                      <span>MDM2</span><span>MET</span><span>MLH1</span><span>MLH3</span><span>MSH2</span><span>MSH6</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                    </div>
                    <div class="gene_list">
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>RET</span><span>ROS1</span>
                    </div>
                    <div class="gene_list"><span>STK11</span><span>TSC1</span><span>TSC2</span></div>
                  </i>
                </div>

                <div class="title py-2 mt-4">
                  <h6 class="mb-0 d-inline-block border-bottom  py-2"><b>CNAs</b> Covered in <span
                      class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2"
                      [ngClass]="'txt-purple'">First</span></h6>
                </div>
                <div class="gene_body " [ngClass]="'gene-bg_purple'"><i>
                    <div class="gene_list">
                      <span>ABL1</span><span>AR</span><span>ATM</span><span>BARD1</span><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span><span>CDK12</span><span>CDK4</span><span>CDK6</span>
                    </div>
                    <div class="gene_list ">
                      <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span><span>EGFR</span><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span><span>EZH2</span><span>FANCL</span><span>FGFR3</span>
                    </div>
                    <div class="gene_list">
                      <span>GAPDH</span><span>IDH2</span><span>JAK2</span><span>KIT</span><span>MDM2</span><span>NRAS</span><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span><span>PPP2R2A</span>
                    </div>
                    <div class="gene_list">
                      <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span><span>RAD54L</span><span>STK11</span><span>TSC1</span><span>TSC2</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="title py-2">
                <h6 class=" mb-0 d-inline-block border-bottom  py-2"><b>Gene Fusions </b> Covered in <span
                    class="font-weight-normal">TAR</span><b>GT</b> <span class="clr-cyan pl-2"
                    [ngClass]="'txt-purple'">First</span></h6>
              </div>
              <div class="gene_body" [ngClass]="'gene-bg_purple'">
                <div class="gene_list"><i><span>ALK</span><span>MET</span><span>RET</span><span>ROS1</span></i></div>


              </div>
            </div>


          </div>
        </div>
      </div>




      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important">LifeCell International Pvt. Ltd</span>
            </p>
            <p>
              #16/9 Vijayaraghava lane, Vijayaraghava Road,
            </p>
            <p>
              T.Nagar,Chennai-600017 Tamil Nadu, India
            </p>
            <p>
              CIN: U85196TN2004PTC053577
            </p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div> -->
    <div
      id="page5"
      class="container position-relative pagee"
      style="background-color: white"
    >
    <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'purple'">TEST METHODOLOGY</h6>
      <!--  -->
      <div class="row mt-3">
        <div class="col">
          <h6 class="font-weight-bold">
            Sample preparation and Library preparation :
          </h6>
          <p>
            DNA isolated from FFPE, or any other fresh tumor tissue source was
            used to perform targeted gene capture using a custom capture kit.
            The libraries were sequenced to mean >350X coverage on Illumina
            sequencing platform.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h6 class="font-weight-bold">
            Bioinformatics Analysis and Reporting :
          </h6>
          <p>
            The sequences obtained are aligned to human reference genome
            (GRCh37/hg19) and variant analysis was performed using set of
            Bioinformatics Pipeline. Only non-synonymous and splice site
            variants found in the panel consisting of specific set of genes were
            used for clinical interpretation. Silent variations that do not
            result in any change in amino acid in the coding region are not
            reported. Clinically relevant mutations were annotated using
            published variants in literature and a set of databases – ClinVar,
            COSMIC and dbSNP. Common variants are filtered based on allele
            frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
            absence of a clinically significant reported known variation(s),
            pathogenicity will be predicted based on in-silico gene
            prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
            taster and prioritized for clinical correlation. The identified
            pathogenic variant will be correlated with observed phenotypic
            features of the patient and interpreted according to American
            College of Medical Genetics (ACMG) guidelines.
          </p>
          <p>
            Somatic variants are classified into two tiers based on their level
            of clinical significance in cancer diagnosis, prognosis, and/or
            therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
            NCCN and ESMO
          </p>
        </div>
      </div>

      <h6 class="py-2 h6_bg mt-2" [ngClass]="'purple'">
        LIMITATIONS AND DISCLAIMER
      </h6>

      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4 pt-4">
              <li>
                DNA studies do not constitute a definitive test for the selected
                condition(s) in all individuals. It should be realized that
                there are possible sources of error. Errors can result from
                trace contamination, rare technical errors, rare genetic
                variants that interfere with analysis, recent scientific
                developments, and alternative classification systems. This test
                should be one of the many aspects used by the healthcare
                provider to help with a diagnosis and treatment plan.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting
                which is usually the longest coding transcript with
                strong/multiple supporting evidence. However, in rare cases,
                clinically relevant variants annotated in alternate complete
                coding transcripts could also be reported.
              </li>
              <li>
                The contents of this test should be carefully assessed by the
                treating physician and further interpreted along with clinical,
                histopathological findings, contraindications and guidelines
                before deciding the course of therapy.
              </li>
              <li>
                The chromosomal aberrations like copy number variations and
                rearrangements may not be reliably detected with this assay and
                have to be confirmed by alternate method.
              </li>
              <li>
                The sensitivity of this assay to detect large
                deletions/duplications of more than 10 bp or copy number
                variations (CNV) is 70-75%. The CNVs detected have to be
                confirmed by alternate method.
              </li>
              <li>
                Most recent block is recommended for testing as the mutation
                profile may change in response to treatment and hence differ at
                different sampling points.
              </li>
              <!-- <li>TARGT FIRST test has been developed, validated and performed by 4baseCare Genomics Pvt. Ltd and has
                not been cleared or approved
                by the FDA.</li> -->
              <li>
                The identified pathogenic variant will be correlated with
                observed phenotypic features of the patient and interpreted
                according to (ASCO) guidelines.
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations
                could be missed. A negative result cannot rule out the
                possibility that the tested tumor sample carries mutations not
                previously associated with cancer and hence not included in the
                panel.
              </li>
              <li>
                Our limit of detection for TARGT First is 5% for SNVs, 10% for InDels and CNV gain >10.
                In addition to this, sequencing quality and coverage is dependent on many factors such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant detection.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!--For Tmh -->
  <div *ngIf="!is4basecare && isTMH">
    <div
      id="page1"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <!-- <div class="logo">
                <img src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px"  >
              </div> -->
          </div>
        </div>
        <div></div>
        <div class="col">
          <!-- <div class="patient-detailes ml-auto float-right">
              <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
                <li>
                  Patient Id <br />
                </li>
                <li class="ml-4">
                  Cancer Site<br />
                  <span>{{report.cancerType}}</span>
                </li>
                <li class="ml-4">
                  SAMPLE ID<br />
                </li>
                <li class="ml-4">
                  Registration Date<br />
                  <span>{{report.labDetails?.sampleCollected | date:'dd/MM/yyyy'}}</span>
                </li>
              </ul>
            </div> -->
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold">SCOPE OF THE TEST</p>
              <p>SNVs, InDels, CNAs and Gene Fusion status</p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-75" />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-100">
              <!-- <p style="color: #208dcb;" class="py-1">
                      <span style="color: #000 !important"></span>
                    </p> -->
              <div class="w-100" style="min-height: 80px">
                <p
                  [ngClass]="'txt-skyblue'"
                  class="py-1 font-weight-bold"
                  *ngIf="report.clinicalSummary != null"
                >
                  <span *ngIf="report.clinicalSummary != ' '"
                    >CLINICAL INDICATION</span
                  >
                </p>

                <p *ngIf="report.clinicalSummary != null">
                  <span
                    style="color: #000 !important"
                    *ngIf="report.clinicalSummary != ' '"
                    >{{ report.clinicalSummary }}</span
                  >
                </p>
              </div>
              <!-- </div> -->
              <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan">Patient Name : </span>
              <span>{{ report.patient?.name }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Gender/Age : </span
              ><span>{{ report.patient?.gender }}</span
              ><span
                *ngIf="
                  report.patient?.gender &&
                  (report.patient?.dateOfBirth || report.patient?.age)
                "
                style="font-size: larger !important"
              >
                / </span
              ><span>{{
                report.patient?.dateOfBirth
                  ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                  : report.patient?.age
                  ? report.patient?.age + " Years"
                  : report.patient?.age
              }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Case No : </span
              ><span>{{ report?.tmhReportInfo?.caseNo }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Path No : </span
              ><span>{{ report?.tmhReportInfo?.pathNo }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Referred By : </span
              ><span>{{ referringClinician }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled mt-4 pt-4">
            <li class="pb-1">
              <span class="clr-cyan">Patient ID : </span
              ><span>{{ report.patient?.organisationPatientId }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Category : </span
              ><span>{{ report?.tmhReportInfo?.category }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Collected on : </span
              ><span>{{
                report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Reported on : </span
              ><span>{{ date | date : "dd/MM/yyyy" }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Hospital Name : </span
              ><span>{{ report.labDetails?.hospital }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <h6 class="py-2 h6_bg">CLINICAL HISTORY/SAMPLE DETAILS</h6>
      <div class="row mt-2 py-2">
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Clinical Evaluations</h6>
          <span>{{ report?.cancerIdentifier }}</span>
        </div>
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Sample Details</h6>
          <span *ngIf="report.labDetails?.sampleSource">{{
            report.labDetails?.sampleSource
          }}</span>
          <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
        </div>
        <div class="col">
          <h6 class="mb-0 font-weight-bold">Tumor content</h6>
          <span>{{ report?.tmhReportInfo?.tumorContent }}</span>
        </div>
        <div class="w-100">
          <div class="col pt-4">
            <p>(Confirmed by - Consultant Pathologist, HBCHRC, Muzaffarpur)</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row mt-2 py-2">
        <div class="col">
          <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
        </div>
      </div>
      <h6 class="py-2 h6_bg">Genomic findings from tumor profiling</h6>

      <!--  -->
      <div class="row">
        <div class="col-12" *ngIf="isReportDownload">
          <img [src]="firstPageTable" alt="" class="img-fluid w-90" />
        </div>

        <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
          <table class="page_1 cust_table w-100 mt-3 first_1">
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr
              *ngIf="
                reportPdfRes.snvHotspotTrailResList.length == 0 &&
                reportPdfRes.cnaHotspotTrailResList.length == 0 &&
                reportPdfRes.fusionHotspotTrailResList.length == 0
              "
            >
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with
                therapeutic significance were not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container>
              <tr
                *ngFor="
                  let snvHotspotTrail of reportPdfRes.snvHotspotTrailResList
                "
              >
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  <span *ngIf="snvHotspotTrail.exonNumber != null">
                    Exon {{ snvHotspotTrail.exonNumber }} ({{
                      snvHotspotTrail.pposition
                    }})</span
                  >
                  <p *ngIf="snvHotspotTrail.exonNumber != null">
                    Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                  </p>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                          <td class="w-50">
                            <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-50">
                            <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                          <td class="w-33 ">
                            <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-33">
                            <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                      <td class="w-33">
                        <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                        {{cancerType.name}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <!-- CNA -->
              <tr
                *ngFor="
                  let cnaHotspotTrail of reportPdfRes.cnaHotspotTrailResList
                "
              >
                <td>
                  <i>{{ cnaHotspotTrail.gene }}</i>
                  {{ cnaHotspotTrail.copyNumber }}
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-50">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="cnaHotspotTrail.sameCancerType != null">
                          <td class="w-50">
                            <div *ngFor="let drugs of cnaHotspotTrail.sameCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-50">
                            <div *ngFor="let association of cnaHotspotTrail.sameCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="cnaHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of cnaHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                      <tr *ngIf="cnaHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="cnaHotspotTrail.otherCancerType != null">
                          <td class="w-33 ">
                            <div *ngFor="let drugs of cnaHotspotTrail.otherCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-33">
                            <div *ngFor="let association of cnaHotspotTrail.otherCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                      <td class="w-33">
                        <div *ngFor="let cancerType of cnaHotspotTrail.otherCancerType.cancerTypeList">
                        {{cancerType.name}}
                        </div>
                      </td>
                        </tr> -->

                      <ng-container
                        *ngIf="cnaHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of cnaHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>

                      <tr *ngIf="cnaHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <!-- Fusion -->
              <tr
                *ngFor="
                  let cnaHotspotTrail of reportPdfRes.fusionHotspotTrailResList
                "
              >
                <td>
                  <i>{{ cnaHotspotTrail.gene }}</i>
                  {{ cnaHotspotTrail.copyNumber }}
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-50">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="cnaHotspotTrail.sameCancerType != null">
                          <td class="w-50">
                            <div *ngFor="let drugs of cnaHotspotTrail.sameCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-50">
                            <div *ngFor="let association of cnaHotspotTrail.sameCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                        </tr> -->
                      <ng-container
                        *ngIf="cnaHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of cnaHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                      <tr *ngIf="cnaHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="cnaHotspotTrail.otherCancerType != null">
                          <td class="w-33 ">
                            <div *ngFor="let drugs of cnaHotspotTrail.otherCancerType.drugList">
                            <span *ngFor="let drug of drugs, let i=index">
                                {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                            </span>
                        </div>
                      </td>
                        <td class="w-33">
                            <div *ngFor="let association of cnaHotspotTrail.otherCancerType.associationList">
                            {{association}}
                        </div>
                      </td>
                      <td class="w-33">
                        <div *ngFor="let cancerType of cnaHotspotTrail.otherCancerType.cancerTypeList">
                        {{cancerType.name}}
                        </div>
                      </td>
                        </tr> -->

                      <ng-container
                        *ngIf="cnaHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of cnaHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>

                      <tr *ngIf="cnaHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>

      <div *ngIf="!geneFlag">
        <div *ngIf="reportPdfRes.geneMutationResList != null" class="mt-3">
          <h6 class="py-2 h6_bg">
            Status of variants in cancer related Biomarkers
          </h6>
        </div>
        <table
          class="staus_gene"
          *ngIf="reportPdfRes.geneMutationResList != null"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <table
          class="staus_gene"
          *ngIf="
            reportPdfRes.geneMutationResList != null &&
            reportPdfRes.geneMutationResList.length > 8
          "
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <h6
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          class="font-weight-bold clr-cyan text-uppercase"
          [ngClass]="'txt-skyblue'"
        >
          Gene Fusion
        </h6>
        <table
          class="staus_gene"
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          [ngClass]="'bg_skyblue'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
      </div>
      <div
        *ngIf="
          report.disclaimerNote != ' ' &&
          report.disclaimerNote != null &&
          tableCount <= 5
        "
      >
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div
          style="font-size: 14px !important"
          [innerHtml]="report.disclaimerNote"
        ></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>
    <div
      id="page1_2"
      *ngIf="newsnvHotspotTrailResList.length > 0"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <!-- <div class="col-3">
              <img src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px"  >
          </div> -->
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <h6 class="py-2 h6_bg">Genomic findings from tumor profiling</h6>
      <div class="row">
        <div class="col-12" id="firstPageTable">
          <table class="page_1 cust_table w-100 mt-3 first_1">
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr *ngIf="newsnvHotspotTrailResList.length == 0">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with
                therapeutic significance were not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container>
              <tr *ngFor="let snvHotspotTrail of newsnvHotspotTrailResList">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  <span *ngIf="snvHotspotTrail.exonNumber != null">
                    Exon {{ snvHotspotTrail.exonNumber }} ({{
                      snvHotspotTrail.pposition
                    }})</span
                  >
                  <p *ngIf="snvHotspotTrail.exonNumber != null">
                    Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                  </p>
                </td>
                <td>
                  <table class="first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <!-- <tr *ngIf="snvHotspotTrail.sameCancerType != null">
                        <td class="w-50">
                          <div *ngFor="let drugs of snvHotspotTrail.sameCancerType.drugList">
                          <span *ngFor="let drug of drugs, let i=index">
                              {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                          </span>
                      </div>
                    </td>
                      <td class="w-50">
                          <div *ngFor="let association of snvHotspotTrail.sameCancerType.associationList">
                          {{association}}
                      </div>
                    </td>
                      </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.sameCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.sameTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Clinical Relevance</i></span
                          >
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"
                            ><i>Cancer Type</i></span
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="snvHotspotTrail.otherCancerType != null">
                        <td class="w-33 ">
                          <div *ngFor="let drugs of snvHotspotTrail.otherCancerType.drugList">
                          <span *ngFor="let drug of drugs, let i=index">
                              {{drug.name}}<span *ngIf="drugs.length-1 != i">,</span>
                          </span>
                      </div>
                    </td>
                      <td class="w-33">
                          <div *ngFor="let association of snvHotspotTrail.otherCancerType.associationList">
                          {{association}}
                      </div>
                    </td>
                    <td class="w-33">
                      <div *ngFor="let cancerType of snvHotspotTrail.otherCancerType.cancerTypeList">
                      {{cancerType.name}}
                      </div>
                    </td>
                      </tr> -->
                      <ng-container
                        *ngIf="snvHotspotTrail.otherCancerType != null"
                      >
                        <tr
                          *ngFor="
                            let item of snvHotspotTrail.otherTypeRelevantData
                          "
                        >
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>
    <div
      id="page2"
      *ngIf="
        (report.addNotes != null && report.addNotes != '<div></div>') ||
        tableCount >= 6 ||
        (report.disclaimer != null &&
          report.disclaimer != '<p></p>' &&
          report.disclaimer != '<div></div>') ||
        otherCountSNV == true ||
        reportPdfRes.cnaReportedVariantList.length > 0 ||
        reportPdfRes.fusionReportedVariantList.length > 0
      "
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <!-- <div class="col-3">
                <img src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px"  >
            </div> -->
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div
        *ngIf="
          report.disclaimerNote != ' ' &&
          report.disclaimerNote != null &&
          tableCount >= 6
        "
      >
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div
          style="font-size: 14px !important"
          [innerHtml]="report.disclaimerNote"
        ></div>
        <!-- <div class="py-2" [innerHtml]='report.disclaimerNote' style=" text-align: justify;font-size: 14px!important;"></div> -->

        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample. Kindly correlate clinically before making any treatment decisions.</p> -->
        <!-- <p>The data size we had received for this sample was inadequate. However, with the patient's best interest in mind we have further processed the sample and a false negative status of the result cannot be ruled out. Kindly correlate clinically before making any treatment decisions.</p> -->
      </div>
      <div *ngIf="geneFlag">
        <div *ngIf="reportPdfRes.geneMutationResList != null" class="mt-3">
          <h6 class="py-2 h6_bg">
            Status of variants in cancer related Biomarkers
          </h6>
        </div>
        <table
          class="staus_gene"
          *ngIf="reportPdfRes.geneMutationResList != null"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <table
          class="staus_gene"
          *ngIf="
            reportPdfRes.geneMutationResList != null &&
            reportPdfRes.geneMutationResList.length > 8
          "
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.geneMutationResList
                  | slice : 8 : 17
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
        <br />
        <h6
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          class="font-weight-bold clr-cyan text-uppercase"
          [ngClass]="'txt-skyblue'"
        >
          Gene Fusion
        </h6>
        <table
          class="staus_gene"
          *ngIf="
            reportPdfRes.fusionGeneMutationResList != null &&
            reportPdfRes.fusionGeneMutationResList.length > 0
          "
          [ngClass]="'bg_skyblue'"
        >
          <tr style="background: #daedf7">
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              Gene
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <i>{{ geneMutation.gene }}</i>
            </td>
          </tr>
          <tr>
            <td
              style="font-size: 18px !important; padding-left: 10px !important"
            >
              <b>Status</b>
            </td>
            <td
              *ngFor="
                let geneMutation of reportPdfRes.fusionGeneMutationResList
                  | slice : 0 : 8
              "
            >
              <span
                *ngIf="
                  geneMutation.status == 'Pathogenic' ||
                  geneMutation.status == 'Likely Pathogenic' ||
                  geneMutation.status == 'Detected'
                "
                style="color: red; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="geneMutation.status == 'VUS'"
                style="color: black; font-weight: 600px"
                ><b>{{ geneMutation.status }}</b></span
              >
              <span
                *ngIf="
                  geneMutation.status == 'Not Detected' ||
                  geneMutation.status == 'Benige'
                "
                >{{ geneMutation.status }}</span
              >
            </td>
          </tr>
        </table>
      </div>

      <div
        class="row mt-3"
        *ngIf="
          otherCountSNV == true ||
          reportPdfRes.cnaReportedVariantList.length != 0 ||
          reportPdfRes.fusionReportedVariantList.length != 0
        "
      >
        <div class="col">
          <h6 class="font-weight-bold clr-cyan text-uppercase">
            Variant Details:
          </h6>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          otherCountSNV == true ||
          reportPdfRes.cnaReportedVariantList.length != 0 ||
          reportPdfRes.fusionReportedVariantList.length != 0
        "
      >
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details">
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant Consequence</th>
                <th>Clinical Significance</th>
                <th>Variant Type</th>
                <th>Reference</th>
              </tr>
            </thead>

            <!-- SNV -->
            <tr
              *ngFor="
                let selectedReportedVariant of reportPdfRes.snvReportedVariantList
              "
            >
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ selectedReportedVariant.refGeneX }}</i>
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.genomicAlteration }},<br />
                {{ selectedReportedVariant.ensembleValue }},<br />
                Exon {{ selectedReportedVariant.exonNumber }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.selectedAAChangeknownGene }},
                {{
                  selectedReportedVariant.mutantBurdenPercent * 0.01 | percent
                }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.exonicFuncknownGene }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ selectedReportedVariant.reference }}
              </td>
            </tr>

            <!-- CNA -->
            <tr
              *ngFor="
                let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
              "
            >
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ cnaReportedVariant.gene }}</i>
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                  cnaReportedVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ cnaReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                Copy Number
                {{ cnaReportedVariant.sample.split(":")[2] | titlecase }}
                ({{ cnaReportedVariant.sample.split("|")[1].split(":")[0] }})
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ cnaReportedVariant.reference }}
              </td>
            </tr>
            <!-- Fusion -->
            <tr
              *ngFor="
                let fusionReportedVariant of reportPdfRes.fusionReportedVariantList
              "
            >
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ fusionReportedVariant.fusionGene }}</i>
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.leftBreakPoint }}<br />{{
                  fusionReportedVariant.rightBreakPoint
                }}
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.significance }}
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                Gene Fusions
              </td>
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.reference }}
              </td>
            </tr>
          </table>
          <p
            *ngIf="
              otherCountSNV == true ||
              reportPdfRes.cnaReportedVariantList.length > 0 ||
              reportPdfRes.fusionReportedVariantList.length > 0
            "
            style="font-size: 13px !important"
            class="mt-3"
          >
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <div
        class="row mt-5"
        *ngIf="report.disclaimer != '<p></p>' && report.disclaimer != null"
      >
        <div class="col">
          <h6 class="py-2 h6_bg">Disclaimer</h6>
          <div
            class="py-2"
            [innerHtml]="report.disclaimer"
            style="text-align: justify; font-size: 14px !important"
          ></div>
        </div>
      </div>

      <!--  -->
      <div class="row mt-5" *ngIf="report.addNotes != '<div></div>'">
        <div class="col">
          <h6 class="font-weight-bold clr-cyan text-uppercase">
            Additional Notes:
          </h6>
          <!-- <p>{{report.addNotes}}</p> -->
          <div
            class="py-2"
            [innerHtml]="report.addNotes"
            style="text-align: justify; font-size: 14px !important"
          ></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>
    <!--  -->

    <div
      id="page3"
      *ngIf="reportPdfRes.drugsResList.length > 0"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <!-- <div class="col-3">
                <img  src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px"  >
            </div> -->
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
              <!-- <li class="ml-4">
                Cancer Site<br />
                <span>{{report.cancerType}}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{report.labDetails?.sampleCollected | date:'dd/MM/yyyy'}}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg">Variants With Clinically Relevant Therapies</h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 custom-table">
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 0 : 4;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page3_1"
      *ngIf="reportPdfRes.drugsResList.length > 4"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <!-- <div class="col-3">
                <img src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px"  >
            </div> -->
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
              <!-- <li class="ml-4">
                Cancer Site<br />
                <span>{{report.cancerType}}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{report.labDetails?.sampleCollected | date:'dd/MM/yyyy'}}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg">Variants With Clinically Relevant Therapies</h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 2nd_table">
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 4 : 8;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>

    <!--  -->
    <div
      id="page3_2"
      *ngIf="reportPdfRes.drugsResList.length > 8"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <div class="col-3">
            <!-- <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div> -->
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
              <!-- <li class="ml-4">
                Cancer Site<br />
                <span>{{report.cancerType}}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{report.labDetails?.sampleCollected | date:'dd/MM/yyyy'}}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg">Variants With Clinically Relevant Therapies</h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 3rd_table">
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 8 : 12;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>

    <!--page3_3 -->
    <!--  -->
    <div
      id="page3_3"
      *ngIf="reportPdfRes.drugsResList.length > 12"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <div class="col-3">
            <!-- <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div> -->
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
              <!-- <li class="ml-4">
                Cancer Site<br />
                <span>{{report.cancerType}}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{report.labDetails?.sampleCollected | date:'dd/MM/yyyy'}}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg">Variants With Clinically Relevant Therapies</h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 first_2 3rd_table">
            <thead>
              <tr>
                <th>Gene (Variant) - Drug association</th>
                <th>Summary</th>
              </tr>
            </thead>
            <!-- SNV -->

            <tbody>
              <tr
                *ngFor="
                  let drugsRes of reportPdfRes.drugsResList | slice : 12 : 15;
                  let i = index
                "
              >
                <td>
                  <ul class="ml-0 pl-2">
                    <li>
                      <i>{{ drugsRes.gene }}</i> {{ drugsRes.pposition }} :
                      <br />
                      <span *ngFor="let drug of drugsRes.drugs; let i = index">
                        {{ drug.name
                        }}<span *ngIf="drugsRes.drugs.length - 1 != i">,</span>
                      </span>
                      - {{ drugsRes.association }}
                      <p>{{ drugsRes.cancerType }}</p>
                    </li>
                  </ul>
                </td>
                <td>
                  <div [innerHtml]="drugsRes.summary"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page4"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <!-- <div class="col-3">
                <img  src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px"  >
            </div> -->
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
              <!-- <li class="ml-4">
                Cancer Site<br />
                <span>{{report.cancerType}}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{report.labDetails?.sampleCollected | date:'dd/MM/yyyy'}}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>

      <h6 class="py-2 h6_bg">References</h6>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4">
              <li>
                Hampel, Heather, et al. "A practice guideline from the American
                College of Medical Genetics and Genomics and the National
                Society of Genetic Counselors: referral indications for cancer
                predisposition assessment." Genetics in Medicine 17.1 (2015):
                70.
              </li>
              <li>
                Hoffman-Andrews, Lily. "The known unknown: the challenges of
                genetic variants of uncertain significance in clinical
                practice." Journal of Law and the Biosciences 4.3 (2017): 648.
              </li>
              <li>
                Landrum M. J. et al., ClinVar: public archive of interpretations
                of clinically relevant variants. Nucleic Acids Res.,
                44(D1):D862-8, 2015.
              </li>
              <li>
                Li, Marilyn M., et al. "Standards and guidelines for the
                interpretation and reporting of sequence variants in cancer: a
                joint consensusrecommendation of the Association for Molecular
                Pathology, American Society of Clinical Oncology, and College of
                American Pathologists." The Journal of molecular diagnostics
                19.1 (2017): 4-23.
              </li>
              <li>
                Nykamp, K., Anderson, M., Powers, M., Garcia, J., Herrera, B.,
                Ho, Y. Y., Topper, S. (2017). Sherloc: a comprehensive
                refinement of the ACMG-AMP variant classification criteria.
                Genetics in medicine: official journal of the American College
                of Medical Genetics, 19(10), 1105–1117. doi:10.1038/gim.2017.37
              </li>
              <li>
                Richards S. et al. Standards and Guidelines for Interpretation
                of Sequence Variants: A joint consensus recommendation of the
                American College of Medical Genetics and Genomics and
                Association for Molecular Pathology. Genetics in Medicine
                (2015); 17: 405- 423
              </li>
              <li>
                Spratt, Daniel E., et al. "Racial/ethnic disparities in genomic
                sequencing." JAMA oncology 2.8 (2016): 1070-1074
              </li>
              <li>
                The AACR Project GENIE Consortium. AACR Project GENIE: powering
                precision medicine through an international consortium. Cancer
                Discovery. 2017;7(8):818-831.
              </li>
              <li>
                The UniProt Consortium. UniProt: a worldwide hub of protein
                knowledge. Nucleic Acids Research. 2019;47: D506-D515
              </li>
            </ul>
          </div>

          <div class="img_signs d-none">
            <div class="row">
              <div class="col-4">
                <img
                  src="../../../assets/targ_first/sign1.png"
                  alt=""
                  style="padding-left: 60px; padding-right: 60px; width: 60%"
                />
                <p
                  style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  "
                >
                  Dr. Chirayu Padhiar, DCP (G25442)<br />Senior Medical Director
                </p>
              </div>
              <div class="col-4 offset-4 text-center">
                <img
                  src="../../../assets/targ_first/sign2.png"
                  alt=""
                  style="padding-left: 60px; padding-right: 60px; width: 60%"
                />
                <p
                  style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  "
                >
                  Dr. Pragya Guptha MBBS, MD(Pathology)<br />PDF Molecular
                  Genetics (TATA MEDICAL) Head - Oncogenomics
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="img_signs">
          <img src="../../../assets/signatures.png" alt="" class="img-fluid">
        </div> -->
          <!--  -->
          <div class="">
            <h6 class="py-2 h6_bg">TEST DESCRIPTION</h6>
            <p class="mb-2">
              <span class="font-weight-normal">Tar</span> <b>GT</b>
              <span class="clr-cyan pl-2">first</span> is a Next Generation
              Sequencing based test which identifies genetic alterations in a
              comprehensive panel of well curated 53 tumor genes which can
              impact response to approved therapy for a particular cancer type.
              Some of the alterations detected may have bearing on prognosis
              and/or therapeutic options and may provide relevant information
              that allows oncologists/clinicians to consider various lines of
              targeted treatment for the patient.
            </p>
          </div>
        </div>
      </div>

      <div class="row pr-2 above-footer">
        <div class="col-4">
          <img
            src="../../../assets/tmc/basusigns.png"
            alt=""
            style="padding-left: 60px; padding-right: 60px; width: 100%"
            class="mb-2"
          />
          <span style="padding-left: 100px; font-weight: bolder"
            >Dr. Moitri Basu, Ph.D.<br /><span
              style="padding-left: 100px; font-weight: bolder"
              >Scientific Officer</span
            ></span
          >
        </div>
        <div class="col"></div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>
    <!--  -->
    <div
      id="page4_1"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <!-- <div class="col-3">
                <img  src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px"  >
            </div> -->
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
              <!-- <li class="ml-4">
                Cancer Site<br />
                <span>{{report.cancerType}}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{report.labDetails?.sampleCollected | date:'dd/MM/yyyy'}}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div
        class="row"
        *ngIf="report.subTestName != 'Homologous recombination repair (HRR)'"
      >
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg">GENES EVALUATED</h6>
            <p>
              <span class="font-weight-normal">TAR</span><b>GT</b>
              <span class="clr-cyan pl-2">First</span
              ><span class="clr-cyan pl-2">- {{ report.subTestName }}</span>
              detects mutations (SNVs and Short Indels), Copy Number Variations
              (CNVs), gene fusions and splice variants in the 53 genes :
            </p>
          </div>
          <!--  -->
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>SNVs/InDels</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2">First</span>
                  </h6>
                </div>
                <div class="gene_body">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>AR</span
                      ><span>ATM</span><span>BARD1</span><span>BRAF</span
                      ><span>BRCA1</span><span>BRCA2</span><span>BRIP1</span
                      ><span>CDK12</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>CDK4</span><span>CDK6</span><span>CDKN2A</span
                      ><span>CHEK1</span><span>CHEK2</span><span>EGFR</span
                      ><span>EPCAM</span><span>ERBB2</span><span>ERBB3</span
                      ><span>EZH2</span>
                    </div>
                    <div class="gene_list">
                      <span>FANCL</span><span>FGFR3</span><span>GAPDH</span
                      ><span>IDH1</span><span>IDH2</span><span>JAK2</span
                      ><span>KIT</span><span>KRAS</span><span>MAP2K1</span
                      ><span>MAP2K2</span>
                    </div>
                    <div class="gene_list">
                      <span>MDM2</span><span>MET</span><span>MLH1</span
                      ><span>MLH3</span><span>MSH2</span><span>MSH6</span
                      ><span>NRAS</span><span>PALB2</span><span>PDGFRA</span
                      ><span>PDGFRB</span>
                    </div>
                    <div class="gene_list">
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span
                      ><span>PPP2R2A</span><span>RAD51B</span><span>RAD51C</span
                      ><span>RAD51D</span><span>RAD54L</span><span>RET</span
                      ><span>ROS1</span>
                    </div>
                    <div class="gene_list">
                      <span>STK11</span><span>TSC1</span><span>TSC2</span>
                    </div>
                  </i>
                </div>

                <div class="title py-2 mt-4">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>CNAs</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2">First</span>
                  </h6>
                </div>
                <div class="gene_body">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>AR</span><span>ATM</span
                      ><span>BARD1</span><span>BRCA1</span><span>BRCA2</span
                      ><span>BRIP1</span><span>CDK12</span><span>CDK4</span
                      ><span>CDK6</span>
                    </div>
                    <div class="gene_list">
                      <span>CDKN2A</span><span>CHEK1</span><span>CHEK2</span
                      ><span>EGFR</span><span>EPCAM</span><span>ERBB2</span
                      ><span>ERBB3</span><span>EZH2</span><span>FANCL</span
                      ><span>FGFR3</span>
                    </div>
                    <div class="gene_list">
                      <span>GAPDH</span><span>IDH2</span><span>JAK2</span
                      ><span>KIT</span><span>MDM2</span><span>NRAS</span
                      ><span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span
                      ><span>PPP2R2A</span>
                    </div>
                    <div class="gene_list">
                      <span>RAD51B</span><span>RAD51C</span><span>RAD51D</span
                      ><span>RAD54L</span><span>STK11</span><span>TSC1</span
                      ><span>TSC2</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>
            <div class="col-7 mt-4 pl-0">
              <div class="title py-2">
                <h6 class="mb-0 d-inline-block border-bottom py-2">
                  <b>Gene Fusions </b> Covered in
                  <span class="font-weight-normal">TAR</span><b>GT</b>
                  <span class="clr-cyan pl-2">First</span>
                </h6>
              </div>
              <div class="gene_body">
                <div class="gene_list">
                  <i
                    ><span>ALK</span><span>MET</span><span>RET</span
                    ><span>ROS1</span></i
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page5"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_log1.png"
            alt=""
            class="logo_first"
          />
        </div>
        <div class="logo_part text-center">
          <img
            src="../../../assets/tmc/tmc_logo2.png"
            alt=""
            class="logo_two"
          />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img
            src="../../../assets/tmc/tmc_logo3.jpg"
            alt=""
            class="logo_three"
          />
        </div>
      </div>
      <div class="row header mt-1">
        <div>
          <!-- <div class="col-3">
                <img src="../../../assets/targ_first/lifecell_logo.png" alt="" style="width: 140px"  >
            </div> -->
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
              <!-- <li class="ml-4">
                Cancer Site<br />
                <span>{{report.cancerType}}</span>
              </li>
              <li class="ml-4">
                SAMPLE ID<br />
              </li>
              <li class="ml-4">
                Registration Date<br />
                <span>{{report.labDetails?.sampleCollected | date:'dd/MM/yyyy'}}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg">TEST METHODOLOGY</h6>
      <!--  -->
      <div class="row mt-3">
        <div class="col">
          <h6 class="font-weight-bold">
            Sample preparation and Library preparation :
          </h6>
          <p>
            DNA isolated from FFPE, or any other fresh tumor tissue source was
            used to perform targeted gene capture using a custom capture kit.
            The libraries were sequenced to mean >350X coverage on Illumina
            sequencing platform.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h6 class="font-weight-bold">
            Bioinformatics Analysis and Reporting :
          </h6>
          <p>
            The sequences obtained are aligned to human reference genome
            (GRCh37/hg19) and variant analysis was performed using set of
            Bioinformatics Pipeline. Only non-synonymous and splice site
            variants found in the panel consisting of specific set of genes were
            used for clinical interpretation. Silent variations that do not
            result in any change in amino acid in the coding region are not
            reported. Clinically relevant mutations were annotated using
            published variants in literature and a set of databases – ClinVar,
            COSMIC and dbSNP. Common variants are filtered based on allele
            frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
            absence of a clinically significant reported known variation(s),
            pathogenicity will be predicted based on in-silico gene
            prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
            taster and prioritized for clinical correlation. The identified
            pathogenic variant will be correlated with observed phenotypic
            features of the patient and interpreted according to American
            College of Medical Genetics (ACMG) guidelines.
          </p>
          <p class="mb-2">
            Somatic variants are classified into two tiers based on their level
            of clinical significance in cancer diagnosis, prognosis, and/or
            therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
            NCCN and ESMO
          </p>
        </div>
      </div>

      <h6 class="py-2 h6_bg mt-2">LIMITATIONS AND DISCLAIMER</h6>

      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4">
              <li>
                DNA studies do not constitute a definitive test for the selected
                condition(s) in all individuals. It should be realized that
                there are possible sources of error. Errors can result from
                trace contamination, rare technical errors, rare genetic
                variants that interfere with analysis, recent scientific
                developments, and alternative classification systems. This test
                should be one of the many aspects used by the healthcare
                provider to help with a diagnosis and treatment plan.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting
                which is usually the longest coding transcript with
                strong/multiple supporting evidence. However, in rare cases,
                clinically relevant variants annotated in alternate complete
                coding transcripts could also be reported.
              </li>
              <li>
                The contents of this test should be carefully assessed by the
                treating physician and further interpreted along with clinical,
                histopathological findings, contraindications and guidelines
                before deciding the course of therapy.
              </li>
              <li>
                The chromosomal aberrations like copy number variations and
                rearrangements may not be reliably detected with this assay and
                have to be confirmed by alternate method.
              </li>
              <li>
                The sensitivity of this assay to detect large
                deletions/duplications of more than 10 bp or copy number
                variations (CNV) is 70-75%. The CNVs detected have to be
                confirmed by alternate method.
              </li>
              <li>
                Most recent block is recommended for testing as the mutation
                profile may change in response to treatment and hence differ at
                different sampling points.
              </li>
              <li>
                TARGT FIRST test has been developed, validated and performed by
                4baseCare Genomics Pvt. Ltd and has not been cleared or approved
                by the FDA.
              </li>
              <li>
                The identified pathogenic variant will be correlated with
                observed phenotypic features of the patient and interpreted
                according to (ASCO) guidelines.
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations
                could be missed. A negative result cannot rule out the
                possibility that the tested tumor sample carries mutations not
                previously associated with cancer and hence not included in the
                panel.
              </li>
              <li>
                Our limit of detection for TARGT First is 5% for SNVs, 10% for InDels and CNV gain >10.
                In addition to this, sequencing quality and coverage is dependent on many factors such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant detection.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>MEDICAL GENOMICS & MOLECULAR ONCOLOGY,</p>
            <p>SRIKRISHNA MEDICAL COLLEGE & HOSPITAL</p>
            <p>UMANAGAR, MUZAFFARPUR-842004.</p>
            <p>CONTACT: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img
              src="../../../assets/tmc/tmc_footer.png"
              alt="footer_image"
              class="footer_img"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
