<div class="container-fluid px-0">
    <h5 class="mb-0 d-inline-block position-relative  mb-3 mt-4">Add Clinical Trial</h5>
   <div class="mx-0 p-4 bg-white cust_shadow rounded w-75">
    <div class="row mx-3">
        <div class="col">
            <form class="form_list" (ngSubmit)="onSubmit()">
                <div class="row mt-5">
                    <div class="col-8">
                        <!-- <div class="form-group row">
                            <label class="col-sm-4 control-label">Study Title :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="title"
                                [(ngModel)]="add_clinical.studyTitle">
                            </div>
                        </div> -->

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Cancer Type :</label>
                            <div class="col-sm-8">
                                <!-- <select class="form-control" name="cancerType" [(ngModel)]="add_clinical.cancerTypeId">
                                    <option *ngFor="let cancerType of CancerType" value={{cancerType.id}}

                                        [selected]="add_clinical.cancerTypeId == cancerType.id">{{cancerType.name}}
                                    </option>
                                </select> -->
                                <ng-multiselect-dropdown [data]="cancerTypeList" [(ngModel)]="selectedcancertype" name="cancerType"
                                    [settings]="cancerTypeDropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Gene :</label>
                            <div class="col-sm-8">
                                <!-- <select class="form-control" name="gene" [(ngModel)]="add_clinical.geneId">
                                    <option *ngFor="let gene of geneList" value={{gene.id}}>{{gene.name}}</option>
                                </select> -->
                                <ng-multiselect-dropdown [data]="geneList" [(ngModel)]="selectedGene" name="gene"
                                    [settings]="geneDropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Biomarker :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="biomarker"
                                    [(ngModel)]="add_clinical.biomarker">
                            </div>
                        </div>

                        <!-- <div class="form-group row">
                            <label class="col-sm-4 control-label">Summary :</label>
                            <div class="ml-5">
                                <ckeditor [editor]="editor" name="summary" [(ngModel)]="add_clinical.summary"></ckeditor>
                            </div>
                        </div> -->

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Drugs :</label>
                            <div class="col-sm-8">
                                <ng-multiselect-dropdown [data]="drugs" [(ngModel)]="selectedDrugs" name="drug"
                                    [settings]="drugsDropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Evidence :</label>
                            <div class="col-sm-8">
                                <select type="text" class="form-control" name="evidence"
                                    [(ngModel)]="add_clinical.evidence" (change)="selectTrailType(add_clinical.evidence)">
                                    <option value="LEVEL_1">LEVEL_1</option>
                                    <option value="LEVEL_2">LEVEL_2</option>
                                    <option value="LEVEL_3">LEVEL_3</option>
                                    <option value="LEVEL_4">LEVEL_4</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Reference :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="reference"
                                    [(ngModel)]="add_clinical.reference">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Association :</label>
                            <div class="col-sm-8">
                                <select type="text" class="form-control" name="association"
                                    [(ngModel)]="add_clinical.association">
                                    <option value="RESPONSIVE">RESPONSIVE</option>
                                    <option value="RESISTANCE">RESISTANCE</option>
                                    <option value="ONGOING">ONGOING</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Trial Type :</label>
                            <div class="col-sm-8">
                                <select type="text" class="form-control" name="clinicalTrailType"
                                    [(ngModel)]="add_clinical.clinicalTrailType">
                                    <option value="FDA">FDA</option>
                                    <option value="NCCN">NCCN</option>
                                    <option value="Clinical trials">Clinical trials</option>
                                    <option value="Pre clinical trial">Pre clinical trial</option>
                                </select>
                            </div>
                        </div>


                        <div class="add-emp  mb-5">
                            <div class="col-sm-8 offset-sm-4 px-0 text-right">
                                <button type="submit" class="btn save_btn text-uppercase bg-softblue ml-2"
                                    [disabled]="submitSpinner">
                                    <span *ngIf="!submitSpinner"> Submit </span>
                                    <div *ngIf="submitSpinner" class="spinner-border text-dark">
                                        <span class="sr-only"></span>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </form>
        </div>
    </div>
   </div>
</div>
