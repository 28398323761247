<div class="row bg-light py-3 px-3 mx-0 variant_titles d-flex justify-content-between align-items-center mx-4 rounded">
    <div class="col-2">
        <h5 class="mb-0 d-inline-block position-relative "><span class="list-icon mr-2">
                <i class="zmdi zmdi-accounts-list"></i>
            </span>{{fdatitle}}</h5>
    </div>
    <div *ngIf="reportedVariant?.report" class="col-3">
      <h5 class="mb-0"  style="font-weight: bolder;color: #218dca;">{{reportedVariant.report?.patient?.ecrfId}}  <span style="font-size: 20px!important;">[{{reportedVariant?.refGeneX}} <span *ngIf="reportedVariant.selectedAAChangeknownGene != null" style="font-size:15px!important">({{reportedVariant.selectedAAChangeknownGene.split(",")[1]}})</span>]</span></h5>
    </div>
    <div class="col-3">
      <input type="checkbox" (click)="selectedTrailsList($event)"  class="input_check"> <span style="font-size:13px; margin-left:4px">{{fdaShow}}</span>
    </div>
    <div *ngIf="!fusionVariantId" class="col">
     <p> Gene : {{gene}}</p>
    </div>
    <div *ngIf="fusionVariantId" class="col">
    <div class="d-flex">
       <span> Gene : {{gene}}</span>
      <select class="form-control" name="gene" [(ngModel)]="gene" (change)="onChangeFusionGene()">
        <option *ngFor="let fusionGene of fusionGenes" value="{{fusionGene}}"> {{fusionGene}}</option>

      </select>
    </div>
    </div>
    <div class="col">
      <div class=" text-right"> <a (click)="backReportedVariant()" class="text_color font-weight-bold back_btn">
        <i class="zmdi zmdi-long-arrow-left"></i>Back to Reported Variants</a></div>
        <!-- <a class="delete_clr" (click)="backReportedVariant()">Back to Reported Variants</a> -->
    </div>

</div>


<div class="mx-4">
  <table class="table cust-checkbox">
    <thead class="thead-light">
        <tr>
            <th *ngIf="isFist72"><input  type="checkbox" class="custom-
              control-input" (click)="deSelectOption($event)" id="save-info" required>  De-Select All</th>
              <th *ngIf="!isFist72"></th>
            <th>Star Selection</th>
            <th *ngIf="switchToggleButton">Priority Order</th>
            <th>Drugs</th>
            <th>Cancer Type</th>
            <th>Variant</th>
            <th>ASN</th>
            <th *ngIf="!isFirstReport">Action</th>
        </tr>

    </thead>
    <tbody class="bg-white">


                <div *ngIf="!trailsListSpinner">
                    <div class="spinner-border" role="status">
                        <span></span>
                    </div>
                </div>
<!--
                <div *ngIf="studyFieldsResponse.studyFields == null && trailsListSpinner">
                    <p>no data</p>
                </div> -->


        <tr *ngFor="let clinicalTrial of clinicalTrialResList; let i = index">
            <td>
                <div class="form-check">

                    <input class="form-check-input" type="checkbox" (click)="addSelectedTrails($event,clinicalTrial.id)"
                        [checked]="clinicalTrial.selected ">
                </div>
            </td>
            <td>
                <div class="form-check">
                    <input *ngIf="clinicalTrial.selected" class="form-check-input" type="checkbox"
                    (click)="addStarSelectedTrails($event,clinicalTrial.id)" [checked]="clinicalTrial.isStarSelected">
                </div>
            </td>
            <td *ngIf="switchToggleButton">
              <input type="number"  name="priorityOrderNumber_{{i}}" [(ngModel)]="clinicalTrial.priorityOrder"
              (ngModelChange)="priorityOrder(clinicalTrial.priorityOrder, clinicalTrial.id)">
            </td>
            <!-- <td>
                <select *ngIf="clinicalTrial.selected" class="form-control" [(ngModel)]="clinicalTrial.cancerType" (change)="addCancerType(clinicalTrial)">
                    <option [ngValue]="null" selected disabled>Select</option>
                    <option value="SAME">SAME</option>
                    <option value="OTHERS">OTHERS</option>
                  </select>
            </td>
            <td>
                <ng-multiselect-dropdown *ngIf="((clinicalTrial.selected) && (clinicalTrial.cancerType == 'OTHERS'))"
                [settings]="dropdownSettings"
                [data]="cancerTypeList"
                [(ngModel)]="clinicalTrial.cancerTypes"
                >
            </ng-multiselect-dropdown>
            </td> -->
            <td>
              <div *ngFor="let drug of clinicalTrial.drugs">
                <span  [ngStyle]="{'color': (isFirstReport && clinicalTrial.cancerType.name == firstCancerType) ? 'blue' : 'red'}">{{drug.name}}</span></div>
            </td>
            <!-- <td *ngIf="!clinicalTrial.selected">
              -
            </td> -->
            <td>{{clinicalTrial.cancerType.name}}</td>
            <td style="word-wrap: normal;">{{clinicalTrial.pposition || "-"}}</td>
            <td *ngIf="clinicalTrial.association=='RESPONSIVE'">S</td>
            <td *ngIf="clinicalTrial.association=='RESISTANCE'">R</td>
            <td *ngIf="clinicalTrial.association=='ONGOING'">O</td>
            <td *ngIf="clinicalTrial.association==''||clinicalTrial.association==null">-</td>
            <td *ngIf="!isFirstReport">
                <a *ngIf="clinicalTrial.selected" class="delete_clr" (click)="assignClinicalTrail(clinicalTrial)"
                 data-toggle="modal" data-target="#selectCancerDetails">Select Cancer</a>
            </td>
        </tr>
    </tbody>
</table>
</div>

<div class="modal fade" id="selectCancerDetails">
    <div class="modal-dialog clinicalTrail-model modal-dialog-centered">
      <div class="modal-content surgery-modal">

        <div class="modal-header px-4 border-bottom-0 bg-light">
          <h5 class="modal-title text-softblue">Trial Details</h5>
          <a class="text-softblue" #closebutton data-dismiss="modal">
            <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
          </a>
        </div>

        <div class="modal-body p-4">
          <div class="row mb-3">
            <div class="col">
              <h4 style="color: #001a56;">Cancer Type</h4>
              <select class="form-control" [(ngModel)]="assignedClinicalTrail.cancerTypeEnum">
                <option [ngValue]="null" selected disabled>Select</option>
                <option value="SAME">SAME</option>
                <option value="OTHERS">OTHERS</option>
              </select>
            </div>
          <div class="col">
            <h4 style="color: #001a56;">Cancer</h4>
            <ng-multiselect-dropdown
                [settings]="dropdownSettings"
                [data]="cancerTypeList"
                [(ngModel)]="assignedClinicalTrail.cancerTypes"
                >
            </ng-multiselect-dropdown>
          </div>
          <div class="col-2">
            <button type="button" [disabled]="isSubmited" class="btn save_btn" style="float:right" id="closeModal"
            (click)="editSelectedTrail()">
                <span *ngIf="!isSubmited"> Save </span>
                <div *ngIf="isSubmited" class="spinner-border text-dark">
                    <span class="sr-only"></span>
                </div>
            </button>
        </div>
          </div>

        </div>

      </div>
    </div>
  </div>
