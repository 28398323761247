<div class="container-fluid px-0">
  <h5 *ngIf="!variantId" class="mb-0 d-inline-block position-relative ml-4 mb-3 mt-4">Add Recurrence Variant</h5>
  <h5 *ngIf="variantId" class="mb-0 d-inline-block position-relative ml-4 mb-3 mt-4">Edit Recurrence Variant</h5>
  <div class="mx-4 p-4 bg-white cust_shadow rounded ">
    <div class="row mx-3">
      <div  class="col-12">
        <div class="d-flex justify-content-center">

        </div>
      </div>
      <form class="form_list" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Sample Id :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="sampleId" [(ngModel)]="recurrenceVariance.sampleId">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Type of Test :</label>
            <div class="col-sm-8">
              <select type="text" class="form-control" name="testType"
                [(ngModel)]="recurrenceVariance.typeOfTest">
                <option value="First 72 Solid">First 72 Solid</option>
                <option value="First 72 Liquid">First 72 Liquid</option>
              </select>
            </div>
          </div>
          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Panel :</label>
            <div class="col-sm-8">
              <select type="text" class="form-control" name="panel"
                [(ngModel)]="recurrenceVariance.panel">
                <option value="SOMATIC">SOMATIC</option>
              </select>
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Cancer Type :</label>
            <div class="col-sm-8">
              <select class="form-control" name="cancerType" [(ngModel)]="recurrenceVariance.cancerTypeId">
                <option *ngFor="let cancerType of cancerTypeList" value={{cancerType.id}}
                  [selected]="recurrenceVariance.cancerTypeId == cancerType.id">{{cancerType.name}}
                </option>
              </select>

            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Gene :</label>
            <div class="col-sm-8">
              <select class="form-control" name="gene" [(ngModel)]="recurrenceVariance.geneId">
                <option *ngFor="let gene of geneList" value={{gene.id}}>{{gene.name}}</option>
              </select>

            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Reference Link :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="referenceLink" [(ngModel)]="recurrenceVariance.referenceLink">
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Reference PmIds :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="referencePmIds"
                [(ngModel)]="recurrenceVariance.referencePmIds">
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">C.Position :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="cposition" [(ngModel)]="recurrenceVariance.cposition">
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">P.Position :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="pposition" [(ngModel)]="recurrenceVariance.pposition">
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Genomic Coordinates :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="genomicCoordinates"
                [(ngModel)]="recurrenceVariance.genomicCoordinates">
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">ENST Id :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="enstId" [(ngModel)]="recurrenceVariance.enstId">
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">dbSNP Id :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="dbSNPId" [(ngModel)]="recurrenceVariance.dbSNPId">
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Clinical Significance :</label>
            <div class="col-sm-8">
              <select type="text" class="form-control" name="clinicalSignificance"
                [(ngModel)]="recurrenceVariance.clinicalSignificance">
                <option value="Benign">Benign</option>
                <option value="Pathogenic">Pathogenic</option>
                <option value="Likely Pathogenic">Likely Pathogenic</option>
                <option value="VUS">VUS</option>
                <option value="Not Classified">Not Classified</option>
              </select>
            </div>
          </div>

          <div class="col-6 form-group row">
            <label class="col-sm-4 control-label">Prognostic Significance :</label>
            <div class="col-sm-8">
              <select type="text" class="form-control" name="prognosticSignificance"
                [(ngModel)]="recurrenceVariance.prognosticSignificance">
                <option value="Poor">Poor</option>
                <option value="Good">Good</option>
                <option value="Not Classified">Not Classified</option>
              </select>
            </div>
          </div>
          <div class="col-12 add-emp mr-10 mb-5">
            <div class="col-sm-8 offset-sm-4 px-0 text-right">
                <button type="submit" class="btn save_btn text-uppercase bg-softblue ml-2"
                    [disabled]="submitSpinner">
                    <!-- <span class="delete_clr"  data-toggle="modal" data-target="#deleteDrugModal"> Submit </span> -->
                    <span *ngIf="!submitSpinner"> Submit </span>
                    <div *ngIf="submitSpinner" class="spinner-border text-dark">
                        <span class="sr-only"></span>
                    </div>
                </button>
            </div>
        </div>
        </div>
      </form>
    </div>
  </div>
</div>
