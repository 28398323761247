import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if(user === null){
      localStorage.clear();
      this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    else if (user['role'] === 'ROLE_ADMIN' || user['role'] === 'ROLE_SUPER_ADMIN') {
      return true;
    }  else {
      localStorage.clear();
      this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
  
}
