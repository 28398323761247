import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterType } from 'aws-sdk/clients/pinpoint';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { interval } from 'rxjs';
import { FilteresCSV, ReportVariant } from 'src/app/_interfaces.ts/excel';
import { ReportRes } from 'src/app/_interfaces.ts/reports';
import { StudyFieldsResponse } from 'src/app/_interfaces.ts/trails';
import { ClinicalTrailService } from 'src/app/_services/clinical-trail.service';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { IgvService } from 'src/app/_services/igv.service';
import { ReportService } from 'src/app/_services/report.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-read-excel',
  templateUrl: './read-excel.component.html',
  styleUrls: ['./read-excel.component.scss'],
})
export class ReadExcelComponent implements OnInit {
  reportId!: number;
  report = {} as ReportRes;
  @ViewChild('closeModal') private closeModal!: ElementRef;
  searchText!: string;
  fileName!: string;
  filters:any
  filterCol:any
  filterParam:any
  filterSpinner=false
  showSaveFilter:boolean=false
  secondFilterData:any=[]
  secondFilterDataBackup:any=[]
  filterTabCols=''
  filterText=""
  filterTabName=""
  filterType=[
    // "equals","not equal","Greater than or equal","Greater than",
    "less than","less than or equal","equals"]
  filterTypeCad=["equals","Greater than or equal","Greater than"]
//   filtertab=[{tab:'Consequence',val:'exonicFuncknownGene,funcknownGene'},
//   {tab:'Population frequency',val:'esp6500siv2All,exAC_ALL,exAC_SAS,af,afSas,kg2015AugAll,kg2015AugSAS'},
//   {tab:'In-Silico Prediction',val:'cadd13Phred,siftPred,polyphen2HvarPred,mutationTasterPred,fathmmPred,metaSvmPred,metaLrPred'},
//   {tab:'Clinical Interpretation',val:'clinvar,interVarAutomated,intervarInhouse,cancerVar'},
// ]
filtertab=[{tab:'Consequence',data:[{key:'funcknownGene',webKey:'Region',value:['.','Downstream','Exonic','Exonic;Splicing','Intergenic','Intronic','ncRNA_Exonic','ncRNA_Intronic','Splicing','UTR3','UTR5'],filter:'simple'},
{key:'exonicFuncknownGene',webKey:'Functional consequences',value:['.','Frameshift deletion','Frameshift insertion','Nonframeshift deletion','Nonframeshift insertion',
'Nonsynonymous SNV','Stopgain','Stoploss','Synonymous SNV','Unknown'],filter:'simple'
}]},
{tab:'Population frequency',data:[{key:'esp6500siv2All',webKey:'Exome Sequencing Project 6500 database - ALL',value:[''],filter:'number'}
,{key:'exAC_ALL',webKey:'ExAC database - ALL',value:[''],filter:'number'}
,{key:'exAC_SAS',webKey:'ExAC database - SAS (South Asian)',value:[''],filter:'number'}
,{key:'af',webKey:'AF database - ALL',value:[''],filter:'number'}
,{key:'afSas',webKey:'AF database - SAS (South Asian)',value:[''],filter:'number'}
,{key:'kg2015AugAll',webKey:'1000 Genomes Project database - ALL',value:[''],filter:'number'}
,{key:'kg2015AugSAS',webKey:'1000 Genomes Project database - SAS (South Asian)',value:[''],filter:'number'}
,{key:'genome_ALL',webKey:'GNOM AD - ALL',value:[''],filter:'number'}
,{key:'genome_EAS',webKey:'GNOM AD - EAS',value:[''],filter:'number'}

],},
{tab:'In-Silico Prediction',data:[{key:'cancerVar',webKey:'CancerVar',value:['.','TIER_I','TIER_II','TIER_III','TIER_IV'],filter:'simple'},
{key:'interVarAutomated',webKey:'InterVar',value:['.','Benign','Likely benign','Uncertain significance','Likely pathogenic','Pathogenic'],filter:'simple'},
{key:'clinvar',webKey:'Clinvar',value:['Pathogenic/Likely pathogenic','Conflicting','Benign/Likely benign','VUS','Drug Response','No Information'],filter:'simple',values:''},
{key:'cadd13Phred',webKey:'CADD Score',value:[''],filter:'number'},
{key:'siftPred',webKey:'SIFT score',value:['.','D','T'],filter:'simple'},
{key:'polyphen2HvarPred',webKey:'Polyphen2 score',value:['.','D','B','P'],filter:'simple'},
{key:'mutationTasterPred',webKey:'Mutation Taster score',value:['.','D','N','A','P'],filter:'simple'},
{key:'fathmmPred',webKey:'FATHMM score',value:['.','D','T'],filter:'simple'},
{key:'metaSvmPred',webKey:'MetaSVM score',value:['.','D','T'],filter:'simple'},
{key:'metaLrPred',webKey:'MetaLR score',value:['.','D','T'],filter:'simple'},
]},
{tab:'ClinSig_Inhouse',data:[{key:'clnsig',webKey:'ClinSig_Inhouse',value:['Pathogenic','Likely pathogenic','Rick Factor','Drug Response','VUS','No Information','Uncertain_significance'],filter:'simple'}
]},
{tab:'Gene filter',data:[
  {key:'testName',webKey:'Test Name',value:[],filter:'dropdown'},
  {key:'cansesite',webKey:'Cancer Site',value:[],filter:'dropdown'},
  {key:'refGeneX',webKey:'Gene',value:[],filter:'dropdown'}]},
// {tab:'Clinical Interpretation',val:'clinvar,interVarAutomated,intervarInhouse,cancerVar'},
]
clinvarExcludeValues:string[]=['Pathogenic','Likely_pathogenic','Conflicting','Benign','Likely_benign','Uncertain_Significance','drug_response']
  filterList:any=[]
  filteredCSV: FilteresCSV[] = [];
  filteredTable = false;
  recordCount!:number;
  filteredCSVDetail = {} as FilteresCSV;
  uploadFileInput = false;
  showFilterOption=true
  variant = {} as ReportVariant;
  filterLoad=false
  currentTab:any=''
  reportVariantIds!: number[];
  switchToggleButton = false;
  backendData:any
  selectedRowfilteredCSV!: boolean;
  selectedStarVariant!: boolean;
  geneList:any=[]
  testGeneList:any=[]
  cancerGeneList:any=[]
  testList:any=[]
  cancerSiteList:any=[]
  geneNameList:any=[]
  testNameList:any=[]
  cancerSiteNameList:any=[]
  filterCsvValues = true;
  geneDropdownSettings={}
  constructor(
    private reportService: ReportService,
    private igvService: IgvService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private dataAdminService:DataAdminService
  ) {}

  igvUrl!: String;

  ngOnInit(): void {
    this.organisationName = localStorage.getItem('organisationName') || '';
    this.reportId = this.routerAct.snapshot.params['id'];
    this.geneDropdownSettings = {
      singleSelection: false,
      // idField: 'values',
      // textField: 'values',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getCancerSiteGeneList();
    this.getTestNames();
    this.getGenes()
    this.filterCol=[]
    this.getFilterList()
    if (this.reportId) {
      this.getReport();

      this.igvStatus();
    }

    if (environment.apiUrl == 'https://www.api.workbench.4basecare.co.in'){
      this.igvUrl = 'https://igv.4basecare.co.in';
    } else {
      this.igvUrl = 'http://52.66.98.251:9505';
    }

  }


  autoUploadFengFile(){
    console.log("autoUploadFengFile");

    this.reportService
      .autoUploadFengFile(this.reportId)
      .subscribe((response: any) => {
        this.filteredTable = true;
        this.uploadFileInput = false;
        this.variantsListSpinner = false;
        this.filterLoad=true
        this.filteredCSV = response['payLoad'];
        this.showFilterOption=true
      });


  }


  selectedFilter(event:any){
    if(event.target.value!=''){
      let id:number=+event.target.value
      this.filterSpinner=true
    this.dataAdminService.getFilterEngineById(id).subscribe((res:any)=>{
      this.backendData=res['payLoad']
      this.setFilterValue()
    })
    }
  }
  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList= response['payLoad']

        this.geneList.forEach((element:any)=>{
          this.geneNameList.push(element.name)
         });
        // this.filtertab.forEach((ele:any)=>{
        //   if(ele.tab==='gene_filter'){
        //     ele.value=this.geneList
        //   }
        // })
      }
    );
  }

  getTestNames(){

    this.dataAdminService.geneTestTypeGeneList().subscribe((res:any)=>{
      this.testList=res["payLoad"];
      console.log('this.testList=>',this.testList)
      this.testList.forEach((element:any)=>{
        this.testNameList.push(element?.testNames)
       });
       console.log('this.testNameList=>',this.testNameList)

    });
  }

  getCancerSiteGeneList(){
    this.dataAdminService.getCancerSiteGeneList().subscribe((res:any)=>{
      this.cancerSiteList=res['payLoad']
      console.log("DATAT ",this.cancerSiteList)
      this.cancerSiteList.forEach((element:any)=>{
        this.cancerSiteNameList.push(element?.cancerSite?.name)
       });
       console.log('this.cancerSiteNameList=>',this.cancerSiteNameList)
    })
  }
  setFilterValue(){
    this.filterCol=[]
      this.secondFilterDataBackup=[]
    this.filtertab.forEach((element:any)=>{
      this.secondFilterData=[]

      element.data.forEach((ele:any)=>{
        console.log(element.tab ," === " ,this.backendData[ele.key])
        let data:any=this.backendData[ele.key]
        if(data!=null){
          if(ele.filter=='number'){

            this.secondFilterData.push({
              key:ele.key,
              values: data.split(',')[0],
              value:ele.value,
              filterType: data.split(',')[1],
              filter:ele.filter,
              webKey:ele.webKey,
              excludeValues:ele.excludeValues
            })
          }else{
            this.secondFilterData.push({
              key:ele.key,
              values: data.split(','),
              value:ele.value,
              filterType: (ele.filterType)? ele.filterType:'',
              filter:ele.filter,
              webKey:ele.webKey,
              excludeValues:ele.excludeValues
            })
            this.filterCol.push( {
            'colName':ele.key,
            'values':data.split(','),
            'excludeValues':ele.excludeValues})
          }
        }else{
          this.secondFilterData.push({
                    key:ele.key,
                    values:(ele.values)? ele.values:'',
                    value:ele.value,
                    filterType:(ele.filterType)? ele.filterType:'',
                    filter:ele.filter,
                    webKey:ele.webKey,
                    excludeValues:ele.excludeValues
                  })
        }


      })
      this.filterTabName=element.tab
      this.secondFilterDataBackup.push({
        tab:element.tab,
        filters:JSON.parse(JSON.stringify(this.secondFilterData))
      })
    })
   setTimeout(()=>{
    this.openFilterTab(this.currentTab)
    this.filterSpinner=false
   },1000)
  }

  getFilterList(){
    this.dataAdminService.getFilterEngineList().subscribe((res:any)=>{
      this.filterList=res['payLoad']
      // this.filterListSpinner=false
     })
  }
  openFilterTab(tabName:any){
    this.currentTab=tabName
    this.getBackup()
    if(tabName!=''){
      this.filterTabCols=''
      this.filtertab.forEach((ele:any)=>{
        if(ele.tab==tabName){
          this.filterTabName=tabName
          this.filterTabCols=ele.val
          this.getFilter()
        }
      })
    }else{
      this.filters=[]
    }
  }

  saveFilter(){
    this.secondFilterDataBackup.forEach((ele:any)=>{
      if(ele.tab==this.filterTabName){
        this.filterTabName
        this.getBackup()
      }
      ele.filters.forEach((element:any)=>{
        let flag=false
        if(element.values!='' && element.values!=undefined && element.values!=null){
          this.filterCol.forEach((elementkey:any)=>{
            if(element.key==elementkey.colName){
              flag=true
              elementkey.values=element.values
              elementkey.filterType=element.filterType
              elementkey.excludeValues=element.excludeValues
            }
          })
         if(!flag){
          this.filterCol.push({
            colName:element.key,
            values:element.values,
            filterType:element.filterType,
            excludeValues:element.excludeValues
          })
         }
        }
      })
    })

    let data:any=JSON.parse(JSON.stringify(this.filterCol))
    data.forEach((ele:any)=>{
      ele.values= ele.values?.toString()
      ele.filterType=(ele.filterType)?ele.filterType:''
    })

    this.reportService.saveFilter(this.reportId,data).subscribe((res:any)=>{
      console.log("res Filter",res)
      this.showFilterOption=false
      this.showSaveFilter=false
      this.showSuccess(res['message']);
    })

  }
  getBackup(){
    this.secondFilterDataBackup.forEach((ele:any)=>{
      if(ele.tab==this.filterTabName){
        this.secondFilterData.forEach((data:any)=>{
          this.filterCol.forEach((element:any)=>{
              if(data.key==element.colName)
              {
                data.values=element.values
                if(data.key=="clinvar"){
                  data['excludeValues']=this.clinvarExcludeValues.toString()
                }
              }

          })
        })
          ele.filters= JSON.parse(JSON.stringify(this.secondFilterData))
      }

    })
  }

  getFilter(){
    let flag=false

    this.filtertab.forEach((tab:any)=>{
     if(tab.tab==this.filterTabName){
     //  tab.data.forEach((tabdata:any)=>{
       if(this.secondFilterDataBackup.length>0){
         this.secondFilterDataBackup.forEach((ele:any)=>{
           if(ele.tab==this.filterTabName){
             flag=true
           }
          })
       }

        if(flag){
         this.restroreBackup()
        }else{
         this.secondFilterDataBackup.push({
           tab:this.filterTabName,
           filters:tab.data,
         })
         this.restroreBackup()
        }
       // })
     }
    })
 }

  restroreBackup(){
    this.secondFilterData=[]
    if(this.secondFilterDataBackup.length>0){
      console.log("this.secondFilterDataBackup ",this.secondFilterDataBackup)
      this.secondFilterDataBackup.forEach((ele:any)=>{
        if(ele.tab==this.filterTabName){
          ele.filters.forEach((ele:any)=>{
            this.secondFilterData.push({
              key:ele.key,
              values:(ele.values)? ele.values:'',
              value:ele.value,
              filterType:(ele.filterType)? ele.filterType:'',
              filter:ele.filter,
              webKey:ele.webKey,
              excludeValues:ele.excludeValues
            })
           })
        }
      })
    }

  }
  updateSelectedFilter(event:any){
    let colsvalue:any=[]
    if(event.target.checked){
      if(this.filterCol.length>0){
        let pushFlag=true
        this.filterCol.forEach((ele:any) => {
          if(ele.colName==event.target.name){
            pushFlag=false
            ele.values.push(event.target.value)
          }
        });
        if(pushFlag){
          if(event.target.name=="clinvar"){
            this.filterCol.push({
              'colName':event.target.name,
              'values':[event.target.value],
              'excludeValues':this.clinvarExcludeValues.toString()
            })
          }else{
            this.filterCol.push({
              'colName':event.target.name,
              'values':[event.target.value]})
          }

        }
      }else{
        if(event.target.name=="clinvar"){
          this.filterCol.push({
            'colName':event.target.name,
            'values':[event.target.value],
            'excludeValues':this.clinvarExcludeValues.toString()
          })
        }else{
          this.filterCol.push({
            'colName':event.target.name,
            'values':[event.target.value]})
        }
      }
    }else{
      this.filterCol.forEach((ele:any) => {
        if(ele.colName==event.target.name){
          let index=  ele.values.indexOf(event.target.value)
          ele.values.splice(index,1)
          if(ele.values.length==0){
            let index=  ele.values.indexOf(ele)
            this.filterCol.splice(index,1)
          }
        }
      })
    }
  }

  isChecked(colName:any,data:any):boolean{
    let flag:boolean=false
    this.filterCol.forEach((ele:any) => {
      if(ele.colName==colName){
        let index= ele.values.indexOf(data)
        if(index>=0){
           flag=true;
        }
      }
    })
   return flag
  }

  applyFilter(){
    this.filterCsvValues = !this.filterCsvValues;
    this.getFilterData()
    this.showSaveFilter=true
  }
  getFilterData(){
    this.secondFilterDataBackup.forEach((ele:any)=>{

      if(ele.tab==this.filterTabName){
        this.filterTabName
        this.getBackup()
      }
      ele.filters.forEach((element:any)=>{
        let flag=false
        if(element.values!='' && element.values!=undefined && element.values!=null){
          this.filterCol.forEach((elementkey:any)=>{
            if(element.key==elementkey.colName){
              flag=true
              elementkey.values=element.values
              elementkey.filterType=element.filterType
              elementkey['excludeValues']=element.excludeValues
            }
          })
         if(!flag){
          this.filterCol.push({
            colName:element.key,
            values:element.values,
            filterType:element.filterType,
            excludeValues:element.excludeValues
          })
         }
        }
      })
    })

    let data:any=JSON.parse(JSON.stringify(this.filterCol))
    data.forEach((ele:any)=>{
      ele.values= ele.values?.toString()
      ele.filterType=(ele.filterType)?ele.filterType:''
      ele.excludeValues=ele.excludeValues
    })
    console.log("data===>",data)
    this.reportService.getFilterData( this.reportId, data).subscribe((res:any)=>{
      this.variantsListSpinner = false;
      this.filterLoad=true
      this.showDiv = true;
      this.filteredCSV = res['payLoad'];
      this.recordCount= this.filteredCSV.length
      if (res['success'] == true) {
        if (this.switchToggleButton) {
          this.variantsListSpinner = true;
          this.getSelectedReportedVariants();
        }
      }
    })
  }
  testNameSelected:any;
  isTestNameSelected=false
  isCancerSiteSelected=false
  cancerSiteName:any
  filterByTestType(event:any){
    this.isTestNameSelected=true
    console.log("event==>",event,"\n",this.isTestNameSelected)
    let testData = this.testList.filter( (test:any)=> {
      if(test['testNames']==event){
        return test;
      }
    });
    this.geneList=testData[0].gene;
    this.geneNameList=[];
    this.testGeneList=[]
    this.geneList.forEach((element:any)=>{
      this.testGeneList.push(element.name)
     });
    if(this.isCancerSiteSelected==true){
      this. filterByCancerAndTestType(this.testGeneList, this.cancerGeneList);
    }else{
      this.geneNameList=this.testGeneList;
      console.log("geneNameList TestName==>",this.geneNameList)

    }
  }

  filterByCancerType(event:any){
    this.isCancerSiteSelected=true
    console.log("event==>",event,"\n", this.isCancerSiteSelected)
    let canceData = this.cancerSiteList.filter( (test:any)=> {
      if(test['cancerSite']['name']==event){
        return test;
      }
    });
    this.geneList=canceData[0].genes;
    this.geneNameList=[];
    this.cancerGeneList=[]
    this.geneList.forEach((element:any)=>{
      this.cancerGeneList.push(element.name)
     });
    console.log("geneList==>",this.geneList);
    if(this.isTestNameSelected==true){
      this. filterByCancerAndTestType(this.testGeneList,this.cancerGeneList);
    }else{
      this.geneNameList=this.cancerGeneList;
      console.log("geneNameList Cancer Name==>",this.geneNameList)

    }
  }

  filterByCancerAndTestType(list1:any, list2:any){
    this.geneNameList=[]
    for(let i=0;i<list1.length;i++){
      for(let j=0;j<list2.length;j++){
        if(list1[i]==list2[j]){
          this.geneNameList.push(list1[i])
        }
      }
    }
    console.log("list1==>",list1,"\nlist2==>",list2,"\ngeneList==>",this.geneNameList)
  }

  cancelFilter(){
    this.filterCol=[]
    this.secondFilterDataBackup=[]
    this.getFilterData()
    this.showSaveFilter=false
    this.filterCsvValues = !this.filterCsvValues;
  }

  onFileChange(event: any) {
    const files = event.target.files;
    const file = files[0];
    if(file){
      this.uploadFileInput = true;
    }
    // this.fileName = file.name;
    this.uploadFile(file);
  }

  uploadFile(file: any) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.reportService
      .uploadAndGetDataFromExcel(formdata, this.reportId)
      .subscribe((response: any) => {
        this.filteredTable = true;
        this.uploadFileInput = false;
        this.variantsListSpinner = false;
        this.filterLoad=true
        this.filteredCSV = response['payLoad'];
        this.showFilterOption=true
      });
  }

  viewFilteredCSV(item: FilteresCSV) {
    console.log(item);
    this.filteredCSVDetail = item;
    console.log(this.filteredCSVDetail.refGeneX.split(';', 1).toString());
  }

  selectingAAChangeknownGene(
    reportedVariantId: number,
    aAChangeknownGene: string
  ) {
    console.log(reportedVariantId, aAChangeknownGene);
    this.reportService
      .selectAAChangeknownGene(reportedVariantId, aAChangeknownGene)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  seletedRow(event: any, filteredCSV: FilteresCSV) {
    this.selectedRowfilteredCSV = event['target']['checked'];

    if (this.selectedRowfilteredCSV) {
      this.reportService
        .changeReportVariantStatus(filteredCSV.id, true)
        .subscribe((response: any) => {
          console.log(response);
        });
    } else if (!this.selectedRowfilteredCSV) {
      if (confirm('Are you sure you want unselect..!')) {
        this.reportService
          .changeReportVariantStatus(filteredCSV.id, false)
          .subscribe((response: any) => {
            console.log(response);
            if (response['success'] == true) {
              if (this.switchToggleButton) {
                this.getSelectedReportedVariants();
              }
            }
          });
      } else {
        this.getReportedVariants();
      }
    }
  }

  seletedStarRow(event: any, filteredCSV: FilteresCSV) {
    this.selectedStarVariant = event['target']['checked'];
    console.log(this.selectedStarVariant);
    this.reportService
      .changeReportVariantStarStatus(filteredCSV.id, this.selectedStarVariant)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  priorityOrder(orderNumber: number, reportedVariantId: number) {
    console.log(orderNumber, reportedVariantId);
    if (orderNumber != null && orderNumber > 0) {
      this.reportService
        .setPriorityOrder(reportedVariantId, orderNumber)
        .subscribe((response: any) => {
          console.log('setPriorityOrder', response);
        });
    }
  }

  organisationName!: string;
  getReport() {
    this.uploadFileInput = true;
    this.reportService.getReport(this.reportId).subscribe((response: any) => {
      console.log(response);
      this.report = response['payLoad'];
      if(this.organisationName != null && this.organisationName.length > 0){
        this.autoUploadFengFile();
      }
      this.uploadFileInput = false;
      if(this.report.isFilterApplied){
        this.showFilterOption=false
      }else{
        this.showFilterOption=true
      }
      if (this.report.filteredCSVLink == null) {
        this.filteredTable = false;
      } else if (this.report.filteredCSVLink != null) {
        this.filteredTable = true;
        if(this.organisationName!=null &&  this.organisationName!=''){
          this.fileName = this.report.filteredCSVLink.split('/')[8];
        }else{
          this.fileName = this.report.filteredCSVLink.split('/')[4].substring(14);
        }
        // this.getReportedVariants();
        this.getFilterData();
      }
    });
  }

  variantsListSpinner = true;
  showDiv = false;
  getReportedVariants() {
    this.variantsListSpinner = true;
    this.reportService
      .getAllReportVariantByReportId(this.reportId)
      .subscribe((response: any) => {
        this.variantsListSpinner = false;
        this.showDiv = true;
        console.log(response);
        this.filteredCSV = response['payLoad'];
        if (response['success'] == true) {
          if (this.switchToggleButton) {
            this.variantsListSpinner = true;
            this.getSelectedReportedVariants();
          }
        }
      });
  }

  getSelectedReportedVariants() {
    this.reportService
      .getSelectedReportedVariants(this.reportId)
      .subscribe((response: any) => {
        this.variantsListSpinner = false;
        console.log(response);
        this.filteredCSV = response['payLoad'];
      });
  }

  switchToggle(event: any) {
    this.variantsListSpinner = true;
    this.switchToggleButton = event['target']['checked'];
    console.log('switchToggleButton', this.switchToggleButton);
    if (this.switchToggleButton) {
      this.getSelectedReportedVariants();
    } else if (!this.switchToggleButton) {
      this.getReportedVariants();
    }
  }

  showSuccess(msg: string) {
    this.toastr.success(msg, '', { timeOut: 2000 });
  }

  showError(msg: string) {
    this.toastr.error(msg, '', { timeOut: 2000 });
  }

  saveVariant() {
    if (
      this.variant.refGeneX &&
      this.variant.refGeneX != null &&
      this.variant.refGeneX != undefined
    ) {
      this.reportService
        .createReportVariant(this.reportId, this.variant)
        .subscribe((res: any) => {
          this.showSuccess(res['message']);
          this.getReportedVariants();
          this.closeModal.nativeElement.click();
          this.variant = {} as ReportVariant;
        });
      //
    } else {
      this.showError('Please enter value for gene');
    }
  }

  filterValues() {
    this.filterCsvValues = !this.filterCsvValues;
    this.filterLoad=!this.filterLoad
    this.openFilterTab('Consequence')
  }

  startIgv(){
    this.igvService.startIgv(this.reportId).subscribe((res:any)=>{
      console.log("IGV RESPONSE ",res);
      this.showSuccess(res['payLoad']);
      this.igvStatus();
    })
  }

  igvStatus(){

    this.igvService.igvStatusProd(this.reportId).subscribe((res:any)=>{
      console.log("igv Status Prod ",res);

      this.igvService.igvStatus(this.reportId).subscribe((res:any)=>{
        console.log("igv Status ",res['payLoad']);
        this.report.oncobenchAnalysisStatus = res['payLoad'];
      });
    });
  }
}
