import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HotspotVariant } from 'src/app/_interfaces.ts/excel';
import { ReportRes } from 'src/app/_interfaces.ts/reports';
import { HotspotService } from 'src/app/_services/hotspot.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-hotspot-excel',
  templateUrl: './hotspot-excel.component.html',
  styleUrls: ['../cna-excel/cna-excel.component.scss']
})
export class HotspotExcelComponent implements OnInit {


  reportId!: number;
  report = {} as ReportRes;
  hotspotVariants: HotspotVariant[] = [];
  selectedForReport!: boolean;
  selectedStarForReport!: boolean;

  hotspotTable = false;
  uploadFileInput = false;
  hotspotListSpinner = true;
  switchToggleButton = false;
  spinDiv=false;


  constructor(
    private hotspotService: HotspotService,
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
  ) { }
  organisationName!: string;

  ngOnInit() {
    console.log("working Hotspot")
    this.organisationName = localStorage.getItem('organisationName') || '';
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }


  onFileChange(event: any) {
    this.uploadFileInput = true;
    const files = event.target.files;
    const file = files[0];
    // this.fileName = file.name;
    this.uploadFile(file);
  }

  uploadFile(file: any) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.hotspotService.uploadAndGetHotspotDataFromExcel(formdata, this.reportId).subscribe(
      (response: any) => {
        this.hotspotTable = true;
        this.uploadFileInput = false;
        this.hotspotListSpinner = false;
        console.log("hotspot data ==>", response);
        this.hotspotVariants = response['payLoad'];
      }
    );
  }

  seletedRow(event: any, hotspot: HotspotVariant) {
    this.selectedForReport = event['target']['checked'];
    console.log("this.selectedForReport->",this.selectedForReport)

    if (this.selectedForReport) {
      this.hotspotService.hotspotVariantStatus(hotspot.id,this.selectedForReport).subscribe(
        (response: any) => {
          console.log("response-->",response);
          if (response['success'] == true) {
            this.getHotspotVariants();
          }
        }
      );
    } else if (!this.selectedForReport) {
      if(confirm("Are you sure you want unselect..!")) {
      this.hotspotService.hotspotVariantStatus(hotspot.id,this.selectedForReport).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getHotspotVariants();
          }
        }
      );
    }
    else{
      this.getHotspotVariants();
    }
  }

  }

  seletedStarRow(event: any,hotspot: HotspotVariant) {
    this.selectedStarForReport = event['target']['checked'];
    console.log(this.selectedStarForReport);
    this.hotspotService.hotspotVariantStarStatus(hotspot.id, this.selectedStarForReport).subscribe(
      (response: any) => {
        console.log(response);
      }
    );
  }

  priorityOrder(orderNumber: number, hotspotVariantId: number){
    console.log(orderNumber, hotspotVariantId);
    if(orderNumber != null && orderNumber > 0){
      this.hotspotService.setHotspotVariantPriorityOrder(hotspotVariantId, orderNumber).subscribe(
        (response: any) => {
          console.log("setPriorityOrder",response);
        }
      );
    }

  }


  switchToggle(event: any) {
    this.hotspotListSpinner = true;
    this.switchToggleButton = event['target']['checked'];
    console.log("switchToggleButton", this.switchToggleButton);
    if (this.switchToggleButton) {
      this.getSelectedHotspotVariants();
    } else if (!this.switchToggleButton) {
      this.getHotspotVariants();
    }

  }

  fileName!: string;
  getReport() {
    this.uploadFileInput = true;
    this.reportService.getReport(this.reportId).subscribe(
      (response: any) => {
        console.log("hotspot component", response);
        this.report = response['payLoad'];
        this.uploadFileInput = false;
        if (this.report.filteredHotspotLink == null) {
          this.hotspotTable = false;
        } else if (this.report.filteredHotspotLink != null) {
          this.hotspotTable = true;
          this.fileName = this.report.filteredHotspotLink.split('/')[5].substring(14);
          this.getHotspotVariants();
        }
      });
  }

  getHotspotVariants() {
    this.hotspotListSpinner = true;
    this.hotspotService.getAllHotspotVariantsByReportId(this.reportId).subscribe(
      (response: any) => {
        this.hotspotListSpinner = false;
        this.spinDiv=true;
        console.log("hotspot data ==>", response);
        this.hotspotVariants = response['payLoad'];
        if (response['success'] == true) {
          if (this.switchToggleButton) {
            this.hotspotListSpinner = true;
            this.getSelectedHotspotVariants();
          }
        }
      }
    );
  }

  getSelectedHotspotVariants() {

    function isSelectedForReport(element: HotspotVariant, index: any, array: any) {
      return (element.selectedForReport == true);
    }
    this.hotspotVariants = this.hotspotVariants.filter(isSelectedForReport);
    this.hotspotListSpinner = false;
  }

  // accordionClick(hotspot: HotspotVariant){
  //   console.log("hotspot",hotspot);
  //   hotspot.show = !hotspot.show
  //   this.getreadNameList(hotspot.readNames);
  // }

  readNameList : String[] = [] ;
  getreadNameList(readName : string){
    console.log("getreadNameList");
    this.readNameList =  readName.split(";");
  }


}
