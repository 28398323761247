import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CancerType } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-cancer-type',
  templateUrl: './cancer-type.component.html',
  styleUrls: ['./cancer-type.component.css']
})
export class CancerTypeComponent implements OnInit {

  add_cancerType = {} as CancerType;
  cancerTypeList: CancerType[] | undefined;
  cancerListSpinner:boolean=true
  statusMsg!: string;

  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  ngOnInit(): void {
    this.getCancerTypes();
  }

  addCancerType(){
    console.log(this.add_cancerType);
    this.dataAdminService.addCancerType(this.add_cancerType).subscribe(
      (response: any) => {
        console.log("response add",response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  deleteCancerType(){
    console.log(this.add_cancerType);
    this.dataAdminService.deleteCancerType(this.add_cancerType.id).subscribe(
      (response: any) => {
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  assignCancerType(cancerType: CancerType){
    this.add_cancerType = cancerType;
    console.log("edit cancer type",this.add_cancerType);
  }

  getCancerTypes(){
    this.dataAdminService.cancerTypeList().subscribe(
      (response: any) => {
        this.cancerListSpinner=false
        this.cancerTypeList = response['payLoad'];
        console.log("cancer type list",this.cancerTypeList);
      }
    );
  }

  closeModal(){
    console.log("close modal")
    this.add_cancerType = {} as  CancerType;
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/cancer_types']);
    });
  }

}
