<!-- <div class="card">
	<div class="card-header">
		<div class="row">
			<div class="col">
				Ecrf Id: {{report.patient?.ecrfId}}
			</div>
			<div class="col">
				Patient Name: {{report.patient?.name}}
			</div>
			<div class="col">
				Gender: {{report.patient?.gender}}
			</div>
			<div class="col text-right">
				<a [routerLink]="['/reports']">Back to Reports</a>
			</div>
		</div>
	</div>
</div> -->

<!-- Upload  -->
<div *ngIf="!filteredTable && !uploadFileInput" class="row mt-4">
  <form id="file-upload-form" class="uploader">
    <input
      id="file-upload"
      type="file"
      name="fileUpload"
      accept=".xlsx"
      (change)="onFileChange($event)"
      [disabled]="uploadFileInput"
    />

    <label for="file-upload" id="file-drag">
      <img id="file-image" src="#" alt="Preview" class="hidden" />
      <div id="start">
        <i class="fa fa-download" aria-hidden="true"></i>
        <div>Select a file or drag here</div>
        <div id="notimage" class="hidden">Please select an image</div>
        <span id="file-upload-btn" class="btn btn-primary">Select a file</span>
      </div>
      <div id="response" class="hidden">
        <div id="messages"></div>
        <progress class="progress" id="file-progress" value="0">
          <span>0</span>%
        </progress>
      </div>
    </label>
  </form>
</div>

<div *ngIf="uploadFileInput" class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden"></span>
  </div>
</div>

<div *ngIf="filteredTable">
  <div class="row mb-2 d-flex justify-content-between align-items-center">
    <div class="col-3">
      <h5 class="text-size text-info mb-0">Filtered CSV Values:-</h5>
    </div>
    <div class="col-3" *ngIf=" this.organisationName==null ||  this.organisationName==''">
      <p>File Name: {{ fileName }}</p>
      <!-- <p *ngIf=" this.organisationName!=null &&  this.organisationName!=''">File Name: {{ fileName}}</p> -->

    </div>
    <div class="col-1" *ngIf=" this.organisationName==null ||  this.organisationName==''">
      <button
        (click)="fileInput.click()"
        type="button"
        class="btn bg-softblue text-white"
      >
        <input
          style="display: none"
          type="file"
          accept=".xlsx,.csv"
          (change)="onFileChange($event)"
          #fileInput
          [disabled]="uploadFileInput"
        />
        Replace
      </button>
    </div>

    <div class="col d-flex">
      <button *ngIf="(this.organisationName==null ||  this.organisationName=='') && showFilterOption"
        type="button"
        class="btn btn-info float-right"
        (click)="filterValues()"
      >
        Filter <i class="zmdi zmdi-swap-vertical ml-1"></i>
      </button>
      <span *ngIf="(this.organisationName==null ||  this.organisationName=='') && showSaveFilter">
        <a class="btn btn-primary ml-2  " data-toggle="modal" data-target="#confirmModal"
        >Save Filter</a>
      </span>
      <!-- <span *ngIf="recordCount>0 && (report.oncobenchAnalysisStatus == null || report.oncobenchAnalysisStatus == 'FAILED' || report.oncobenchAnalysisStatus == 'ERROR')">
        <button (click)="startIgv()"  type="button" class="btn sm btn-primary text-white ml-5">
          Start IGV
        </button>
      </span>

      <span class="ml-5" *ngIf="(this.organisationName==null ||  this.organisationName=='') && recordCount>0 && report.oncobenchAnalysisStatus != null">
        IGV Status: {{report.oncobenchAnalysisStatus}}
      </span> -->

    </div>
    <div class="col mr-0">
      <!--  -->
      <div class="switch_panel pt-2" *ngIf="showDiv">
        <div class="switch_main ml-auto mr-5">
          <span class="switch_off">Show All</span>
          <span class="switch_on">Selected Rows</span>
          <label class="switch">
            <input type="checkbox" (click)="switchToggle($event)" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
  <div
    *ngIf="filterCsvValues"
    class="px-5 py-3 bg-white cust_shadow mx-0 rounded filter-csv_table"
  >
    <div class="row justify-content-between align-items-center d-flex">
      <div class="col-2" *ngIf=" this.organisationName==null ||  this.organisationName==''">
        <span class="indicatorspan"
          ><div class="indicatorbox created"></div>
          <label class="ml-2 mb-0">Manually Created</label></span
        >
      </div>
      <div class="col-2" *ngIf=" this.organisationName==null ||  this.organisationName==''">
        <span class="indicatorspan"
          ><div class="indicatorbox updated"></div>
          <label class="ml-2 mb-0">Updated</label>
        </span>
      </div>
      <div class="col-2">
        <span>No of Variants : </span>
        <span>{{recordCount}}</span>
      </div>
      <div class="col-4">
        <div class="row reports_info mr-4 reports_info float-right">
          <label class="mt-2">Search: </label>
          <input
            class="col ml-1 form-control"
            type="text"
            name="search"
            [(ngModel)]="searchText"
            autocomplete="off"
            placeholder="Key"
          />
        </div>
      </div>
      <div class="col-2" *ngIf=" this.organisationName==null ||  this.organisationName==''">
        <a
          href="#"
          class="btn sm btn-primary text-white ml-5"
          style="float: right"
          data-backdrop="static"
          data-keyboard="false"
          data-toggle="modal"
          data-target="#addVariants"
          >Add Variant</a
        >
        <!-- <button (click)="saveVariant()"  type="button" class="btn sm btn-primary text-white ml-5">
					Add Variant
				</button> -->
      </div>
    </div>
    <div class="row">
     <div *ngIf="!filterLoad" class="d-flex justify-content-center" style="left:50%;position: relative;">
      <div class="spinner-border" role="status" style="position: absolute;">
        <span class="visually-hidden"></span>
      </div>
    </div>
      <table class="table mt-3" *ngIf="filterLoad">
        <thead class="thead-light">
          <tr>
            <th scope="col"></th>
            <th scope="col" *ngIf="switchToggleButton">Star Select</th>
            <th scope="col" *ngIf="switchToggleButton">Priority Order</th>
            <th scope="col">Chromosome No</th>
            <th scope="col">Start position</th>
            <th scope="col">End position</th>
            <th scope="col">Reference allele</th>
            <th scope="col">Altered allele</th>
            <th scope="col">Gene</th>
            <!-- <th scope="col">AA changeknownGene</th> -->
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <div *ngIf="variantsListSpinner">
          <div class="spinner-border" role="status">
            <span></span>
          </div>
        </div>
        <tbody>
          <tr
            [ngClass]="{
              created: item.variantStatus === 'CREATED',
              updated: item.variantStatus === 'UPDATED'
            }"
            *ngFor="
              let item of filteredCSV | filter : searchText;
              let i = index
            "
          >
            <td>
              <div class="form-check cust_form-ckeck">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="seletedRow($event, item)"
                  [checked]="item.selectedForReport"
                />
              </div>
            </td>
            <td *ngIf="switchToggleButton">
              <div class="form-check cust_form-ckeck">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="seletedStarRow($event, item)"
                  [checked]="item.selectedStarForReport"
                />
              </div>
            </td>
            <td *ngIf="switchToggleButton">
              <input
                type="number"
                name="priorityOrderNumber_{{ i }}"
                [(ngModel)]="item.priorityOrder"
                (change)="priorityOrder(item.priorityOrder, item.id)"
              />
            </td>
            <td>{{ item.chrom }}</td>
            <td>{{ item.pos }}</td>
            <td>{{ item.end }}</td>
            <td>{{ item.refX ? (item.refX | limitTo : "5") : item.refX }}</td>
            <td>{{ item.altX ? (item.altX | limitTo : "5") : item.altX }}</td>
            <td>
              {{ item.refGeneX ? item.refGeneX.split(";")[0] : item.refGeneX }}
            </td>
            <!-- <td>
							<select class="form-control" name="aAChangeknownGene" [(ngModel)]="item.selectedAAChangeknownGene"
							(change)="selectingAAChangeknownGene(item.id,item.selectedAAChangeknownGene)">
								<option [ngValue]="null" selected disabled>Select</option>
								<option *ngFor="let aAChangeknownGene of item.aachangeknownGeneList" [selected]="item.selectedAAChangeknownGene == aAChangeknownGene"
								value={{aAChangeknownGene}}>{{aAChangeknownGene}}</option>
							</select>
						</td> -->
            <td>
              <ul class="list-unstyled d-flex mb-0 actions">
                <li>
                  <a
                    class="delete_clr"
                    [routerLink]="[
                      '/report',
                      reportId,
                      'view_variant',
                      item.id
                    ]"
                    target="_blank"
                  >
                    <i class="zmdi zmdi-eye mr-2"></i>View Details
                  </a>
                </li>
                <li class="ml-1" *ngIf="report.oncobenchAnalysisStatus == 'COMPLETED'">
									<a class="delete_clr" href="{{igvUrl}}/home/{{item.id}}" target="_blank">| Show IGV </a>
								</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div
    class="px-4 py-3 bg-white cust_shadow mx-0 rounded filter-csv_table"
    *ngIf="!filterCsvValues"
  >
    <div class="menu_tabs">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a href="#consequence" class="nav-link active" data-toggle="tab" (click)="openFilterTab('Consequence')"
            >Consequence</a
          >
        </li>
        <li class="nav-item">
          <a href="#population_frequency" class="nav-link" data-toggle="tab" (click)="openFilterTab('Population frequency')"
            >Population frequency</a
          >
        </li>
        <li class="nav-item">
          <a href="#in-Silico_prediction" class="nav-link" data-toggle="tab" (click)="openFilterTab('In-Silico Prediction')"
            >In-Silico Prediction</a
          >
        </li>
        <li class="nav-item">
          <a href="#clinsig_inhouse" class="nav-link" data-toggle="tab" (click)="openFilterTab('ClinSig_Inhouse')"
            >ClinSig_Inhouse</a
          >
        </li>
        <li class="nav-item">
          <a href="#gene_filter" class="nav-link" data-toggle="tab" (click)="openFilterTab('Gene filter')"
            >Gene</a
          >
        </li>
        <!-- <li class="nav-item">
          <a href="#clinical_interpretation" class="nav-link" data-toggle="tab" (click)="openFilterTab('')"
            >Clinical Interpretation</a
          >
        </li> -->
      </ul>
        <div class=" ml-2 drop_down ">
          <select
          class=" bg-white form-control w-80 dropdown-toggle dr"
          (change)="selectedFilter($event)"
          >
          <option  value="" disabled="true" selected> Select </option>
          <option *ngFor="let filter of filterList" [value]="filter.id">{{filter.name}}</option>
          </select>
        </div>


      <button class="btn btn-primary cancel_btn px-4" (click)="applyFilter()">Apply </button>
      <button class="btn btn-primary  btn_c px-4 ml-2 " (click)="cancelFilter()">Cancel </button>

      <div *ngIf="filterSpinner" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
      <div [hidden]="filterSpinner" class="tab-content py-3 px-1 mt-3">
        <div class="tab-pane fade show active" id="consequence">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>
            <div *ngFor="let filter of geneName.value ">
              <input
                type="checkbox"
                [id]="i"
                [name]="geneName.key"
                [value]="filter"
                [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                (change) ="updateSelectedFilter($event)"
              />
              <label for="vehicle1" class="pl-1"> {{filter}}</label>
            </div>
            <!-- <div>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              <label for="vehicle1" class="pl-1"> Second Check</label>
            </div> -->
          </div>
        </div>
        <div class="tab-pane fade" id="population_frequency">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>
            <!-- <div *ngFor="let filter of geneName.value "> -->
              <!-- <input
                type="checkbox"
                [id]="i"
                [name]="geneName.key"
                [value]="filter"
                [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                (change) ="updateSelectedFilter($event)"
              />
              <label for="vehicle1" class="pl-1"> {{filter}}</label> -->
              <div class="row col"  >
                <div class="col-4">
                  <select style="width: 200px;"
                  class="bg-white form-control  dropdown-toggle"
                  [(ngModel)]=" geneName.filterType"
                >
                  <option selected value="">Select</option>
                  <option *ngFor="let type of filterType" [value]="type">{{type}}</option>
                </select>
                </div>
                <div class="col-2" *ngIf="geneName.filterType">
                  <input type="text" class="form-control" [(ngModel)]="geneName.values">
                </div>

              </div>


            <!-- </div> -->
          </div>
        </div>
        <div class="tab-pane fade" id="clinsig_inhouse">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>
            <div *ngIf="!(geneName.key=='cadd13Phred') " >
              <div *ngFor="let filter of geneName.value ">
                <input
                  type="checkbox"
                  [id]="i"
                  [name]="geneName.key"
                  [value]="filter"
                  [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                  (change) ="updateSelectedFilter($event)"
                />
                <label for="vehicle1" class="pl-1"> {{filter}}</label>
              </div>
            </div>
            <div class="row col" *ngIf="(geneName.key=='cadd13Phred')" >
              <div class="col-4">
                <select style="width: 200px;"
                class="bg-white form-control  dropdown-toggle"
                [(ngModel)]="geneName.filterType"
              >
                <option selected value="">Select</option>
                <option *ngFor="let type of filterTypeCad" [value]="type">{{type}}</option>
              </select>
              </div>
              <div class="col-2" *ngIf="geneName.filterType">
                <input type="text" class="form-control" [(ngModel)]="geneName.values">
              </div>

            </div>
          </div>
        </div>


        <div class="tab-pane fade" id="in-Silico_prediction">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>
            <div *ngIf="!(geneName.key=='cadd13Phred') " >
              <div *ngFor="let filter of geneName.value ">
                <input
                  type="checkbox"
                  [id]="i"
                  [name]="geneName.key"
                  [value]="filter"
                  [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                  (change) ="updateSelectedFilter($event)"
                />
                <label for="vehicle1" class="pl-1"> {{filter}}</label>
              </div>
            </div>
            <div class="row col" *ngIf="(geneName.key=='cadd13Phred')" >
              <div class="col-4">
                <select style="width: 200px;"
                class="bg-white form-control  dropdown-toggle"
                [(ngModel)]="geneName.filterType"
              >
                <option selected value="">Select</option>
                <option *ngFor="let type of filterTypeCad" [value]="type">{{type}}</option>
              </select>
              </div>
              <div class="col-2" *ngIf="geneName.filterType">
                <input type="text" class="form-control" [(ngModel)]="geneName.values">
              </div>

            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="clinical_interpretation">
          <div class="tab_check mb-2" *ngFor="let geneName of filters;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.key}}</h6>
            <div *ngFor="let filter of geneName.value ">
              <input
                type="checkbox"
                [id]="i"
                [name]="geneName.key"
                [value]="filter"
                [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                (change) ="updateSelectedFilter($event)"
              />
              <label for="vehicle1" class="pl-1"> {{filter}}</label>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="gene_filter">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>

            <span *ngIf="geneName.webKey=='Test Name'">
              <select class="form-control" style="width: 200px;display: block;" name="testNameList"
                [(ngModel)]="testNameSelected" (change)="filterByTestType(testNameSelected)">
                <option *ngFor="let test of testNameList" [selected]="geneName.values == test" value={{test}}>{{test}}</option>
              </select>
            </span>

            <span *ngIf="geneName.webKey=='Cancer Site'">
              <select class="form-control" style="width: 200px;display: block;" name="testNameList" [(ngModel)]="cancerSiteName"
                (change)="filterByCancerType(cancerSiteName)">
                <option *ngFor="let test of cancerSiteNameList" [selected]="geneName.values == test" value={{test}}>{{test}}
                </option>
              </select>
            </span>

            <span *ngIf="geneName.webKey=='Gene'">
              <ng-multiselect-dropdown style="width: 200px;display: block;" [data]="geneNameList"
                 [(ngModel)]="geneName.values" name="gene" [settings]="geneDropdownSettings">
              </ng-multiselect-dropdown></span>
            <!-- <select style="width: 200px;"
                  class="bg-white form-control  dropdown-toggle"
                  [(ngModel)]="geneName.values"
                >
                  <option selected disabled>Select</option>
                  <option *ngFor="let genes of geneList" [value]="genes.name">{{genes.name}}</option>
                </select> -->
            <!-- <div *ngFor="let filter of geneName.value ">
              <input
                type="checkbox"
                [id]="i"
                [name]="geneName.key"
                [value]="filter"
                [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                (change) ="updateSelectedFilter($event)"
              />
              <label for="vehicle1" class="pl-1"> {{filter}}</label>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- The view FilteredCSV Details Modal -->
<div class="modal fade" id="viewFilteredCSVDetails">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content surgery-modal">
      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Details</h5>
        <a class="text-softblue" data-dismiss="modal">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4 ml-3">
        <div class="row">CHROM : {{ filteredCSVDetail.chrom }}</div>
        <br />
        <div class="row">POS : {{ filteredCSVDetail.pos }}</div>
        <br />
        <div class="row">End : {{ filteredCSVDetail.end }}</div>
        <br />
        <div class="row">REF_x : {{ filteredCSVDetail.refX }}</div>
        <br />
        <div class="row">ALTF_x : {{ filteredCSVDetail.altX }}</div>
        <br />
        <div class="row">refGeneX : {{ filteredCSVDetail.refGeneX }}</div>
        <br />
        <div class="row">
          funcknownGene : {{ filteredCSVDetail.funcknownGene }}
        </div>
        <br />
        <div class="row">
          exonicFuncknownGene : {{ filteredCSVDetail.exonicFuncknownGene }}
        </div>
        <br />
        <div class="row">
          aAChangeknownGene : {{ filteredCSVDetail.selectedAAChangeknownGene }}
        </div>
        <br />
        <div class="row">
          interproDomain : {{ filteredCSVDetail.interproDomain }}
        </div>
        <br />
        <div class="row">avsnp150 : {{ filteredCSVDetail.avsnp150 }}</div>
        <br />
        <div class="row">clndn : {{ filteredCSVDetail.clndn }}</div>
        <br />
        <div class="row">clndisdb : {{ filteredCSVDetail.clndisdb }}</div>
        <br />
        <div class="row">clinvar : {{ filteredCSVDetail.clinvar }}</div>
        <br />
        <div class="row">
          interVarAutomated : {{ filteredCSVDetail.interVarAutomated }}
        </div>
        <br />
        <div class="row">
          intervarInhouse : {{ filteredCSVDetail.intervarInhouse }}
        </div>
        <br />
        <div class="row">cancerVar : {{ filteredCSVDetail.cancerVar }}</div>
        <br />
        <div class="row">omim : {{ filteredCSVDetail.omim }}</div>
        <br />
        <div class="row">pathway : {{ filteredCSVDetail.pathway }}</div>
        <br />
        <div class="row">therapList : {{ filteredCSVDetail.therapList }}</div>
        <br />
        <div class="row">diagList : {{ filteredCSVDetail.diagList }}</div>
        <br />
        <div class="row">progList : {{ filteredCSVDetail.progList }}</div>
        <br />
        <div class="row">
          esp6500siv2All : {{ filteredCSVDetail.esp6500siv2All }}
        </div>
        <br />
        <div class="row">exAC_ALL : {{ filteredCSVDetail.exAC_ALL }}</div>
        <br />
        <div class="row">exAC_SAS : {{ filteredCSVDetail.exAC_SAS }}</div>
        <br />
        <div class="row">af : {{ filteredCSVDetail.af }}</div>
        <br />
        <div class="row">afSas : {{ filteredCSVDetail.afSas }}</div>
        <br />
        <div class="row">
          kg2015AugAll : {{ filteredCSVDetail.kg2015AugAll }}
        </div>
        <br />
        <div class="row">
          kg2015AugSAS : {{ filteredCSVDetail.kg2015AugSAS }}
        </div>
        <br />
        <div class="row">cadd13Phred : {{ filteredCSVDetail.cadd13Phred }}</div>
        <br />
        <div class="row">siftPred : {{ filteredCSVDetail.siftPred }}</div>
        <br />
        <div class="row">
          polyphen2HvarPred : {{ filteredCSVDetail.polyphen2HvarPred }}
        </div>
        <br />
        <div class="row">
          mutationTasterPred : {{ filteredCSVDetail.mutationTasterPred }}
        </div>
        <br />
        <div class="row">fathmmPred : {{ filteredCSVDetail.fathmmPred }}</div>
        <br />
        <div class="row">metaSvmPred : {{ filteredCSVDetail.metaSvmPred }}</div>
        <br />
        <div class="row">metaLrPred : {{ filteredCSVDetail.metaLrPred }}</div>
        <br />
        <div class="row">infoMq : {{ filteredCSVDetail.infoMq }}</div>
        <br />
        <div class="row">tIeAd : {{ filteredCSVDetail.tIeAd }}</div>
        <br />
        <div class="row">tIeAf : {{ filteredCSVDetail.tIeAf }}</div>
        <br />
        <div class="row">tIeDp : {{ filteredCSVDetail.tIeDp }}</div>
        <br />
        <div class="row">tIeFt : {{ filteredCSVDetail.f1r2 }}</div>
        <br />
        <div class="row">tIeGt : {{ filteredCSVDetail.tIeGt }}</div>
        <br />
        <div class="row">tIeMb : {{ filteredCSVDetail.tIeMb }}</div>
        <br />
        <div class="row">tIeObam : {{ filteredCSVDetail.tIeObam }}</div>
        <br />
        <div class="row">tIeObamrc : {{ filteredCSVDetail.tIeObamrc }}</div>
        <br />
        <div class="row">tIeObf : {{ filteredCSVDetail.tIeObf }}</div>
        <br />
        <div class="row">tIeObp : {{ filteredCSVDetail.tIeObp }}</div>
        <br />
        <div class="row">tIeObq : {{ filteredCSVDetail.tIeObq }}</div>
        <br />
        <div class="row">tIeObqrc : {{ filteredCSVDetail.tIeObqrc }}</div>
        <br />
        <div class="row">tIePs : {{ filteredCSVDetail.tIePs }}</div>
        <br />
        <div class="row">tIeSb : {{ filteredCSVDetail.tIeSb }}</div>
        <br />
        <div class="row">tIeSq : {{ filteredCSVDetail.tIeSq }}</div>
        <br />
      </div>
    </div>
  </div>
</div>
<!--  -->
<div class="modal fade" id="addVariants">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 1200px">
    <div class="modal-content surgery-modal">
      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue font-weight-bold">
          Create Variant
        </h5>
        <a class="text-softblue" #closeModal data-dismiss="modal">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>
      <div class="modal-body">
        <!-- <p>{{patientDocument.comments}}</p> -->

        <form action="">
          <div class="form-group row w-100">
            <label class="col-sm-2 control-label text-right">CHROM :</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="chrom"
                [(ngModel)]="variant.chrom"
              />
            </div>
            <label class="col-sm-2 control-label text-right">POS :</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="pos"
                [(ngModel)]="variant.pos"
              />
            </div>
          </div>
          <div class="form-group row mb-0 w-100 mb-3">
            <label class="col-sm-2 control-label text-right">End :</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="end"
                [(ngModel)]="variant.end"
              />
            </div>
            <label class="col-sm-2 control-label text-right">REF_x :</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="refX"
                [(ngModel)]="variant.refX"
              />
            </div>
          </div>
          <div class="form-group row mb-0 w-100 mb-3">
            <label class="col-sm-2 control-label text-right">ALT_x :</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="altX"
                [(ngModel)]="variant.altX"
              />
            </div>
            <label class="col-sm-2 control-label text-right">Gene :</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="refGeneX"
                [(ngModel)]="variant.refGeneX"
              />
            </div>
          </div>
          <div class="form-group row mb-0 w-100 mb-3">
            <label class="col-sm-2 control-label text-right"
              >FuncknownGene :</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="funcknownGene"
                [(ngModel)]="variant.funcknownGene"
              />
            </div>
            <label class="col-sm-2 control-label text-right"
              >exonicFuncknownGene :</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="exonicFuncknownGene"
                [(ngModel)]="variant.exonicFuncknownGene"
              />
            </div>
          </div>

          <div class="form-group row mb-0 w-100 mb-3">
            <label class="col-sm-2 control-label text-right"
              >Ensemble Value :</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="ensembleValue"
                [(ngModel)]="variant.ensembleValue"
              />
            </div>
            <label class="col-sm-2 control-label text-right">IGV Link :</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="igvLink"
                [(ngModel)]="variant.igvLink"
              />
            </div>
          </div>
          <div class="form-group row mb-0 w-100 mb-3">
            <label class="col-sm-2 control-label text-right"
              >AAChangeknownGene :</label
            >
            <div class="col-sm-4">
              <textarea
                type="text"
                class="form-control"
                name="aaChangeknownGene"
                [(ngModel)]="variant.aaChangeknownGene"
              ></textarea>
            </div>
            <label class="col-sm-2 control-label text-right"
              >Genomic Alteration :</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                name="genomicAlteration"
                [(ngModel)]="variant.genomicAlteration"
              />
            </div>
          </div>
          <div class="w-100 mr-3">
            <button
              type="button"
              class="btn save_btn px-5 ml-auto d-block mt-3 mr-4"
              (click)="saveVariant()"
            >
              <span> Save </span>
              <!-- <div  class="spinner-border text-dark">
					  <span class="sr-only"></span>
					</div> -->
            </button>
          </div>
        </form>

        <!-- Form End -->
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="confirmModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content surgery-modal">

      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Save Filter Data</h5>
        <a class="text-softblue" data-dismiss="modal">
            <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4">
              <h6 class=" mb-4">Are you sure want to save this filter Data ?</h6>
            <div class="mx-0 ml-auto d-table">
                <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                data-dismiss="modal" (click)="saveFilter()">Yes</button>
                <button type="button" class="btn bg-softblue text-white px-5"
                data-dismiss="modal">No</button>
            </div>
      </div>

    </div>
  </div>
</div>
