<div class="row bg-light py-3 px-3 mx-0 variant_titles justify-content-between d-flex align-items-center mx-4 rounded">
  <div class="col">
    <h5 class="mb-0 d-inline-block position-relative "><span class="list-icon mr-2">
        <i class="zmdi zmdi-accounts-list"></i>
      </span>Trials List</h5>
  </div>
  <div *ngIf="reportedVariant?.report" class="col-4">
    <h5  style="font-weight: bolder;color: #218dca;">{{reportedVariant.report?.patient?.ecrfId}}  <span style="font-size: 20px!important;">[{{reportedVariant?.refGeneX}} <span *ngIf="reportedVariant.selectedAAChangeknownGene != null" style="font-size:15px!important">({{reportedVariant.selectedAAChangeknownGene.split(",")[1]}})</span>]</span></h5>
  </div>
  <div class="col">
		<input type="checkbox" (click)="selectedTrailsList($event)" class="input_check"> <span style="font-size: 13px!important; margin-left: 4px;">Show selected trials</span>
  </div>
  <div *ngIf="!fusionVariantId" class="col">
    <p>Gene : {{gene}}</p>
  </div>
  <div *ngIf="fusionVariantId" class="col">
    Gene : {{gene}}
    <select class="form-control" name="gene" [(ngModel)]="gene" (change)="onChangeFusionGene()">
      <option *ngFor="let fusionGene of fusionGenes" value="{{fusionGene}}"> {{fusionGene}}</option>

      </select>
    </div>
    <div class="col">
      <div class="input-group">
      <input type="text" class="form-control" placeholder="Search with NCT ID" name="nctId" [(ngModel)]="nctId">
      <div class="input-group-btn">
        <button class="btn btn-primary" (click)="searchNCTId()">Search</button>
      </div>
    </div>
      <!-- <input type="text" placeholder="search with NCT ID" name="nctId" [(ngModel)]="nctId">
      <button class="ml-2" (click)="searchNCTId()">search</button> -->
    </div>
    <div class="col">
      <div class=" text-right"> <a (click)="backReportedVariant()" class="text_color font-weight-bold back_btn">
        <i class="zmdi zmdi-long-arrow-left"></i> Back to Reported Variants</a>
      </div>
      <!-- <a class="delete_clr" (click)="backReportedVariant()">Back to Reported Variants</a> -->
    </div>

  </div>



<div class="mx-4">
  <table class="table cust-checkbox">
    <thead class="thead-light">
      <tr>
        <th></th>
        <th>Star Selection</th>
        <th *ngIf="switchToggleButton">Priority Order</th>
        <th>Official Title</th>
        <!-- <th>Effective</th> -->
        <th>Intervention Type</th>
        <th>NCT ID</th>
        <th>Action</th>
      </tr>

    </thead>
    <tbody class="bg-white">


      <div *ngIf="!trailsListSpinner">
        <div class="spinner-border" role="status">
          <span></span>
        </div>
      </div>

      <!-- <div *ngIf="studyFieldsResponse.studyFields == null && trailsListSpinner">
        <p>no data</p>
      </div> -->


      <tr *ngFor="let item of studyFieldsResponse.studyFields | paginate
      : {
          id: 'listing_pagination',
          itemsPerPage: 20,
          currentPage: currentPage,
          totalItems: totalPages * 20
        };
        let i = index">
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" (click)="addSelectedTrails($event,item)"
              [checked]="item.selected">
          </div>
        </td>
        <td>
          <div *ngIf="item.selected && starVariant" class="form-check">
            <!-- <input *ngIf="!item.isStarSelected" class="form-check-input" type="checkbox" data-toggle="modal"data-target="#biomarker"
             (click)="selectedStarTrails($event,item)"  [checked]="item.isStarSelected"> -->
             <input class="form-check-input" type="checkbox"
             (click)="selectedStarTrails($event,item)"  [checked]="item.isStarSelected">
          </div>
        </td>
        <td *ngIf="switchToggleButton">
          <input type="number"  name="priorityOrderNumber_{{i}}" [(ngModel)]="item.priorityOrder"
          (change)="priorityOrder(item.priorityOrder, item.id)">
        </td>
        <td>
        <div *ngFor="let ot of item.officialTitle">
          {{ot | limitTo : '60'}}</div>
        </td>
        <!-- <td>
            <select *ngIf="item.selected" class="form-control" [(ngModel)]="item.association" (change)="addAssociation(item.association,item)">
              <option [ngValue]="null" selected disabled>Select</option>
              <option value="RESPONSIVE">RESPONSIVE</option>
              <option value="RESISTANCE">RESISTANCE</option>
            </select>
        </td> -->
        <td>{{item.interventionType[0]}}</td>
        <td>{{item.nctId}}</td>
        <td><a class="delete_clr" data-toggle="modal" data-target="#viewClinical" (click)="assignStudyField(item)">View
            more</a></td>
      </tr>
    </tbody>
  </table>
  <p *ngIf="studyFieldsResponse.studyFields == null && trailsListSpinner"  style="text-align: center;font-size:25px!important;">*-----No Data-----*</p>
  <div class="text-right mx-3">
    <pagination-controls id="listing_pagination" (pageChange)="changePage($event)">
    </pagination-controls>
  </div>
</div>



<!-- </div> -->

<div class="modal fade" id="viewClinical">
  <div class="modal-dialog clinicalTrail-model modal-dialog-centered">
    <div class="modal-content surgery-modal">

      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Trial Details</h5>
        <a class="text-softblue" #closebutton data-dismiss="modal">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4">
        <div *ngIf="studyField.selected" class="row mb-3">
          <div class="col-2">
            <h5 style="color: #001a56;">Effective</h5>
            <select class="form-control" [(ngModel)]="studyField.association">
              <option [ngValue]="null" selected disabled>Select</option>
              <option value="RESPONSIVE">RESPONSIVE</option>
              <option value="RESISTANCE">RESISTANCE</option>
              <option value="ONGOING">ONGOING TRIAL</option>
            </select>
          </div>
          <div class="col-2">
            <h5 style="color: #001a56;">Cancer Type</h5>
            <select class="form-control" [(ngModel)]="studyField.cancerTypeEnum">
              <option [ngValue]="null" selected disabled>Select</option>
              <option value="SAME">SAME</option>
              <option value="OTHERS">OTHERS</option>
            </select>
          </div>
        <div class="col-3">
          <h5 style="color: #001a56;">Cancer</h5>
          <ng-multiselect-dropdown
          [settings]="dropdownSettings"
          [data]="cancerTypeList"
          [(ngModel)]="studyField.cancerTypes"
          name="cancerTypeId"
          >
      </ng-multiselect-dropdown>
        </div>
        <div class="col-3">
          <h5 style="color: #001a56;">Drug</h5>
          <ng-multiselect-dropdown
          [settings]="dropdownSettings"
          [data]="studyField.interventionName"
          [(ngModel)]="studyField.drugs"
          name="drugName"
          >
      </ng-multiselect-dropdown>

        </div>
        <div class="col-2">
          <button type="button" [disabled]="isSubmited" class="btn save_btn" style="float:right" id="closeModal"
          (click)="addAssociation(studyField)">
              <span *ngIf="!isSubmited"> Save </span>
              <div *ngIf="isSubmited" class="spinner-border text-dark">
                  <span class="sr-only"></span>
              </div>
          </button>
      </div>
        </div>

        <div *ngIf="studyField.selected" class="row mb-3">
            <div class="col-1 text-right">
          <input *ngIf="!studyField.starNctClinicalTrail" class="form-check-input" type="checkbox" data-toggle="modal" data-target="#biomarker"
          (click)="starNctMethod($event)">
          <input *ngIf="studyField.starNctClinicalTrail" class="form-check-input" type="checkbox"
          (click)="starNctMethod($event)">
          </div>
          <div class="col text-left">
            <label>Select Star Nct Clinical Trial</label>
          </div>
        </div>

<!--  -->
        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Brief Summary :</label>
          <div class="col-sm-8">
            <p *ngFor="let briefSummary of studyField.briefSummary" class="pl-2"><span class="arrow_mark">-></span>{{briefSummary}}</p>
          </div>
        </div>



        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Condition :</label>
          <div class="col-sm-8">
            <p *ngFor="let cond of studyField.condition" class="pl-2"><span class="arrow_mark">-></span> {{cond}}</p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Intervention Description :</label>
          <div class="col-sm-8">
            <p *ngFor="let interventionDescription of studyField.interventionDescription" class="pl-2"><span class="arrow_mark">-></span>
              {{interventionDescription}}</p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Intervention Name :</label>
          <div class="col-sm-8">
            <p *ngFor="let interventionName of studyField.interventionName" class="pl-2"><span class="arrow_mark">-></span> {{interventionName}}</p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Intervention Type :</label>
          <div class="col-sm-8">
            <p *ngFor="let interventionType of studyField.interventionType" class="pl-2"><span class="arrow_mark">-></span> {{interventionType}}</p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Last Updated :</label>
          <div class="col-sm-8">
            <p *ngFor="let lastUpdated of studyField.lastUpdated" class="pl-2"><span class="arrow_mark">-></span> {{lastUpdated}}</p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">NCT Id :</label>
          <div class="col-sm-8">
            <p *ngFor="let nctId of studyField.nctId" class="pl-2"><span class="arrow_mark">-></span> <a href="https://clinicaltrials.gov/ct2/show/{{nctId}}" target="_blank"> {{nctId}}</a></p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Official Title :</label>
          <div class="col-sm-8">
            <p *ngFor="let officialTitle of studyField.officialTitle" class="pl-2"><span class="arrow_mark">-></span> {{officialTitle}}</p>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Phase :</label>
          <div class="col-sm-8">
            <p *ngFor="let phase of studyField.phase" class="pl-2"><span class="arrow_mark">-></span> {{phase}}</p>
          </div>
        </div>


      </div>

    </div>
  </div>
</div>


<div class="modal fade" id="biomarker">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content surgery-modal">

      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Trial Details</h5>
        <a class="text-softblue" data-dismiss="modal">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4">

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Biomarker :</label>
          <div class="col-sm-8">
            <textarea type="text" class="form-control" name="bioMarker" [(ngModel)]="selectedTrail.bioMarker"></textarea>

          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 control-label text-right">Condition :</label>
          <div class="col-sm-8">
            <textarea type="text" class="form-control" name="condition" [(ngModel)]="selectedTrail.condition"></textarea>
          </div>
        </div>

        <div>
          <button type="button" class="btn save_btn" style="float:right" data-dismiss="modal"
          (click)="addSelectedStarTrails()"> Save </button>
        </div>

      </div>

    </div>
  </div>
</div>
