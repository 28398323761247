import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  Gene,
  PubMedServerDetails,
  TruncationDetails,
} from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PaginationReqInterface } from 'src/app/_interfaces.ts/pagination';

@Component({
  selector: 'app-pub-med-server',
  templateUrl: './pub-med-server.component.html',
  styleUrls: ['./pub-med-server.component.css'],
})
export class PubMedServerComponent implements OnInit {
  editor = ClassicEditor;
  pubMedPaginationReq = {} as PaginationReqInterface;

  pubMedServerSpinner: boolean = true;
  addPubMedServerDetails = {} as PubMedServerDetails;
  pubMedsList: PubMedServerDetails[] = [];
  statusMsg: string | undefined;
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  geneDropdownSettings = {};
  searchText!:any;

  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }
  showError(msg: any) {
    this.toastr.error(msg, '', { timeOut: 2000 });
  }
  currentPage!: number;
  totalPages!: number;
  ngOnInit(): void {
    this.pubMedPaginationReq.reqPageNo = 0;
    this.pubMedPaginationReq.pageSize = 10;
    this.pubMedPaginationReq.sortBy = 'lastModified';
    this.pubMedPaginationReq.sortOrder = 'desc';
    this.pubMedPaginationReq.searchKey = '';
    this.getAllPubMedServerList();
  }

  addPubMedDetail() {
    console.log('Add Pub Med Details :  ', this.addPubMedServerDetails);
    this.dataAdminService.addPubMedServer(this.addPubMedServerDetails).subscribe(
        (response: any) => {
          console.log('response add', response);
          this.statusMsg = response['message'];
          this.showSuccess();
          this.reload();
        },
        (error) => {
          console.log(error);
          this.showError(error.error.message);
        }
      );
  }
  assignPubMedDetails(pubMedServerDetails: PubMedServerDetails) {
    this.addPubMedServerDetails = pubMedServerDetails;
    console.log('edit Pub Med Details =>', this.addPubMedServerDetails);
  }
  processCount = 0;

  getAllPubMedServerList() {
    this.dataAdminService.getAllPubMedServer(this.pubMedPaginationReq).subscribe((response: any) => {
        this.pubMedServerSpinner = false;
        this.pubMedsList = response['payLoad']['pubMedServerList'];

        this.totalPages = response['payLoad']['totalPages'];
        this.currentPage = response['payLoad']['currentPage'] + 1;
        console.log('totalPages =>', this.totalPages);
        console.log('currentPage =>', this.currentPage);
        console.log('list pub med Details =>', response);

      });
  }

  changePage(event: number) {
    console.log('event', event);
    this.currentPage = event;
    this.pubMedPaginationReq.reqPageNo = this.currentPage - 1;
    this.getAllPubMedServerList();
  }

  searchData(searchstr: any) {
    if (searchstr != '') {
      this.pubMedPaginationReq.searchKey = searchstr;
      this.pubMedPaginationReq.reqPageNo = 0;
    } else {
      this.pubMedPaginationReq.searchKey = '';
      this.pubMedPaginationReq.reqPageNo = 0;
    }
    this.getAllPubMedServerList();
  }

  closeModal() {
    console.log('close modal');
    this.addPubMedServerDetails = {} as PubMedServerDetails;
  }

  reload() {
    console.log('reload');
    this.router
      .navigateByUrl('/reports', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/super_admin/pub_med_server']);
      });
  }
}
