import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ToastrService } from 'ngx-toastr';
import {
  FirstReportPdfRes,
  RelevantData,
  ReportRes,
} from 'src/app/_interfaces.ts/reports';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-first72-liquid4bc',
  templateUrl: './first72-liquid4bc.component.html',
  styleUrls: ['./first72-liquid4bc.component.css'],
})
export class First72Liquid4bcComponent implements OnInit {
  // footerDiscription =
  //   'All the 4baseCare test samples are processed at 4basecare Offshore Development Centre (ODC) - Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15189:2012 for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO. 69P,71/4P,78/8AP,134P,76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification';
  footerDiscription ="All the 4baseCare test samples are processed at 4baseCare Offshore Development Centre (ODC) - "+
                "Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15109:2012 "+
                "for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO 69P,71/4P,70/BAP,134P, "+
                "76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, "+
                "INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification."
  footerDiscription2 = "4baseCare (Genomics Tarang ODC), SJP2-S1-1F-C wing, Wipro Limited, SEZ, Sarjapur 2, SyNo.69 (P), Doddakannelli, "+
                "Sarjapura Road, Bengaluru 560035, KA, India"

  is4basecare = true;
  isReportDownload: boolean = false;
  imgsings: any = '';
  firstPageTable: any = '';
  reportId!: number;
  reportPdfRes = {} as FirstReportPdfRes;
  report = {} as ReportRes;
  date = Date();
  downloadSpinner = false;
  data: any;
  reportData = false;
  statusMsg!: string;
  newsnvHotspotTrailResList: any = [];
  geneFlag = false;
  variantFlag = false;
  tableShow = false;
  sampleSource!: string;
  firstTableFlag: boolean = false;
  mixedVariants: any = [];
  snvSameRelevantData: RelevantData[] = [];
  snvOtherRelevantData: RelevantData[] = [];

  cnaSameRelevantData: RelevantData[] = [];
  cnaOtherRelevantData: RelevantData[] = [];

  fusionSameRelevantData: RelevantData[] = [];
  fusionOtherRelevantData: RelevantData[] = [];
  prognasticCount = 0;

  snvHotSpot: any = [];
  cnaHotSpot: any = [];
  fusionHotSpot: any = [];
  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.reportId = this.routerAct.snapshot.params['id'];
    this.temp = this.routerAct.snapshot.params['temp'];
    console.log('temp ->', this.temp);
    if (this.reportId) {
      this.getReport();
    }
  }
  ageOfBlock!: any;

  tableCount = 0;
  referringClinician!: string;
  labHospital!: string;
  geneMutationlist: any[] = [];
  fusionGeneMutationList: any[] = [];
  getReport() {
    this.reportService.first72ReportPdfResponse(this.reportId).subscribe(
      (response: any) => {
        console.log(response);
        this.reportData = true;
        this.statusMsg = response.message;
        this.showSuccess();
        this.reportPdfRes = response['payLoad'];
        this.report = response['payLoad']['report'];
        console.log('clientType--->', this.report.clientType);

        this.reportPdfRes.snvHotspotTrailResList.forEach((snv) => {
          this.snvHotSpot.push(snv);
        });
        this.reportPdfRes.cnaHotspotTrailResList.forEach((cna) => {
          this.cnaHotSpot.push(cna);
        });
        this.reportPdfRes.fusionHotspotTrailResList.forEach((fusion) => {
          this.fusionHotSpot.push(fusion);
        });
        if (
          this.reportPdfRes.geneMutationResList != null &&
          this.reportPdfRes.geneMutationResList?.length > 0
        ) {
          for (
            let i = 0;
            i < this.reportPdfRes.geneMutationResList?.length;
            i++
          ) {
            if (
              this.reportPdfRes.geneMutationResList[i]?.gene != null &&
              this.reportPdfRes.geneMutationResList[i]?.gene?.length != 0
            ) {
              this.geneMutationlist.push(
                this.reportPdfRes.geneMutationResList[i]
              );
            }
          }
        }
        console.log('geneMutationlist =>', this.geneMutationlist);
        if (
          this.reportPdfRes.fusionGeneMutationResList != null &&
          this.reportPdfRes.fusionGeneMutationResList?.length > 0
        ) {
          for (
            let i = 0;
            i < this.reportPdfRes.fusionGeneMutationResList?.length;
            i++
          ) {
            if (
              this.reportPdfRes.fusionGeneMutationResList[i]?.gene != null &&
              this.reportPdfRes.fusionGeneMutationResList[i]?.gene?.length != 0
            ) {
              this.fusionGeneMutationList.push(
                this.reportPdfRes.fusionGeneMutationResList[i]
              );
            }
          }
        }

        if (
          this.is4basecare == true &&
          this.report?.labDetails?.hospital.includes('Cytecare')
        ) {
          this.referringClinician = 'Cytecare Hospitals';
          this.labHospital = 'Cytecare Hospitals';
        } else {
          this.referringClinician =
            this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
          this.labHospital = this.report?.labDetails?.hospital;
        }
        this.tableCount = this.reportPdfRes.allHotspotTrailList.length;
        console.log('additional noted before-->', this.report.addNotes);
        if (
          this.report.addNotes == null ||
          this.report.addNotes == undefined ||
          this.report.addNotes == ''
        ) {
          this.report.addNotes = '<p></p>';
          console.log('additional noted Not requried-->', this.report.addNotes);
        }
        if (this.report.addNotes != null || this.report.addNotes == '<p></p>') {
          this.report.addNotes = this.report.addNotes.replace(/<p>/g, '<div>');
          this.report.addNotes = this.report.addNotes.replace(
            /<\/p>/g,
            '</div>'
          );
          this.report.addNotes = this.report.addNotes.replace(/{/g, '<sup>');
          this.report.addNotes = this.report.addNotes.replace(/}/g, '</sup>');
          this.report.addNotes = this.report.addNotes.replace(/~/g, '<sub>');
          this.report.addNotes = this.report.addNotes.replace(/!/g, '</sub>');
          console.log('additional noted after-->', this.report.addNotes);
        }
        var start = new Date(this.report.labDetails?.sampleCollected);
        var end = new Date(this.report.labDetails?.dateOfHistopathologyReport);
        this.ageOfBlock = Math.floor(
          (Date.UTC(start.getFullYear(), start.getMonth(), start.getDate()) -
            Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())) /
            (1000 * 60 * 60 * 24)
        );

        if (
          this.report.disclaimerNote != null
        ) {
          this.report.disclaimerNote = this.report.disclaimerNote.replace(/{/g, '<sup>');
          this.report.disclaimerNote = this.report.disclaimerNote.replace(/}/g, '</sup>');
          this.report.disclaimerNote = this.report.disclaimerNote.replace(/~/g, '<sub>');
          this.report.disclaimerNote = this.report.disclaimerNote.replace(/!/g, '</sub>');

          this.report.disclaimerNote = this.report.disclaimerNote.replaceAll(
            '$',
            '<sup>$</sup>'
          );
        }
        if (this.report.labDetails != null) {
          // console.log("sampleScorce-->",this.report.labDetails.sampleSource)
          this.sampleSource = this.report.labDetails?.sampleSource?.replace(
            /FFPE BlockId:/g,
            ''
          );
        }
        if (this.reportPdfRes.cnaReportedVariantList.length > 0) {
          this.tableShow = true;
        }
        this.getExonNumbers();
        let geneLength: number = 0;
        let associationListLenght: number = 0;
        let drugListLength: number = 0;

        console.log('size', associationListLenght, ' ', drugListLength);
        if (
          associationListLenght >= 7 ||
          drugListLength >= 7 ||
          geneLength > 6
        ) {
          this.firstTableFlag = true;
        }
      },
      (error) => {
        console.log('oops...!', error);
        this.statusMsg = error.error.error;
        this.showFail();
      }
    );
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }
  showFail() {
    this.toastr.error(this.statusMsg, '', { timeOut: 10000 });
  }
  isStatusDisplay = false;

  count = 0;
  countOther = 0;
  WTcountSNV: boolean = false;
  otherCountSNV = false;
  temp: any = 'no_temp';

  getExonNumbers() {
    this.reportPdfRes.snvReportedVariantList.forEach((reportedVariant) => {
      if (reportedVariant.prognasticSignificance != null) {
        this.prognasticCount++;
      }
      reportedVariant.aachangeknownGeneList.forEach(
        (aachangeknownGene, index) => {
          if (
            reportedVariant.selectedAAChangeknownGene &&
            reportedVariant.selectedAAChangeknownGene.length > 0
          ) {
            if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
              reportedVariant.exonNumber = aachangeknownGene
                .split(':')[2]
                .substring(4);
            }
          } else {
            if (aachangeknownGene == 'UNKNOWN') {
              // console.log("UNKNOWN");
              reportedVariant.selectedAAChangeknownGene = ':::::';
              // console.log("reportedVariant.selectedAAChangeknownGene UNKNOWN", reportedVariant.selectedAAChangeknownGene);
            } else if (aachangeknownGene == '.') {
              reportedVariant.selectedAAChangeknownGene = ':::::';
              // console.log("reportedVariant.selectedAAChangeknownGene dot", reportedVariant.selectedAAChangeknownGene);
            } else if (
              aachangeknownGene.includes(reportedVariant.ensembleValue)
            ) {
              reportedVariant.selectedAAChangeknownGene =
                aachangeknownGene.split(':')[3];
              reportedVariant.selectedAAChangeknownGene =
                reportedVariant.selectedAAChangeknownGene +
                ', ' +
                aachangeknownGene.split(':')[4];
              console.log('AAAAAa', reportedVariant.selectedAAChangeknownGene);
              reportedVariant.exonNumber = aachangeknownGene
                .split(':')[2]
                .substring(4);
            }

            // console.log("reportedVariant.selectedAAChangeknownGene", reportedVariant.selectedAAChangeknownGene);

            if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
              // console.log("reportedVariant.selectedAAChangeknownGene index", reportedVariant.selectedAAChangeknownGene);

              if (reportedVariant.selectedAAChangeknownGene == null) {
                let selectedAA: any = reportedVariant.aachangeknownGeneList[0];
                reportedVariant.selectedAAChangeknownGene =
                  selectedAA.split(':')[3];
                reportedVariant.selectedAAChangeknownGene =
                  reportedVariant.selectedAAChangeknownGene +
                  ', ' +
                  selectedAA.split(':')[4]; // reportedVariant.selectedAAChangeknownGene = reportedVariant.aachangeknownGeneList[0];
                reportedVariant.exonNumber =
                  reportedVariant.aachangeknownGeneList[0]
                    .split(':')[2]
                    .substring(4);
                // console.log("reportedVariant.selectedAAChangeknownGene null", reportedVariant.selectedAAChangeknownGene);
              }
            }
          }
          // console.log("==>", aachangeknownGene, reportedVariant.ensembleValue);
        }
      );
      console.log('JJJJ', reportedVariant);
      if (
        reportedVariant.significance == 'NA' ||
        reportedVariant.significance == 'Not Classified'
      ) {
        this.count++;
      }
      if (
        reportedVariant.significance != 'NA' &&
        reportedVariant.significance != 'Not Classified'
      ) {
        this.countOther++;
      }

      if (this.count == 0) {
        this.WTcountSNV = true;
      }
      if (this.countOther > 0) {
        this.otherCountSNV = true;
      }
    });
    this.reportPdfRes.cnaReportedVariantList.forEach((reportedVariant) => {
      if (reportedVariant.prognasticSignificance != null) {
        this.prognasticCount++;
      }
    });
    // console.log('NA.count: ', this.WTcountSNV, '\n Other.count: ', this.otherCountSNV);
  }
  downloadReport() {
    this.downloadSpinner = true;
    // this.page1()
    this.print();
  }

  page1() {
    let data: any = document.getElementById('firstPageTable');
    html2canvas(data).then((canvas) => {
      // var foreignObjectRendering: true;
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      this.firstPageTable = contentDataURL;
      this.print();
    });
  }
  firstPageTable1: any = '';
  firstPageTable2: any = '';

  getpposition(data: any) {
    if (data != null) {
      if (data.includes(',')) {
        if (data.split(',')[1].includes('p.')) {
          return data.split(',')[1];
        }
      }
    }
  }
  page4_img() {
    let data: any = document.getElementById('imgsings');
    html2canvas(data).then((canvas) => {
      // var foreignObjectRendering: true;
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      this.imgsings = contentDataURL;
      this.isReportDownload = true;
      this.print();
    });
  }

  print() {
    this.isReportDownload = true;
    setTimeout(() => {
      this.downloadSpinner = false;
      document.title =
        'tarGT_First_72_Liquid' +
        '-' +
        this.report?.patient?.name?.replace(/\./g, ' ');
      window.print();
      this.isReportDownload = false;
    }, 3000);
  }

  savePdf(pdf: jsPDF) {
    this.downloadSpinner = false;
    pdf.save(
      'tarGT_First_72_Liquid-' + this.report.patient.name.replace(/\./g, ' ')
    );
  }
}
