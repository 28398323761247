
<div class="container-fluid ">
  <div *ngIf="report.reportType != null" class="row px-4">
    <div class="col-4 pl-0">
      <!-- <div class="report_icon">
        <a href="/" class="text_color ">
        <i class="zmdi zmdi-long-arrow-left "></i>
        Back to  Reports</a>
      </div> -->
    </div>
    <!--  -->
    <div class="col pr-1">
     <div class="d-flex justify-content-end">
      <div class="">
        <div *ngIf="report.reportType.name !='BRCA 1 and 2' && report.reportType.name != 'First' && report.reportType.name != 'First 72 Liquid' && report.reportType.name !='First 72 Solid' && report.reportType.name !='Lung Liquid Advanced' && report.reportType.name !='Lung Liquid Basic' && report.reportType.name != 'Core' && !isGermlineReport && report.reportType.name !='HRR Somatic' && report.reportType.name !='Indiegene'" class="report_icon">
          <a [routerLink]="[ '/view_report', reportId ]" target="_blank"
          class="text_color "> <i class="zmdi zmdi-eye eye"></i>
          View Report</a>
        </div>
        <div *ngIf="report.reportType.name =='Indiegene' " class="report_icon">
          <a [routerLink]="[ '/view_report_indiegene', reportId ]" target="_blank"
          class="text_color "> <i class="zmdi zmdi-eye eye"></i>
          View Report</a>
        </div>
        <div *ngIf="report.reportType.name == 'Core'" class="report_icon">
            <a [routerLink]="[ '/core_report', reportId ]" target="_blank"
            class="text_color "> <i class="zmdi zmdi-eye eye"></i>
            View Report</a>
        </div>
        <div *ngIf="report.reportType.name == 'First'" class="report_icon">
           <a [routerLink]="[ '/first_view_report', reportId ]" target="_blank"
          class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
        </div>
        <div *ngIf="isGermlineReport && (report.reportType.name == 'Germline+' || report.reportType.name == 'Germline+ V2')" class="report_icon">
            <a [routerLink]="[ '/germline_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div>
         <div *ngIf="report.reportType.name == 'HRR Germline' && isGermlineReport" class="report_icon">
            <a [routerLink]="[ '/hrr_germline_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div>
         <div *ngIf="report.reportType.name == 'Family Testing' && isGermlineReport" class="report_icon">
            <a [routerLink]="[ '/family_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div>
         <div *ngIf="report.reportType.name =='HRR Somatic'" class="report_icon">
            <a [routerLink]="[ '/hrr_somatic', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div>
         <div *ngIf="report.reportType.name =='First 72 Solid' " class="report_icon">
          <a [routerLink]="[ '/first72_view_report', reportId, temp ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div>
<!--
         <div *ngIf="report.reportType.name =='First 72 Solid' && is4basecare && (temp==null ||  temp=='') && only4bc" class="report_icon">
          <a [routerLink]="[ '/first72_solid_4bc_view_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div> -->

         <!-- <div *ngIf="report.reportType.name =='First 72 Solid' && !is4basecare && !isTMH" class="report_icon">
          <a [routerLink]="[ '/first72_view_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div> -->
                     <!-- <a [routerLink]="[ '/first72_view_report', reportId, temp ]" target="_blank" -->

         <div *ngIf="report.reportType.name =='First 72 Liquid' " class="report_icon">
          <a [routerLink]="[ '/first72_liquid_view_report', reportId, temp ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div>

         <!-- <div *ngIf="report.reportType.name =='First 72 Liquid' && is4basecare && (temp==null ||  temp=='') && only4bc" class="report_icon">
          <a [routerLink]="[ '/first72_liquid_4bc_view_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div> -->

         <!--******* <div *ngIf="report.reportType.name =='First 72 Liquid' && !is4basecare && !isHCG" class="report_icon">
          <a [routerLink]="[ '/first72_liquid_view_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div> -->
                     <!-- <a [routerLink]="[ '/first72_liquid_view_report', reportId, temp ]" target="_blank" -->

         <div *ngIf="report.reportType.name =='Lung Liquid Basic'" class="report_icon">
            <a [routerLink]="[ '/lung_liquid_basic_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div>
         <div *ngIf="report.reportType.name =='Lung Liquid Advanced'" class="report_icon">
            <a [routerLink]="[ '/lung_liquid_advanced_report', reportId ]" target="_blank"
           class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
         </div>
         <div *ngIf="report.reportType.name =='BRCA 1 and 2'" class="report_icon">
          <a [routerLink]="[ '/brca1and2_view_report', reportId ]" target="_blank"
         class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
       </div>
      </div>
      <div class="ml-3">
        <div class=" text-right  ml-4" *ngIf=" this.organisationName==null ||  this.organisationName==''">
          <select class="form-control" (change)="changeReportStatus($event.target)">
              <option selected disabled> select report Delivered</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
          </select>
      </div>
      <div class=" text-right  ml-4" *ngIf=" this.organisationName!=null &&  this.organisationName!=''">
        <select class="form-control" (change)="changeReportAndOncoStatus($event.target)">
            <option selected disabled> Is the report completed?</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
        </select>
    </div>

      </div>
     </div>
     <!--  -->

     <!--  -->

    </div>

  </div>
</div>

<!-- selecting report type -->
<div *ngIf="report.reportType == null" class="container-fluid  patient-info_tabs position-relative reports_tabs">
    <div *ngIf="reportTypeChange" class="d-flex justify-content-center mt-5">
        <span class="visually-hidden"></span>
        <div
          class="spinner-border"
          style="width: 8rem; height: 8rem"
          role="status"
        ></div>
      </div>
  <!--  -->
    <header class=" pl-3  mb-3" *ngIf="!reportTypeChange">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#patient-info">Patient Info</a>
            </li>
        </ul>

        <div class="tab-content">
            <div id="patient-info" class="container-fluid tab-pane active"><br>
                <div id="tab7" class="">
                    <div class=" cust_shadow p-4">
                        <div class="form-group row mb-0">
                            <label class="col-sm-4 control-label text-right">Ecrf Id :</label>
                            <div class="col-sm-8">
                                <label>{{report?.patient?.ecrfId}}</label>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-sm-4 control-label text-right">Name :</label>
                            <div class="col-sm-8">
                                <label>{{report?.patient?.name}}</label>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-sm-4 control-label text-right">Gender :</label>
                            <div class="col-sm-8">
                                <label>{{report?.patient?.gender}}</label>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-sm-4 control-label text-right">Collected On :</label>
                            <div class="col-sm-8">
                                <label>{{report?.labDetails?.sampleCollected}}</label>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-sm-4 control-label text-right">FFPE ID :</label>
                            <div class="col-sm-8">
                                <label>{{report?.labDetails?.sampleBlockId}}</label>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-sm-4 control-label text-right">Report Type :</label>
                            <div class="col-sm-8">
                              <ng-select [items]="reportTypeList" bindLabel="name" bindValue="name"
                                            [multiple]="false" placeholder="Select Report Type" (change)="addReportType($event)"
                                            [(ngModel)]="selected"></ng-select>
                                <!-- <select class="form-control" style="width: 15%" (change)="addReportType($event)">
                                    <option selected disabled>Select</option>
                                    <option value="Absolute">Absolute</option>
                                    <option value="Core">Core</option>
                                    <option value="Indiegene">Indiegene</option>
                                    <option value="Focus">Focus</option>
                                    <option value="First">First</option>
                                    <option value="Germline+">Germline+</option>
                                    <option value="Germline+ V2">Germline+ V2</option>
                                    <option value="HRR Germline">HRR Germline</option>
                                    <option value="Family Testing">Family Testing</option>
                                    <option value="HRR Somatic">HRR Somatic</option>
                                    <option value="First 72 Solid">First 72 Solid</option>
                                    <option value="First 72 Liquid">First 72 Liquid</option>
                                    <option value="Lung Liquid Basic">Lung Liquid Basic</option>
                                    <option value="Lung Liquid Advanced">Lung Liquid Advanced</option>
                                </select> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </header>

</div>

<!-- if report is not germline type -->
<div *ngIf="report.reportType != null && !isGermlineReport" class="container-fluid patient-info_tabs position-relative reports_tabs">
    <div *ngIf="reportTypeChange" class="d-flex justify-content-center mt-5">
        <span class="visually-hidden"></span>
        <div
          class="spinner-border"
          style="width: 8rem; height: 8rem"
          role="status"
        ></div>
      </div>
    <div  *ngIf="!reportTypeChange">
        <header class=" pl-3  mb-3">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#patient-info" (click)="textcount('clinicalSummary-editors')">Patient Info</a>
                </li>
                <li class="nav-item" *ngIf="is4basecare && report.reportType.name =='First 72 Solid'">
                  <a class="nav-link" data-toggle="tab" (click)="assign('hotspot')"  href="#hotspot">Hotspot</a>
              </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" (click)="assign('snv')" href="#snv">SNV / InDels</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" (click)="assign('cna')" href="#cna">CNA</a>
                </li>
                <li class="nav-item" *ngIf="report.reportType.name != 'Focus' && report.reportType.name !='HRR Somatic'">
                    <a class="nav-link" (click)="assign('fusion')" data-toggle="tab" href="#fusion">Fusion</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" *ngIf="report.reportType.name =='HRR Somatic'" data-toggle="tab" href="#notes" (click)="textcount('addNote-editor')">Additional Notes </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" *ngIf="report.reportType.name =='HRR Somatic'" (click)="assign('coverage')" data-toggle="tab" href="#coverage">Coverage</a>
                </li>
                <li class="nav-item" *ngIf="isTargetFirst">
                    <a class="nav-link" data-toggle="tab" href="#disclaimer" *ngIf="report.reportType.name !='HRR Somatic'" (click)="textcount('disclaimer-editors')" >
                      <span >Additional Notes</span></a>
                </li>
                <li class="nav-item" *ngIf="report.reportType.name != 'First' && report.reportType.name !='First 72 Solid' && report.reportType.name != 'First 72 Liquid' && report.reportType.name !='Lung Liquid Advanced' && report.reportType.name !='Lung Liquid Basic' && report.reportType.name !='HRR Somatic'">
                    <a class="nav-link" data-toggle="tab" href="#summary" (click)="textcount('evidenceSummary-editors')">* Evidence Summary</a>
                </li>
                <li class="nav-item" *ngIf="report.reportType.name != 'First' && report.reportType.name !='First 72 Solid' && report.reportType.name != 'First 72 Liquid' && report.reportType.name !='Lung Liquid Advanced' && report.reportType.name !='Lung Liquid Basic'">
                    <a class="nav-link" data-toggle="tab" href="#interpretation" (click)="textcount('interpretation-editors')">Interpretation</a>
                </li>
                <li class="nav-item" *ngIf="report.reportType.name != 'First' && report.reportType.name !='First 72 Solid' && report.reportType.name != 'First 72 Liquid' && report.reportType.name !='HRR Somatic' && report.reportType.name !='Lung Liquid Advanced' && report.reportType.name !='Lung Liquid Basic' ">
                    <a class="nav-link" data-toggle="tab" href="#Immunodata">* Immunodata</a>
                </li>
                <li *ngIf="immunoDetails.pdl1Status == 'EXPRESSION' && report.reportType.name !='HRR Somatic'" class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#pdl1trails">PD-L1 Trials</a>
                </li>
                <li class="nav-item" *ngIf="report.reportType.name != 'First' && report.reportType.name !='First 72 Solid' && report.reportType.name != 'First 72 Liquid' && report.reportType.name !='HRR Somatic' && report.reportType.name !='Lung Liquid Advanced' && report.reportType.name !='Lung Liquid Basic'">
                    <a class="nav-link" data-toggle="tab" href="#website">Websites</a>
                </li>
            </ul>
        </header>
        <div *ngIf="(report.reportType.name =='First 72 Liquid' ) || report.reportType.name =='First 72 Solid'" class="d-flex justify-content-end" style="padding-top:5px!important;">
                <div class="">
                  <select class="form-control" (change)="tempSelection($event)">
                    <option value="temp1" selected="selected"> Template 1</option>
                    <!-- <option value="temp1">Template 1</option> -->
                    <option value="temp2">Template 2</option>
                    <option value="temp3">Template 3</option>
                  </select>
                </div>
                <div class="ml-3">
                  <div class=" text-right  ml-4">
                  </div>
                </div>
               </div>
        <div class="tab-content">

            <!-- Patient Info -->
            <div id="patient-info" class="container-fluid tab-pane active"><br>
                <div id="tab1" class="">
                    <div class="cust_shadow p-4 bg-white  rounded">
                        <div class="levels_section  row mt-2 py-3 rounded mx-0" style="background: #f1f6f6;">
                            <div class=" col-6">
                                <p *ngIf=" this.organisationName==null ||  this.organisationName==''">
                                    <label class="col-8">Select Sample Type :
                                        <ng-select [items]="patientSamples" bindLabel="name" bindValue="name"
                                            [multiple]="true" placeholder="Select samples" (change)="addSampleID()"
                                            [(ngModel)]="selected"></ng-select>
                                    </label>
                                </p>
                                <p  *ngIf=" this.organisationName==null ||  this.organisationName==''"><label class="col">Ecrf Id : {{report?.patient?.ecrfId}}</label></p>
                                <p  *ngIf=" this.organisationName!=null &&  this.organisationName!='' && this.organisationName=='Lifecell'"><label class="col">Patient Id : {{report?.patient?.orgLabId}}</label></p>
                                <p  *ngIf=" this.organisationName!=null &&  this.organisationName!='' && this.organisationName!='Lifecell'"><label class="col">Patient Id : {{report?.patient?.organisationPatientId}}</label></p>

                                <p><label class="col">Name : {{report?.patient?.name}}</label></p>
                                <p><label class="col">Gender : {{report.patient.gender}}</label></p>
                                <p><label class="col">Collected On : {{report.labDetails.sampleCollected}}</label></p>
                                <p><label class="col" *ngIf=" this.organisationName==null ||  this.organisationName==''">FFPE ID : {{report.labDetails.sampleBlockId}}</label></p>
                                <p><label class="col" *ngIf="is4basecare && only4bc && (report.reportType.name =='First 72 Solid' ||  report.reportType.name =='First 72 Liquid')">Age Of Block :
                                  <span class="text-black"
                                  *ngIf="report.labDetails.dateOfHistopathologyReport!=null">{{ageOfBlock}}<span *ngIf="ageOfBlock!=null"> days</span></span>
                                <span class="text-black" *ngIf="report.labDetails.dateOfHistopathologyReport==null"></span></label></p>
                                <p><label class="col" *ngIf="is4basecare && only4bc && (report.reportType.name =='First 72 Solid' ||  report.reportType.name =='First 72 Liquid')">Tumour Content Assessment : {{report.labDetails.tumourContentAssessment || ""}}<span *ngIf="report.labDetails.tumourContentAssessment!=null">%</span></label></p>

                            </div>
                            <div class="col-6" >
                                <p *ngIf=" this.organisationName==null ||  this.organisationName==''">
                                    <label class="col-8 ">Change Report Type :
                                      <ng-select [items]="reportTypeList" bindLabel="name" bindValue="name"
                                            [multiple]="false" placeholder="Select Report Type" (change)="changeReportType($event)"
                                            [(ngModel)]="selected"></ng-select>
                                    </label>
                                </p>
                                <p><label class="col">DOB/Age : <span *ngIf="report.patient.dateOfBirth != null">{{report.patient.dateOfBirth | date:'dd/MM/yyyy'}}</span> <span *ngIf="report.patient.age != null">{{report.patient.age}} Years </span></label>
                                </p>
                                <p><label class="col">Report Type : Targt {{report.reportType.name}}</label></p>
                                <p><label class="col">Cancer Site : {{report.cancerType}}</label></p>
                                <p *ngIf=" this.organisationName==null ||  this.organisationName==''"><label class="col">Sample Id : {{report.labDetails.sampleId}}</label></p>
                                <p><label class="col" >Sample Source : {{report.labDetails.sampleSource}}</label></p>
                                <p><label class="col">Referring Clinician :
                                        {{referringClinician}}</label></p>
                                <p><label class="col">Hospital : {{report.labDetails.hospital}}</label></p>


                            </div>


                        </div>

                        <!-- selecet cancer type for first -->
                        <div *ngIf="report.reportType?.name == 'First' || report.reportType.name =='First 72 Solid' ||  report.reportType.name =='First 72 Liquid' || report.reportType.name =='Lung Liquid Advanced' || report.reportType.name =='Lung Liquid Basic' ||  report.reportType.name =='HRR Somatic'" class="levels_section row mt-2 py-1 rounded mx-0" style="background: #f1f6f6;">
                          <div class=" col-6">
                                <p *ngIf=" this.organisationName==null ||  this.organisationName==''" >
                              <label class="col-8">Cancer Type :
                                        <ng-multiselect-dropdown
                                        [settings]="cancerTypeDropdownSettings"
                                        [data]="cancerTypeList"
                                        name="cancerType"
                                        [(ngModel)]="selectedcancertype"
                                        (onSelect)="cancerTypeSelect($event)"
                                  (onDeSelect)="cancerTypeDeSelect($event)">
                                </ng-multiselect-dropdown>
                              </label>
                            </p>
                                <p *ngIf=" this.organisationName!=null &&  this.organisationName!=''" >
                              <label class="col-8">Cancer Type : {{selectedcancertype[0].name}}
                                  </label></p>

                          </div>
                          <div class=" col-6">
                           <p *ngIf="is4basecare && only4bc && (report.reportType.name =='First 72 Solid' ||  report.reportType.name =='First 72 Liquid')">
                              <label class="col-8">Result :<br />
                                <input type="checkbox" [checked]="option1" (click)="onChange($event,option1Value)">
                                <span style="padding-left:inherit!important">Clinically significant alterations detected</span>
                                <br/>
                                <input type="checkbox" [checked]="option2" (click)="onChange($event,option2Value)" >
                                <span style="padding-left:inherit!important">No clinically significant alterations detected</span>
                              </label>
                            </p>
                          </div>
                          <!-- <div class=" col-6" *ngIf="report.reportType?.name == 'First'">
                                                        <p>
                                                            <label class="col-8">Cancer Site :
                                                                <ng-multiselect-dropdown
                                                                [settings]="cancerSiteDropdownSettings"
                                                                [data]="cancerSiteList"
                                                                name="cancerSite"
                                                                [(ngModel)]="selectedCancerSite"
                                                                (onSelect)="cancerSiteSelect($event)"
                                                                (onDeSelect)="cancerSiteDeSelect($event)"
                                                                >
                                                            </ng-multiselect-dropdown>
                                                            </label>
                                                        </p>

                                                    </div> -->
                        </div>

                        <!-- DNA / RNA Details -->
                        <div *ngIf="appUser.email == 'harshi@4basecare.com'" class="levels_section row mt-2 py-1 rounded mx-0" style="background: #f1f6f6;">
                            <div class=" col-6">
                                <p>
                                    <label class="col">DNA Exome Available :
                                        <select class="form-control" name="dnaAvailable" (change)="dnaAvailable($event.target)"
                                        [(ngModel)]="dnaAvailableValue">
                                            <option selected disabled>select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </label>
                                </p>
                                <p *ngIf="dnaAndRnaDetailsReq.dnaAvailable">
                                    <label class="col">DNA Sequencing Data :
                                        <input class="form-control" type="number" name="dnaSequencingData" [(ngModel)]="dnaAndRnaDetailsReq.dnaSequencingData">
                                    </label>
                                </p>
                            </div>
                            <div class=" col-6">
                                <p>
                                    <label class="col">RNA Exome Available :
                                        <select class="form-control" name="rnaAvailable" (change)="rnaAvailable($event.target)"
                                        [(ngModel)]="rnaAvailableValue">
                                            <option selected disabled>select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </label>
                                </p>
                                <p *ngIf="dnaAndRnaDetailsReq.rnaAvailable">
                                    <label class="col">RNA Sequencing Data :
                                        <input class="form-control" type="number" name="rnaSequencingData" [(ngModel)]="dnaAndRnaDetailsReq.rnaSequencingData">
                                    </label>
                                </p>
                            </div>

                            <button type="button" [disabled]="isSubmitedDnaRna" class="btn save_btn"
                            style="float:right" (click)="addingRnaAndDna()">
                            <span *ngIf="!isSubmitedDnaRna"> Save </span>
                            <div *ngIf="isSubmitedDnaRna" class="spinner-border text-dark">
                                <span class="sr-only"></span>
                            </div>
                        </button>

                        </div>
                    </div>
                </div>

                <form class="general-form  mt-4">
                    <div class="form-group row" *ngIf="report.reportType.name != 'HRR Somatic'">
                        <div class="col">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Clinical Summary:</label>
                            <div style="text-align: right; padding-bottom: 5px;" *ngIf="clinicalSummary!=null ||clinicalSummary!=undefined" >
                            <P *ngIf="clinicalSummary.length"><strong>[{{clinicalSummary.length}}/3000]</strong></P>
                            </div>
                        </div>
                            <input  *ngIf="(this.organisationName==null ||  this.organisationName=='')" type="text" class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary">
                            <input  *ngIf="(this.organisationName!=null &&  this.organisationName!='')" type="text" class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary" [disabled]="true">

                      </div>
                  </div>
                    <div class="form-group row" *ngIf="report.reportType.name == 'HRR Somatic'">
                        <div class="col">
                            <div class="d-flex justify-content-between align-items-center">
                            <label>Clinical Indication:</label>
                            <div style="text-align: right; padding-bottom: 5px;" *ngIf="clinicalSummary!=null ||clinicalSummary!=undefined" >
                                <P *ngIf="clinicalSummary.length"><strong>[{{editorText.length}}/3000]</strong></P>
                            </div>
                            </div>
                            <ckeditor  [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList'] }"
                            name="clinicalSummary" [(ngModel)]="clinicalSummary" (keyup)="textcount('clinicalSummary-editor')"></ckeditor>
                            <div class="clinicalSummary-editor" hidden="true" [innerHtml]="clinicalSummary"  ></div>

                            <!-- <input *ngIf="isGermlinePlus" type="text" class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary"> -->
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="isTargetFirst ">
                      <div class="col">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Notes:</label>
                        </div>
                        <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                      name="disclaimerNote" [(ngModel)]="disclaimerNote" ></ckeditor>
                      </div>
                    </div>
                    <p *ngIf="isTargetFirst " style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
                    <!-- <div class="form-group row" *ngIf="isTargetFirst && only4bc ">
                      <div class="col">
                        <div class="d-flex justify-content-between align-items-center">
                          <label>Key Information:</label>
                        </div>
                        <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                          name="keyInformation" [(ngModel)]="keyInformation"></ckeditor>
                      </div>
                    </div> -->
                    <!-- </div> -->

                    <div *ngIf="report.clinicalSummary != null || report.addNotes!=null && (this.organisationName==null ||  this.organisationName=='')">
                        <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn"
                            style="float:right" (click)="addClinicalSummary()">
                            <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
                            <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                                <span class="sr-only"></span>
                            </div>
                        </button>
                    </div>
                    <div *ngIf="report.clinicalSummary == null && report.addNotes == null && (this.organisationName==null ||  this.organisationName=='')">
                        <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn"
                            style="float:right" (click)="addClinicalSummary()">
                            <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
                            <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                                <span class="sr-only"></span>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
            <!-- hotspot -->
            <div id="hotspot" *ngIf="tab=='hotspot'" class="container-fluid tab-pane fade"><br>
              <div id="tab2">
                  <app-hotspot-excel></app-hotspot-excel>
              </div>
          </div>
            <!-- snv -->
            <div id="snv" *ngIf="tab=='snv'" class="container-fluid tab-pane fade"><br>
                <div id="tab13">

                    <app-read-excel></app-read-excel>
                    <!-- <app-view-report-variant></app-view-report-variant> -->

                </div>
            </div>

            <!-- CNA -->
            <div id="cna" *ngIf="tab=='cna'"  class="container-fluid tab-pane fade"><br>
                <div id="tab3" >
                    <app-cna-excel></app-cna-excel>
                </div>
            </div>

            <!-- Fusion -->
            <div id="fusion" *ngIf="tab=='fusion'" class="container-fluid tab-pane fade"><br>
                <div id="tab4">
                    <app-fusion-excel></app-fusion-excel>
                </div>
            </div>
            <!-- Additional Notes -->
            <div id="notes" class="container-fluid tab-pane fade"><br>
                <div id="tab11">
                    <form class="general-form  mt-3">
                        <div class="form-group row">
                            <div class="col">
                            <div class="d-flex justify-content-between align-items-center">
                                <label>Additional Notes:</label>
                                <div *ngIf="addNote!=null ||addNote!=undefined" >
                                <P *ngIf="addNote.length"><strong>[{{editorText.length}}/3000]</strong></P>
                                </div>
                            </div>

                            <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList'] }" name="addNote"
                            [(ngModel)]="addNote" (keyup)="textcount('addNote-editor')"></ckeditor>
                            <div class="addNote-editor" hidden="true" [innerHtml]="addNote"  ></div>
                        </div>
                        </div>
                        <!-- </div> -->

                        <div *ngIf="report.addNotes!=null">
                            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn"
                                style="float:right" (click)="addNotesGermline()">
                                <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
                                <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="report.addNotes == null">
                            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn"
                                style="float:right" (click)="addNotesGermline()">
                                <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
                                <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- coverage -->
            <div id="coverage" class="container-fluid tab-pane fade"><br>
                <div id="tab12" *ngIf="tab=='coverage'">
                    <app-coverage-excel></app-coverage-excel>
                </div>
            </div>

            <!-- Evidence Summary -->
            <div id="summary" class="container-fluid tab-pane fade"><br>
                <div id="tab5" class="">
                    <form class="general-form">
                        <div class="form-group row">
                            <div class="col">
                            <div style="text-align: right; padding-bottom: 5px;" *ngIf="evidenceSummary!=null ||evidenceSummary!=undefined" >
                                <P *ngIf="evidenceSummary.length"><strong>[{{editorText.length}}/3000]</strong></P>
                            </div>
                                <!-- <textarea type="text" class="form-control" cols="30" rows="10" name="evidenceSummary"
                                    [(ngModel)]="evidenceSummary"> </textarea> -->
                                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                                    name="evidenceSummary" [(ngModel)]="evidenceSummary" (keyup)="textcount('evidenceSummary-editors')"></ckeditor>
                                    <div class="evidenceSummary-editors" hidden="true" [innerHtml]="evidenceSummary" ></div>
                                <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> →
                                    {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> →
                                    {{sub}}<strong>]</strong></p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col">
                                <label>PMIDS:</label>
                                <input type="text" class="form-control" placeholder="separate each pmId by comma(,)"
                                    name="pmIds" [(ngModel)]="pmIds">
                            </div>
                        </div>

                        <div *ngIf="report.evidenceSummary != null">
                            <button type="button" [disabled]="isSubmitedSummary" class="btn save_btn" style="float:right"
                                (click)="submitSummary()">
                                <span *ngIf="!isSubmitedSummary"> Update </span>
                                <div *ngIf="isSubmitedSummary" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="report.evidenceSummary == null">
                            <button type="button" [disabled]="isSubmitedSummary" class="btn save_btn" style="float:right"
                                (click)="submitSummary()">
                                <span *ngIf="!isSubmitedSummary"> Save </span>
                                <div *ngIf="isSubmitedSummary" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Interpratation -->
            <div id="interpretation" class="container-fluid tab-pane fade"><br>
                <div id="tab6" class="">
                    <form class="general-form">

                        <div class="form-group row mt-2 mb-5">
                            <div class="col-3  ">
                                <div class="bg-white">
                                <ng-multiselect-dropdown  placeholder="Select Tag"
                                    [settings]="interpretationDropdownSettings" [data]="interpretationListRes"
                                    (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)">
                                </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-check cust_form-ckeck" *ngIf="report.reportType.name == 'HRR Somatic'" style="text-align:right;padding-top:10px">
                              <label>Is Brca 1/2 positive ?</label> &nbsp;<span>
                              <input  type="checkbox" (click)="seletedRow($event)"
                              [checked]="brca1By2"></span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col">
                            <div style="text-align: right; padding-bottom: 5px;" *ngIf="interpretation!=null ||interpretation!=undefined" >
                                <P *ngIf="interpretation.length"><strong>[{{editorText.length}}/11000]</strong></P>
                            </div>
                                <!-- <textarea type="text" class="form-control" cols="30" rows="10" name="interpretation"
                                    [(ngModel)]="interpretation"> </textarea> -->
                                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                                    name="interpretation" [(ngModel)]="interpretation" (keyup)="textcount('interpretation-editors')"></ckeditor>
                                    <div class="interpretation-editors" hidden="true" [innerHtml]="interpretation" ></div>
                                    <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>

                                </div>
                        </div>
                        <div class="form-group row">
                            <div class="col">
                                <label>PMIDS:</label>
                                <input type="text" class="form-control" placeholder="separate each pmId by comma(,)"
                                    name="pmIds" [(ngModel)]="pmIds">
                            </div>
                        </div>
                        <div class="col px-0" style="padding-bottom: 5px!important;" *ngIf="report.reportType.name != 'HRR Somatic' && report.reportType.name != 'Indiegene'">
                        <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList' ] }"
                            name="referenceWebsite" [(ngModel)]="referenceWebsite"></ckeditor>
                    </div>

                        <div *ngIf="report.interpretation != null">
                            <button type="button" [disabled]="isSubmitedInterpretation" class="btn save_btn"
                                style="float:right" (click)="addInterpretation()">
                                <span *ngIf="!isSubmitedInterpretation"> Update </span>
                                <div *ngIf="isSubmitedInterpretation" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="report.interpretation == null">
                            <button type="button" [disabled]="isSubmitedInterpretation" class="btn save_btn"
                                style="float:right" (click)="addInterpretation()">
                                <span *ngIf="!isSubmitedInterpretation"> Save </span>
                                <div *ngIf="isSubmitedInterpretation" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Immuno data -->
            <div id="Immunodata" class="container-fluid tab-pane fade "><br>
                <div id="tab7" class="w-50 m-auto ">
                    <div class="p-4 my-5 bg-white cust_shadow rounded w-100 d-inline-block">
                        <h5 class="py-2 px-4 w-100  rounded mb-3 d-block" style="background: #ddd;">Data</h5>
                        <form class="w-75">
                            <div class="form-group row"
                                *ngIf="report.reportType.name == 'Absolute' || report.reportType.name == 'Indiegene'">
                                <label for="" class="col-sm-4 col-form-label text-right">PD-L1 :</label>
                                <div class="col-sm-8">
                                    <select class="form-control" name="pdl1Status" [(ngModel)]="immunoDetails.pdl1Status"
                                        (change)="pdl1Trails()">
                                        <option value="EXPRESSION">EXPRESSION</option>
                                        <option value="NO_EXPRESSION">NO_EXPRESSION</option>
                                        <option value="NOT_PERFORMED">NOT_PERFORMED</option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="immunoDetails.pdl1Status != 'NOT_PERFORMED' && immunoDetails.pdl1Status != null"
                                class="form-group row">
                                <label class="col-sm-4 control-label text-right">Upload PD-L1 Images:</label>
                                <div class="col-sm-8">
                                <!-- <p style="font-size: 9px!important; "><b>To select the 3 images hold ctrl + click on the image that needs to be selected..!</b></p> -->
                                    <input type="file" class="form-control"  (change)="uploadImage($event)">
                                    <input type="file" class="form-control"  (change)="uploadImage($event)">
                                    <input type="file" class="form-control"  (change)="uploadImage($event)">*
                                    <!-- <input type="file" class="form-control" multiple (change)="uploadImage($event)">* -->

                                    confirm select 3 images
                                    <br>
                                    <div *ngIf="immunoDetails.pdl1Images != null">
                                    <img *ngFor='let url of imageUrls'  [src]="url" height="75" width="100px" style="margin: 3px;"> <br/>
                                        <!-- <img style="height: 50px; width: 50px;padding: 5px; font-size: 10px!important;"
                                            [src]=immunoDetails.pdl1Images[0] alt="_img-1">
                                        <img style="height: 50px; width: 50px;padding: 5px; font-size: 10px!important;"
                                            [src]=immunoDetails.pdl1Images[1] alt="_img-2">
                                        <img style="height: 50px; width: 50px;padding: 5px; font-size: 10px!important;"
                                            [src]=immunoDetails.pdl1Images[2] alt="_img-3"> -->
                                    </div>

                                </div>
                            </div>
                            <div class="form-group row" *ngIf="report.reportType.name != 'Core'">
                                <label for="" class="col-sm-4 col-form-label text-right">TMB :</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" name="tmb" [(ngModel)]="immunoDetails.tmb">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label text-right">MSI :</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" name="msi" [(ngModel)]="immunoDetails.msi">
                                </div>
                            </div>
                            <div>
                            <button type="button" [disabled]="isSubmitedImmunoDetails" class="btn save_btn"
                            style="float:right" (click)="addImmunoDetails()">
                                    <span *ngIf="!isSubmitedImmunoDetails"> Save </span>
                                    <div *ngIf="isSubmitedImmunoDetails" class="spinner-border text-dark">
                                        <span class="sr-only"></span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- PD-L1 Trails -->
            <div *ngIf="immunoDetails.pdl1Status == 'EXPRESSION'" id="pdl1trails" class="container-fluid tab-pane fade ">
                <br>
                <div id="tab8" class="w-50 m-auto ">
                    <table class="table">
                        <thead class="thead-light bg-white">
                            <tr>
                                <th></th>
                                <th>Cancer Type</th>
                                <th>Drugs</th>
                                <!-- <th>Action</th> -->
                            </tr>
                            <tr *ngFor="let clinicalTrial of clinicalTrialResList; let i = index">
                                <td>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                            (click)="addSelectedTrails($event,clinicalTrial.id)"
                                            [checked]="clinicalTrial.selected">
                                    </div>
                                </td>
                                <td>{{clinicalTrial.cancerType.name}}</td>
                                <td>
                                    <div *ngFor="let drug of clinicalTrial.drugs">
                                        {{drug.name}}
                                    </div>
                                </td>
                            </tr>

                        </thead>
                    </table>
                </div>
            </div>

            <!-- website -->
            <div id="website" class="container-fluid tab-pane fade"><br>
                <div id="tab9" class="">
                    <form class="general-form">
                        <div class="form-group row">
                            <div class="col">
                                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList' ] }"
                                    name="referenceWebsite" [(ngModel)]="referenceWebsite"></ckeditor>
                            </div>
                        </div>

                        <div>
                            <button type="button" [disabled]="isSubmitedReferenceWebsite" class="btn save_btn"
                                style="float:right" (click)="addReferenceWebsite()">
                                <span *ngIf="!isSubmitedReferenceWebsite"> Save </span>
                                <div *ngIf="isSubmitedReferenceWebsite" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            <!-- Disclaimer -->
            <div id="disclaimer" class="container-fluid tab-pane fade"><br>
                <div id="tab10" class="">
                    <form class="general-form">

                        <div class="form-group row mt-2 mb-5">
                            <div class="col-3  ">
                                <div class="bg-white">
                                <ng-multiselect-dropdown  placeholder="Select Disclaimer"
                                    [settings]="disclaimerDropdownSettings" [data]="disclaimerList"
                                    (onSelect)="onDisclaimerItemSelect($event)" (onDeSelect)="onDisclaimerItemDeSelect($event)">
                                </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="col-3  ">
                                <span *ngIf="isTargetFirst">(Disclaimer DB)</span>
                          </div>
                        </div>
                        <div class="form-group row" span *ngIf="isTargetFirst">
                          <div class="col">
                          <div style="text-align: right; padding-bottom: 5px;" *ngIf="addNote!=null ||addNote!=undefined" >
                              <P *ngIf="addNote.length"><strong>[{{editorText.length}}/11000]</strong></P>
                          </div>

                              <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                                  name="addNote" [(ngModel)]="addNote" ></ckeditor>
                                  <div class="addNote-editors" hidden="true" [innerHtml]="addNote" ></div>
                          </div>
                      </div>

                        <div class="form-group row"span *ngIf="!isTargetFirst">
                            <div class="col">
                            <div style="text-align: right; padding-bottom: 5px;" *ngIf="disclaimer!=null ||disclaimer!=undefined" >
                                <P *ngIf="disclaimer.length"><strong>[{{editorText.length}}/11000]</strong></P>
                            </div>

                                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                                    name="disclaimer" [(ngModel)]="disclaimer" (keyup)="textcount('disclaimer-editors')"></ckeditor>
                                    <div class="disclaimer-editors" hidden="true" [innerHtml]="disclaimer" ></div>
                            </div>
                        </div>
                        <div *ngIf="isTMH" class="form-group row">
                            <div class="col">
                            <div class="d-flex justify-content-between align-items-center">
                                <label>Disclaimer Notes:</label>
                            </div>
                            <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                            name="disclaimerNote" [(ngModel)]="disclaimerNote" ></ckeditor>
                            </div>
                        </div>
                        <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
                        <div class="form-group row" *ngIf="report.reportType.name == 'First' || report.reportType.name == 'First 72 Liquid' || report.reportType.name =='First 72 Solid'">
                          <div class="col">
                              <label>PMIDS:</label>
                              <input type="text" class="form-control" placeholder="separate each pmId by comma(,)"
                                  name="pmIds" [(ngModel)]="pmIds">
                          </div>
                      </div>


                        <div *ngIf="report.disclaimer != null && !isTargetFirst">
                            <button type="button" [disabled]="isSubmitedDisclaimer" class="btn save_btn"
                                style="float:right" (click)="addDisclaimer()">
                                <span *ngIf="!isSubmitedDisclaimer"> Update </span>
                                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="report.disclaimer == null && !isTargetFirst">
                            <button type="button"  class="btn save_btn"
                                style="float:right" (click)="addDisclaimer()">
                                <span *ngIf="!isSubmitedDisclaimer"> Save </span>
                                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>


                        </div>
                        <div *ngIf="report.disclaimer != null && isTargetFirst">
                          <button type="button" [disabled]="isSubmitedDisclaimer" class="btn save_btn"
                              style="float:right" (click)="AddPMIDSAndAdditionalNotes()">
                              <span *ngIf="!isSubmitedDisclaimer"> Update </span>
                              <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                                  <span class="sr-only"></span>
                              </div>
                          </button>
                      </div>
                      <div *ngIf="report.disclaimer == null && isTargetFirst">
                          <button type="button"  class="btn save_btn"
                              style="float:right" (click)="AddPMIDSAndAdditionalNotes()">
                              <span *ngIf="!isSubmitedDisclaimer"> Save </span>
                              <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                                  <span class="sr-only"></span>
                              </div>
                          </button>
                          </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- if report is germline type -->
<div *ngIf="report.reportType != null && isGermlineReport" class="container-fluid patient-info_tabs position-relative reports_tabs">
    <div *ngIf="reportTypeChange" class="d-flex justify-content-center mt-5">
        <span class="visually-hidden"></span>
        <div
          class="spinner-border"
          style="width: 8rem; height: 8rem"
          role="status"
        ></div>
      </div>
      <div  *ngIf="!reportTypeChange">
        <header class=" pl-3  mb-3">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#patient-info" (click)="textcount('clinicalSummary-editor')">Patient Info</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#snv">SNV / InDels</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#cna" (click)="assign('cna')">CNA</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#notes" (click)="textcount('addNote-editor')">Additional Notes </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" *ngIf="!isGermlinePlus && report.reportType.name !='Family Testing'" (click)="assign('coverage')" data-toggle="tab" href="#coverage">Coverage</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" *ngIf="report.reportType.name !='Family Testing'" data-toggle="tab" href="#family">Family Hiistory</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#website">Websites</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"  data-toggle="tab" href="#interpretation" (click)="textcount('interpretation-editor')">Interpretation</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"  data-toggle="tab" href="#disclaimer" (click)="textcount('editor1')">Disclaimer</a>
                </li>
            </ul>
        </header>


        <div class="tab-content">

            <!-- Patient Info -->
            <div id="patient-info" class="container-fluid tab-pane active"><br>
                <div id="tab1" class="">
                    <div class="cust_shadow bg-white p-4">
                        <div class="levels_section row mt-2 py-1 rounded mx-0" style="background: #f1f6f6;">
                            <div class=" col-6">
                                <p>
                                    <label class="col-8">Select Sample Type :
                                        <ng-select [items]="patientSamples" bindLabel="name" bindValue="name"
                                            [multiple]="true" placeholder="Select samples" (change)="addSampleID()"
                                            [(ngModel)]="selected"></ng-select>
                                    </label>
                                </p>
                                <p><label class="col">Ecrf Id : {{report?.patient?.ecrfId}}</label></p>
                                <p><label class="col">Name : {{report?.patient?.name}}</label></p>
                                <p><label class="col">Gender : {{report.patient.gender}}</label></p>
                                <p><label class="col">Collected On : {{report.labDetails.sampleCollected}}</label></p>
                                <p><label class="col">FFPE ID : {{report.labDetails.sampleBlockId}}</label></p>
                            </div>
                            <div class="col">
                                <p>
                                    <label class="col-8 ">Change Report Type :
                                      <ng-select [items]="reportTypeList" bindLabel="name" bindValue="name"
                                            [multiple]="false" placeholder="Select Report Type" (change)="changeReportType($event)"
                                            [(ngModel)]="selected"></ng-select>

                                        <!-- <select class="form-control" [(ngModel)]="newreportType"  style="width: 60%" (change)="changeReportType($event)">
                                            <option selected disabled>Select</option>
                                            <option value="Absolute">Absolute</option>
                                            <option value="Core">Core</option>
                                            <option value="Indiegene">Indiegene</option>
                                            <option value="Focus">Focus</option>
                                            <option value="First">First</option>
                                            <option value="Germline+">Germline+</option>
                                            <option value="Germline+ V2">Germline+ V2</option>
                                            <option value="HRR Germline">HRR Germline</option>
                                            <option value="Family Testing">Family Testing</option>
                                            <option value="HRR Somatic">HRR Somatic</option>
                                            <option value="First 72 Solid">First 72 Solid</option>
                                            <option value="First 72 Liquid">First 72 Liquid</option>
                                            <option value="Lung Liquid Basic">Lung Liquid Basic</option>
                                            <option value="Lung Liquid Advanced">Lung Liquid Advanced</option>
                                        </select> -->
                                    </label>
                                </p>
                                <p><label class="col">Age/DOB : <span *ngIf="report.patient.dateOfBirth != null">{{report.patient.dateOfBirth | date:'dd/MM/yyyy'}} </span>  <span *ngIf="report.patient.age != null"> {{report.patient.age}} Years </span></label>
                                </p>
                                <p><label class="col">Report Type : {{report.reportType.name}}</label></p>
                                <p><label class="col">Cancer Site : {{report.cancerType}}</label></p>
                                <p><label class="col">Sample Id : {{report.labDetails.sampleId}}</label></p>
                                <p><label class="col">Referring Clinician :
                                        {{referringClinician}}</label></p>
                                <p><label class="col">Hospital : {{report.labDetails.hospital}}</label></p>
                            </div>


                        </div>

                        <!-- DNA / RNA Details -->
                        <div *ngIf="appUser.email == 'harshi@4basecare.com'" class="levels_section row mt-2 py-1 rounded mx-0" style="background: #f1f6f6;">
                            <div class=" col-6">
                                <p>
                                    <label class="col">DNA Exome Available :
                                        <select class="form-control" name="dnaAvailable" (change)="dnaAvailable($event.target)"
                                        [(ngModel)]="dnaAvailableValue">
                                            <option selected disabled>select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </label>
                                </p>
                                <p *ngIf="dnaAndRnaDetailsReq.dnaAvailable">
                                    <label class="col">DNA Sequencing Data :
                                        <input class="form-control" type="number" name="dnaSequencingData" [(ngModel)]="dnaAndRnaDetailsReq.dnaSequencingData">
                                    </label>
                                </p>
                            </div>
                            <div class=" col-6">
                                <p>
                                    <label class="col">RNA Exome Available :
                                        <select class="form-control" name="rnaAvailable" (change)="rnaAvailable($event.target)"
                                        [(ngModel)]="rnaAvailableValue">
                                            <option selected disabled>select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </label>
                                </p>
                                <p *ngIf="dnaAndRnaDetailsReq.rnaAvailable">
                                    <label class="col">RNA Sequencing Data :
                                        <input class="form-control" type="number" name="rnaSequencingData" [(ngModel)]="dnaAndRnaDetailsReq.rnaSequencingData">
                                    </label>
                                </p>
                            </div>

                            <button type="button" [disabled]="isSubmitedDnaRna" class="btn save_btn"
                            style="float:right" (click)="addingRnaAndDna()">
                            <span *ngIf="!isSubmitedDnaRna"> Save </span>
                            <div *ngIf="isSubmitedDnaRna" class="spinner-border text-dark">
                                <span class="sr-only"></span>
                            </div>
                        </button>

                        </div>
                    </div>
                </div>

                <form class="general-form  mt-3">
                    <div class="form-group row">
                        <div class="col">
                          <div class="d-flex justify-content-between align-items-center">
                            <label>Clinical Indication:</label>
                            <div style="text-align: right; padding-bottom: 5px;" *ngIf="clinicalSummary!=null ||clinicalSummary!=undefined" >
                              <P *ngIf="clinicalSummary.length"><strong>[{{editorText.length}}/3000]</strong></P>
                            </div>
                          </div>
                          <ckeditor  [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList'] }"
                          name="clinicalSummary" [(ngModel)]="clinicalSummary" (keyup)="textcount('clinicalSummary-editor')"></ckeditor>
                          <div class="clinicalSummary-editor" hidden="true" [innerHtml]="clinicalSummary"  ></div>

                            <!-- <input *ngIf="isGermlinePlus" type="text" class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary"> -->
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="report.reportType.name !='BRCA 1 and 2' && report.reportType.name !='Family Testing'">
                        <div class="col">
                            <label>GENES OF INTEREST:</label>
                            <ckeditor  [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                            name="interestGenes" [(ngModel)]="interestGenes"></ckeditor>
                            <!-- <input type="text" class="form-control" name="interestGenes" [(ngModel)]="interestGenes"> -->
                        </div>
                    </div>

                    <div *ngIf="report.clinicalSummary != null">
                        <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn"
                            style="float:right" (click)="addClinicalSummary()">
                            <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
                            <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                                <span class="sr-only"></span>
                            </div>
                        </button>
                    </div>
                    <div *ngIf="report.clinicalSummary == null">
                        <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn"
                            style="float:right" (click)="addClinicalSummary()">
                            <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
                            <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                                <span class="sr-only"></span>
                            </div>
                        </button>
                    </div>
                </form>
            </div>

            <!-- snv -->
            <div id="snv" class="container-fluid tab-pane fade"><br>
                <div id="tab2">

                    <app-read-excel></app-read-excel>
                    <!-- <app-view-report-variant></app-view-report-variant> -->

                </div>
            </div>

            <!-- CNA -->
            <div id="cna" class="container-fluid tab-pane fade"><br>
                <div id="tab3" *ngIf="tab=='cna'">
                    <app-cna-excel ></app-cna-excel>
                </div>
            </div>

            <!-- Additional Notes -->
            <div id="notes" class="container-fluid tab-pane fade"><br>
                <div id="tab4">
                    <form class="general-form  mt-3">
                        <div class="form-group row">
                            <div class="col">
                               <div class="d-flex justify-content-between align-items-center">
                                <label>Additional Notes:</label>
                                <div *ngIf="addNote!=null ||addNote!=undefined" >
                                  <P *ngIf="addNote.length"><strong>[{{editorText.length}}/3000]</strong></P>
                                </div>
                               </div>

                            <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList'] }" name="addNote"
                            [(ngModel)]="addNote" (keyup)="textcount('addNote-editor')"></ckeditor>
                            <div class="addNote-editor" hidden="true" [innerHtml]="addNote"  ></div>
                          </div>
                          </div>
                        <!-- </div> -->

                        <div *ngIf="report.addNotes!=null">
                            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn"
                                style="float:right" (click)="addNotesGermline()">
                                <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
                                <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="report.addNotes == null">
                            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn"
                                style="float:right" (click)="addNotesGermline()">
                                <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
                                <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

             <!-- coverage -->
             <div id="coverage" class="container-fluid tab-pane fade"><br>
                <div id="tab5" *ngIf="tab=='coverage'">
                    <app-coverage-excel></app-coverage-excel>
                </div>
            </div>

            <!-- Family history -->
            <div id="family" class="container-fluid tab-pane fade"><br>
                <div id="tab6" class="mx-5">
                    <div class="form-group row add_interpretation">
                        <label class="col-sm-2 control-label">
                           <a (click)="addFamily()" class="font_14 text_color">Add Family History <i class="zmdi zmdi-plus ml-2"></i></a> :</label>

                          <div class="col-sm-8 ">
                            <div *ngFor="let add_family of addFamilyHistory.addFamilyHistorys; let i = index " class="w-75 bg-white">
                          <div class="form-group row p-4 mx-0 pr-0" style="border: 1px solid #d5dadf ;padding:5px;border-radius: 5px;">
                            <label for="" class="col-sm-3 col-form-label">Family Type :</label>
                            <div class="col-sm-3 position-relative mb-1 pr-0">
                              <select type="text" class="form-control" name="familyType_{{ i }}" [(ngModel)]="add_family.familyType">
                                <option  value="Proband">Proband</option>
                                <option  value="Immediate Relatives">Immediate Relatives</option>
                                <option  value="Son/Daughter">Son/Daughter</option>
                                <option  value="Paternal Relatives">Paternal Relatives</option>
                                <option  value="Maternal Relatives">Maternal Relatives</option>
                              </select>
                            </div>
                            <label class="col-sm-3 col-form-label mb-1">Relationship :</label>
                            <div class="col-sm-3 position-relative pl-0">
                              <input type="text" name="relationship_{{ i }}" class="form-control" [(ngModel)]="add_family.relationship" />
                            </div>
                            <div class="w-100"></div>
                            <label for="" class="col-sm-3 col-form-label">CancerType :</label>
                            <div class="col-sm position-relative" style="margin-bottom:4px;">
                              <input type="text" name="cancerType_{{ i }}" class="form-control" [(ngModel)]="add_family.cancerType" />
                            </div>
                            <div class="w-100"></div>
                            <label for="" class="col-sm-3 col-form-label">Diagnosis Age :</label>
                            <div class="col-sm position-relative">
                              <input type="text" class="form-control" name="diagnosisAge_{{ i }}"
                              [(ngModel)]="add_family.diagnosisAge">
                            </div>
                            <!-- btn close -->
                            <div class="btn_close" (click)="removeFamily(i,add_family)">
                              <a ><i class="zmdi zmdi-close"></i></a>
                            </div>
                          </div>


                          </div>
                          <div class="text-right w-75">
                            <button *ngIf="addFamilyHistory.addFamilyHistorys.length > 0" class="btn save_btn"
                              (click)="addFamilyHistoryDetails()" [disabled]="familySaveButton">save</button>
                          </div>

                          </div>
                      </div>
                </div>
            </div>

            <!-- website -->
            <div id="website" class="container-fluid tab-pane fade"><br>
                <div id="tab7" class="">
                    <form class="general-form">
                        <div class="form-group row">
                            <div class="col">
                                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList' ] }"
                                    name="referenceWebsite" [(ngModel)]="referenceWebsite"></ckeditor>
                            </div>
                        </div>

                        <div>
                            <button type="button" [disabled]="isSubmitedReferenceWebsite" class="btn save_btn"
                                style="float:right" (click)="addReferenceWebsite()">
                                <span *ngIf="!isSubmitedReferenceWebsite"> Save </span>
                                <div *ngIf="isSubmitedReferenceWebsite" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

             <!-- Interpratation -->
             <div id="interpretation" class="container-fluid tab-pane fade"><br>
                <div id="tab8" class="">
                    <form class="general-form">

                        <div class="form-group row mt-2 mb-5">
                            <div class="col-3  ">
                                <div class="bg-white">
                                  <ng-multiselect-dropdown  placeholder="Select Tag"
                                    [settings]="interpretationDropdownSettings" [data]="interpretationListRes"
                                    (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)">
                                </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="report.reportType.name=='BRCA 1 and 2'">
                          <label>Is Brca 1/2 Interpretation empty ?</label> &nbsp;<span>
                            <input  type="checkbox" (click)="brcaInterpretationSelection($event)"
                            [checked]="bracaInterpretation"></span>
                        </div>

                        <div class="form-group row">
                            <div class="col">
                              <div style="text-align: right; padding-bottom: 5px;" *ngIf="interpretation!=null ||interpretation!=undefined" >
                                <P *ngIf="interpretation.length"><strong>[{{editorText.length}}/11000]</strong></P>
                              </div>
                                <!-- <textarea type="text" class="form-control" cols="30" rows="10" name="interpretation"
                                    [(ngModel)]="interpretation"> </textarea> -->
                                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                                    name="interpretation" [(ngModel)]="interpretation" (keyup)="textcount('interpretation-editor')"></ckeditor>
                                    <div class="interpretation-editor" hidden="true" [innerHtml]="interpretation"  ></div>
                                    <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
                            </div>
                        </div>
                        <div class="form-group row">
                          <div class="col">
                              <label>PMIDS:</label>
                              <input type="text" class="form-control" placeholder="separate each pmId by comma(,)"
                                  name="pmIds" [(ngModel)]="pmIds">
                          </div>
                      </div>

                        <div *ngIf="report.interpretation != null">
                            <button type="button" [disabled]="isSubmitedInterpretation" class="btn save_btn"
                                style="float:right" (click)="addInterpretation()">
                                <span *ngIf="!isSubmitedInterpretation"> Update </span>
                                <div *ngIf="isSubmitedInterpretation" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="report.interpretation == null">
                            <button type="button" [disabled]="isSubmitedInterpretation" class="btn save_btn"
                                style="float:right" (click)="addInterpretation()">
                                <span *ngIf="!isSubmitedInterpretation"> Save </span>
                                <div *ngIf="isSubmitedInterpretation" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

             <!-- Disclaimer -->
             <div id="disclaimer" class="container-fluid tab-pane fade"><br>
                <div id="tab8" class="">
                    <form class="general-form">

                        <div class="form-group row mt-2 mb-5">
                            <div class="col-3  ">
                                <div class="bg-white">
                                  <ng-multiselect-dropdown  placeholder="Select Disclaimer"
                                    [settings]="disclaimerDropdownSettings" [data]="disclaimerList"
                                    (onSelect)="onDisclaimerItemSelect($event)" (onDeSelect)="onDisclaimerItemDeSelect($event)">
                                </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col">
                              <div style="text-align: right; padding-bottom: 5px;" *ngIf="disclaimer!=null ||disclaimer!=undefined" >
                                <P *ngIf="disclaimer.length"><strong>[{{(editorText.length)}}/11000]</strong></P>
                              </div>
                                <!-- <textarea type="text" class="form-control" cols="30" rows="10" name="interpretation"
                                    [(ngModel)]="interpretation"> </textarea> -->
                                <ckeditor [editor]="editor" class="" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic'  , 'bulletedList'] }"
                                    name="disclaimer" [(ngModel)]="disclaimer" (keyup)="textcount('editor1')"></ckeditor>
                                    <div class="editor1" hidden="true" [innerHtml]="disclaimer" ></div>
                                    <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
                            </div>
                        </div>
                        <div class="form-group row">
                          <div class="col">
                              <label>PMIDS:</label>
                              <input type="text" class="form-control" placeholder="separate each pmId by comma(,)"
                                  name="pmIds" [(ngModel)]="pmIds">
                          </div>
                      </div>

                        <div *ngIf="report.disclaimer != null">
                            <button type="button" [disabled]="isSubmitedDisclaimer" class="btn save_btn"
                                style="float:right" (click)="addDisclaimer()">
                                <span *ngIf="!isSubmitedDisclaimer"> Update </span>
                                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        <div *ngIf="report.disclaimer == null">
                            <button type="button"  class="btn save_btn"
                                style="float:right" (click)="addDisclaimer()">
                                <span *ngIf="!isSubmitedDisclaimer"> Save </span>
                                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>

</div>

