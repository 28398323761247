import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DomainFunction } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-domain-function',
  templateUrl: './domain-function.component.html',
  styleUrls: ['./domain-function.component.css']
})
export class DomainFunctionComponent implements OnInit {

  editor = ClassicEditor;

  domainListSpinner:boolean=true;
  add_Domain_Funcution = {} as DomainFunction;
  domainList: DomainFunction[] | undefined;
  statusMsg: string | undefined;
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }
  showError(msg:any) {
    this.toastr.error(msg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {
    this.add_Domain_Funcution.domainFunction=''
    this.getDomainFunctions();
  }

  addDomainFunction(){
    console.log(this.add_Domain_Funcution);
    this.dataAdminService.addDomainFunction(this.add_Domain_Funcution).subscribe(
      (response: any) => {
        console.log("response add",response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
        (error) =>{
          console.log(error);
            this.showError(error.error.message);
        });
  }

  deleteDomainFunction(){
    console.log(this.add_Domain_Funcution);
    this.dataAdminService.deleteDomainFunction(this.add_Domain_Funcution.id).subscribe(
      (response: any) => {
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }
  uploadFileInput=false;
  onFileChanges(event: any) {
    const files = event.target.files;
    const file = files[0];
    if(file){
      this.uploadFileInput = true;
    }
    // this.fileName = file.name;
    this.uploadFile(file);
  }
  uploadFile(file: any) {
    console.log("evt=>",file)
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.dataAdminService
      .uploadAndGetDomainFunctionDataFromExcel(formdata)
      .subscribe((response: any) => {
        console.log("response=>\n",response)
        console.log("list of not uploaded=>\n",response['payLoad'])
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
}

  assignDomainFunction(gene: DomainFunction){
    this.add_Domain_Funcution = gene;
    console.log("edit Domain fuction=>",this.add_Domain_Funcution);
  }

  getDomainFunctions(){
    this.dataAdminService.getDomainFunctionsList().subscribe(
      (response: any) => {
        this.domainListSpinner=false
        this.domainList = response['payLoad'];
        console.log("list Domain fuction=>",this.domainList);
      }
    );
  }

  closeModal(){
    console.log("close modal")
    this.add_Domain_Funcution = {} as  DomainFunction;
    this.add_Domain_Funcution.domainFunction=''
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/domain_funtions']);
    });
  }

}
