
<div class="container loader" *ngIf="filterId && !filterLoad">
  <div  class="row  data-spinner-parent">
    <div class="spinner-border data-spinner-child" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
  <!-- <div  class="d-flex justify-content-center" style="left:50%;position: relative;">
    <div class="spinner-border" role="status" style="position: absolute;">
      <span class="visually-hidden"></span>
    </div>
  </div> -->
</div>

<div *ngIf="((filterId)?(filterId && filterLoad):true) "class="container-fluid px-0">
    <h5 class="mb-0 d-inline-block position-relative  mb-3 mt-4">
        <span *ngIf="!filterId"  class="list-icon mr-2"> Add Filter Engine</span>
        <span *ngIf="filterId" class="list-icon mr-2"> Edit Filter Engine</span>
      </h5>
      <button class="btn btn-primary cancel_btn px-4 btn-save"  (click)="applyFilter()">Save </button>
      <form action="" class="mt-1">
      <div class="mx-0 p-4 cust_shadow rounded bg-white row">

        <label class="col-1 control-label text-right">Name :</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" name="name" [(ngModel)]="name">
                </div>
        </div>

    </form>
    <div
    class="px-4 py-3 bg-white cust_shadow mx-0 rounded filter-csv_table"

  >
    <div class="menu_tabs">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a href="#consequence" class="nav-link active" data-toggle="tab" (click)="openFilterTab('Consequence')"
            >Consequence</a
          >
        </li>
        <li class="nav-item">
          <a href="#population_frequency" class="nav-link" data-toggle="tab" (click)="openFilterTab('Population frequency')"
            >Population frequency</a
          >
        </li>
        <li class="nav-item">
          <a href="#in-Silico_prediction" class="nav-link" data-toggle="tab" (click)="openFilterTab('In-Silico Prediction')"
            >In-Silico Prediction</a
          >
        </li>
        <li class="nav-item">
          <a href="#clinsig_inhouse" class="nav-link" data-toggle="tab" (click)="openFilterTab('ClinSig_Inhouse')"
            >ClinSig_Inhouse</a
          >
        </li>
        <li class="nav-item">
          <a href="#gene_filter" class="nav-link" data-toggle="tab" (click)="openFilterTab('Gene filter')"
            >Gene</a
          >
        </li>
        <!-- <li class="nav-item">
          <a href="#clinical_interpretation" class="nav-link" data-toggle="tab" (click)="openFilterTab('')"
            >Clinical Interpretation</a
          >
        </li> -->
      </ul>

      <div class="tab-content py-3 px-1 mt-3">
        <div class="tab-pane fade show active" id="consequence">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>
            <div *ngFor="let filter of geneName.value ">
              <input
                type="checkbox"
                [id]="i"
                [name]="geneName.key"
                [value]="filter"
                [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                (change) ="updateSelectedFilter($event)"
              />
              <label for="vehicle1" class="pl-1"> {{filter}}</label>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="population_frequency">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>
              <div class="row col"  >

                <div class="col-4">
                  <select style="width: 200px;"
                  class="bg-white form-control  dropdown-toggle"
                  [(ngModel)]="geneName.filterType"
                >
                  <option selected value="">Select</option>
                  <option *ngFor="let type of filterType" [value]="type">{{type}}</option>
                </select>
                </div>
                <div class="col-2" *ngIf="geneName.filterType" >
                    <input type="text" class="form-control" [(ngModel)]="geneName.values">
                  </div>
              </div>
          </div>
        </div>

        <div class="tab-pane fade" id="clinsig_inhouse">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>
            <div *ngIf="!(geneName.key=='cadd13Phred') " >
              <div *ngFor="let filter of geneName.value ">
                <input
                  type="checkbox"
                  [id]="i"
                  [name]="geneName.key"
                  [value]="filter"
                  [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                  (change) ="updateSelectedFilter($event)"
                />
                <label for="vehicle1" class="pl-1"> {{filter}}</label>
              </div>
            </div>
            <div class="row col" *ngIf="(geneName.key=='cadd13Phred')" >
              <div class="col-4">
                <select style="width: 200px;"
                class="bg-white form-control  dropdown-toggle"
                [(ngModel)]="geneName.filterType"
              >
                <option selected value="">Select</option>
                <option *ngFor="let type of filterTypeCad" [value]="type">{{type}}</option>
              </select>
              </div>
              <div class="col-2" *ngIf="geneName.filterType">
                <input type="text" class="form-control" [(ngModel)]="geneName.values">
              </div>

            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="in-Silico_prediction">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>
            <div *ngIf="!(geneName.key=='cadd13Phred')" >
              <div *ngFor="let filter of geneName.value ">
                <input
                  type="checkbox"
                  [id]="i"
                  [name]="geneName.key"
                  [value]="filter"
                  [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                  (change) ="updateSelectedFilter($event)"
                />
                <label for="vehicle1" class="pl-1"> {{filter}}</label>
              </div>
            </div>
            <div class="row col" *ngIf="(geneName.key=='cadd13Phred')" >

              <div class="col-4">
                <select style="width: 200px;"
                class="bg-white form-control  dropdown-toggle"
                [(ngModel)]=" geneName.filterType"
              >
                <option selected value="">Select</option>
                <option *ngFor="let type of filterTypeCad" [value]="type">{{type}}</option>
              </select>
              </div>
              <div class="col-2" *ngIf=" geneName.filterType">
                <input type="text" class="form-control" [(ngModel)]="geneName.values">
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="gene_filter">
          <div class="tab_check mb-2" *ngFor="let geneName of secondFilterData;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.webKey}}</h6>

            <span *ngIf="geneName.webKey=='Test Name'">
              <select class="form-control" style="width: 200px;display: block;" name="testNameList"
                [(ngModel)]="testNameSelected" (change)="filterByTestType(testNameSelected)">
                <option *ngFor="let test of testNameList" [selected]="geneName.values == test" value={{test}}>{{test}}</option>
              </select>
            </span>

            <span *ngIf="geneName.webKey=='Cancer Site'">
              <select class="form-control" style="width: 200px;display: block;" name="testNameList" [(ngModel)]="cancerSiteName"
                (change)="filterByCancerType(cancerSiteName)">
                <option *ngFor="let test of cancerSiteNameList" [selected]="geneName.values == test" value={{test}}>{{test}}
                </option>
              </select>
            </span>

            <span *ngIf="geneName.webKey=='Gene'">
              <ng-multiselect-dropdown style="width: 200px;display: block;" [data]="geneNameList"
                 [(ngModel)]="geneName.values" name="gene" [settings]="geneDropdownSettings">
              </ng-multiselect-dropdown></span>

            <!-- <select style="width: 200px;"
                  class="bg-white form-control  dropdown-toggle"
                  [(ngModel)]="geneName.values"
                >
                  <option selected disabled>Select</option>
                  <option *ngFor="let genes of geneList" [value]="genes.name">{{genes.name}}</option>
                </select> -->
            <!-- <div *ngFor="let filter of geneName.value ">
              <input
                type="checkbox"
                [id]="i"
                [name]="geneName.key"
                [value]="filter"
                [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                (change) ="updateSelectedFilter($event)"
              />
              <label for="vehicle1" class="pl-1"> {{filter}}</label>
            </div> -->
          </div>
        </div>
        <div class="tab-pane fade" id="clinical_interpretation">
          <div class="tab_check mb-2" *ngFor="let geneName of filters;let i=index;">
            <h6 class="font-weight-bold">{{i+1}}.{{ geneName.key}}</h6>
            <div *ngFor="let filter of geneName.value ">
              <input
                type="checkbox"
                [id]="i"
                [name]="geneName.key"
                [value]="filter"
                [checked]="(isChecked(geneName.key,filter) ? 'checked' : '')"
                (change) ="updateSelectedFilter($event)"
              />
              <label for="vehicle1" class="pl-1"> {{filter}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


