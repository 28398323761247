import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClinicalTrialRes, Gene } from 'src/app/_interfaces.ts/admin';
import { DepthDetailsReqDto, FilteresCSV, HotspotVariant, ReportVariant } from 'src/app/_interfaces.ts/excel';
import { StudyFieldsResponse } from 'src/app/_interfaces.ts/trails';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { ReportService } from 'src/app/_services/report.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Title } from '@angular/platform-browser';
import { PrognasticBiomarkerRes } from 'src/app/_interfaces.ts/admin';
import { ReportRes } from 'src/app/_interfaces.ts/reports';
import { ReportType} from 'src/app/_interfaces.ts/reports';
import { HotspotService } from 'src/app/_services/hotspot.service';
import { AppUser } from 'src/app/_interfaces.ts/app_user';
@Component({
  selector: 'app-view-hotspot-variant',
  templateUrl: './view-hotspot-variant.component.html',
  styleUrls: ['./view-hotspot-variant.component.scss']
})
export class ViewHotspotVariantComponent implements OnInit {
  sup = 'A{b} → Superscript';
  sub = 'A~b! → Subscript';
  public editor:any = ClassicEditor;
    editorText: string = '';
  pmIds!: string;
  varient!: string;
  btnShow: boolean = false;
  showEdit: boolean = false;
  hotspotVariantId!: number;
  reportId!: number;
  hotspotVariant = {} as HotspotVariant;
  studyFieldsResponse = {} as StudyFieldsResponse;
  selectedPrognasticBiomarker: any;
  prognasticBiomarkerDropdownSettings: any;
  clinicalTrialResList: ClinicalTrialRes[] = [];
  hotspotList: ClinicalTrialRes[] = [];

  isSubmited!: boolean;
  statusMsg!: string;
  gene!: Gene;
  isGermline = false;
  prognasticTagList: PrognasticBiomarkerRes[] = [];
  add_depthDetailsReqDto= {} as DepthDetailsReqDto;
  constructor(
    private reportService: ReportService,
    private hotspotService:HotspotService,
    private routerAct: ActivatedRoute,
    private router: Router,
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private titleService: Title
  ) {}

  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }
  organisationName!: string;
  appUser = {} as AppUser;

  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
    console.log("appUser",this.appUser)

    this.organisationName = localStorage.getItem('organisationName') || '';
    let report = {} as ReportRes;
    let reportType = {} as ReportType;
    this.hotspotVariant.report = report;
    this.hotspotVariant.report.reportType = reportType;
    this.hotspotVariant.report.reportType.name = '';
    this.selectedPrognasticBiomarker = [];
    this.hotspotVariant.prognasticDescription = '';
    this.prognasticBiomarkerDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'prognasticTag',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.hotspotVariant.significance = 'Not Classified';
    this.hotspotVariantId = this.routerAct.snapshot.params['fid'];
    this.reportId = this.routerAct.snapshot.params['reportId'];
    this.selectPrognasticBiomarkers();
    console.log("hotspotVariantId->",this.hotspotVariantId)
    if (this.hotspotVariantId) {
      this.getReportedVariant();
    }
  }

  selectPrognasticBiomarkers() {
    this.dataAdminService.prognasticBiomarkerList().subscribe((res: any) => {
      this.prognasticTagList = res['payLoad'];
    });
  }

  getPrognasticTag(significance: string) {
    this.dataAdminService.getPrognasticBySignificance(significance).subscribe((res:any)=>{
      this.prognasticTagList=res['payLoad']
      this.hotspotVariant.prognasticDescription=''
      this.selectedPrognasticBiomarker=[]
    })
  }
  selectPrognasticBiomarker(prognasticTag: any) {
    this.prognasticTagList.forEach((element: any) => {
      if (this.selectedPrognasticBiomarker.length > 0) {
        if (element.id == this.selectedPrognasticBiomarker[0].id) {
          this.hotspotVariant.prognasticDescription = element.description;
          console.log('prognasticTagList ', element);
          let pmid: any;
          for (let i = 0; i < element?.geneVariants.length; i++) {
            if (pmid != null) {
              pmid += ',' + element?.geneVariants[i].referencePmIds;
            } else {
              pmid = element?.geneVariants[i].referencePmIds;
            }
          }
          console.log('pmid===>', pmid);
          if (this.hotspotVariant.variantPmIds != null) {
            this.hotspotVariant.variantPmIds += ',' + pmid;
          } else {
            this.hotspotVariant.variantPmIds = pmid;
          }

          console.log('pmids', this.hotspotVariant.variantPmIds);
        }
      }
    });
  }

  textcount(classname: any) {
    var dom = document.getElementsByClassName(classname);
    var data: any = dom[0];
    this.editorText = data?.outerText;
  }

  defaultdescription!: any;
  geneDescriptionMessage!: String;
  previousDescription!: any;
  getGeneDescription() {
    if (this.hotspotVariant.significance == 'Benign') {
      this.hotspotVariant.addGeneDescription = this.previousDescription;
    } else if (this.hotspotVariant.significance == 'Pathogenic') {
        this.geneDescriptionMessage =
          'The (p.) variant of the (gene name) gene has a total depth of X and an alternative depth of X. The In-silico prediction tools like SIFT, PolyPhen and MutationTaster have predicted this variant to be ----, ---- and -----, respectively. The variant is found at a frequency of ---- and --- in gnomAD and 1000 genome databases, respectively. The variant is present in exon X of (gene name) gene and ----- domain of ---- protein. (Functional evidence if any). Further the variant is reported as pathogenic/oncogenic in ClinVar/cBioportal/OncoKB. Based on the available evidence the variant has been classified as Pathogenic. ';
      this.hotspotVariant.addGeneDescription =
        this.previousDescription + `<br/>` + this.geneDescriptionMessage;
    } else if (this.hotspotVariant.significance == 'VUS') {

        this.geneDescriptionMessage =
          'The (p.) variant of the (gene name) gene has a total depth of X and an alternative depth of X. The In-silico prediction tools like SIFT, PolyPhen and MutationTaster have predicted this variant to be ----, ---- and ----- respectively. The variant is found at a frequency of ---- and --- in gnomAD and 1000 genome databases, respectively. The variant is present in exon X of (gene name) gene and ----- domain of ---- protein. Currently there are no/limited functional and clinical evidence available for this variant. Hence, this variant has been classified as Variant of Uncertain Significance (VUS) and must be clinically correlated with other findings for any further management. ';

      this.hotspotVariant.addGeneDescription =
        this.previousDescription + `<br/>` + this.geneDescriptionMessage;
    } else if (
      this.hotspotVariant.significance == 'NA' ||
      this.hotspotVariant.significance == 'Not Classified'
    ) {
      this.hotspotVariant.addGeneDescription = this.previousDescription;
    } else if (this.hotspotVariant.significance == 'Likely Pathogenic') {
      this.hotspotVariant.addGeneDescription = this.previousDescription;
    }
  }
  isFirst72 = false;
  nstudiesFound = 0;
  testList: Array<string> = [];
  allelicBurden:any;
  alternativeAlleleDepth:any;
  totalDept:any;
  ecrfPatientId:any;
  hotspotSpinner=true
  getReportedVariant() {
    console.log("getHotspotVariantById working")!!
    this.hotspotService.getHotspotVariantById(this.hotspotVariantId).subscribe(
      (response: any) => {
        this.hotspotVariant = response['payLoad'];
        this.hotspotSpinner=false
        if (this.organisationName == 'Lifecell') {
          this.ecrfPatientId = this.hotspotVariant?.report?.patient.orgLabId;
        } else if (this.organisationName == '4baseCare Onco Solutions') {
          this.ecrfPatientId = this.hotspotVariant?.report?.patient?.patientId;
        } else {
          this.ecrfPatientId = this.hotspotVariant?.report?.patient.organisationPatientId;
        }
        console.log("this.getHotspotVariantById =>",this.hotspotVariant )
        if(this.hotspotVariant.significance==null){
          this.hotspotVariant.significance='Not Classified'
        }
        this.showEdit=this.hotspotVariant.selectedForReport;
        this.testList.push("BRCA 1 and 2");
        this.testList.push("Family Testing");
        if(this.hotspotVariant.report.reportType.name.includes("Germline") || this.testList.includes(this.hotspotVariant.report.reportType.name)){
          this.isGermline = true;
        }
        if(this.hotspotVariant.report.reportType.name=="First 72 Solid" || this.hotspotVariant.report.reportType.name=="First 72 Liquid"){
          this.isFirst72 = true;
          console.log("Main work")
          this.getAllHotspotClinicalTrailByGene(this.hotspotVariant.geneName.split(';', 1).toString(), 3);
        }
        if(this.hotspotVariant.report.reportStatus == 'REPORTED'){
          this.statusMsg = "Report is already delivered no operations can be done";
          this.showSuccess();
          this.router.navigate(['/reports']);
          return;
        }
        if(this.hotspotVariant.significance=='NA'){
          this.hotspotVariant.significance='Not Classified'
        }
        if(this.hotspotVariant.aoPercentage!=null){
        this.allelicBurden=Math.round(this.hotspotVariant.aoPercentage);
        }else{
          this.allelicBurden=0;
        }
        if(this.hotspotVariant.ro!=null){
          this.alternativeAlleleDepth=Math.floor(this.hotspotVariant.ro);
        }else{
          this.alternativeAlleleDepth=0;
        }
        if(this.hotspotVariant.dp!=null){
          this.totalDept=Math.floor(this.hotspotVariant.dp);
        }else{
        this.totalDept=0;
        }
        this.titleService.setTitle(this.hotspotVariant.report.patient.ecrfId + " - Hotspot - " + this.hotspotVariant.geneName);
        this.pmIds = this.hotspotVariant.report.pmIds.toString();




        this.dataAdminService.getGeneByName(this.hotspotVariant.geneName).subscribe(
          (response: any) => {
            this.gene = response['payLoad'];
            console.log("gene details", this.gene);
            this.previousDescription = this.hotspotVariant?.addGeneDescription;
            // this is to remove the <p></p> in gene.description the is being displayed above the add gene description
            if(this.gene.description!=null){
            this.gene.description = this.gene.description.replace(/<p>/g, '');
            this.gene.description = this.gene.description.replace(/<\/p>/g, '');
          }
          }
        );

        if (this.hotspotVariant.significance == null) {
          this.hotspotVariant.significance = "Benign";
        }
        if (this.hotspotVariant?.prognasticSignificance == null){
          this.hotspotVariant.prognasticSignificance = "NA";
        }



          if (response['success']) {
            this.dataAdminService.getAllHotspotClinicalTrailByGene(this.hotspotVariant.geneName).subscribe(
              (response: any) => {
                this.clinicalTrialResList = response['payLoad'];
                console.log("hotspot trail db",this.clinicalTrialResList);
              }
            );

        }
        setTimeout(()=>{
          this.textcount('addGeneDescription-editor')
        },3000)
      }
    );
  }


  getAllHotspotClinicalTrailByGene(gene: string, type: number) {
    this.dataAdminService
      .getAllHotspotClinicalTrailByGene(gene)
      .subscribe((response: any) => {
        this.hotspotList = response['payLoad'];
        console.log(
          '111 getAllHotspotClinicalTrailByGene db',
          this.hotspotList
        );
      });
  }
  submitDetails() {
    this.isSubmited = true;
    console.log(
      'this.hotspotVariant.significance',
      this.hotspotVariant.significance
    );
    this.hotspotService
      .addHotspotVariantSignificance(
        this.hotspotVariantId,
        this.hotspotVariant
      )
      .subscribe((response: any) => {
        this.isSubmited = false;
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
      });
    this.isSubmited = true;
    if (this.pmIds.length > 0) {
      this.pmIds = this.pmIds;
      this.reportService
        .addPMIds(this.reportId, this.pmIds)
        .subscribe((response: any) => {
          console.log(response);
          this.isSubmited = false;
        });
    }
    this.add_depthDetailsReqDto.variantType="hotspot";
    this.add_depthDetailsReqDto.totalDept=this.totalDept;
    this.add_depthDetailsReqDto.alternativeAlleleDepth=this.alternativeAlleleDepth;
    this.add_depthDetailsReqDto.allelicBurden=this.allelicBurden;
    this.reportService
        .addVariantDepthDetails(this.hotspotVariantId, this.add_depthDetailsReqDto)
        .subscribe((response: any) => {
          console.log(response);
          this.isSubmited = false;
    });
  }

  edit(reportVarient: string) {
    this.btnShow = true;
    this.varient = reportVarient;
  }

  close() {
    this.varient = '0';
  }


}
