import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IgvService {

  constructor(
    private httpClient: HttpClient
  ) { }


  startIgv(reportId: number){
    return this.httpClient.get(`/report/start_igv/${reportId}`);
  }

  igvStatus(reportId: number){
    return this.httpClient.get(`/report/igv_status/${reportId}`);
  }

  igvStatusProd(reportId: number){
    return this.httpClient.get('/igv/' + reportId +'/status');
  }
}
