import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Patient } from 'src/app/_interfaces.ts/patient';
import { PatientService } from 'src/app/_services/patient.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {


  add_patient = {} as Patient;
  ecrfId!: number;
  statusMsg!: string;

  constructor(
    private patientService: PatientService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, 'Patient',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {

    this.ecrfId = this.routerAct.snapshot.params['id'];
    
    if(this.ecrfId){
      console.log("this.ecrfId",this.ecrfId);
      this.getPatient();
    }
  }

  editPatient(){
    console.log(this.add_patient);
    this.patientService.editPatient(this.add_patient).subscribe(
      (response: any) =>{
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.cancelEditPatient();
    });
  }

  cancelEditPatient(){
    this.router.navigate(['/reports']);
  }

  getPatient(){
    this.patientService.getPatient(this.ecrfId.toString()).subscribe(
      (response: any) => {
        console.log(response);
        this.add_patient = response['payLoad'];
        
      });
  }

}
