<div class="container-fluid px-0">

  <div class="row  py-3 mx-0">
    <div class="col pl-0">
      <h5 class="mb-0 d-inline-block position-relative pt-2"> Test Type Genes List</h5>
    </div>
    <div class="col pr-0">
      <div class="text-right ml-auto d-table">
        <div class="add-new">
          <a class="add_post mr-2" [routerLink]="[ '/super_admin/add-testtypegene' ]">Add Test Type Gene<i
              class="zmdi zmdi-plus ml-2"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="row col " style="padding: 5px!important; margin: 0;">
    <div class="reports_info d-flex ml-auto">
      <label class="mr-2" style="font-size: large;"> Type:</label>
      <select class="bg-white form-control  dropdown-toggle" (change)="filterByType($event)">
        <option selected disabled>Select</option>
        <option value="All">All</option>
        <option value="SOMATIC">Somatic</option>
        <option value="GERMLINE">Germline</option>

      </select>
    </div>
  </div>
  <div *ngIf="genePatternListSpinner" class="row data-spinner-parent">
    <div class="spinner-border data-spinner-child" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
  <div *ngIf="!genePatternListSpinner" class="bg-white cust_shadow  p-5 rounded">
    <div class="tableFixHead">
      <table class="care_companion table ">
        <thead class="thead-light">
          <tr>
            <th>Sl.No</th>
            <th>Sample Type</th>
            <th>Test Name </th>
            <!-- <th>Gene</th> -->
            <th>Actions</th>
          </tr>

        </thead>
        <tbody>
          <tr *ngFor="let genepattern of genePatternList; let i = index">
            <td>{{i+1}}</td>
            <td>{{getToString(genepattern.sampleType)}}</td>
            <td>{{getToString(genepattern.testNames)}}</td>
            <!-- <td><span *ngFor="let gene of genepattern.gene; let i = index"><p>{{gene.name}}</p></span></td> -->
            <td>
              <ul class="list-unstyled mb-0 d-flex actions">
                <li>
                  <a href="" (click)="getGenePatternById(genepattern.id)" data-toggle="modal"
                    data-target="#viewPatient"><i class="zmdi zmdi-eye mr-2"></i>View&nbsp;</a>
                </li>
                <li><a class="mr-1 delete_clr" [routerLink]="[ '/super_admin/add-testtypegene/', genepattern.id ]">|
                    Edit </a></li>
                <li><a class="delete_clr" data-toggle="modal" data-target="#deleteClinicalModal"
                    (click)="setGenePattern(genepattern)">| Delete</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <!-- The delete Modal -->
  <div class="modal fade" id="deleteClinicalModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content surgery-modal">

        <div class="modal-header px-4 border-bottom-0 bg-light">
          <h5 class="modal-title text-softblue">Delete</h5>
          <a class="text-softblue" data-dismiss="modal">
            <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
          </a>
        </div>

        <div class="modal-body p-4">
          <h6 class=" mb-4">Are you sure want to delete ?</h6>
          <div class="mx-0 ml-auto d-table">
            <button type="button" class="btn bg-softblue text-white px-5 mr-2" data-dismiss="modal"
              (click)="deleteGenePattern()">Yes</button>
            <button type="button" class="btn bg-softblue text-white px-5" data-dismiss="modal">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- view patient modal -->
<div class="modal fade" id="viewPatient" #closeModal>
  <div class="modal-dialog modal-dialog-centered" style="max-width: 1100px">
    <div class="modal-content surgery-modal">
      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Test type Gene Details</h5>
        <a class="text-softblue" data-dismiss="modal">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4" style="text-align: left">
        <div class="row">
          <div class="col">
            <div class="form-group row mb-0">
              <label class="col-sm-2 control-label text-right text-softblue"> Id :</label>
              <div class="col-sm-5">
                <label>{{ genepattern?.id }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-2 control-label text-right text-softblue">SampleType :</label>
              <div class="col-sm-5">
                <label>{{ genepattern?.sampleType }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-2 control-label text-right text-softblue">Test Name :</label>
              <div class="col-sm-5">
                <label>{{ genepattern?.testNames }}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-2 control-label text-right text-softblue">Other Name :</label>
              <div class="col-sm-5">
                <span [innerHtml]="genepattern?.otherName" style="line-height: 28px; text-align: justify"></span>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-2 control-label text-right text-softblue">Genes :</label>
              <div class="col-sm-5">
                <table>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 0 : 10">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 10 : 20">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 20 : 30">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 30 : 40">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 40 : 50">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 50 : 60">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 60 : 70">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 70 : 80">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 80 : 90">{{ gene?.name
                      }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 10px !important;" *ngFor="let gene of geneList | slice : 90 : 100">{{ gene?.name
                      }}</td>
                  </tr>
                </table>
              </div>
            </div>

          </div>
        </div>
        <!-- <button type="button" class="btn btn-primary text-uppercase bg-softblue" style="float:right"
        data-dismiss="modal"> Save </button> -->
      </div>
    </div>
  </div>
</div>
