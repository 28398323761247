import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoverageFile } from 'src/app/_interfaces.ts/excel';
import { ReportRes } from 'src/app/_interfaces.ts/reports';
import { CoverageService } from 'src/app/_services/coverage.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-coverage-excel',
  templateUrl: './coverage-excel.component.html',
  styleUrls: ['./coverage-excel.component.scss']
})
export class CoverageExcelComponent implements OnInit {

  reportId!: number;
  coverageVariants: CoverageFile[] = [];
  report = {} as ReportRes;
  selectedForReport!: boolean;
  coverageTable = false;
  uploadFileInput = false;
  coverageListSpinner = true;
  switchToggleButton = false;
  spinDiv=false;
  fileName!: string;
  isAllChecked=false;

  clinicalSiginificanceList = ["Pathogenic", "Likely Pathogenic", "VUS","Not Detected"];

  constructor(
    private coverageService: CoverageService,
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }


  onFileChange(event: any) {
    console.log("coverage component");

    this.uploadFileInput = true;
    const files = event.target.files;
    const file = files[0];
    // this.fileName = file.name;
    this.uploadFile(file);
  }

  uploadFile(file: any) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.coverageService.uploadCoverageExcel(formdata, this.reportId).subscribe(
      (response: any) => {
        this.coverageTable = true;
        this.uploadFileInput = false;
        this.coverageListSpinner = false;
        console.log("Coverage data ==>", response);
        this.coverageVariants = response['payLoad'];
      }
    );
  }

  seletedAll(event: any, id: number) {
    this.selectedForReport = event['target']['checked'];
    this.coverageListSpinner = true;

    if (this.selectedForReport) {
      this.coverageService.setAllCoverageVariantStatus(id, true).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getCoverageVariants();
            this.coverageListSpinner = false;
          }
        }
      );
    } else if (!this.selectedForReport) {
      if(confirm("Are you sure you want to unselect all..!")) {
      this.coverageService.setAllCoverageVariantStatus(id, false).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getCoverageVariants();
            this.coverageListSpinner = false;

          }
        }
      );
      }
      else{
        this.getCoverageVariants();
      }
    }

  }


  seletedRow(event: any, coverageVariant: CoverageFile) {
    this.selectedForReport = event['target']['checked'];

    if (this.selectedForReport) {
      this.coverageService.coverageVariantStatus(coverageVariant.id, true).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getCoverageVariants();
          }
        }
      );
    } else if (!this.selectedForReport) {
      if(confirm("Are you sure you want unselect..!")) {
      this.coverageService.coverageVariantStatus(coverageVariant.id, false).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getCoverageVariants();
          }
        }
      );
      }
      else{
        this.getCoverageVariants();
      }
    }

  }

  switchToggle(event: any) {
    this.coverageListSpinner = true;
    this.switchToggleButton = event['target']['checked'];
    console.log("switchToggleButton", this.switchToggleButton);
    if (this.switchToggleButton) {
      this.getSelectedCoverageVariants();
    } else if (!this.switchToggleButton) {
      this.getCoverageVariants();
    }

  }

  getReport() {
    this.uploadFileInput = true;
    this.reportService.getReport(this.reportId).subscribe(
      (response: any) => {
        this.report = response['payLoad'];
        this.uploadFileInput = false;

        if (response['payLoad'].coverageExcelFile == null) {
          this.coverageTable = false;
        } else if (response['payLoad'].coverageExcelFile != null) {
          this.coverageTable = true;
          this.fileName = this.report.coverageExcelFile.split('/')[5].substring(14);
          this.getCoverageVariants();
        }
      });
  }

  selectSignificance(coverageVariantId: number, significance: string){
    this.coverageService.addCoverageSignificance(coverageVariantId, significance).subscribe(
      (response: any) => {
        console.log(response);
      }
    );

  }

  getCoverageVariants() {
    this.coverageListSpinner = true;
    this.coverageService.getAllCoverageListByReportId(this.reportId).subscribe(
      (response: any) => {
        this.coverageListSpinner = false;
        this.spinDiv=true;
        this.coverageVariants = response['payLoad'];
        var isSelectedCount=0;
        var coverageLength=this.coverageVariants.length;
        for(let i=0; i<this.coverageVariants.length;i++){
          if(this.coverageVariants[i].selectedForReport==true){
            isSelectedCount++
          }
        }
        if(isSelectedCount==coverageLength){
          this.isAllChecked=true;
        }else{
          this.isAllChecked=false
        }
        if (response['success'] == true) {
          if (this.switchToggleButton) {
            this.coverageListSpinner = true;
            this.getSelectedCoverageVariants();
          }
        }
      }
    );
  }

  getSelectedCoverageVariants() {

    function isSelectedForReport(element: CoverageFile, index: any, array: any) {
      return (element.selectedForReport == true);
    }
    this.coverageVariants = this.coverageVariants.filter(isSelectedForReport);
    this.coverageListSpinner = false;
  }

}
