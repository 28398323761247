import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUser } from '../_interfaces.ts/app_user';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

  appUser = {} as AppUser;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
  }

  logout(){
    localStorage.clear();
    this.router.navigate(['/auth']);

  }

}
