import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { Gene, GenePattern, TestTypeGene } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
  selector: 'app-add-test-type-gene',
  templateUrl: './add-test-type-gene.component.html',
  styleUrls: ['./add-test-type-gene.component.css'],
})
export class AddTestTypeGeneComponent implements OnInit {
  editor = ClassicEditorBuild;
  genepatternid!: number;
  genepattern = {} as TestTypeGene;
  sampleList = [
    { ids: 1, name: 'SOMATIC' },
    { ids: 2, name: 'GERMLINE' },
  ];
  sampleTypeList: any = [];
  geneList: Gene[] | any | undefined;
  element!:Gene;
  gene: any;
  sampleTest: any;
  subType: any;
  subTypeBackupList = [
    { id: 1, sampleType: 'GERMLINE', testNames: 'Germline+' },
    { id: 2, sampleType: 'GERMLINE', testNames: 'HRR Germline' },
    { id: 3, sampleType: 'GERMLINE', testNames: 'Family member testing' },
    { id: 5, sampleType: 'SOMATIC', testNames: 'Absolute' },
    { id: 6, sampleType: 'SOMATIC', testNames: 'Core' },
    { id: 7, sampleType: 'SOMATIC', testNames: 'First' },
    { id: 8, sampleType: 'SOMATIC', testNames: 'Focus' },
    { id: 9, sampleType: 'SOMATIC', testNames: 'Indiegene' },
    { id: 10, sampleType: 'SOMATIC', testNames: 'HRR Somatic' },
  ];
  subTypeList: any = [];
  sampleDropdownSettings = {};
  subTypeDropdownSettings = {};
  geneDropdownSettings = {};
  submitSpinner: boolean = false;
  uploadFileInput = false;

  statusMsg: string = '';
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getGenes();
    this.genepatternid = +this.routerAct.snapshot.params['id'];
    this.sampleDropdownSettings = {
      singleSelection: true,
      idField: 'ids',
      textField: 'name',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };

    this.subTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'testNames',
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.geneDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    if (this.genepatternid) {
      this.getGenePatternById();
    }
  }

  getGenePatternById() {
    // this.sampleTest=[]
    // this.subType=[]
    this.dataAdminService
      .getTestTypeGeneById(this.genepatternid)
      .subscribe((res: any) => {
        console.log('Res==>', res);
        this.genepattern = res['payLoad'];
        let geneData: any = res['payLoad']['gene'];
        console.log('geneData=>', geneData);
        if (geneData != null) {
          this.gene = geneData;
          console.log('this.gene', this.gene);
        }

        if (res['payLoad']['sampleType']?.length > 0) {
          this.sampleTest = this.sampleList.filter((element) =>
            res['payLoad']['sampleType'].includes(element.name)
          );
          if (res['payLoad']['testNames']?.length > 0) {
            this.subType = this.subTypeBackupList.filter((element) =>
              res['payLoad']['testNames'].includes(element.testNames)
            );
          }
        }
      });
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }
  showError(msg: any) {
    this.toastr.error(msg, '', { timeOut: 2000 });
  }

  filterTests(test: any) {
    if (test && test.length > 0) {
      this.sampleTypeList = [];
      this.subTypeList = [];
      test.forEach((element: any) => {
        this.sampleTypeList.push(element.name);
      });
      this.subTypeList = this.subTypeBackupList.filter((element) =>
        this.sampleTypeList.includes(element.sampleType)
      );
    }
  }

  getGenes() {
    this.dataAdminService.geneList().subscribe((response: any) => {
      this.geneList = response['payLoad'];
    });
  }

  onSubmit() {
    let testNames!: string;
    let sampleTestNames!: string;
    let geneId: any = [];
    console.log('this.subType', this.subType);
    console.log('this.sampleTest', this.sampleTest);
    if (this.subType && this.subType.length > 0) {
      testNames = this.subType[0].testNames;

      // this.subType.forEach((element:any) => {
      //   testNames=(element.testNames)
      // });
    }
    if (this.sampleTest && this.sampleTest.length > 0) {
      sampleTestNames = this.sampleTest[0].name;
      // this.sampleTest.forEach((element:any) => {
      //   sampleTestNames=(element.name)
      // });
    }

    if (this.gene && this.gene.length > 0) {
      this.gene.forEach((genes: any) => {
        geneId.push(genes.id);
      });
    }
    console.log('sampleTestNames', sampleTestNames);
    console.log('testNames', testNames);

    this.genepattern.sampleType = sampleTestNames;
    this.genepattern.testNames = testNames;
    this.genepattern.geneIds = geneId;
    console.log('this.genepattern=>', this.genepattern);
    if (this.genepatternid) {
      this.genepattern.id = this.genepatternid;
      this.dataAdminService.addTestTypeGenesDetails(this.genepattern).subscribe(
        (res: any) => {
          console.log('============>', res['payLoad']);

          this.statusMsg = res['message'];
          this.showSuccess();
          this.reload();
        },
        (error) => {
          this.showError(error.error['message']);
        }
      );
    } else {
      console.log('this.genepattern=>', this.genepattern);

      this.dataAdminService.addTestTypeGenesDetails(this.genepattern).subscribe(
        (res: any) => {
          console.log('============>', res['payLoad']);

          this.statusMsg = res['message'];
          this.showSuccess();
          this.reload();
        },
        (error) => {
          this.showError(error.error['message']);
        }
      );
    }
  }
  onFileChange(event: any) {
    console.log('event==>', event);
    this.uploadFileInput = true;
    const files = event.target.files;
    const file = files[0];
    // this.fileName = file.name;
    this.uploadFile(file);
  }

  uploadFile(file: any) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    console.log('formdata', formdata);
    this.dataAdminService
      .uploadAndGetGenesFromExcel(formdata)
      .subscribe((response: any) => {
        this.uploadFileInput = false;
        console.log('Gene data ==>', response);
        if (response != null) {
          this.gene = response['payLoad'];
          console.log('this.gene', this.gene);
        }
        // this.cnaVariants = response['payLoad'];
      });
  }

  reload() {
    console.log('reload');
    this.router
      .navigateByUrl('/reports', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/super_admin/testtypegene']);
      });
  }
  datas: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  dataset:any=[];
  dataNoSet:any=[];
  onFileChanges(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.datas = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(this.datas);
      for(let i=0;i<this.datas.length;i++){
        for(let j=0;j<this.datas[i].length;j++){
          console.log("hqejgwq",this.datas[i][j])

          // this.dataset.push(this.datas[i][j])
          const result = this.geneList.find((element:any) =>
          element.name === this.datas[i][j] );
          if(result!=undefined){
            this.dataset.push(result)
          }else{
            this.dataNoSet.push(this.datas[i][j])

          }
        }

      }
      console.log("-->",this.dataset);
      console.log("no-->",this.dataNoSet);

      this.gene=this.dataset;
      if(this.dataNoSet!=null){
        this.showError(this.dataNoSet+ " Not Found in Gene List");
        }
    };

    reader.readAsBinaryString(target.files[0]);
  }





}
