import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HotspotClinicalTrialRes } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-hotspot-trails',
  templateUrl: './hotspot-trails.component.html',
  styleUrls: ['./hotspot-trails.component.css']
})
export class HotspotTrailsComponent implements OnInit {

  ClinicalTrialList!: HotspotClinicalTrialRes[];
  hotspotClinicalId!: number;
  statusMsg!: string;
  hotspotDrugListSpinner:boolean=true
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) { }
  searchText!:any;

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  ngOnInit(): void {
    this.getHotspotClinicalTrailList();
  }

  getHotspotClinicalTrailList(){
    this.dataAdminService.hotspotClinicalTrailList().subscribe(
      (response: any) => {
        this.hotspotDrugListSpinner=false
        this.ClinicalTrialList = response['payLoad'];
        console.log(this.ClinicalTrialList);

        this.ClinicalTrialList.forEach((ele:any)=>{
          let geneNames:any=[]
          if(!ele.gene){
            ele.gene={
              name:''
            }
            if(ele.genes.length>0){
              ele.genes.forEach((element:any) => {
                geneNames.push(element.name)
              });
              ele.gene['name']=geneNames.toString()
            }
          }
        })
        console.log("DATA",this.ClinicalTrialList)
      }
    );

  }

  assignClinicalTrail(clinicalTrial: any){
    console.log(clinicalTrial);
    this.hotspotClinicalId = clinicalTrial['id'];
  }

  deleteClinicalTrail(){
    console.log(this.hotspotClinicalId);
    this.dataAdminService.deleteHotspotClinicalTrail(this.hotspotClinicalId).subscribe(
      (response: any) =>{
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
    });
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/hotspot-clinical-trails']);
    });
  }

}
