<div class="container-fluid ">
  <div *ngIf="report.reportType != null" class="row px-4">
    <div class="col-4 pl-0">
    </div>
    <!--  -->
    <div class="col pr-1">
      <div class="d-flex justify-content-end">
        <div class="">
          <!-- <div class="report_icon">
            <a [routerLink]="[ '/view_report', reportId ]" target="_blank" class="text_color "> <i
                class="zmdi zmdi-eye eye"></i>
              View Report</a>
          </div> -->

        </div>
        <!-- <div class="ml-3">
          <div class=" text-right  ml-4" *ngIf=" this.organisationName==null ||  this.organisationName==''">
            <select class="form-control" (change)="changeReportStatus($event.target)">
              <option selected disabled> select report Delivered</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class=" text-right  ml-4" *ngIf=" this.organisationName!=null &&  this.organisationName!=''">
            <select class="form-control" (change)="changeReportAndOncoStatus($event.target)">
              <option selected disabled> Is the report completed?</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

        </div> -->
      </div>
      <!--  -->

      <!--  -->

    </div>

  </div>
</div>

<!-- if report is not germline type -->
<div class="container-fluid patient-info_tabs position-relative reports_tabs">
  <div *ngIf="reportTypeChange" class="d-flex justify-content-center mt-5">
    <span class="visually-hidden"></span>
    <div class="spinner-border" style="width: 8rem; height: 8rem" role="status"></div>
  </div>
  <div *ngIf="!reportTypeChange">
    <header class=" pl-3  mb-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#patient-info"
            (click)="textcount('clinicalSummary-editors')">Patient Info</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#ihc" data-toggle="tab">IHC</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#interpretationPDL1"
            (click)="textcount('interpretation-editors')">Interpretation</a>
        </li>

        <li class="nav-item" *ngIf="report.reportPDL1Status=='Positive'">
          <a class="nav-link" data-toggle="tab" href="#pdl1trails">PD-L1 Trials</a>
        </li>

      </ul>
    </header>

    <div class="tab-content">

      <!-- Patient Info -->
      <div id="patient-info" class="container-fluid tab-pane active"><br>
        <div id="tab1" class="">
          <div class="cust_shadow p-4 bg-white  rounded">
            <div class="levels_section  row mt-2 py-3 rounded mx-0" style="background: #f1f6f6;">
              <div class=" col-6">

                <p>
                  <label class="col-8">Select Report Sub Type :
                    <select class="form-control" [(ngModel)]="reportSubType" style="width: 60%"
                      (change)="addReportSubType($event)">
                      <option selected disabled>Select</option>
                      <option value="22C3 Dako">22C3 Dako</option>
                      <option value="SP263 Ventana">SP263 Ventana</option>
                      <option value="SP142 Ventana">SP142 Ventana</option>

                    </select>
                  </label>
                </p>
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''"><label class="col">Ecrf Id :
                    {{report?.patient?.ecrfId}}</label></p>
                <p
                  *ngIf=" this.organisationName!=null &&  this.organisationName!='' && this.organisationName=='Lifecell'">
                  <label class="col">Patient Id : {{report?.patient?.orgLabId}}</label>
                </p>
                <p
                  *ngIf=" this.organisationName!=null &&  this.organisationName!='' && this.organisationName!='Lifecell'">
                  <label class="col">Patient Id : {{report?.patient?.organisationPatientId}}</label>
                </p>

                <p><label class="col">Name : {{report?.patient?.name}}</label></p>
                <p><label class="col">Gender : {{report.patient.gender}}</label></p>
                <p><label class="col">Collected On : {{report.labDetails.sampleCollected}}</label></p>
                <p><label class="col" *ngIf=" this.organisationName==null ||  this.organisationName==''">FFPE ID :
                    {{report.labDetails.sampleBlockId}}</label></p>
              </div>
              <div class="col-6">
                <p>
                  <label class="col-8">Select Report Status :
                    <select class="form-control" [(ngModel)]="reportPDL1Status" style="width: 60%"
                      (change)="addReportStatus($event)">
                      <option selected disabled>Select</option>
                      <option value="Positive">Positive</option>
                      <option value="Negative">Negative</option>
                    </select>
                  </label>
                </p>
                <p><label class="col">DOB/Age : <span
                      *ngIf="report.patient.dateOfBirth != null">{{report.patient.dateOfBirth |
                      date:'dd/MM/yyyy'}}</span> <span *ngIf="report.patient.age != null">{{report.patient.age}} Years
                    </span></label>
                </p>
                <p><label class="col">Report Type : Targt {{report.reportType.name}}</label></p>
                <p><label class="col">Cancer Site : {{report.cancerType}}</label></p>
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''"><label class="col">Sample Id :
                    {{report.labDetails.sampleId}}</label></p>
                <p><label class="col">Sample Source : {{report.labDetails.sampleSource}}</label></p>
                <p><label class="col">Referring Clinician :
                    {{referringClinician}}</label></p>
                <p><label class="col">Hospital : {{report.labDetails.hospital}}</label></p>
              </div>
            </div>

            <!-- selecet cancer type for first -->
            <div class="levels_section row mt-2 py-1 rounded mx-0" style="background: #f1f6f6;">
              <div class=" col-6">
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''">
                  <label class="col-8">Cancer Type :
                    <ng-multiselect-dropdown [settings]="cancerTypeDropdownSettings" [data]="cancerTypeList"
                      name="cancerType" [(ngModel)]="selectedcancertype" (onSelect)="cancerTypeSelect($event)"
                      (onDeSelect)="cancerTypeDeSelect($event)">
                    </ng-multiselect-dropdown>
                  </label>
                </p>
                <p *ngIf=" this.organisationName!=null &&  this.organisationName!=''">
                  <label class="col-8">Cancer Type : {{selectedcancertype[0].name}}
                  </label>
                </p>

              </div>

            </div>


          </div>
        </div>

        <form class="general-form  mt-4">
          <div class="form-group row">
            <div class="col">
              <div class="d-flex justify-content-between align-items-center">
                <label>Clinical Summary:</label>
                <div style="text-align: right; padding-bottom: 5px;"
                  *ngIf="clinicalSummary!=null ||clinicalSummary!=undefined">
                  <P *ngIf="clinicalSummary.length"><strong>[{{clinicalSummary.length}}/3000]</strong></P>
                </div>
              </div>
              <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList'] }"
                name="clinicalSummary" [(ngModel)]="clinicalSummary" (keyup)="textcount('clinicalSummary-editor')">
              </ckeditor>
              <div class="clinicalSummary-editor" hidden="true" [innerHtml]="clinicalSummary"></div>
            </div>
          </div>
          <div
            *ngIf="report.clinicalSummary != null || report.addNotes!=null && (this.organisationName==null ||  this.organisationName=='')">
            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
              (click)="addClinicalSummary()">
              <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
              <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
          <div
            *ngIf="report.clinicalSummary == null && report.addNotes == null && (this.organisationName==null ||  this.organisationName=='')">
            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
              (click)="addClinicalSummary()">
              <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
              <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
        </form>
      </div>

      <!-- interpretationPDL1 -->
      <div id="interpretationPDL1" class="container-fluid tab-pane fade"><br>
        <div id="tab11" class="">
          <form class="general-form">
            <ckeditor [editor]="editor" class=""
              [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic'  , 'bulletedList'] }" name="intertext1"
              [(ngModel)]="intertext1"></ckeditor>
            <br />
            <ckeditor [editor]="editor" class=""
              [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic'  , 'bulletedList'] }" name="intertext2"
              [(ngModel)]="intertext2"></ckeditor>
          </form>
        </div>
      </div>

      <!-- PD-L1 Trails -->
      <div *ngIf="immunoDetails.pdl1Status == 'EXPRESSION'  || report.reportPDL1Status=='Positive'" id="pdl1trails"
        class="container-fluid tab-pane fade ">
        <br>
        <div id="tab8" class="w-50 m-auto ">
          <table class="table">
            <thead class="thead-light bg-white" style="hight:80px!important">
              <tr>
                <th>Select/Deselect All
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" (click)="selectAndDeSelectAllTrails($event)"
                      [checked]="isAllSelectedOrDeSelected">
                  </div>
                </th>
                <th>Cancer Type</th>
                <th>Drugs</th>
                <!-- <th>Action</th> -->
              </tr>
              <tr *ngFor="let clinicalTrial of clinicalTrialResList; let i = index">
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" (click)="addSelectedTrails($event,clinicalTrial.id)"
                      [checked]="clinicalTrial.selected">
                  </div>
                </td>
                <td>{{clinicalTrial.cancerType.name}}</td>
                <td>
                  <div *ngFor="let drug of clinicalTrial.drugs">
                    {{drug.name}}
                  </div>
                </td>
              </tr>

            </thead>
          </table>
        </div>
      </div>

      <!-- IHC -->
      <div id="ihc" class="container-fluid tab-pane fade"><br>
        <div id="tab11" class="">
          <form class="general-form">
            <div class="form-group row">
              <div class="col">
                <table>
                  <tr>
                    <th>Specimen</th>
                    <td style="width:1400px!important; hight:30px!important">
                      <ckeditor [editor]="editor" class=""
                        [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic'  , 'bulletedList'] }" name="specimen"
                        [(ngModel)]="specimen"></ckeditor>
                    </td>
                  </tr>
                  <tr>
                    <th>Macroscopic findings</th>
                    <td>
                      <ckeditor [editor]="editor" class=""
                        [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic'  , 'bulletedList'] }"
                        name="macroscopicFindings" [(ngModel)]="macroscopicFindings"></ckeditor>
                    </td>
                  </tr>
                  <tr>
                    <th>Microscopic description</th>
                    <td>
                      <ckeditor [editor]="editor" class=""
                        [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic'  , 'bulletedList'] }"
                        name="microscopicDescription" [(ngModel)]="microscopicDescription"></ckeditor>
                    </td>
                  </tr>
                  <tr>
                    <th>Impression</th>
                    <td>
                      <ckeditor [editor]="editor" class=""
                        [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic'  , 'bulletedList'] }" name="impression"
                        [(ngModel)]="impression"></ckeditor>
                    </td>
                  </tr>
                </table>
              </div>
            </div>


            <div
              *ngIf="report.specimen != null || report.macroscopicFindings != null || report.microscopicDescription != null || report.impression != null">
              <button type="button" [disabled]="isSubmitedDisclaimer" class="btn save_btn" style="float:right"
                (click)="addIHCDetails()">
                <span *ngIf="!isSubmitedIhc"> Update </span>
                <div *ngIf="isSubmitedIhc" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div
              *ngIf="report.specimen == null && report.macroscopicFindings == null && report.microscopicDescription == null && report.impression == null">
              <button type="button" class="btn save_btn" style="float:right" (click)="addIHCDetails()">
                <span *ngIf="!isSubmitedIhc"> Save </span>
                <div *ngIf="isSubmitedIhc" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>



    </div>
  </div>
</div>
