<div class="row main position-relative mx-0">
  <div class="col-8  full-height bg-softblue pr-0">
    <h2 class="mt-2 py-3 ml-4 border-bottom text-white pr-0 font-weight-light">WORK BENCH</h2>
    <div class="doctors_slide_panel">
      <div id="demo" class="carousel slide" data-ride="carousel">
        <!-- The slideshow -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../../../assets/Carousel_1.png" alt="Doctor-image" class="img-fluid w-25 m-auto ">
            <div class="doctor-info py-3">
              <p class="text-center">In view of the increasing number of cancer cases and limited time available with
                the oncologists, it is crusial that a clear overview of the patients data is available at all times to
                ensure fact based decision making.</p>
            </div>
          </div>
          <div class="carousel-item ">
            <img src="../../../assets/Carousel_2.png" alt="Doctor-image" class="img-fluid w-25 m-auto">
            <div class="doctor-info py-3">
              <p class="text-center">Currently, the data of all the patients is available is non-standard hard copy
                reports, which are difficult for the patients to manage and share with their consulting oncologists .
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img src="../../../assets/Carousel_3.png" alt="Doctor-image" class="img-fluid w-25 m-auto">
            <div class="doctor-info py-3">
              <p class="text-center">4basecare has created a secure nad structured tool for capturing oncology-specific
                data from the patients and summarizing it in a seamless format so that optimal treatment decision can be
                taken. </p>
            </div>
          </div>
        </div>
        <!-- Indicators -->
        <ul class="carousel-indicators slider-indicators">
          <li data-target="#demo" data-slide-to="0" class="active"></li>
          <li data-target="#demo" data-slide-to="1"></li>
          <li data-target="#demo" data-slide-to="2"></li>
        </ul>
      </div>
    </div>

    <!-- Contact sales button -->


    <div class="btn-section">
      <div class="text-center">
        <p class="mb-0">For more details of the product and a Demo, please</p>
        <button type="button" class="btn sale-btn mt-1" data-toggle="modal" data-target="#Contactsales">Click
          More</button>
      </div>
      <div class="modal fade" id="Contactsales">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body p-3">
              <p class="">Please write an email to <a href="mailto: support@4basecare.com">support@4basecare.com</a>
                with all your details</p>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-4 px-5 bg-white">
    <div class="logo py-3">
      <img src="../../../assets/logo.png" alt="logo" class="img-fluid">
    </div>
    <div class="login-section">
      <h4 class="mb-0 text_color">
        <span>Good Morning!</span>
      </h4>
      <span class="text_color mb-3 font_size">Login to Work bench</span>
      <form (ngSubmit)="submitLogin()" class="mt-3 login-page">
        <div class="form-group">
          <input type="name" class="form-control" placeholder="email" name="email" [(ngModel)]="model.email" required>
        </div>
        <div class="form-group">
          <input type="password" class="form-control" placeholder="Password" name="pswd" [(ngModel)]="model.password"
            required>
        </div>
        <button [disabled]="loginSpinner" type="submit" class="btn text-white w-100 text-uppercase bg-softblue login_btn">
          <span *ngIf="!loginSpinner"> Login </span>
          <div *ngIf="loginSpinner" class="spinner-border text-dark">
            <span class="sr-only"></span>
          </div>
        </button>
        <a href="#" class="ml-auto d-block text-right text_color py-2">Forgot Password?</a>
      </form>
    </div>

    <div class="company-info">
      <div class="text-center d-block">
        <p class="mb-0">A Product from 4baseCare Genomics Pvt. Ltd. </p>
      </div>
    </div>

  </div>
</div>

<!-- script files -->
