<div class="variant_view p-4 bg-white cust_shadow  d-inline-block w-100">
    <div class="variant_title d-flex  justify-content-between  align-items-center">
      <h5 class="mb-0 font-weight-bold">{{cnaVariant?.report?.patient?.ecrfId}}
        <span>[{{cnaVariant?.gene}}]</span></h5>
        <!-- <span class="back_icon"><a [routerLink]="[ '/dash-reports', reportId ]" [state]="{ cnaIsLinkActive: 1 }"><i
                    class="zmdi zmdi-chevron-left"></i></a>
        </span>
        <div>
            <ul class="list-unstyled d-flex">
                <li> Gene </li>
                <li> Sample </li>
                <li> Comparison </li>
            </ul>
        </div> -->
        <div class="ml-auto clinical_trail-title" *ngIf="!isGermline">
          <a *ngIf="this.nstudiesFound == 0 && cnaVariant?.report?.reportType?.name != 'First' && cnaVariant?.report?.reportType?.name != 'First 72 Solid' && cnaVariant?.report?.reportType?.name != 'First 72 Liquid' && cnaVariant?.report?.reportType?.name !='Lung Liquid Advanced' && cnaVariant?.report?.reportType?.name !='Lung Liquid Basic' "
             [routerLink]="[ '/cna_trail_list', cnaVariantId]">No Clinical Trials</a>
            <a *ngIf="this.nstudiesFound > 0 && cnaVariant?.report?.reportType?.name != 'First' && cnaVariant?.report?.reportType?.name != 'First 72 Solid' && cnaVariant?.report?.reportType?.name != 'First 72 Liquid' && cnaVariant?.report?.reportType?.name !='Lung Liquid Advanced' && cnaVariant?.report?.reportType?.name !='Lung Liquid Basic'"
                 [routerLink]="[ '/cna_trail_list', cnaVariantId]">Select
                ClinicalTrial </a>
            <a *ngIf="this.clinicalTrialResList.length > 0 && cnaVariant?.report?.reportType?.name != 'First' && cnaVariant?.report?.reportType?.name != 'First 72 Solid' && cnaVariant?.report?.reportType?.name != 'First 72 Liquid' && cnaVariant?.report?.reportType?.name !='Lung Liquid Advanced' && cnaVariant?.report?.reportType?.name !='Lung Liquid Basic'"
                [routerLink]="[ '/cna_fda_trail_list', cnaVariantId]">| Select FDA/NCCN Approved </a>
            <a *ngIf="this.clinicalTrialResList.length > 0 && (cnaVariant?.report?.reportType?.name == 'First' || cnaVariant?.report?.reportType?.name == 'First 72 Solid' || cnaVariant?.report?.reportType?.name == 'First 72 Liquid' || cnaVariant?.report?.reportType?.name =='Lung Liquid Advanced' || cnaVariant?.report?.reportType?.name =='Lung Liquid Basic')"
                [routerLink]="[ '/cna_fda_trail_list', cnaVariantId]">Select Hotspot Drugs </a>
            <a *ngIf="this.clinicalTrialResList.length <= 0 && (cnaVariant?.report?.reportType?.name == 'First' || cnaVariant?.report?.reportType?.name == 'First 72 Solid' || cnaVariant?.report?.reportType?.name == 'First 72 Liquid' || cnaVariant?.report?.reportType?.name =='Lung Liquid Advanced' || cnaVariant?.report?.reportType?.name =='Lung Liquid Basic')"
              [routerLink]="[ '/cna_fda_trail_list', cnaVariantId]">No Hotspot Drugs </a>
        </div>
    </div>

    <div class="row mb-5  mt-3 mx-1 p-4  cust_shadow">
        <h5 class="mb-3 text_color ">CNA Variant detail</h5>
        <div class="w-100"></div>
        <div class="col-3 border p-4">
            <p>Gene</p>
            <p>{{cnaVariant.gene}}</p>
        </div>
        <div class="col-3 border p-4">
            <p>Sample</p>
            <p>{{cnaVariant.sample}}</p>
        </div>
        <div class="col-6 border p-4">
            <p>Comparison</p>
            <p *ngFor = "let item of comparisionList">{{item}}</p>
        </div>
    </div>

    <div class="row ml-2 mb-4">
        <div class="col-3 ">
            <h5 class=" text_color ">*Clinical Siginificance</h5>
            <select class="form-control" name="significance" [(ngModel)]="cnaVariant.significance" (click)="getGeneDescription()">
                <option value="Benign">Benign</option>
                <option value="Pathogenic">Pathogenic</option>
                <option value="Likely Pathogenic">Likely Pathogenic</option>
                <option value="VUS">VUS</option>
                <option value="Not Classified">Not Classified</option>
            </select>
        </div>
        <div class="col-3">
            <h5 class="text_color ">Reference</h5>
            <input type="text" class="form-control" name="reference" [(ngModel)]="cnaVariant.reference">
        </div>

        <div *ngIf="isGermline" class="col-3 mt-2">
            <h5 class=" text_color ">Zygosity</h5>
            <select class="form-control" name="significance" [(ngModel)]="cnaVariant.zygosity">
                <option value="Homozygous">Homozygous</option>
                <option value="Heterozygous">Heterozygous</option>
                <option value="Compound Heterozygous">Compound Heterozygous</option>
                <option value="Hemizygous">Hemizygous</option>
            </select>
        </div>

        <div *ngIf="isGermline" class="col-3 mt-2">
            <h5 class=" text_color ">Mode of Inheritance</h5>
            <select class="form-control" name="significance" [(ngModel)]="cnaVariant.inheritanceMode">
                <option value="Autosomal Dominant">Autosomal Dominant</option>
                <option value="Autosomal Recessive">Autosomal Recessive</option>
                <option value="X-linked">X-linked</option>
                <option value="Autosomal Dominant/ Autosomal Recessive">Autosomal Dominant/ Autosomal Recessive</option>
                <option value="Others">Others</option>
            </select>
        </div>

        <div *ngIf="isGermline" class="col-3 mt-2">
            <h5 class=" text_color ">Cancer Type</h5>
            <input class="form-control" name="cancerType" [(ngModel)]="cnaVariant.cancerType">
        </div>
        <!-- <div class="col-3">
            <h4 class="text_color "> Alternative Allele Depth</h4>
            <input type="text" class="form-control" name="reference" [(ngModel)]="cnaVariant.mutantAlt">
        </div>
        <div class="col-2">
            <h4 class="text_color ">Total Depth</h4>
            <input type="text" class="form-control" name="reference" [(ngModel)]="cnaVariant.mutantRef">
        </div> -->
    </div>

    <div class="row ml-2 mb-4" *ngIf="cnaVariant?.report?.reportType?.name != 'First' && cnaVariant?.report?.reportType?.name != 'First 72 Solid' && cnaVariant?.report?.reportType?.name != 'First 72 Liquid' && cnaVariant?.report?.reportType?.name !='Lung Liquid Advanced' && cnaVariant?.report?.reportType?.name !='Lung Liquid Basic'">
        <div class="col">
            <h5 class=" text_color ">Gene Description ({{gene?.name}})</h5>
            <p>{{gene?.description}}</p>
        </div>
    </div>

    <div class="row ml-2 mb-4" *ngIf="cnaVariant?.report?.reportType?.name != 'First' && cnaVariant?.report?.reportType?.name != 'First 72 Solid' && cnaVariant?.report?.reportType?.name != 'First 72 Liquid' && cnaVariant?.report?.reportType?.name !='Lung Liquid Advanced' && cnaVariant?.report?.reportType?.name !='Lung Liquid Basic'">
        <div class="col" >
            <div class="d-flex justify-content-between align-items-center  my-1">
              <h5 class="text_color mb-0">Add Gene Description</h5>
            <!-- <textarea type="text" class="form-control"  style="height: 200px;"[config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' ] }" name="addGeneDescription"
                [(ngModel)]="cnaVariant.addGeneDescription"></textarea> -->
                <div  *ngIf="cnaVariant.addGeneDescription!=null ||cnaVariant.addGeneDescription!=undefined" >
                  <P *ngIf="cnaVariant.addGeneDescription.length"><strong>[{{editorText.length}}/2000]</strong></P>
                </div>
            </div>
            <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
            name="addGeneDescription" [(ngModel)]="cnaVariant.addGeneDescription" (keyup)="textcount('addGeneDescription-editor')"></ckeditor>
            <div class="addGeneDescription-editor" hidden="true" [innerHtml]="cnaVariant.addGeneDescription"  ></div>
            <p class="pt-2"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
        </div>
    </div>
    <div class="row ml-2 mb-4"  *ngIf="cnaVariant?.report?.reportType?.name == 'First' || cnaVariant?.report?.reportType?.name == 'First 72 Solid' || cnaVariant?.report?.reportType?.name == 'First 72 Liquid' ||  cnaVariant?.report?.reportType?.name =='Lung Liquid Advanced' || cnaVariant?.report?.reportType?.name =='Lung Liquid Basic'">
      <div class="col" >
          <h5 class="text_color ">Additional Notes</h5>
          <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
          name="addNotes" [(ngModel)]="cnaVariant.addNotes"></ckeditor>

      </div>
  </div>

    <div class="form-group row ml-2 mb-4" *ngIf="cnaVariant?.report?.reportType?.name != 'First' && cnaVariant?.report?.reportType?.name != 'First 72 Solid' && cnaVariant?.report?.reportType?.name != 'First 72 Liquid' && cnaVariant?.report?.reportType?.name !='Lung Liquid Advanced' && cnaVariant?.report?.reportType?.name !='Lung Liquid Basic'">
      <div class="col">
          <label>PMIDS:</label>
          <input type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
              [(ngModel)]="pmIds">
      </div>
  </div>

  <div class="row ml-2 mb-4" *ngIf="isFirst72">
    <div class="col-3">
        <h5 class=" text_color ">Prognostic Siginificance</h5>

        <select class="form-control" name="significance" [(ngModel)]="cnaVariant.prognasticSignificance" (ngModelChange)="getPrognasticTag($event)" >
            <option value="Poor">Poor</option>
            <option value="Good">Good</option>
            <option value="NA">NA</option>

        </select>
    </div>

    <div class="col-3" >
        <h5 class=" text_color ">Prognostic Tag</h5>
        <ng-multiselect-dropdown [settings]="prognasticBiomarkerDropdownSettings" [data]="prognasticTagList"  [(ngModel)]="selectedPrognasticBiomarker" (ngModelChange)="selectPrognasticBiomarker($event)" name="prognasticTag"
        >
        </ng-multiselect-dropdown>
    </div>
    <div class="col">
    </div>
</div>


<div class="row ml-2 mb-4" *ngIf="isFirst72">
    <div class="col">
       <div class="d-flex justify-content-between align-items-center  my-1">
        <h5 class="text_color">Add Prognostic Description</h5>

            <div  *ngIf="cnaVariant.prognasticDescription!=null ||cnaVariant.prognasticDescription!=undefined" >
              <P *ngIf="cnaVariant.prognasticDescription.length"><strong>[{{editorText.length}}/2000]</strong></P>
            </div>
       </div>
            <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
            name="addPrognasticDescription" [(ngModel)]="cnaVariant.prognasticDescription" (keyup)="textcount('addPrognasticDescription-editor')"></ckeditor>
            <div class="addPrognasticDescription-editor" hidden="true" [innerHtml]="cnaVariant.prognasticDescription"  ></div>
            <p class="pt-2"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
    </div>
</div>

    <div class="row">
        <div class="col">
            <button type="button" [disabled]="isSubmited" class="btn save_btn" style="float:right"
                (click)="submitDetails()">
                <span *ngIf="!isSubmited"> Save </span>
                <div *ngIf="isSubmited" class="spinner-border text-dark">
                    <span class="sr-only"></span>
                </div>
            </button>
        </div>
    </div>


</div>
