import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CnaService {

  constructor(
    private httpClient: HttpClient
  ) { }

  uploadCNAExcel(params: any,reportId: Number) {
    return this.httpClient.post('/cna/' + reportId + '/upload_excel_file', params);
  }

  autoUploadCnaFile(reportId: Number) {
    return this.httpClient.post('/cna/' + reportId + '/auto_upload_cna_file', null);
  }

  getAllCNAVariantsByReportId(reportId: number){
    return this.httpClient.get('/cna/' + reportId + '/get_cna_report_variants');
  }

  cnaVariantStatus(cnaVariantId: number, aBoolean: boolean){
    return this.httpClient.post('/cna/' + cnaVariantId + '/cna_variant_status' ,aBoolean);
  }

  cnaStarVariantStatus(cnaVariantId: number, aBoolean: boolean){
    return this.httpClient.post('/cna/' + cnaVariantId + '/cna_star_variant_status' ,aBoolean);
  }

  setPriorityOrder(cnaVariantId: number, priorityOrderNumber: number) {
    return this.httpClient.post('/cna/' + cnaVariantId + '/priority_order', priorityOrderNumber);
  }

  getCNAVariant(cnaVariantId: number){
    return this.httpClient.get('/cna/' + cnaVariantId + '/get_cna_variant');
  }

  addCNAVariantSignificance(cnaVariantId: number, body: any){
    return this.httpClient.post('/cna/' + cnaVariantId + '/add_significance' ,body);
  }
}
