<div class="variant_view p-4  cust_shadow my-3 d-inline-block w-100 bg-white">
  <div class="variant_title d-flex justify-content-between align-items-center   ">
    <h5 class="mb-0 font-weight-bold" >{{fusionVariant.report?.patient?.ecrfId}}
      <span >[{{fusionVariant?.fusionGene}}]</span>
      </h5>
      <!-- <span class="back_icon"><a [routerLink]="[ '/dash-reports', reportId ]"  [state]="{ fusionIsLinkActive: 2 }"><i
                  class="zmdi zmdi-chevron-left"></i></a>
      </span>
      <div>
          <ul class="list-unstyled d-flex">
              <li> Fusion Gene </li>
              <li> Score </li>
              <li> Left Break Point </li>
          </ul>
      </div> -->
      <div class="ml-auto clinical_trail-title">
          <!-- <span *ngIf="this.nstudiesFound == 0 && fusionVariant.report.reportType.name != 'First'"
              style="font-size: 15px!important;">No Clinical Trials</span> -->
          <a *ngIf="this.nstudiesFound == 0 && (fusionVariant.report?.reportType?.name != 'First' && fusionVariant.report.reportType.name != 'First 72 Solid' && fusionVariant.report.reportType.name != 'First 72 Liquid' && fusionVariant.report.reportType.name != 'Lung Liquid Basic' && fusionVariant.report.reportType.name != 'Lung Liquid Advanced' )"
               [routerLink]="[ '/fusion_trail_list', fusionVariantId]">No Clinical Trials
              </a>
          <a *ngIf="this.nstudiesFound > 0 && (fusionVariant.report?.reportType?.name != 'First' && fusionVariant.report.reportType.name != 'First 72 Solid' && fusionVariant.report.reportType.name != 'First 72 Liquid' && fusionVariant.report.reportType.name != 'Lung Liquid Basic' && fusionVariant.report.reportType.name != 'Lung Liquid Advanced' )"
               [routerLink]="[ '/fusion_trail_list', fusionVariantId]">Select
              ClinicalTrial </a>
          <a *ngIf="this.clinicalTrialListCount > 0 && (fusionVariant.report.reportType.name != 'First' && fusionVariant.report.reportType.name != 'First 72 Solid' && fusionVariant.report.reportType.name != 'First 72 Liquid' && fusionVariant.report.reportType.name != 'Lung Liquid Basic' && fusionVariant.report.reportType.name != 'Lung Liquid Advanced' ) " class="delete_clr   "
              [routerLink]="[ '/fusion_fda_trail_list', fusionVariantId]">| Select FDA/NCCN Approved </a>
          <a *ngIf="this.clinicalTrialListCount > 0 && (fusionVariant.report?.reportType?.name == 'First'|| fusionVariant.report.reportType.name == 'First 72 Solid' || fusionVariant.report.reportType.name == 'First 72 Liquid' ||  fusionVariant.report.reportType.name == 'Lung Liquid Basic' || fusionVariant.report.reportType.name == 'Lung Liquid Advanced' )" class="delete_clr   "
              [routerLink]="[ '/fusion_fda_trail_list', fusionVariantId]">Select Hotspot Drugs </a>
          <a *ngIf="this.clinicalTrialListCount <= 0 && (fusionVariant.report?.reportType?.name == 'First' || fusionVariant.report.reportType.name == 'First 72 Solid' || fusionVariant.report.reportType.name == 'First 72 Liquid' ||  fusionVariant.report.reportType.name == 'Lung Liquid Basic' || fusionVariant.report.reportType.name == 'Lung Liquid Advanced' )"
                [routerLink]="[ '/fusion_fda_trail_list', fusionVariantId]">No Clinical Trials</a>
              <!-- <span *ngIf="this.clinicalTrialListCount <= 0 && fusionVariant.report.reportType.name == 'First'"
              >No Clinical Trials</span> -->
      </div>
  </div>

  <div class="row mb-5  mt-3 mx-1 p-4  cust_shadow">
      <h5 class="mb-3 text_color ">Fusion Variant detail</h5>
      <div class="w-100"></div>
      <div class="col-3 border p-4">
          <p>Gene</p>
          <h6>{{fusionVariant.fusionGene}}</h6>
      </div>
      <div class="col-3 border p-4">
          <p>Score</p>
          <h6>{{fusionVariant.score}}</h6>
      </div>
      <div class="col-6 border p-4">
          <p>Read Name</p>
          <h6 *ngFor = "let item of readNameList">{{item}}</h6>
      </div>
      <div class="col-3 border p-4">
        <p>Left Break Point</p>
        <h6>{{fusionVariant.leftBreakPoint}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>Rigth Break Point</p>
      <h6>{{fusionVariant.rightBreakPoint}}</h6>
  </div>
  </div>

  <div class="row ml-2 mb-4">
      <div class="col-3">
          <h5 class=" text_color ">*Clinical Siginificance</h5>
          <select class="form-control" name="significance" [(ngModel)]="fusionVariant.significance" (click)=" getGeneDescription()">
              <option value="Benign">Benign</option>
              <option value="Pathogenic">Pathogenic</option>
              <option value="Likely Pathogenic">Likely Pathogenic</option>
              <option value="VUS">VUS</option>
              <option value="Not Classified">Not Classified</option>
          </select>
      </div>
      <div class="col-3">
          <h5 class="text_color ">Reference</h5>
          <input type="text" class="form-control" name="reference" [(ngModel)]="fusionVariant.reference">
      </div>
      <div class="col-2">
        <h5 class="text_color ">Left Exon</h5>
        <input type="text" class="form-control" name="reference" [(ngModel)]="fusionVariant.leftExon">
    </div>
    <div class="col-2">
        <h5 class="text_color ">Rigth Exon</h5>
        <input type="text" class="form-control" name="reference" [(ngModel)]="fusionVariant.rightExon">
    </div>
      <!-- <div class="col-2">
          <h5 class="text_color ">Mutant Alt</h5>
          <input type="text" class="form-control" name="reference" [(ngModel)]="fusionVariant.mutantAlt">
      </div>
      <div class="col-2">
          <h5 class="text_color ">Mutant Ref</h5>
          <input type="text" class="form-control" name="reference" [(ngModel)]="fusionVariant.mutantRef">
      </div> -->
  </div>

  <div class="row ml-2 mb-4" *ngIf="fusionVariant.report?.reportType?.name != 'First' && fusionVariant.report.reportType.name != 'First 72 Solid' &&  fusionVariant.report.reportType.name != 'First 72 Liquid' && fusionVariant.report.reportType.name != 'Lung Liquid Basic' && fusionVariant.report.reportType.name != 'Lung Liquid Advanced' ">
      <div class="col">
          <h5 class=" text_color ">Gene Description ({{gene?.name}})</h5>
         <p> {{gene?.description}}</p>
      </div>
  </div>

  <div class="row ml-2 mb-4" *ngIf="fusionVariant.report?.reportType?.name != 'First' && fusionVariant.report.reportType.name != 'First 72 Solid' && fusionVariant.report.reportType.name != 'First 72 Liquid' && fusionVariant.report.reportType.name != 'Lung Liquid Basic' && fusionVariant.report.reportType.name != 'Lung Liquid Advanced' ">
      <div class="col" >
        <div class="d-flex justify-content-between align-items-center  my-1">
          <h5 class="text_color mb-0 ">Add Gene Description</h5>
          <!-- <textarea type="text" class="form-control"  style="height: 200px;" name="addGeneDescription"
              [(ngModel)]="fusionVariant.addGeneDescription"></textarea> -->
              <div  *ngIf="fusionVariant.addGeneDescription!=null ||fusionVariant.addGeneDescription!=undefined" >
                <P *ngIf="fusionVariant.addGeneDescription.length"><strong>[{{editorText.length}}/2000]</strong></P>
              </div>
        </div>
              <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
               name="addGeneDescription" [(ngModel)]="fusionVariant.addGeneDescription" (keyup)="textcount('addGeneDescription-editor')"></ckeditor>
               <div class="addGeneDescription-editor" hidden="true" [innerHtml]="fusionVariant.addGeneDescription"  ></div>
               <p class="pt-2"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>

      </div>
  </div>
  <div class="row ml-2 mb-4" *ngIf="fusionVariant.report?.reportType?.name == 'First' || fusionVariant.report.reportType.name == 'First 72 Solid' || fusionVariant.report.reportType.name == 'First 72 Liquid' || fusionVariant.report.reportType.name == 'Lung Liquid Basic' || fusionVariant.report.reportType.name == 'Lung Liquid Advanced' ">
    <div class="col" >
        <h5 class="text_color ">Additional Notes</h5>
            <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
             name="addNotes" [(ngModel)]="fusionVariant.addNotes"></ckeditor>

    </div>
</div>
  <div class="form-group row ml-2 mb-4" *ngIf="fusionVariant.report?.reportType?.name != 'First' && fusionVariant.report.reportType.name != 'First 72 Solid' && fusionVariant.report.reportType.name != 'First 72 Liquid' && fusionVariant.report.reportType.name != 'Lung Liquid Basic' && fusionVariant.report.reportType.name != 'Lung Liquid Advanced' ">
    <div class="col">
        <label>PMIDS:</label>
        <input type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
            [(ngModel)]="pmIds">
    </div>
</div>

  <div class="row">
      <div class="col">
          <button type="button" [disabled]="isSubmited" class="btn save_btn" style="float:right"
              (click)="submitDetails()">
              <span *ngIf="!isSubmited"> Save </span>
              <div *ngIf="isSubmited" class="spinner-border text-dark">
                  <span class="sr-only"></span>
              </div>
          </button>
      </div>
  </div>


</div>
