import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddGenePathway, Gene, GenePathwayListRes, pathwayRes } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-gene-pathway',
  templateUrl: './gene-pathway.component.html',
  styleUrls: ['./gene-pathway.component.css']
})
export class GenePathwayComponent implements OnInit {


  add_gene_pathway = {} as AddGenePathway;
  geneList!: Gene[];
  pathwayList!: pathwayRes[];
  statusMsg!: string;
  genePathwayListRes!: GenePathwayListRes[]
  genePathwayObj = {} as GenePathwayListRes;
  

  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getGenePathwayList();
    this.getGenes();
    this.getPathwayList();
  }

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  showFail() {
    this.toastr.error(this.statusMsg,'',
    { timeOut: 3000}
    );
  }

  addGenePathway(){
    console.log(this.add_gene_pathway);
    this.dataAdminService.addGenePathway(this.add_gene_pathway).subscribe(
      (response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error =>{
        this.statusMsg = error.error['message']
        this.showFail();
        this.reload();
      }
    );
  }

  assignGenePathway(genePathway: GenePathwayListRes){
    this.genePathwayObj = genePathway;
    console.log("assign genePathwayObj",this.genePathwayObj);
  }

  deleteGenePathway(){
    console.log("assign genePathway Id",this.genePathwayObj.id);
    this.dataAdminService.deleteGenePathway(this.genePathwayObj.id).subscribe(
      (response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      },
      error =>{
        this.statusMsg = error.error['message']
        this.showFail();
        this.reload();
      }
    );
  }

  getGenePathwayList(){
    this.dataAdminService.getGenePathwayList().subscribe(
      (response: any) => {
        this.genePathwayListRes = response['payLoad'];
      },
      error =>{
        console.log("error =>",error);
      }
    );
  }

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  getPathwayList() {
    this.dataAdminService.getPathwayList().subscribe(
      (response: any) => {
        this.pathwayList = response['payLoad']['list'];
      }
    );
  }

  closeModal(){
    this.add_gene_pathway = {} as AddGenePathway;
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/gene-pathways']);
    });
  }

}
