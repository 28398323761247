<div class="container-fluid px-0">
  <h5 class="mb-0 d-inline-block position-relative  mb-3 mt-4">
    <span *ngIf="!drugId" class="list-icon mr-2"> Add Drug</span>
    <span *ngIf="drugId"class="list-icon mr-2"> Edit Drug</span>
  </h5>
   <div class="mx-0 p-4 cust_shadow rounded bg-white w-75">
    <div class="row">
      <div class="col mr-auto">
        <form action="" class="mt-5">
          <div class="form-group row w-75">
            <label class="col-sm-4 control-label text-right">Name :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="name" [(ngModel)]="add_drug.name">
            </div>
          </div>
          <div class="form-group row mb-0 w-75 mb-3">
            <label class="col-sm-4 control-label text-right">Drug Category :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="drugCategory" [(ngModel)]="add_drug.drugCategory">
            </div>
          </div>
          <div class="form-group row mb-0 w-75 mb-3">
            <label class="col-sm-4 control-label text-right">Reference :</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="reference" [(ngModel)]="add_drug.reference">
            </div>
          </div>
          <div class="form-group row mb-0 w-75">
            <label class="col-sm-4 control-label">Description :</label>
           <div class="col-sm-8">
            <div style="padding-left: 340px;" *ngIf="add_drug.description!=null || add_drug.description!=undefined" >
              <P *ngIf="add_drug.description.length"><strong>[{{editorText.length}}/1200]</strong></P>
            </div>
            <ckeditor [editor]="editor" name="description" [(ngModel)]="add_drug.description" (keyup)="textcount('description-editor')"></ckeditor>
            <div class="description-editor" hidden="true" [innerHtml]="add_drug.description"  ></div>
            <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br/><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
           </div>
          </div>
          <div class="w-75 mr-3">
            <button type="button" [disabled]="addDrugSpinner" class="btn save_btn px-5 ml-auto d-block mt-3 mr-4"
              (click)="addDrug()">
              <span *ngIf="!addDrugSpinner"> Save </span>
              <div *ngIf="addDrugSpinner" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
   </div>

  </div>
