import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FusionService {

  constructor(
    private httpClient: HttpClient
  ) { }


  uploadFusionExcel(params: any,reportId: Number) {
    return this.httpClient.post('/fusion/' + reportId + '/upload_excel_file', params);
  }

  autoUploadFusionFile(reportId: Number) {
    return this.httpClient.post('/fusion/' + reportId + '/auto_upload_fusion_file', null);
  }

  getAllFusionVariantsByReportId(reportId: number){
    return this.httpClient.get('/fusion/' + reportId + '/get_fusion_report_variants');
  }

  selectFusionForReport(fusionVariantId: number, aBoolean: boolean){
    return this.httpClient.post('/fusion/' + fusionVariantId + '/select_fusion' ,aBoolean);
  }

  selectStarFusionForReport(fusionVariantId: number, aBoolean: boolean){
    return this.httpClient.post('/fusion/' + fusionVariantId + '/select_star_fusion' ,aBoolean);
  }

  setPriorityOrder(fusionVariantId: number, priorityOrderNumber: number) {
    return this.httpClient.post('/fusion/' + fusionVariantId + '/priority_order', priorityOrderNumber);
  }

  getFusionVariant(fusionVariantId: number){
    return this.httpClient.get('/fusion/' + fusionVariantId + '/get_fusion_variant');
  }

  addFusionVariantSignificance(fusionVariantId: number, body: any){
    return this.httpClient.post('/fusion/' + fusionVariantId + '/add_significance' ,body);
  }

}
