<div class="container-fluid px-0">
  <!--  -->
  <div class="row  py-3 mx-0">
    <div class="col pl-0">
      <h5 class="mb-0 d-inline-block text_color pt-2">Pub Med Server List</h5>
    </div>
    <div class="col pr-0">
      <div class="text-right ml-auto d-table">
        <div class="add-new">
          <a class="add_post mr-2" data-toggle="modal" data-target="#addGeneModal">Add Pub Med Details <i
              class="zmdi zmdi-plus"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="pubMedServerSpinner " class="row data-spinner-parent">
    <div class="spinner-border data-spinner-child" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
  <!--  -->
  <div *ngIf="!pubMedServerSpinner" class="row reports_info ml-3 reports_info" style="padding-bottom:5px!important">
    <label class="col-9 text-right ">Search: </label>
    <input class="col form-control" type="text" name="search" (ngModelChange)="searchData($event)"
      [(ngModel)]="searchText" autocomplete="off" placeholder="Key" />
  </div>
  <div *ngIf="!pubMedServerSpinner" class="bg-white cust_shadow p-4 rounded">
    <div class="tableFixHead">
      <table class="care_companion table ">
        <thead class="thead-light">
          <tr>
            <th>Sl.No</th>
            <th>PMID</th>
            <th>Research Article</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="pubMedsList.length > 0">
          <tr *ngFor="let pub of pubMedsList | paginate : { id: 'listing_pagination', currentPage: currentPage,itemsPerPage: 10,totalItems: totalPages * 10 };let i=index">
            <td>{{i+1}}</td>
            <td>{{pub.pmId}}</td>
            <td>{{pub.researchArticle}}</td>
            <td>
              <ul class="list-unstyled mb-0 d-flex actions">
                <li><a (click)="assignPubMedDetails(pub)" class="mr-1 delete_clr" data-toggle="modal" data-target="#addGeneModal"> Edit </a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-right mx-3">
        <pagination-controls id="listing_pagination" (pageChange)="changePage($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
  <!-- add/edit modal -->
  <div class="modal fade" id="addGeneModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content surgery-modal">
        <div class="modal-header px-4 border-bottom-0 bg-light">
          <h5 class="modal-title text-softblue">Add Pub Med Details</h5>
          <a class="text-softblue" data-dismiss="modal" (click)="closeModal()">
            <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
          </a>
        </div>
        <div class="modal-body p-4">
          <div class="form-group row">
            <label for="" class="col-sm-2 col-form-label">PMID :</label>
            <div class="col-sm-8 position-relative mb-1 pr-0">
              <input type="text" class="form-control" name="startPosition" [(ngModel)]="addPubMedServerDetails.pmId">
            </div>
          </div>
          <div class="form-group row">
            <label for="" class="col-sm-2 col-form-label">Research Article :</label>
            <div class="col-sm-8 position-relative mb-1 pr-0">
              <textarea type="text" class="form-control" cols="20" rows="10" name="evidenceSummary"
                [(ngModel)]="addPubMedServerDetails.researchArticle"> </textarea>
            </div>
          </div>
          <button type="button" class="btn text-uppercase save_btn" style="float:right" data-toggle="modal"
            data-target="#confirmationModal" data-dismiss="modal"> Save </button>
        </div>
      </div>
    </div>
  </div>
  <!-- The confirmation Modal -->
  <div class="modal fade" id="confirmationModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content surgery-modal">
        <div class="modal-header px-4 border-bottom-0 bg-light">
          <h5 class="modal-title text-softblue">add Pub Med</h5>
          <a (click)="closeModal()" class="text-softblue" data-dismiss="modal">
            <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
          </a>
        </div>
        <div class="modal-body p-4">
          <h6 class=" mb-4">Are you sure want to Add ?</h6>
          <div class="mx-0 ml-auto d-table">
            <button type="button" class="btn bg-softblue text-white px-5 mr-2" data-dismiss="modal"
              (click)="addPubMedDetail()">Yes</button>
            <button type="button" class="btn bg-softblue text-white px-5" data-dismiss="modal"
              (click)="closeModal()">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
