import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PrognasticBiomarkerReqDto, PrognasticBiomarkerRes } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-prognastic-biomarker',
  templateUrl: './prognastic-biomarker.component.html',
  styleUrls: ['./prognastic-biomarker.component.css']
})
export class PrognasticBiomarkerComponent implements OnInit {
  prognasticListSpinner:boolean=false
  prognasticList:PrognasticBiomarkerRes[]=[]
  prognastic = {} as PrognasticBiomarkerRes;
  statusMsg:string=''
  prognasticId!: number;
  constructor(  private dataAdminService: DataAdminService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAllPrognasticBiomarker()
  }

  getAllPrognasticBiomarker(){
    this.dataAdminService.prognasticBiomarkerList().subscribe((res:any)=>{
      this.prognasticList=res['payLoad']
    })
  }

  deletePrognastic(){
    this.dataAdminService.deletePrognasticBiomarker(this.prognasticId).subscribe((response:any)=>{
      this.statusMsg = response['message'];
      this.showSuccess();
      this.reload();
    })
  }

  assignPrognasticBiomarker(id: number) {
    this.prognasticId = id;
    console.log("prognasticId",this.prognasticId);
  }
  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  prognasticBiomarkerDetails(data:PrognasticBiomarkerRes){
    this.prognastic=data
  }
  closeModal(){
    this.prognastic = {} as PrognasticBiomarkerRes;
  }
  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/prognasticbiomarker']);
    });
  }
}
