<div class="container-fluid px-0">
    <!--  -->
    <div class="row  py-3  mx-0">
      <div class="col pl-0">
        <h5 class="mb-0 d-inline-block  pt-2 text_color">Cancer Site Genes List</h5>
      </div>
      <div class="col pr-0">
        <div class="text-right ml-auto d-table">
          <div class="add-new">
            <a class="add_post mr-2" [routerLink]="[ '/super_admin/add-cancer_site_gene']">Add Cancer Site Genes<i class="zmdi zmdi-plus"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div *ngIf="cancerListSpinner" class="row data-spinner-parent">
      <div class="spinner-border data-spinner-child" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  <div *ngIf="!cancerListSpinner"  class="bg-white cust_shadow  p-4 rounded">
    <div class="tableFixHead">
      <table class="care_companion table ">
        <thead class="thead-light">
          <tr>
            <th>Sl.No</th>
            <th>Cancer Site</th>
            <th>Gene</th>
            <th>Actions</th>
          </tr>
  
        </thead>
        <tbody>
          <tr *ngFor="let cancerSitegene of cancerSiteGeneList; let i = index">
            <td>{{i+1}}</td>
            <td>{{cancerSitegene.cancerSite.name}}</td>
            <td><span *ngFor="let gene of cancerSitegene.genes;let i=index">
              {{gene.name}}<span *ngIf="cancerSitegene.genes.length>1 && i<cancerSitegene.genes.length-1">,</span>
              </span>
            </td>
            <td>
              <ul class="list-unstyled mb-0 d-flex actions">
                <li><a [routerLink]="[ '/super_admin/edit-cancer_site_gene',cancerSitegene.id]" class="mr-1 delete_clr" data-toggle="modal" data-target="#addCancerSiteModal"> Edit </a></li>
                <li><a (click)="assignCancerSiteGene(cancerSitegene.id)" class="delete_clr" data-toggle="modal" data-target="#deleteCancerSiteGeneModal">| Delete</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
   
  </div>
  <!-- The delete Modal -->
  <div class="modal fade" id="deleteCancerSiteGeneModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content surgery-modal">

        <div class="modal-header px-4 border-bottom-0 bg-light">
          <h5 class="modal-title text-softblue">Delete</h5>
          <a (click)="closeModal()" class="text-softblue" data-dismiss="modal">
              <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
          </a>
        </div>

        <div class="modal-body p-4">
                <h6 class=" mb-4">Are you sure want to delete ?</h6>
              <div class="mx-0 ml-auto d-table">
                  <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                  data-dismiss="modal" (click)="deleteCancerSiteGene()">Yes</button>
                  <button type="button" class="btn bg-softblue text-white px-5"
                  data-dismiss="modal" (click)="closeModal()">No</button>
              </div>
        </div>

      </div>
    </div>
  </div>
</div>