<div class="container-fluid px-0">
  <h5 *ngIf="!genepatternid" class="mb-0 d-inline-block position-relative  mb-3 mt-4">Add Test Type Gene </h5>
  <h5 *ngIf="genepatternid" class="mb-0 d-inline-block position-relative mb-3 mt-4">Edit Test Type Gene</h5>
  <div class="mx-0 p-4 bg-white cust_shadow rounded ">
    <div class="row mx-3">
      <div class="col">
        <form class="form_list" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-6 form-group row">
              <label class="col-sm-5 control-label">Sample Type :</label>
              <div class="col-sm-7">
                <ng-multiselect-dropdown [settings]="sampleDropdownSettings" [data]="sampleList"
                  (ngModelChange)="filterTests($event)" [(ngModel)]="sampleTest" name="sampleType">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="col-6 form-group row">
              <label class="col-sm-5 control-label">Test Name:</label>
              <div class="col-sm-7">
                <ng-multiselect-dropdown [settings]="subTypeDropdownSettings" [data]="subTypeList" [(ngModel)]="subType"
                  name="testNames">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="col-6 form-group row">
              <label class="col-sm-5 control-label">Gene:</label>
              <div class="col-sm-7">
                <ng-multiselect-dropdown [settings]="geneDropdownSettings" [data]="geneList" [(ngModel)]="gene"
                  name="gene">
                </ng-multiselect-dropdown>

              </div>
            </div>
            <div class="col-6 form-group row">
              <!-- <form id="file-upload-form" class="uploader">
                <input id="file-upload" type="file" name="fileUpload" accept=".xlsx" (change)="onFileChange($event)"
                  [disabled]="uploadFileInput">

                <label for="file-upload" id="file-drag">

                  <div id="response" class="hidden">
                    <div id="messages"></div>

                  </div>
                </label>
              </form> -->

              <input type="file" (change)="onFileChanges($event)" multiple="false" />

            </div>

            <div class="col-12 form-group row" style="padding-left: 45px;">
              <label class="col-sm-2 control-label">Other Names :</label>
              <div class="col-sm-10">
                <!-- <textarea type="text" class="form-control" cols="30" rows="10" maxlength="3000" name="disclaimerDetails"
                          [(ngModel)]="disclaimer.disclaimerDetails"> </textarea> -->
                <ckeditor [editor]="editor" name="otherName" [(ngModel)]="genepattern.otherName"></ckeditor>
              </div>
            </div>
            <div class="col-12 add-emp mr-10 mb-5">
              <div class="col-sm-8 offset-sm-4 px-0 text-right">
                <button type="submit" class="btn save_btn text-uppercase bg-softblue ml-2" [disabled]="submitSpinner">
                  <!-- <span class="delete_clr"  data-toggle="modal" data-target="#deleteDrugModal"> Submit </span> -->
                  <span *ngIf="!submitSpinner"> Submit </span>
                  <div *ngIf="submitSpinner" class="spinner-border text-dark">
                    <span class="sr-only"></span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
