import { HrrSomaticReportComponent } from './hrr-somatic-report/hrr-somatic-report.component';
import { FamilyTestingReportComponent } from './family-testing-report/family-testing-report.component';
import { GermlineReportComponent } from './germline-report/germline-report.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_services/auth.guard';
import { CnaExcelComponent } from './cna-excel/cna-excel.component';
import { DashReportsComponent } from './dash-reports/dash-reports.component';
import { FdaClinicalTrailsComponent } from './fda-clinical-trails/fda-clinical-trails.component';
import { PagesComponent } from './pages.component';
import { PatientDocumentsComponent } from './patient-documents/patient-documents.component';
import { PatientComponent } from './patient/patient.component';
import { PdfContentComponent } from './pdf-content/pdf-content.component';
import { ReadExcelComponent } from './read-excel/read-excel.component';
import { ReportsComponent } from './reports/reports.component';
import { TargetFirstReportComponent } from './target-first-report/target-first-report.component';
import { TrailsListComponent } from './trails-list/trails-list.component';
import { ViewCnaComponent } from './view-cna/view-cna.component';
import { ViewFusionComponent } from './view-fusion/view-fusion.component';
import { ViewReportVariantComponent } from './view-report-variant/view-report-variant.component';
import { HrrGermlineReportComponent } from './hrr-germline-report/hrr-germline-report.component';
import { CoreReportComponent } from './core-report/core-report.component';
import { DemoForpdfComponent } from './demo-forpdf/demo-forpdf.component';
import { First72ReportComponent } from './first72-report/first72-report.component';
import { First72LiquidComponent } from './first72-liquid/first72-liquid.component';
import { LungLiquidBasicReportComponent } from './lung-liquid-basic-report/lung-liquid-basic-report.component';
import { LungLiquidAdvancedReportComponent } from './lung-liquid-advanced-report/lung-liquid-advanced-report.component';
import { BrcaReportComponent } from './brca-report/brca-report.component';
import { First72Solid4bcComponent } from './first72SolidReports/first72-solid4bc/first72-solid4bc.component';
import { First72Liquid4bcComponent } from './first72LiquidReports/first72-liquid4bc/first72-liquid4bc.component';
import { IndiegeneComponent } from './IndiegeneReports/indiegene-dashboard/indiegene/indiegene.component';
import { ViewHotspotVariantComponent } from './view-hotspot-variant/view-hotspot-variant.component';
import { Pdl1DashboardComponent } from './pdl1Report/pdl1-dashboard/pdl1-dashboard.component';
import { IndiegeneDashboardComponent } from './IndiegeneReports/indiegene-dashboard/indiegene-dashboard.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: PagesComponent,
    children: [
      {
        path: '',
        component: DashReportsComponent,
      },
      {
        path: 'reports',
        component: DashReportsComponent,
      },
      {
        path: 'reports/:organisationName',
        component: DashReportsComponent,
      },
      {
        path: 'edit_patient/:id',
        component: PatientComponent,
      },
      {
        path: 'read_excel/:id',
        component: ReadExcelComponent,
      },
      {
        path: 'cna_excel/:id',
        component: CnaExcelComponent,
      },
      {
        path: 'trail_list/:id',
        component: TrailsListComponent,
      },
      {
        path: 'fda_trail_list/:id',
        component: FdaClinicalTrailsComponent,
      },
      {
        path: 'dash-reports/:id',
        component: ReportsComponent,
      },
      {
        path: 'dash-reports/PDL1/:id',
        component: Pdl1DashboardComponent,
      },
      {
        path: 'dash-reports/Indiegene/:id',
        component: IndiegeneDashboardComponent,
      },
      {
        path: 'report_pdf',
        component: PdfContentComponent,
      },
      {
        path: 'view_variant',
        component: ViewReportVariantComponent,
      },
      {
        path: 'report/:rid/view_variant/:id',
        component: ViewReportVariantComponent,
      },
      {
        path: 'report/:rid/cna_variant/:cid',
        component: ViewCnaComponent,
      },
      {
        path: 'cna_trail_list/:cid',
        component: TrailsListComponent,
      },
      {
        path: 'cna_fda_trail_list/:cid',
        component: FdaClinicalTrailsComponent,
      },
      {
        path: 'hotspot_fda_trail_list/:hid',
        component: FdaClinicalTrailsComponent,
      },
      {
        path: 'report/:rid/fusion_variant/:fid',
        component: ViewFusionComponent,
      },
      {
        path: 'report/:rid/hotspot_variant/:fid',
        component: ViewHotspotVariantComponent,
      },
      {
        path: 'fusion_trail_list/:fid',
        component: TrailsListComponent,
      },
      {
        path: 'fusion_fda_trail_list/:fid',
        component: FdaClinicalTrailsComponent,
      },
      {
        path: 'patient-documents/:ecrfId',
        component: PatientDocumentsComponent,
      },
    ],
  },
  {
    path: 'demo',
    canActivate: [AuthGuard],
    component: DemoForpdfComponent,
  },
  {
    path: 'view_report/:id',
    canActivate: [AuthGuard],
    component: PdfContentComponent,
  },
  {
    path: 'view_report_indiegene/:id',
    component: IndiegeneComponent,
  },
  {
    path: 'first_view_report/:id',
    canActivate: [AuthGuard],
    component: TargetFirstReportComponent,
  },

  {
    path: 'first72_solid_4bc_view_report/:id',
    canActivate: [AuthGuard],
    component: First72Solid4bcComponent,
  },

  {
    path: 'first72_liquid_4bc_view_report/:id',
    canActivate: [AuthGuard],
    component: First72Liquid4bcComponent,
  },

  {
    path: 'first72_view_report/:id/:temp',
    canActivate: [AuthGuard],
    component: First72ReportComponent,
  },
  {
    path: 'first72_view_report/:id',
    canActivate: [AuthGuard],
    component: First72ReportComponent,
  },
  {
    path: 'brca1and2_view_report/:id',
    canActivate: [AuthGuard],
    component: BrcaReportComponent,
  },
  {
    path: 'first72_liquid_view_report/:id/:temp',
    canActivate: [AuthGuard],
    component: First72LiquidComponent,
  },
  {
    path: 'first72_liquid_view_report/:id',
    canActivate: [AuthGuard],
    component: First72LiquidComponent,
  },
  {
    path: 'lung_liquid_basic_report/:id',
    canActivate: [AuthGuard],
    component: LungLiquidBasicReportComponent,
  },
  {
    path: 'lung_liquid_advanced_report/:id',
    canActivate: [AuthGuard],
    component: LungLiquidAdvancedReportComponent,
  },
  {
    path: 'core_report/:id',
    canActivate: [AuthGuard],
    component: CoreReportComponent,
  },
  {
    path: 'germline_report/:id',
    canActivate: [AuthGuard],
    component: GermlineReportComponent,
  },
  {
    path: 'hrr_germline_report/:id',
    canActivate: [AuthGuard],
    component: HrrGermlineReportComponent,
  },
  {
    path: 'family_report/:id',
    component: FamilyTestingReportComponent,
  },
  {
    path: 'hrr_somatic/:id',
    component: HrrSomaticReportComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
