import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CancerSite } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-cancer-site',
  templateUrl: './cancer-site.component.html',
  styleUrls: ['./cancer-site.component.css']
})
export class CancerSiteComponent implements OnInit {

  add_cancerSite = {} as CancerSite;
  cancerSiteList: CancerSite[] | undefined;
  cancerListSpinner:boolean=true
  statusMsg!: string;

  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  ngOnInit(): void {
    this.getCancerSites();
  }

  addCancerSite(){
    console.log(this.add_cancerSite);
    this.dataAdminService.addCancerSite(this.add_cancerSite).subscribe(
      (response: any) => {
        console.log("response add",response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  deleteCancerSite(){
    console.log(this.add_cancerSite);
    this.dataAdminService.deleteCancerSite(this.add_cancerSite.id).subscribe(
      (response: any) => {
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  assignCancerSite(cancerType: CancerSite){
    this.add_cancerSite = cancerType;
    console.log("edit cancer type",this.add_cancerSite);
  }

  getCancerSites(){
    this.dataAdminService.getCancerSiteList().subscribe(
      (response: any) => {
        this.cancerListSpinner=false
        this.cancerSiteList = response['payLoad'];
        console.log("cancer type list",this.cancerSiteList);
      }
    );
  }

  closeModal(){
    console.log("close modal")
    this.add_cancerSite = {} as  CancerSite;
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/cancer_site']);
    });
  }

}
