<div class="container-fluid px-0">
    <div class="row  py-3 px-3 mx-0">
        <div class="col">
            <h5 class="mb-0 d-inline-block  text_color pt-2">Gene-Pathway List</h5>
        </div>
        <div class="col pl-0">
            <div class="text-right ml-auto d-table">
                <div class="add-new">
                    <a class="add_post mr-2" data-toggle="modal" data-target="#addGenePathwayModal">Add Gene-Pathway <i
                            class="zmdi zmdi-plus"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-white cust_shadow mx-4 p-4 rounded">
    <table class="care_companion table ">
        <thead class="thead-light">
            <tr>
                <th>Sl.No</th>
                <th>Gene</th>
                <th>Pathway</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let genePathway of genePathwayListRes; let i = index">
                <td>{{i+1}}</td>
                <td>{{genePathway.gene.name}}</td>
                <td>{{genePathway.pathway.name}}</td>
                <td>
                    <ul class="list-unstyled mb-0 d-flex actions">
                        <!-- <li><a class="delete_clr">Edit |</a></li> -->
                        <li><a (click)="assignGenePathway(genePathway)" data-toggle="modal" data-target="#deleteGenePathwayModal"
                            class="delete_clr ml-1">Delete</a></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
    </div>


    <!-- add/edit modal -->
    <div class="modal fade" id="addGenePathwayModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content surgery-modal">

                <div class="modal-header px-4 border-bottom-0 bg-light">
                    <h5 class="modal-title text-softblue">Add Cancer Type</h5>
                    <a class="text-softblue" data-dismiss="modal" (click)="closeModal()">
                        <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
                    </a>
                </div>

                <div class="modal-body p-4">
                    <div class="form-group row">
                        <label class="col-sm-4 control-label">Gene :</label>
                        <div class="col-sm-8">
                            <select type="text" class="form-control" name="name" [(ngModel)]="add_gene_pathway.geneId">
                                <option *ngFor="let gene of geneList" value={{gene.id}}>{{gene.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 control-label">Pathway :</label>
                        <div class="col-sm-8">
                            <select type="text" class="form-control" name="name" [(ngModel)]="add_gene_pathway.pathwayId">
                                <option *ngFor="let pathway of pathwayList" value={{pathway.pathwayId}}>{{pathway.pathwayName}}</option>
                            </select>
                        </div>
                    </div>

                    <button type="button" class="btn btn-primary text-uppercase bg-softblue" style="float:right"
                        (click)="addGenePathway()" data-dismiss="modal"> Save </button>
                </div>

            </div>
        </div>
    </div>

    <!-- The delete Modal -->
    <div class="modal fade" id="deleteGenePathwayModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content surgery-modal">

            <div class="modal-header px-4 border-bottom-0 bg-light">
              <h5 class="modal-title text-softblue">Delete</h5>
              <a (click)="closeModal()" class="text-softblue" data-dismiss="modal">
                  <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
              </a>
            </div>

            <div class="modal-body p-4">
                    <h6 class=" mb-4">Are you sure want to delete ?</h6>
                  <div class="mx-0 ml-auto d-table">
                      <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                      data-dismiss="modal" (click)="deleteGenePathway()">Yes</button>
                      <button type="button" class="btn bg-softblue text-white px-5"
                      data-dismiss="modal" (click)="closeModal()">No</button>
                  </div>
            </div>

          </div>
        </div>
      </div>

</div>
