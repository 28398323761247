import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gene, TestTypeGene } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-test-type-gene',
  templateUrl: './test-type-gene.component.html',
  styleUrls: ['./test-type-gene.component.css'],
})
export class TestTypeGeneComponent implements OnInit {
  genePatternList: any = [];
  genePatternId!: number;
  statusMsg!: string;
  genePatternListSpinner: boolean = true;
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllGenePattern();
  }
  genePatternListBackup: any;
  getAllGenePattern() {
    this.dataAdminService.geneTestTypeGeneList().subscribe((res: any) => {
      this.genePatternList = res['payLoad'];
      this.genePatternListBackup = this.genePatternList;
      console.log('this.genePatternList', res);
      this.genePatternListSpinner = false;
    });
  }

  setGenePattern(genepattern: any) {
    this.genePatternId = genepattern['id'];
  }
  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }

  deleteGenePattern() {
    this.dataAdminService
      .deleteTestTypeGene(this.genePatternId)
      .subscribe((response: any) => {
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  getToString(data: any): string {
    if (data) {
      return data.toString();
    }
    return '';
  }
  filterByType(item: any) {
    let value = item.target.value;
    if (!(value == 'All')) {
      this.genePatternList = this.genePatternListBackup.filter(
        (element: any) => {
          return element.sampleType == value;
        }
      );
    } else {
      this.getAllGenePattern();
    }
  }
  genepattern: any;
  geneList!: Gene[];
  getGenePatternById(id: any) {
    this.dataAdminService.getTestTypeGeneById(id).subscribe((res: any) => {
      console.log('Res==>', res);
      this.genepattern = res['payLoad'];
      this.geneList = res['payLoad']['gene'];
      console.log(' this.geneList', this.geneList);
    });
  }

  reload() {
    console.log('reload');
    this.router
      .navigateByUrl('/reports', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/super_admin/testtypegene']);
      });
  }
}
