<h3 class="ml-3">Documents</h3>

<div class="row">
    <div class="col">
        <button (click)="fileInput.click()" type="button" class="btn bg-softblue text-white ml-3">
            <input style="display: none" type="file" #fileInput (change)="onFileChange($event)" /> Upload Final Report
        </button>
    </div>
    <div class="col">
        <select class="form-control" name="reportStatus" [(ngModel)]="reportStatus"
            (change)="selectedReport(reportStatus)">
            <option value="" disabled selected>select report status</option>
            <option value="failed">Failed</option>
            <option value="success">Success</option>
        </select>
    </div>
    <div class="col">
        <button type="button" class="btn btn-primary" (click)="submitFinalReport()"
            [disabled]="!submitReport">Submit</button>
    </div>
</div>

<div class="mt-3 mb-2 ml-2 mr-2">
    <label>Cancer Indentifier:</label>
    <p>{{cancerIdentifier}}</p>
</div>

<table class="table">
    <thead>
        <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Status</th>
            <th>Uploaded On</th>
            <th>Actions</th>
            <th>Comments</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let document of documentsList">
            <td>{{document.title}}
                <span *ngIf="document.title == null">NA</span>
            </td>
            <td>
                {{document.type}}
                <span *ngIf="document.type == null">NA</span>
            </td>
            <td>
                {{document.status}}
            </td>
            <td>
                {{document.uploadedOn}}
                <span *ngIf="document.uploadedOn == null">NA</span>
            </td>
            <td>
                <div class="d-flex gap-3">
                    <a *ngIf="document.status == 'Uploaded'" class="text-primary"
                        (click)="downloadDocument(document.documentUrl,document.title)"><i class="zmdi zmdi-download"></i></a>
                </div>
            </td>
            <td><a href="#" data-toggle="modal" data-target="#comments" (click)="viewComment(document)">View Comments</a></td>
        </tr>
    </tbody>
</table>

<div class="modal fade" id="comments" #closeModal>
    <div class="modal-dialog modal-dialog-centered" style="max-width: 500px">
      <div class="modal-content surgery-modal">
        <div class="modal-header px-4 border-bottom-0 bg-light">
          <h5 class="modal-title text-softblue">{{patientDocument.type}} File Comments</h5>
          <a class="text-softblue" data-dismiss="modal">
            <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
          </a>
        </div>
        <div class="modal-body">
            <p>{{patientDocument.comments}}</p>
          </div>
      </div>
    </div>
  </div>