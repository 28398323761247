import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InterpretationListRes } from 'src/app/_interfaces.ts/admin';
import { ToastrService } from 'ngx-toastr';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-interpretations',
  templateUrl: './interpretations.component.html',
  styleUrls: ['./interpretations.component.css']
})
export class InterpretationsComponent implements OnInit {

  interpretationListRes: InterpretationListRes[] = [];
  interpretationListBackup: InterpretationListRes[] = [];
  interpretation = {} as InterpretationListRes;
  interpretationsListSpinner:boolean=true
  typeList:any=['SOMATIC','GERMLINE']
  constructor(
    private dataAdminService: DataAdminService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.interpretationList();
  }

  InterpretationId!: number;
  statusMsg!: string;
  interpretationList(){
    this.dataAdminService.interpretationList().subscribe(
      (response: any) => {
        this.interpretationsListSpinner=false
        this.interpretationListRes = response['payLoad'];
        this.interpretationListBackup=response['payLoad'];
        console.log("interpretationList",this.interpretationListRes);
      }
    );
  }
  assignDrug(id: number) {
    this.InterpretationId = id;
    console.log("InterpretationId",this.InterpretationId);
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  interpretationDetails(interpretation: InterpretationListRes){
    console.log(interpretation);
    this.interpretation = interpretation;
  }

  closeModal(){
    this.interpretation = {} as InterpretationListRes;
  }
  filterByType(item:any){
    let value=item.target.value
    if(!(value=='All')){
      this.interpretationListRes=this.interpretationListBackup.filter((element:any)=>{
        return element.typeOfTest==value
      })
    }else{
     this.interpretationList()
    }
    
  }

  deleteInterpretation(){
       console.log("InterpretationId",this.InterpretationId);
    this.dataAdminService.deleteInterpretation(this.InterpretationId).subscribe(
      (response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );
  }
  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/interpretations']);
    });
  }
}
