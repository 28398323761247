import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { Drug } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-add-drug',
  templateUrl: './add-drug.component.html',
  styleUrls: ['./add-drug.component.css']
})
export class AddDrugComponent implements OnInit {

  sup="A{b} → Superscript";
  sub="A~b! → Subscript";

  editor = ClassicEditor;
  add_drug = {} as Drug;
  statusMsg!: string;
  editorText:string=''
  drugId!: number;

  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }


  ngOnInit(): void {

    this.add_drug.description = "";
    this.drugId = +this.routerAct.snapshot.params['id'];

    if (this.drugId) {
      this.getDrug();
    }

  }

  textcount(classname:any){
    var dom=document.getElementsByClassName(classname);
      var data:any=dom[0]
      this.editorText= data.outerText
  }

  addDrugSpinner = false;
  addDrug() {
    this.addDrugSpinner = true;
    console.log("add Drug", this.add_drug);
    this.dataAdminService.addDrug(this.add_drug).subscribe(
      (response: any) => {
        console.log("add pathway res", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  getDrug() {
    this.dataAdminService.getDrugById(this.drugId).subscribe(
      (response: any) => {
        console.log(response);
        this.add_drug = response['payLoad'];
      }
    );
  }


  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/drugs']);
    });
  }


}
