
<div class="container-fluid px-0">
  <!--  -->
  <div class="row  py-3 mx-0">
    <div class="col pl-0">
      <h5 class="mb-0 d-inline-block text_color pt-2">Truncation Details List</h5>
    </div>

    <div class="col pr-0">
      <div class="text-right ml-auto d-table">
        <div class="add-new">
          <a class="add_post mr-2" data-toggle="modal" data-target="#addGeneModal">Add Truncation Details <i class="zmdi zmdi-plus"></i></a>

        </div>
      </div>

    </div>
  </div>
  <div class=" justify-content-end d-flex mb-5t" style="padding: 5px!important;">
    <button (click)="fileInput.click()" type="button" class="btn btn-primary w-md">
      <input style="display: none" type="file"
        (change)="onFileChanges($event)" #fileInput />
      Upload file
    </button>
  </div>
  <div *ngIf="truncationDetailsSpinner " class="row data-spinner-parent">
    <div class="spinner-border data-spinner-child" role="status">
        <span class="visually-hidden"></span>
    </div>
</div>
  <!--  -->
  <div *ngIf="!truncationDetailsSpinner" class="bg-white cust_shadow p-4 rounded">
    <div class="tableFixHead">
      <table class="care_companion table ">
        <thead class="thead-light">
          <tr>
            <th>Sl.No</th>
            <th>Gene Name</th>
            <th>Actions</th>
          </tr>

        </thead>
        <tbody>
          <tr *ngFor="let truncationDetails of truncationDetailsList; let i = index">
            <td>{{i+1}}</td>
            <td>{{truncationDetails.gene}}</td>
            <td>
              <ul class="list-unstyled mb-0 d-flex actions">
                <li><a (click)="assignTruncationDetails(truncationDetails)" class="mr-1 delete_clr" data-toggle="modal" data-target="#addGeneModal"> Edit </a></li>
                <li><a (click)="assignTruncationDetails(truncationDetails)" class="delete_clr" data-toggle="modal" data-target="#deleteGeneModal">| Delete</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

</div>

  <!-- add/edit modal -->
  <div class="modal fade" id="addGeneModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content surgery-modal">

          <div class="modal-header px-4 border-bottom-0 bg-light">
            <h5 class="modal-title text-softblue">Add Truncation Details</h5>
            <a class="text-softblue" data-dismiss="modal" (click)="closeModal()">
              <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
            </a>
          </div>

          <div class="modal-body p-4">




            <div class="form-group row">
              <label for="" class="col-sm-2 col-form-label">Gene :</label>
                              <div class="col-sm-8 position-relative mb-1 pr-0">
                                <select type="text" class="form-control" name="gene" [(ngModel)]="addTruncationDetails.gene">
                                  <option *ngFor="let gene of geneList" value="{{ gene.name }}">
                                    {{ gene.name }}
                                  </option>
                                </select>
                              </div>

              </div>

            <div class="form-group row">
              <label for="" class="col-sm-2 col-form-label">Start Position :</label>
              <div class="col-sm-8 position-relative mb-1 pr-0">
                <input type="text" class="form-control" name="startPosition" [(ngModel)]="addTruncationDetails.startPosition">
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-2 col-form-label">End Posirion :</label>
              <div class="col-sm-8 position-relative mb-1 pr-0">
                <input type="text" class="form-control" name="endPosition" [(ngModel)]="addTruncationDetails.endPosition">
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-2 col-form-label">Transcript Id</label>
              <div class="col-sm-8 position-relative mb-1 pr-0">
                <input type="text" class="form-control" name="transcriptId" [(ngModel)]="addTruncationDetails.transcriptId">
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-2 col-form-label">Chr :</label>
              <div class="col-sm-8 position-relative mb-1 pr-0">
                <input type="text" class="form-control" name="chr" [(ngModel)]="addTruncationDetails.chr">
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-sm-2 col-form-label">P.Position :</label>
              <div class="col-sm-8 position-relative mb-1 pr-0">
                <input type="text" class="form-control" name="pposition" [(ngModel)]="addTruncationDetails.pposition">
              </div>
            </div>


            <button type="button" class="btn text-uppercase save_btn" style="float:right"
            (click)="addTruncationDetail()" data-dismiss="modal"> Save </button>
          </div>

        </div>
      </div>
    </div>

     <!-- The delete Modal -->
     <div class="modal fade" id="deleteGeneModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content surgery-modal">

          <div class="modal-header px-4 border-bottom-0 bg-light">
            <h5 class="modal-title text-softblue">Delete</h5>
            <a (click)="closeModal()" class="text-softblue" data-dismiss="modal">
                <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
            </a>
          </div>

          <div class="modal-body p-4">
                  <h6 class=" mb-4">Are you sure want to delete ?</h6>
                <div class="mx-0 ml-auto d-table">
                    <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                    data-dismiss="modal" (click)="deleteTruncationDetails()">Yes</button>
                    <button type="button" class="btn bg-softblue text-white px-5"
                    data-dismiss="modal" (click)="closeModal()">No</button>
                </div>
          </div>

        </div>
      </div>
    </div>

</div>
