import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { DisclaimerVariant } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-add-disclaimer',
  templateUrl: './add-disclaimer.component.html',
  styleUrls: ['./add-disclaimer.component.css']
})
export class AddDisclaimerComponent implements OnInit {

  editor = ClassicEditorBuild; 
  disclaimer={} as DisclaimerVariant;
  submitSpinner:boolean=false;
  statusMsg=""
  disclaimerId!:number

  constructor(private dataAdminService:DataAdminService, private toastr: ToastrService,private router: Router,
    private routerAct: ActivatedRoute) { }
  
  ngOnInit(): void {
    this.disclaimerId = +this.routerAct.snapshot.params['id'];
    if(this.disclaimerId){
      this.getDisclaimerById()
    }

  }

  getDisclaimerById(){
    this.dataAdminService.getDisclaimerById(this.disclaimerId).subscribe((res:any)=>{
      this.disclaimer=res['payLoad']
      console.log("resss",this.disclaimer.testType)
    })
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  showError(msg:any) {
    this.toastr.error(msg, '',
      { timeOut: 2000 }
    );
  }

  onSubmit(){

    if(this.disclaimerId){
      this.disclaimer.id=this.disclaimerId
      if( this.disclaimer.disclaimerTag!=null &&  this.disclaimer.disclaimerTag!='undefined'){
        this.dataAdminService.addDisclaimer(this.disclaimer).subscribe((res:any)=>{
          this.statusMsg=res["message"]
          this.showSuccess()
          this.reload()
        })
      }else{
        this.showError("Please Enter Disclaimer Tag")
      }

    }else{
      if( this.disclaimer.disclaimerTag!=null &&  this.disclaimer.disclaimerTag!='undefined'){
        this.dataAdminService.addDisclaimer(this.disclaimer).subscribe((res:any)=>{
          this.statusMsg=res["message"]
          this.showSuccess()
          this.reload()
        })
      }else{
        this.showError("Please Enter Disclaimer Tag")
      }
    }

  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/disclaimer']);
    });
  }


}
