import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.css']
})
export class AutoLoginComponent implements OnInit {

  model: any = {};
  organisationName!: string;
  reportId!: number;
  email!: string;

  constructor(
    private loginServ: LoginService,
    private router: Router,
    private toastr: ToastrService,
    private routerAct: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.email = this.routerAct.snapshot.params['email'];
    this.organisationName = this.routerAct.snapshot.params['organisationName'];
    this.reportId = this.routerAct.snapshot.params['reportId'];
    console.log(this.email);


    if ((this.email == 'lab-aditya@mailinator.com')) {
      this.model.email = 'lab-aditya@mailinator.com';
      this.model.password = '123';
    } else if ((this.email == 'lab-tmh@mailinator.com')) {
      this.model.email = 'lab-tmh@mailinator.com';
      this.model.password = '123';
    } else {
      this.model.email = 'integration@mailinator.com';
      this.model.password = '123';
    }
    this.submitLogin();
  }

  submitLogin() {
    console.log("auto login",this.model);

    this.loginServ.login(this.model).subscribe(
      (data: any) => {
        console.log("auto login",data);
        localStorage.setItem('token', JSON.stringify(data['payLoad']['authToken']));
        localStorage.setItem('user', JSON.stringify(data['payLoad']));
        localStorage.setItem('organisationName', this.organisationName);
        if(this.reportId){
          this.router.navigate(['/dash-reports',this.reportId]);
        } else{
        this.router.navigate(['/reports',this.organisationName]);
        }
      });
  }

}
