import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gene, GeneVariantReq, PrognasticBiomarkerReqDto } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-add-prognastic-biomarker',
  templateUrl: './add-prognastic-biomarker.component.html',
  styleUrls: ['./add-prognastic-biomarker.component.css']
})
export class AddPrognasticBiomarkerComponent implements OnInit {
  editor = ClassicEditor;
 add_prognastic= {} as PrognasticBiomarkerReqDto
 geneList!: Gene[];
 prognasticId!:number;
  selectedGene: Gene[] = [];
  addGeneVarinats!: {
    addGeneVarinat: GeneVariantReq[];
  };
  editorText:string=''
  statusMsg!: string;
  submitSpinner:boolean=false
  constructor(private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute) { }

  ngOnInit(): void {
    this.addGeneVarinats = {
      addGeneVarinat: [],
    };
    this.add_prognastic.description = "";
    this.prognasticId = +this.routerAct.snapshot.params['id'];
    if(this.prognasticId){
      this.getPrognasticById()
    }
    this.getGenes();
  }

  getPrognasticById(){
    this.dataAdminService.getPrognasticBiomarkerById(this.prognasticId).subscribe((response:any)=>{
      this.add_prognastic = response['payLoad'];      
     
      console.log(response['payLoad']);
      for(var i=0; i<response['payLoad']['geneVariants'].length;i++){
        console.log("geneVarientId-->",response['payLoad']['geneVariants'][i]['gene']['id']);

        let genevarient={} as GeneVariantReq;
        genevarient.geneId=response['payLoad']['geneVariants'][i]['gene']['id'];
        genevarient.variant=response['payLoad']['geneVariants'][i]['variant'];
        genevarient.referencePmids=response['payLoad']['geneVariants'][i]['referencePmIds'];
        genevarient.reference=response['payLoad']['geneVariants'][i]['referenceLink'];
        this.addGeneVarinats.addGeneVarinat.push(genevarient);
        this.add_prognastic.geneVariantReqList = this.addGeneVarinats.addGeneVarinat;
       
      }

    })
  }
  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }
  addGeneVariant(): void {
    this.addGeneVarinats.addGeneVarinat.push({
      geneId: 0,
      variant: "",
      reference:"",
      referencePmids:""
    });

    this.add_prognastic.geneVariantReqList = this.addGeneVarinats.addGeneVarinat;
    console.log(this.addGeneVarinats.addGeneVarinat);
  }

  removeGeneVariant(i: number) {
    console.log("remove Gene variant", i);
    this.addGeneVarinats.addGeneVarinat.splice(i, 1);
    this.add_prognastic.geneVariantReqList = this.addGeneVarinats.addGeneVarinat;
    console.log("remove Gene variant", this.add_prognastic.geneVariantReqList);
  }
  onSubmit(){
    this.submitSpinner = true;

    console.log(this.add_prognastic);
    this.dataAdminService.addPrognasticBiomarker(this.add_prognastic).subscribe(
      (response: any) => {
        this.submitSpinner = false;
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );
  }
  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/prognasticbiomarker']);
    });
  }
  
  textcount(classname:any){
    var dom=document.getElementsByClassName(classname);
      var data:any=dom[0]
      this.editorText= data.outerText
  }
}
