// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiUrl: 'http://localhost:8080',
  // apiUrl: 'http://52.66.98.251:9501',
  apiUrl: 'https://www.api.workbench.4basecare.co.in',

  AWS_ACCESS_KEY_ID: 'AKIASLG5BKZA4VQDYGU2',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'ap-south-1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
