import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FusionVariant } from 'src/app/_interfaces.ts/excel';
import { ReportRes } from 'src/app/_interfaces.ts/reports';
import { FusionService } from 'src/app/_services/fusion.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-fusion-excel',
  templateUrl: './fusion-excel.component.html',
  styleUrls: ['../cna-excel/cna-excel.component.scss']
})
export class FusionExcelComponent implements OnInit {


  reportId!: number;
  report = {} as ReportRes;
  fusionVariants: FusionVariant[] = [];
  selectedForReport!: boolean;
  selectedStarForReport!: boolean;

  fusionTable = false;
  uploadFileInput = false;
  fusionListSpinner = true;
  switchToggleButton = false;
  spinDiv=false;


  constructor(
    private fusionService: FusionService,
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
  ) { }
  organisationName!: string;

  ngOnInit() {
    this.organisationName = localStorage.getItem('organisationName') || '';
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }

  autoUploadFusionFile(){
    console.log("autoUploadFusionFile");
    this.fusionService
      .autoUploadFusionFile(this.reportId)
      .subscribe((response: any) => {
        this.fusionTable = true;
        this.uploadFileInput = false;
        this.fusionListSpinner = false;
        console.log("fusion data ==>", response);
        this.fusionVariants = response['payLoad'];
      });


  }
  onFileChange(event: any) {
    this.uploadFileInput = true;
    const files = event.target.files;
    const file = files[0];
    // this.fileName = file.name;
    this.uploadFile(file);
  }

  uploadFile(file: any) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.fusionService.uploadFusionExcel(formdata, this.reportId).subscribe(
      (response: any) => {
        this.fusionTable = true;
        this.uploadFileInput = false;
        this.fusionListSpinner = false;
        console.log("fusion data ==>", response);
        this.fusionVariants = response['payLoad'];
      }
    );
  }

  seletedRow(event: any, fusionVariant: FusionVariant) {
    this.selectedForReport = event['target']['checked'];

    if (this.selectedForReport) {
      this.fusionService.selectFusionForReport(fusionVariant.id, true).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getFusionVariants();
          }
        }
      );
    } else if (!this.selectedForReport) {
      if(confirm("Are you sure you want unselect..!")) {
      this.fusionService.selectFusionForReport(fusionVariant.id, false).subscribe(
        (response: any) => {
          console.log(response);
          if (response['success'] == true) {
            this.getFusionVariants();
          }
        }
      );
    }
    else{
      this.getFusionVariants();
    }
  }

  }

  seletedStarRow(event: any,fusionVariant: FusionVariant) {
    this.selectedStarForReport = event['target']['checked'];
    console.log(this.selectedStarForReport);
    this.fusionService.selectStarFusionForReport(fusionVariant.id, this.selectedStarForReport).subscribe(
      (response: any) => {
        console.log(response);
      }
    );
  }

  priorityOrder(orderNumber: number, fusionVariantId: number){
    console.log(orderNumber, fusionVariantId);
    if(orderNumber != null && orderNumber > 0){
      this.fusionService.setPriorityOrder(fusionVariantId, orderNumber).subscribe(
        (response: any) => {
          console.log("setPriorityOrder",response);
        }
      );
    }

  }


  switchToggle(event: any) {
    this.fusionListSpinner = true;
    this.switchToggleButton = event['target']['checked'];
    console.log("switchToggleButton", this.switchToggleButton);
    if (this.switchToggleButton) {
      this.getSelectedFusionVariants();
    } else if (!this.switchToggleButton) {
      this.getFusionVariants();
    }

  }

  fileName!: string;
  getReport() {
    this.uploadFileInput = true;
    this.reportService.getReport(this.reportId).subscribe(
      (response: any) => {
        console.log("fusion component", response);
        this.report = response['payLoad'];
        this.uploadFileInput = false;
        if(this.report.fusionExcelFile == null && this.organisationName != null && this.organisationName.length > 0){
          this.autoUploadFusionFile();
        }
        if (this.report.fusionExcelFile == null) {
          this.fusionTable = false;
        } else if (this.report.fusionExcelFile != null) {
          this.fusionTable = true;
          this.fileName = this.report.fusionExcelFile.split('/')[5].substring(14);
          this.getFusionVariants();
        }
      });
  }

  getFusionVariants() {
    this.fusionListSpinner = true;
    this.fusionService.getAllFusionVariantsByReportId(this.reportId).subscribe(
      (response: any) => {
        this.fusionListSpinner = false;
        this.spinDiv=true;
        console.log("fusion data ==>", response);
        this.fusionVariants = response['payLoad'];
        if (response['success'] == true) {
          if (this.switchToggleButton) {
            this.fusionListSpinner = true;
            this.getSelectedFusionVariants();
          }
        }
      }
    );
  }

  getSelectedFusionVariants() {

    function isSelectedForReport(element: FusionVariant, index: any, array: any) {
      return (element.selectedForReport == true);
    }
    this.fusionVariants = this.fusionVariants.filter(isSelectedForReport);
    this.fusionListSpinner = false;
  }

  accordionClick(fusion: FusionVariant){
    console.log("fusion",fusion);
    fusion.show = !fusion.show
    this.getreadNameList(fusion.readNames);
  }

  readNameList : String[] = [] ;
  getreadNameList(readName : string){
    console.log("getreadNameList");
    this.readNameList =  readName.split(";");
  }


}
