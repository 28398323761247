import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClinicalTrialRes } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-clinical-trail',
  templateUrl: './clinical-trail.component.html',
  styleUrls: ['./clinical-trail.component.css']
})
export class ClinicalTrailComponent implements OnInit {

  ClinicalTrialList!: ClinicalTrialRes[];
  clinicalId!: number;
  statusMsg!: string;
  clinicalTrailListSpinner:boolean=true
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  ngOnInit(): void {
    this.getClinicalTrailList();
  }

  getClinicalTrailList(){
    this.dataAdminService.getClinicalTrailList().subscribe(
      (response: any) => {
        this.clinicalTrailListSpinner=false
        this.ClinicalTrialList = response['payLoad'];
        console.log(this.ClinicalTrialList);

      }
    );
  }

  assignClinicalTrail(clinicalTrial: any){
    console.log(clinicalTrial);
    this.clinicalId = clinicalTrial['id'];
  }

  deleteClinicalTrail(){
    console.log(this.clinicalId);
    this.dataAdminService.deleteClinicalTrail(this.clinicalId).subscribe(
      (response: any) =>{
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
    });
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/clinical-trails']);
    });
  }

}
