<div class="container-fluid px-0">
    <h5 *ngIf="!disclaimerId" class="mb-0 d-inline-block position-relative ml-4 mb-3 mt-4">Add Disclaimer</h5>
    <h5 *ngIf="disclaimerId" class="mb-0 d-inline-block position-relative ml-4 mb-3 mt-4">Edit Disclaimer</h5>
   <div class="mx-4 p-4 bg-white cust_shadow rounded ">
    <div class="row mx-3">
        <div class="col">
            <form class="form_list" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-6 form-group row">
                        <label class="col-sm-5 control-label">Type of Test :</label>
                        <div class="col-sm-7">
                            <select type="text" class="form-control" name="testType"
                                [(ngModel)]="disclaimer.testType">
                                <option value="SOMATIC">SOMATIC</option>
                                <option value="GERMLINE">GERMLINE</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6 form-group row">
                        <label class="col-sm-5 control-label">Disclaimer Tag :</label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" name="disclaimerTag"
                                [(ngModel)]="disclaimer.disclaimerTag">
                        </div>
                    </div>
                    <div class="col-12 form-group row" style="padding-left: 45px;">
                        <label class="col-sm-2 control-label">Disclaimer Details :</label>
                        <div class="col-sm-10">
                            <!-- <textarea type="text" class="form-control" cols="30" rows="10" maxlength="3000" name="disclaimerDetails"
                            [(ngModel)]="disclaimer.disclaimerDetails"> </textarea> -->
                            <ckeditor [editor]="editor"  name="disclaimerDetails" [(ngModel)]="disclaimer.disclaimerDetails"></ckeditor>
                        </div>
                    </div>
                    <div class="col-12 add-emp mr-10 mb-5">
                        <div class="col-sm-8 offset-sm-4 px-0 text-right">
                            <button type="submit" class="btn save_btn text-uppercase bg-softblue ml-2"
                                [disabled]="submitSpinner">
                                <!-- <span class="delete_clr"  data-toggle="modal" data-target="#deleteDrugModal"> Submit </span> -->
                                <span *ngIf="!submitSpinner"> Submit </span>
                                <div *ngIf="submitSpinner" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </div>
            </div>
            </form>
        </div>
    </div>
   </div>
</div>
