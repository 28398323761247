import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  model: any = {};

  loginSpinner = false;

  constructor( 
    private loginServ: LoginService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  showSuccess() {
    this.toastr.success('Welcome','',
    { timeOut: 2000}
    );
  }

  showError(msg:any) {
    this.toastr.error(msg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {
  }

  submitLogin() {
    this.loginSpinner = true;
    console.log("model", this.model);
    this.loginServ.login(this.model).subscribe(
      (data: any) => {
        this.showSuccess();
        console.log(data);
        localStorage.setItem('token', JSON.stringify(data['payLoad']['authToken']));
        localStorage.setItem('user', JSON.stringify(data['payLoad']));
        this.router.navigate(['/reports'])
        this.loginSpinner = false;
      },
      error =>{
        this.loginSpinner = false;        
      this.showError(error.error['message'])
      });

  }

}
