import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gene } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-gene',
  templateUrl: './gene.component.html',
  styleUrls: ['./gene.component.css']
})
export class GeneComponent implements OnInit {
  editor = ClassicEditor;

  geneListSpinner:boolean=true;
  add_gene = {} as Gene;
  geneList: Gene[] | undefined;
  statusMsg: string | undefined;
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  ngOnInit(): void {
    this.add_gene.description=''
    this.getGenes();
  }

  addGene(){
    console.log(this.add_gene);
    this.dataAdminService.addGene(this.add_gene).subscribe(
      (response: any) => {
        console.log("response add",response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  deleteGene(){
    console.log(this.add_gene);
    this.dataAdminService.deleteGene(this.add_gene.id).subscribe(
      (response: any) => {
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  assignGene(gene: Gene){
    this.add_gene = gene;
    console.log("edit cancer type",this.add_gene);
  }

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneListSpinner=false
        this.geneList = response['payLoad'];
        console.log("gene list",this.geneList);
      }
    );
  }

  closeModal(){
    console.log("close modal")
    this.add_gene = {} as  Gene;
    this.add_gene.description=''
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/genes']);
    });
  }

}
