import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectedTrail } from '../_interfaces.ts/trails';

@Injectable({
  providedIn: 'root'
})
export class ClinicalTrailService {

  constructor(
    private http: HttpClient
  ) { }

  getTrailsCount(refGeneX: string){
    return this.http.get('/trails/trail_count/' + refGeneX);
  }

  getTrails(refGeneX: string, pageNumber: number){
    return this.http.get('/trails/search/' + pageNumber + "/" + refGeneX);
  }

  addSelectedTrail(selectedTrail: SelectedTrail){
    return this.http.post('/trails/select_trail', selectedTrail);
  }

  editSelectedTrail(selectedTrail: SelectedTrail){
    return this.http.post('/trails/edit_select_trail', selectedTrail);
  }

  removeSelectedTrail(internalId: number, referenceId: number, type: number){
    return this.http.post('/trails/' + internalId + '/remove_select_trail/' + type, referenceId);
  } 

  addStarSelectedTrail(internalId: number, referenceId: number, type: number){
    return this.http.post('/trails/' + internalId + '/select_star_trail/' + type, referenceId);
  }

  removeStarSelectedTrail(internalId: number, referenceId: number, type: number){
    return this.http.post('/trails/' + internalId + '/remove_star_trail/' + type, referenceId);
  }

  addAssociation(selectedTrail: SelectedTrail){
    return this.http.post('/trails/add_association', selectedTrail);
  }

  removeSelectedNCTIdTrail(nctId: string, referenceId: number, type: number){
    return this.http.get('/trails/' + nctId + '/remove_select_nct_trail/' + type + '/' + referenceId);
  }

  addStarSelectedNCTIdTrail(selectedTrail: SelectedTrail){
    return this.http.post('/trails/select_star_nct_trail', selectedTrail);
  }
  

  starNCTTrail(selectedTrail: SelectedTrail){
    return this.http.post('/trails/star_nct_clinical_trail', selectedTrail);
  }

  removeStarNCTTrail(selectedTrail: SelectedTrail){
    return this.http.post('/trails/remove_nct_clinical_trail', selectedTrail);
  }

  removeStarSelectedNCTIdTrail(nctId: string, referenceId: number, type: number){
    return this.http.get('/trails/' + nctId + '/remove_star_nct_trail/'+ type + '/' + referenceId);
  }

  getSelectedTrails(referenceId: number, type: number, isInternal: boolean){
    return this.http.get('/trails/ '+ referenceId + '/get_selected_trail/' + type + "/" + isInternal);
  }

  addPDL1Trails(reportId: number, trailId: number, isSelected: boolean){
    return this.http.post('/trails/' + reportId + '/select_pdl1_trail/' + trailId,isSelected);
  }

  getSelectedPDL1Trails(reportId: number){
    return this.http.get('/trails/' + reportId  + '/get_selected_pdl1_trails');
  }
}
