import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private httpClient: HttpClient
  ) { }

  editPatient(body: any){
    return this.httpClient.post('/patient/add',body);
  }

  getPatient(ecrfId: string){
    return this.httpClient.get('/patient/' + ecrfId + '/get_patient');
  }
}
