import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HotspotService {

  constructor(
    private httpClient: HttpClient
  ) { }
  uploadAndGetHotspotDataFromExcel(params: any,reportId: Number) {
    return this.httpClient.post('/hot-spot/' + reportId + '/upload_hotspot_file', params);
  }

  checkAutoUploadHostspotFile(reportId: Number) {
    return this.httpClient.post('/hot-spot/' + reportId + '/auto_upload_hotspot_file',null,);
  }

  getHotspotVariantById(reportVariantId: number) {
    return this.httpClient.get('/hot-spot/' + reportVariantId + '/get_report_variant',);
  }

  getAllHotspotVariantsByReportId(reportId: number) {
    return this.httpClient.get('/hot-spot/' + reportId + '/get_hotspot_variants',);
  }

  hotspotVariantStarStatus(reportVariantId: number, aBoolean: boolean) {
    return this.httpClient.post('/hot-spot/' + reportVariantId + '/hotspot_variant_star_status', aBoolean,);
  }

  hotspotVariantStatus(reportVariantId: number, aBoolean: boolean) {
    return this.httpClient.post('/hot-spot/' + reportVariantId + '/hotspot_variant_status', aBoolean,);
  }

  addHotspotVariantSignificance(reportVariantId: number, body: any) {
    return this.httpClient.post('/hot-spot/' + reportVariantId + '/add_significance', body,);
  }

  getSelectedHotspotVariants(reportId: number) {
    return this.httpClient.get('/hot-spot/' + reportId + '/get_selected_report_variants',);
  }

  setHotspotVariantPriorityOrder(reportVariantId: number, priorityOrderNumber: number) {
    return this.httpClient.post('/hot-spot/' + reportVariantId + '/priority_order', priorityOrderNumber,);
  }
}
