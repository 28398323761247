<div class="container-fluid px-0">
    <!--  -->
    <div class="row  py-3  mx-0">
      <div class="col pl-0">
        <h5 class="mb-0 d-inline-block  pt-2 text_color">Cancer Type List</h5>
      </div>
      <div class="col pr-0">
        <div class="text-right ml-auto d-table">
          <div class="add-new">
            <a class="add_post mr-2" data-toggle="modal" data-target="#addCancerTypeModal">Add Cancer Type <i class="zmdi zmdi-plus"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div *ngIf="cancerListSpinner" class="row data-spinner-parent">
      <div class="spinner-border data-spinner-child" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  <div *ngIf="!cancerListSpinner"  class="bg-white cust_shadow  p-4 rounded">
    <div class="tableFixHead">
      <table class="care_companion table ">
        <thead class="thead-light">
          <tr>
            <th>Sl.No</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
  
        </thead>
        <tbody>
          <tr *ngFor="let cancerType of cancerTypeList; let i = index">
            <td>{{i+1}}</td>
            <td>{{cancerType.name}}</td>
            <td>
              <ul class="list-unstyled mb-0 d-flex actions">
                <li><a (click)="assignCancerType(cancerType)" class="mr-1 delete_clr" data-toggle="modal" data-target="#addCancerTypeModal"> Edit </a></li>
                <li><a (click)="assignCancerType(cancerType)" class="delete_clr" data-toggle="modal" data-target="#deleteCancerTypeModal">| Delete</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
   
  </div>

    <!-- add/edit modal -->
    <div class="modal fade" id="addCancerTypeModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content surgery-modal">

            <div class="modal-header px-4 border-bottom-0 bg-light">
              <h5 class="modal-title text_color ">Add Cancer Type</h5>
              <a class="text-softblue" data-dismiss="modal" (click)="closeModal()">
                <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
              </a>
            </div>

            <div class="modal-body p-4">

              <div class="form-group row">
                <label class="col-sm-4 control-label">Name :</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="name" [(ngModel)]="add_cancerType.name">
                </div>
              </div>

              <button type="button" class="btn save_btn text-uppercase " style="float:right"
              (click)="addCancerType()" data-dismiss="modal"> Save </button>
            </div>

          </div>
        </div>
      </div>

       <!-- The delete Modal -->
       <div class="modal fade" id="deleteCancerTypeModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content surgery-modal">

            <div class="modal-header px-4 border-bottom-0 bg-light">
              <h5 class="modal-title text-softblue">Delete</h5>
              <a (click)="closeModal()" class="text-softblue" data-dismiss="modal">
                  <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
              </a>
            </div>

            <div class="modal-body p-4">
                    <h6 class=" mb-4">Are you sure want to delete ?</h6>
                  <div class="mx-0 ml-auto d-table">
                      <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                      data-dismiss="modal" (click)="deleteCancerType()">Yes</button>
                      <button type="button" class="btn bg-softblue text-white px-5"
                      data-dismiss="modal" (click)="closeModal()">No</button>
                  </div>
            </div>

          </div>
        </div>
      </div>

  </div>
