<div class="container-fluid px-0">
    <!--  -->
    <div class="row py-3 px-3 mx-0">
        <div class="col">
            <h5 class="mb-0 d-inline-block position-relative pt-2">Therapy List</h5>
        </div>
        <div class="col">
            <div class="text-right ml-auto d-table">
                <div class="add-new">
                    <a class="add_post mr-2" data-toggle="modal" data-target="#addTherapyModal">Add Therapy <i
                            class="zmdi zmdi-plus"></i></a>
                </div>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="bg-white cust_shadow mx-4 p-5 rounded">
    <table class="care_companion table ">
        <thead class=" thead-light">
            <tr>
                <th>Sl.No</th>
                <th>Title</th>
                <th>Actions</th>
            </tr>

        </thead>
        <tbody>
            <tr *ngFor="let therapy of therapyList; let i = index">
                <td>{{i+1}}</td>
                <td>{{therapy.name}}</td>
                <td>
                    <ul class="list-unstyled mb-0 d-flex actions">
                        <li><a (click)="assignTherapy(therapy)" data-toggle="modal" data-target="#addTherapyModal"
                            class="mr-1 delete_clr"> Edit </a></li>
                        <li><a (click)="assignTherapy(therapy)" data-toggle="modal" data-target="#deleteTherapyModal"
                            class="delete_clr">| Delete</a></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
    </div>

    <!-- add/edit modal -->
    <div class="modal fade" id="addTherapyModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content surgery-modal">

                <div class="modal-header px-4 border-bottom-0 bg-light">
                    <h5 class="modal-title text-softblue">Add Therapy</h5>
                    <a class="text-softblue" data-dismiss="modal" (click)="closeModal()">
                        <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
                    </a>
                </div>

                <div class="modal-body p-4">
                    <div class="form-group row">
                        <label class="col-sm-4 control-label">Name :</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" name="name" [(ngModel)]="add_therapy.name">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 control-label">Drugs :</label>
                        <div class="col-sm-8">
                            <ng-multiselect-dropdown [data]="drugs" [(ngModel)]="selectedDrugs"
                                [settings]="drugsDropdownSettings">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>

                    <button type="button" class="btn save_btn text-uppercase " style="float:right"
                        data-dismiss="modal" (click)="addTherapy()"> Save </button>
                </div>

            </div>
        </div>
    </div>

    <!-- The delete Modal -->
    <div class="modal fade" id="deleteTherapyModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content surgery-modal">

                <div class="modal-header px-4 border-bottom-0 bg-light">
                    <h5 class="modal-title text-softblue">Delete</h5>
                    <a class="text-softblue" data-dismiss="modal">
                        <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
                    </a>
                </div>

                <div class="modal-body p-4">
                    <h6 class=" mb-4">Are you sure want to delete ?</h6>
                    <div class="mx-0 ml-auto d-table">
                        <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                            data-dismiss="modal" (click)="deleteTherapy()">Yes</button>
                        <button type="button" class="btn bg-softblue text-white px-5" data-dismiss="modal">No</button>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
