
<div class="container-fluid px-0">
  <!--  -->
  <div class="row  py-3 mx-0">
    <div class="col pl-0">
      <h5 class="mb-0 d-inline-block text_color pt-2">Domain Function List</h5>
    </div>

    <div class="col pr-0">
      <div class="text-right ml-auto d-table">
        <div class="add-new">
          <a class="add_post mr-2" data-toggle="modal" data-target="#addGeneModal">Add Domain Function <i class="zmdi zmdi-plus"></i></a>

        </div>
      </div>

    </div>
  </div>
  <div class=" justify-content-end d-flex mb-5t" style="padding: 5px!important;">
    <button (click)="fileInput.click()" type="button" class="btn btn-primary w-md">
      <input style="display: none" type="file"
        (change)="onFileChanges($event)" #fileInput />
      Upload file
    </button>
  </div>
  <div *ngIf="domainListSpinner " class="row data-spinner-parent">
    <div class="spinner-border data-spinner-child" role="status">
        <span class="visually-hidden"></span>
    </div>
</div>
  <!--  -->
  <div *ngIf="!domainListSpinner" class="bg-white cust_shadow p-4 rounded">
    <div class="tableFixHead">
      <table class="care_companion table ">
        <thead class="thead-light">
          <tr>
            <th>Sl.No</th>
            <th>Short Name</th>
            <th>Actions</th>
          </tr>

        </thead>
        <tbody>
          <tr *ngFor="let domain of domainList; let i = index">
            <td>{{i+1}}</td>
            <td>{{domain.shortName}}</td>
            <td>
              <ul class="list-unstyled mb-0 d-flex actions">
                <li><a (click)="assignDomainFunction(domain)" class="mr-1 delete_clr" data-toggle="modal" data-target="#addGeneModal"> Edit </a></li>
                <li><a (click)="assignDomainFunction(domain)" class="delete_clr" data-toggle="modal" data-target="#deleteGeneModal">| Delete</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

</div>

  <!-- add/edit modal -->
  <div class="modal fade" id="addGeneModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content surgery-modal">

          <div class="modal-header px-4 border-bottom-0 bg-light">
            <h5 class="modal-title text-softblue">Add Domain Function</h5>
            <a class="text-softblue" data-dismiss="modal" (click)="closeModal()">
              <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
            </a>
          </div>

          <div class="modal-body p-4">

            <div class="form-group row">
              <label class="col-sm-4 control-label">Short Name :</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" name="shortName" [(ngModel)]="add_Domain_Funcution.shortName">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">Domain Name :</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" name="domainName" [(ngModel)]="add_Domain_Funcution.domainName">
              </div>
            </div>



            <div class="form-group row">
              <label class="col-sm-4 control-label">Domain Function :</label>
              <div class="col-sm-8">
                  <ckeditor [editor]="editor"  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList' ] }"
                  name="domainFunction" [(ngModel)]="add_Domain_Funcution.domainFunction"></ckeditor>
              </div>
            </div>

            <button type="button" class="btn text-uppercase save_btn" style="float:right"
            (click)="addDomainFunction()" data-dismiss="modal"> Save </button>
          </div>

        </div>
      </div>
    </div>

     <!-- The delete Modal -->
     <div class="modal fade" id="deleteGeneModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content surgery-modal">

          <div class="modal-header px-4 border-bottom-0 bg-light">
            <h5 class="modal-title text-softblue">Delete</h5>
            <a (click)="closeModal()" class="text-softblue" data-dismiss="modal">
                <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
            </a>
          </div>

          <div class="modal-body p-4">
                  <h6 class=" mb-4">Are you sure want to delete ?</h6>
                <div class="mx-0 ml-auto d-table">
                    <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                    data-dismiss="modal" (click)="deleteDomainFunction()">Yes</button>
                    <button type="button" class="btn bg-softblue text-white px-5"
                    data-dismiss="modal" (click)="closeModal()">No</button>
                </div>
          </div>

        </div>
      </div>
    </div>

</div>
