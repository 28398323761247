import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CancerType, Drug } from 'src/app/_interfaces.ts/admin';
import { CNAVariants, FilteresCSV, FusionVariant } from 'src/app/_interfaces.ts/excel';
import { SelectedTrail, selectedTrailPriority, StudyFields, StudyFieldsResponse } from 'src/app/_interfaces.ts/trails';
import { ClinicalTrailService } from 'src/app/_services/clinical-trail.service';
import { CnaService } from 'src/app/_services/cna.service';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { FusionService } from 'src/app/_services/fusion.service';
import { ReportService } from 'src/app/_services/report.service';
import { StringLiteralLike } from 'typescript';

@Component({
  selector: 'app-trails-list',
  templateUrl: './trails-list.component.html',
  styleUrls: ['./trails-list.component.css']
})
export class TrailsListComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  reportedVariantId!: number;
  cnaVariantId!: number;
  switchToggleButton!:boolean;
  fusionVariantId!: number;
  reportedVariant = {} as FilteresCSV;
  cnaVariant = {} as CNAVariants;
  fusionVariant = {} as FusionVariant;
  studyFieldsResponse = {} as StudyFieldsResponse;
  isSelectedTrail!: boolean;
  isStarSelectedTrail = false;
  // selectedNctIds!: string[];
  selectedTrail = {} as SelectedTrail;
  trailsListSpinner = false;
  studyField = {} as StudyFields;
  gene!: string;
  selectedTrailList: SelectedTrail[] = [];
  selectedTrailNCTIds: string[] = [];
  selectedStarTrailNCTIds: string[] = [];
  selectedTrailsPriority:selectedTrailPriority[]=[]
  association!: string;
  selectedStudyFields: StudyFields[] = [];
  checkedTrailList!: boolean;
  selectedStarNctId!: string;
  dropdownSettings = {};
  selectedCancers: CancerType[] = [];
  cancerIds: number[] = [];
  isSubmited!: boolean;
  statusMsg!: string;
  starVariant = false;

  nctId!: string;

  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private trailService: ClinicalTrailService,
    private router: Router,
    private dataAdminService: DataAdminService,
    private cnaService: CnaService,
    private fusionService: FusionService,
    private toastr: ToastrService
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {

    this.reportedVariantId = this.routerAct.snapshot.params['id'];
    if (this.reportedVariantId) {
      this.getReportedVariant();
    }

    this.cnaVariantId = this.routerAct.snapshot.params['cid'];
    if (this.cnaVariantId) {
      console.log("this.cnaVariantId", this.cnaVariantId);
      this.getCNAVariant();
    }

    this.fusionVariantId = this.routerAct.snapshot.params['fid'];
    if (this.fusionVariantId) {
      console.log("this.fusionVariantId", this.fusionVariantId);
      this.getFusionVariant();
    }

    this.getCancerTypes();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


  addSelectedTrails(event: any, studyField: StudyFields) {
    this.isSelectedTrail = event['target']['checked'];
    console.log(studyField);
    if (this.isSelectedTrail) {
      this.selectedTrail.isInternal = false;
      this.selectedTrail.isStar = false;
      if (this.reportedVariantId) {
        this.selectedTrail.type = 0;
        this.selectedTrail.referenceId = this.reportedVariant.id;
      } else if (this.cnaVariantId) {
        this.selectedTrail.type = 1;
        this.selectedTrail.referenceId = this.cnaVariant.id;
      } else if (this.fusionVariantId) {
        this.selectedTrail.type = 2;
        this.selectedTrail.referenceId = this.fusionVariant.id;
      }
      this.selectedTrail.nctId = studyField.nctId[0];
      console.log("this.selectedTrail", this.selectedTrail);

      this.trailService.addSelectedTrail(this.selectedTrail).subscribe(
        (response: any) => {
          this.selectedTrail = {} as SelectedTrail;
          console.log("addSelectedTrails", response);
          if (this.reportedVariantId) {
            this.getSelectedTrails(this.reportedVariantId, 0);
          } else if (this.cnaVariantId) {
            this.getSelectedTrails(this.cnaVariantId, 1);
          } else if (this.fusionVariantId) {
            this.getSelectedTrails(this.fusionVariantId, 2);
          }
        }
      );
    } else {
      if(this.reportedVariantId){
        this.removeSelectedNCTIdTrail(studyField.nctId[0],this.reportedVariantId,0);
      } else if(this.cnaVariantId){
        this.removeSelectedNCTIdTrail(studyField.nctId[0],this.cnaVariantId,1);
      } else if(this.fusionVariantId){
        this.removeSelectedNCTIdTrail(studyField.nctId[0],this.fusionVariantId,2);
      }
    }
  }

  removeSelectedNCTIdTrail(nctId: string, referenceId: number, type: number){
    this.trailService.removeSelectedNCTIdTrail(nctId, referenceId, type).subscribe(
      (response: any) => {
        console.log("removeSelectedTrail", response);
      this.getSelectedTrails(referenceId,type);
      }
    );
  }

  addAssociation(studyField: StudyFields) {
    this.closebutton.nativeElement.click();
    this.isSubmited = true;
    if(this.reportedVariantId){
    this.selectedTrail.referenceId = this.reportedVariant.id;
    this.selectedTrail.type = 0;
    } else if(this.cnaVariantId){
      this.selectedTrail.referenceId = this.cnaVariantId;
      this.selectedTrail.type = 1;
    } else if(this.fusionVariantId){
      this.selectedTrail.referenceId = this.fusionVariantId;
      this.selectedTrail.type = 2;
    }
    this.selectedTrail.nctId = studyField.nctId[0];
    this.selectedTrail.association = studyField.association;
    this.selectedTrail.cancerTypeEnum = studyField.cancerTypeEnum;

    if (studyField.cancerTypes.length > 0) {
      for (let i = 0; i < this.studyField.cancerTypes.length; i++) {
        const id = this.studyField.cancerTypes[i]['id'];
        this.cancerIds.push(id);
      }
    }
    this.selectedTrail.cancerIds = this.cancerIds;
    this.selectedTrail.drugs = studyField.drugs;
    console.log("this.selectedTrail", this.selectedTrail);

    this.cancerIds = [];
    this.trailService.addAssociation(this.selectedTrail).subscribe(
      (response: any) => {
        this.isSubmited = false;
        console.log(response);
        this.cancerIds = [];
        this.statusMsg = response['message'];
        this.showSuccess();
        // if(this.reportedVariantId){
        //   this.getSelectedTrails(this.reportedVariantId,0);
        // } else if(this.cnaVariantId){
        //   this.getSelectedTrails(this.cnaVariantId,1);
        // }
      }
    );
  }

  selectedStarTrails(event: any, studyField: StudyFields) {
    this.isStarSelectedTrail = event['target']['checked'];

    if (this.isStarSelectedTrail) {
      this.selectedStarNctId = studyField.nctId[0];
      this.selectedTrail.nctId = this.selectedStarNctId;
      if (this.reportedVariantId) {
        this.selectedTrail.type = 0;
        this.selectedTrail.referenceId = this.reportedVariant.id;
      } else if (this.cnaVariantId) {
        this.selectedTrail.type = 1;
        this.selectedTrail.referenceId = this.cnaVariant.id;
      } else if (this.fusionVariantId) {
        this.selectedTrail.type = 2;
        this.selectedTrail.referenceId = this.fusionVariant.id;
      }

      console.log(this.selectedTrail);

      this.trailService.addStarSelectedNCTIdTrail(this.selectedTrail).subscribe(
        (response: any) => {
          this.selectedTrail = {} as SelectedTrail;
          console.log(response);
        }
      );
    } else {
      console.log(this.isStarSelectedTrail);
      if(this.reportedVariantId){
        this.removeStarSelectedNCTIdTrail(studyField.nctId[0],this.reportedVariantId,0);
      } else if(this.cnaVariantId){
        this.removeStarSelectedNCTIdTrail(studyField.nctId[0],this.cnaVariantId,1);
      } else if(this.fusionVariantId){
        this.removeStarSelectedNCTIdTrail(studyField.nctId[0],this.fusionVariantId,2);
      }
    }

  }

  removeStarSelectedNCTIdTrail(nctId: string, referenceId: number, type: number){
    this.trailService.removeStarSelectedNCTIdTrail(nctId, referenceId, type).subscribe(
      (response: any) => {
        console.log(response);
          // this.getSelectedTrails(referenceId,type);
      }
    );
  }

  starNctClinicalTrail!: boolean;
  starNctMethod(event: any){
    this.starNctClinicalTrail = event['target']['checked'];
    console.log("starNctClinicalTrail",this.starNctClinicalTrail);
  }

  addSelectedStarTrails() {
    this.selectedTrail.nctId = this.studyField.nctId[0];
      if (this.reportedVariantId) {
        this.selectedTrail.type = 0;
        this.selectedTrail.referenceId = this.reportedVariant.id;
      } else if (this.cnaVariantId) {
        this.selectedTrail.type = 1;
        this.selectedTrail.referenceId = this.cnaVariant.id;
      } else if (this.fusionVariantId) {
        this.selectedTrail.type = 2;
        this.selectedTrail.referenceId = this.fusionVariant.id;
      }

    if (this.starNctClinicalTrail) {
      console.log(this.selectedTrail);
      this.trailService.starNCTTrail(this.selectedTrail).subscribe(
        (response: any) => {
          this.selectedTrail = {} as SelectedTrail;
          console.log(response);
        }
      );
    } else {
      console.log(this.selectedTrail);
      this.trailService.removeStarNCTTrail(this.selectedTrail).subscribe(
        (response: any) => {
          this.selectedTrail = {} as SelectedTrail;
          console.log(response);
        }
      );
    }

  }


  getReportedVariant() {
    this.reportService.getReportVariantById(this.reportedVariantId).subscribe(
      (response: any) => {
        this.reportedVariant = response['payLoad'];
        if(this.reportedVariant.selectedStarForReport){
          this.starVariant = true;
        }
        this.reportedVariant.aachangeknownGeneList.forEach(aachangeknownGene => {
          if (aachangeknownGene.includes(this.reportedVariant.ensembleValue)) {
            this.reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3] + ", " + aachangeknownGene.split(":")[4];
          }
        });
        this.gene = this.reportedVariant.refGeneX.split(';', 1).toString();

        if (response['success']) {
          this.getTrailsApi(this.reportedVariantId, 0);

        }

      }
    );
  }

  getCNAVariant() {
    this.cnaService.getCNAVariant(this.cnaVariantId).subscribe(
      (response: any) => {
        this.cnaVariant = response['payLoad'];
        this.starVariant = true;
        this.gene = this.cnaVariant.gene.split(';', 1).toString();
        console.log("cnaVariant", this.cnaVariant);

        if (response['success']) {
          this.getTrailsApi(this.cnaVariantId, 1);
        }

      }
    );
  }

  fusionGenes: string[] = [];
  getFusionVariant() {
    this.fusionGenes = [];
    this.fusionService.getFusionVariant(this.fusionVariantId).subscribe(
      (response: any) => {
        this.fusionVariant = response['payLoad'];
        this.starVariant = true;
        let fusionGene = this.fusionVariant.fusionGene;
        let fusionGene1 = this.fusionVariant.fusionGene.split('--')[0];
        let fusionGene2 = this.fusionVariant.fusionGene.split('--')[1];
        this.gene = fusionGene1 + "-" + fusionGene2;
        this.fusionGenes.push(fusionGene1 + "-" + fusionGene2);
        this.fusionGenes.push(fusionGene2 + "-" + fusionGene1);
        this.fusionGenes.push(fusionGene1 + " fusion");
        this.fusionGenes.push(fusionGene2 + " fusion");
        this.fusionGenes.push(fusionGene);
        console.log("fusionVariant", this.fusionVariant);

        if (response['success']) {
          this.getTrailsApi(this.fusionVariantId, 2);
        }

      }
    );
  }

  onChangeFusionGene(){
    this.studyFieldsResponse.studyFields = [];
    this.getTrailsApi(this.fusionVariantId, 2);
  }

  currentPage!: number;
  reqPageNo = 1;
  totalPages!: number;
  changePage(event: any) {
    console.log("event", event);
    this.trailsListSpinner = false;
    this.currentPage = event;
    this.reqPageNo = this.currentPage;
    if(this.reportedVariantId){
      this.getTrailsApi(this.reportedVariantId,0);
    }else if(this.cnaVariantId){
      this.getTrailsApi(this.cnaVariantId,1);
    }else if(this.fusionVariantId){
      this.getTrailsApi(this.fusionVariantId,2);
    }

  }

  searchNCTId(){
    console.log("searchNCTId",this.nctId);
    if(this.nctId != null && this.nctId != ""){
    if(this.reportedVariantId){
      this.trailService.getTrails(this.nctId,1).subscribe(
        (res: any) => {
          console.log("studyFieldsResponse trails res", res);

          this.trailsListSpinner = true;
          this.studyFieldsResponse = res['payLoad']['studyFieldsResponse'];
          this.totalPages = this.studyFieldsResponse.nstudiesFound/20;
          console.log("studyFieldsResponse trails", this.studyFieldsResponse);
          this.getSelectedTrails(this.reportedVariantId, 0);
        }
      );
    } else if(this.cnaVariantId){
      this.trailService.getTrails(this.nctId,1).subscribe(
        (res: any) => {
          console.log("studyFieldsResponse trails res", res);

          this.trailsListSpinner = true;
          this.studyFieldsResponse = res['payLoad']['studyFieldsResponse'];
          this.totalPages = this.studyFieldsResponse.nstudiesFound/20;
          console.log("studyFieldsResponse trails", this.studyFieldsResponse);
          this.getSelectedTrails(this.cnaVariantId, 1);
        }
      );
    } else if(this.fusionVariantId){
      this.trailService.getTrails(this.nctId,1).subscribe(
        (res: any) => {
          console.log("studyFieldsResponse trails res", res);

          this.trailsListSpinner = true;
          this.studyFieldsResponse = res['payLoad']['studyFieldsResponse'];
          this.totalPages = this.studyFieldsResponse.nstudiesFound/20;
          console.log("studyFieldsResponse trails", this.studyFieldsResponse);
          this.getSelectedTrails(this.fusionVariantId, 2);
        }
      );
    }
  } else {
    if(this.reportedVariantId){
      this.getTrailsApi(this.reportedVariantId,0);
    }else if(this.cnaVariantId){
      this.getTrailsApi(this.cnaVariantId,1);
    }else if(this.fusionVariantId){
      this.getTrailsApi(this.fusionVariantId,2);
    }
  }
  }

  getTrailsApi(referenceId: number, type: number) {
    console.log("reqPageNo",this.reqPageNo);

    this.trailService.getTrails(this.gene,this.reqPageNo).subscribe(
      (res: any) => {
        console.log("studyFieldsResponse trails res", res);

        this.trailsListSpinner = true;
        this.studyFieldsResponse = res['payLoad']['studyFieldsResponse'];
        this.totalPages = this.studyFieldsResponse.nstudiesFound/20;
        console.log("studyFieldsResponse trails", this.studyFieldsResponse);
        this.getSelectedTrails(referenceId, type);
      }
    );
  }

//trail tushar
  getSelectedTrails(referenceId: number, type: number) {
    console.log("getSelectedTrails method",referenceId,type);
    this.selectedTrailNCTIds = [];
    this.selectedStarTrailNCTIds = [];
    this.selectedTrailsPriority=[];
    this.trailService.getSelectedTrails(referenceId, type, false).subscribe(
      (response: any) => {
        this.selectedTrailList = response['payLoad'];
        console.log("getSelectedTrails", this.selectedTrailList);
        this.selectedTrailList.forEach(
          element => {
            this.selectedTrailNCTIds.push(element.nctId);
            if (element.isStar) {
              this.selectedStarTrailNCTIds.push(element.nctId);
            }
          }
        );

        if(this.checkedTrailList){
          this.studyFieldsResponse.studyFields = [];
          this.selectedTrailList.forEach(
            selectedTrail => {
              this.trailService.getTrails(selectedTrail.nctId,1).subscribe(
                (res: any) => {
                  console.log(res['payLoad']['studyFieldsResponse']);
                  res['payLoad']['studyFieldsResponse']['studyFields'][0].selected = true;
                  if (this.selectedStarTrailNCTIds.includes(res['payLoad']['studyFieldsResponse']['studyFields'][0].nctId[0])) {
                    res['payLoad']['studyFieldsResponse']['studyFields'][0].isStarSelected = true;
                  } else {
                    res['payLoad']['studyFieldsResponse']['studyFields'][0].isStarSelected = false;
                  }
                  res['payLoad']['studyFieldsResponse']['studyFields'][0].association = selectedTrail.association;
                  res['payLoad']['studyFieldsResponse']['studyFields'][0].cancerTypeEnum = selectedTrail.cancerTypeEnum;
                  res['payLoad']['studyFieldsResponse']['studyFields'][0].cancerTypes = selectedTrail.cancerTypes;
                  res['payLoad']['studyFieldsResponse']['studyFields'][0].drugs = selectedTrail.drugs;
                  res['payLoad']['studyFieldsResponse']['studyFields'][0].priorityOrder = selectedTrail.priorityOrder;
                  res['payLoad']['studyFieldsResponse']['studyFields'][0].id=selectedTrail.id
                  this.studyFieldsResponse.studyFields.push(res['payLoad']['studyFieldsResponse']['studyFields'][0]);
                }
              );
            }
          );
          this.totalPages = 1;
          this.trailsListSpinner = true;
        } else {
          this.studyFieldsResponse.studyFields.forEach(
            studyField => {
              if (this.selectedTrailNCTIds.includes(studyField.nctId[0])) {
                studyField.selected = true;
              }
              if (this.selectedStarTrailNCTIds.includes(studyField.nctId[0])) {
                studyField.isStarSelected = true;
              } else {
                studyField.isStarSelected = false;
              }

              this.selectedTrailList.forEach(
                selectedTrail => {
                  if (studyField.nctId[0] == selectedTrail.nctId) {
                    studyField.association = selectedTrail.association;
                    studyField.cancerTypeEnum = selectedTrail.cancerTypeEnum;
                    studyField.cancerTypes = selectedTrail.cancerTypes;
                    studyField.drugs = selectedTrail.drugs;
                  }
                }
              );
            }
          );
        }


        // if (this.checkedTrailList) {
        //   console.log("this.selectedStudyFields", this.selectedStudyFields);
        //   this.studyFieldsResponse.studyFields = this.selectedStudyFields;
        //   this.trailsListSpinner = true;
        // }


      }
    );


  }

  assignStudyField(studyField: StudyFields) {
    console.log(studyField);
    this.studyField = studyField;


  }

  selectedTrailsList(event: any) {
    this.checkedTrailList = event['target']['checked'];
    this.switchToggleButton=event['target']['checked'];
    this.trailsListSpinner = false;
    console.log(this.checkedTrailList);
    if (this.checkedTrailList) {
      if(this.reportedVariantId){
        this.getSelectedTrails(this.reportedVariantId,0);
      } else if(this.cnaVariantId){
        this.getSelectedTrails(this.cnaVariantId,1);
      } else if(this.fusionVariantId){
        this.getSelectedTrails(this.fusionVariantId,2);
      }

    } else {
      this.selectedStudyFields = [];
      this.studyFieldsResponse.studyFields = [];
      if(this.reportedVariantId){
        this.getReportedVariant();
      } else if(this.cnaVariantId){
        this.getCNAVariant();
      } else if(this.fusionVariantId){
        this.getFusionVariant();
      }
    }

  }

  cancerTypeList: CancerType[] = [];
  getCancerTypes() {
    this.dataAdminService.cancerTypeList().subscribe(
      (response: any) => {
        this.cancerTypeList = response['payLoad'];
        console.log("cancerTypeList ==> ", this.cancerTypeList);

      }
    );
  }

  backReportedVariant() {
    if (this.reportedVariantId) {
      this.router.navigate(['/report', this.reportedVariant.report.id, 'view_variant', this.reportedVariant.id]);
    } else if (this.cnaVariantId) {
      this.router.navigate(['/report', this.cnaVariant.report.id, 'cna_variant', this.cnaVariant.id]);
    }
  }

  priorityOrder(priorityOrder:number,selectedTrailsId:number){

    if(priorityOrder != null && priorityOrder > 0){
       
            this.reportService.setTailsPriorityOrder(selectedTrailsId, priorityOrder).subscribe(
              (response: any) => {
                console.log("setPriorityOrder",response);
              }
            );
     
    }
  }

}
