import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { AnySoaRecord } from 'dns';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { Gene, GenePattern } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-add-gene-pattern',
  templateUrl: './add-gene-pattern.component.html',
  styleUrls: ['./add-gene-pattern.component.css']
})
export class AddGenePatternComponent implements OnInit {

  editor = ClassicEditorBuild;
  genepatternid!:number;
  genepattern={} as GenePattern
  sampleList=[{ids:1,name:"SOMATIC"},{ids:2,name:"GERMLINE"}]
  sampleTypeList:any=[]
  geneList:Gene[] | any|undefined;
  gene:any
  sampleTest:any
  subType:any
  subTypeBackupList=[
    {id:1,sampleType:"GERMLINE",testNames:"Germline+"},{id:2,sampleType:"GERMLINE",testNames:"HRR Germline"},
    {id:3,sampleType:"GERMLINE",testNames:"Family member testing"},
    {id:5,sampleType:"SOMATIC",testNames:"Absolute"},{id:6,sampleType:"SOMATIC",testNames:"Core"},
    {id:7,sampleType:"SOMATIC",testNames:"First"},{id:8,sampleType:"SOMATIC",testNames:"Focus"},
    {id:9,sampleType:"SOMATIC",testNames:"Indiegene"},{id:10,sampleType:"SOMATIC",testNames:"HRR Somatic"},
]
subTypeList:any=[]
sampleDropdownSettings = {};
subTypeDropdownSettings = {};
geneDropdownSettings = {};
submitSpinner:boolean=false;
statusMsg:string=''
  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.getGenes();
    this.genepatternid = +this.routerAct.snapshot.params['id'];
this.sampleDropdownSettings = {
      singleSelection: false,
      idField: 'ids',
      textField: 'name',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.subTypeDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'testNames',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.geneDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    if(this.genepatternid){
      this.getGenePatternById()
    }

  }

  getGenePatternById(){
    this.sampleTest=[]
    this.subType=[]
    this.dataAdminService.getGenePatternById(this.genepatternid).subscribe((res:any)=>{
      this.genepattern=res['payLoad']
       let geneData:any =res['payLoad']["gene"]
       if(geneData!=null){
        this.gene=[{
          id:geneData["id"],
          name:geneData["name"]
        }]
       }

      if(res['payLoad']['sampleType']?.length>0){
        this.sampleTest = this.sampleList.filter(element=>res['payLoad']['sampleType'].includes(element.name))
          if(res['payLoad']['testNames']?.length>0){
            this.subType=this.subTypeBackupList.filter(element=>res['payLoad']['testNames'].includes(element.testNames))
          }

      }

    })
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  filterTests(test:any){
    if(test && test.length>0){
      this.sampleTypeList=[]
      this.subTypeList=[]
      test.forEach((element:any)=>{
        this.sampleTypeList.push(element.name)
      })
      this.subTypeList= this.subTypeBackupList.filter(element=>this.sampleTypeList.includes(element.sampleType))
    }

  }

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  onSubmit(){
    let testNames:any=[];
    let sampleTestNames:any=[]
    let geneId!:number
    if(this.subType && this.subType.length>0){
      this.subType.forEach((element:any) => {
        testNames.push(element.testNames)
      });
    }
   if(this.sampleTest && this.sampleTest.length>0){
    this.sampleTest.forEach((element:any) => {
      sampleTestNames.push(element.name)
    });
   }
   if(this.gene && this.gene.length>0){
    geneId=this.gene[0].id
   }

    this.genepattern.sampleType=sampleTestNames
    this.genepattern.testNames=testNames
    this.genepattern.geneId=geneId;
    console.log("this.genepattern",this.genepattern)
    if(this.genepatternid){
      this.genepattern.id=this.genepatternid
      this.dataAdminService.addGenePattern(this.genepattern).subscribe((res:any)=>{
        this.statusMsg=res["message"]
        this.showSuccess()
        this.reload()
      })

    }else{
      this.dataAdminService.addGenePattern(this.genepattern).subscribe((res:any)=>{
        this.statusMsg=res["message"]
        this.showSuccess()
        this.reload()
      })

    }
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/genepattern']);
    });
  }

}
