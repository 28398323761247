import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.css']
})
export class FilterListComponent implements OnInit {

  constructor(private toastr: ToastrService, private dataAdminService: DataAdminService,) { }
  filteList:any=[]
  filterListSpinner=true
  filterEngineId!:number
  ngOnInit(): void {
    this.getFilterList()
  }

  getFilterList(){
    this.dataAdminService.getFilterEngineList().subscribe((res:any)=>{
      console.log("List Data ",res )
      this.filteList=res['payLoad']
      this.filterListSpinner=false
     })
  }

  assignFilter(id:number){
    this.filterEngineId=id
  }

  deleteFilterEngine(){
    this.dataAdminService.deleteFilterEngineById(this.filterEngineId).subscribe((res:any)=>{
      this.getFilterList()
     })
  }
  
}
