import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Pathway, pathwayListRes, pathwayRes } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-pathway',
  templateUrl: './pathway.component.html',
  styleUrls: ['./pathway.component.css']
})
export class PathwayComponent implements OnInit {


  pathwayList: pathwayRes[] | undefined;
  pathwayId!: number;
  statusMsg!: string;

  pathwayListSpinner = true;


  constructor(
    private dataAdminService: DataAdminService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {
    this.getPathwayList();
  }

  getPathwayList() {
    this.dataAdminService.getPathwayList().subscribe(
      (response: any) => {
        this.pathwayListSpinner = false;
        this.pathwayList = response['payLoad']['list'];
        console.log("pathway list =>",this.pathwayList);
      }
    );
  }

  assignPathway(id: number) {
    this.pathwayId = id;
    console.log("pathwayId",this.pathwayId);
  }

  deletePathway(){
    console.log("pathwayId",this.pathwayId);
    this.dataAdminService.deletePathway(this.pathwayId).subscribe(
      (response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/pathways']);
    });
  }

}
