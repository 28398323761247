import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ToastrService } from 'ngx-toastr';
import { FirstReportPdfRes, RelevantData, ReportRes } from 'src/app/_interfaces.ts/reports';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-first72-solid-tmh',
  templateUrl: './first72-solid-tmh.component.html',
  styleUrls: ['./first72-solid-tmh.component.css']
})
export class First72SolidTMHComponent implements OnInit {


  constructor(
  ) { }

  ngOnInit(): void {

  }

}
