import { Component, OnInit } from '@angular/core';
import { CancerType, Drug, Gene, HotspotClinicalTrailReqDto, HotspotClinicalTrialRes, Therapy } from 'src/app/_interfaces.ts/admin';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-hotspot-trail',
  templateUrl: './add-hotspot-trail.component.html',
  styleUrls: ['./add-hotspot-trail.component.css']
})
export class AddHotspotTrailComponent implements OnInit {

  editor = ClassicEditor;
  add_clinical = {} as HotspotClinicalTrailReqDto;
  submitSpinner = false;
  cancerTypeList!: CancerType[];
  geneList!: Gene[];
  therapyList!: Therapy[];
  statusMsg!: string;

  hotspotClinicalTrailId!: number;
  drugsDropdownSettings = {};
  cancerTypeDropdownSettings = {};
  geneDropdownSettings = {};

  drugs = [];
  drugIds: number[] = [];
  selectedDrugs: Drug[] = [];
  selectedcancertype: CancerType[] = [];
  selectedGene: Gene[] = [];

  getSelectedGene: Gene[] = [];
  getSelectedCancerType: CancerType[] = [];
  hotspotDropdownSettings = {};
  ClinicalTrialList: HotspotClinicalTrialRes[]=[];

  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {
    this.getHotspotClinicalTrailList();
    this.add_clinical.summary = "";
    this.hotspotClinicalTrailId = +this.routerAct.snapshot.params['id'];
    if(this.hotspotClinicalTrailId){
      this.getHotspotClinicalTrail();
    }

    this.getCancerTypes();
    this.getGenes();
    this.getTherspyList();

    this.getDrugs();

    this.drugsDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.cancerTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.geneDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.hotspotDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'tag',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  tags : string[] = []
  getHotspotClinicalTrailList(){
    this.dataAdminService.hotspotClinicalTrailList().subscribe(
      (response: any) => {
        this.ClinicalTrialList = response['payLoad'];
        console.log("working..!\n",this.ClinicalTrialList)
        this.statusMsg="Ready to edit...!";
        this.showSuccess();
      }
    );
  }
  drugnames:string[]=[];
  onSubmit(){
    this.submitSpinner = true;

    if(this.selectedDrugs.length > 0){
      for (let i = 0; i < this.selectedDrugs.length; i++) {
        const id = this.selectedDrugs[i]['id'];
        const name = this.selectedDrugs[i]['name'];
        this.drugnames.push(name);
        this.drugIds.push(id);
      }
    }
    console.log("drignames==>\n",this.drugnames,this.selectedGene.length,this.selectedGene)

    

    if(this.selectedcancertype.length == 1){
      this.add_clinical.cancerTypeId = this.selectedcancertype[0]['id'];
    }
    this.add_clinical.drugIds = this.drugIds;
    this.drugIds = [];
    let geneNames:any=[]
    let geneIds:any=[]
    if(this.selectedGene.length>0){
      this.selectedGene.forEach((gene:any)=>{
        geneIds.push(gene['id'])
        geneNames.push(gene['name'])
      })
    }
    if(this.selectedGene.length >0){
      this.add_clinical.geneIds =geneIds;
  }
    if(this.drugnames.length==0){
      this.add_clinical.tag=geneNames.toString()+", "+this.selectedcancertype[0]['name']+", "+this.add_clinical.association+", "+this.add_clinical.evidence+".";

    }else{
      this.add_clinical.tag=geneNames.toString()+", "+this.selectedcancertype[0]['name']+", ("+this.drugnames.toString()+"), "+this.add_clinical.association+", "+this.add_clinical.evidence+".";
    }

    console.log("add_clinical",this.add_clinical);
    this.dataAdminService.addHotspotClinicalTrail(this.add_clinical).subscribe(
      (response: any) => {
        this.submitSpinner = false;
        console.log("sumbit response",response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );
  }

  getHotspotClinicalTrail(){
    this.dataAdminService.getHotspotClinicalTrail(this.hotspotClinicalTrailId).subscribe(
      (response:any) => {
        this.add_clinical = response['payLoad'];
        this.selectedDrugs = response['payLoad']['drugs'];

        if(response['payLoad']['gene'] != null  ){
          this.getSelectedGene.push(response['payLoad']['gene']);        
          this.selectedGene = this.getSelectedGene;
        }else{
          if(response['payLoad']['genes'].length>0){
            response['payLoad']['genes'].forEach((element:any) => {
              this.getSelectedGene.push(element);
            });
            this.selectedGene = this.getSelectedGene;
          }
        }

        if(response['payLoad']['cancerType'] != null){
        this.getSelectedCancerType.push(response['payLoad']['cancerType']);
        this.selectedcancertype = this.getSelectedCancerType;
        }

      }
    );
  }

onItemSelect(hotspotreq:any){
    console.log(hotspotreq);
   this.ClinicalTrialList.filter(hotso => {
      return hotso.id == hotspotreq.id
    }).map( (hotspot) =>  {
      if(hotspot.genes && hotspot.genes.length>0){
        hotspot.genes.forEach((element:any)=>{
          this.getSelectedGene.push(element);
        })
      }
      this.selectedGene = this.getSelectedGene;
      this.getSelectedCancerType.push(hotspot.cancerType);
      this.selectedcancertype = this.getSelectedCancerType;
      this.selectedDrugs = hotspot.drugs;
      this.add_clinical.evidence=hotspot.evidence;
      this.add_clinical.association=hotspot.association;
      this.add_clinical.referenceLink=hotspot.referenceLink;
      this.add_clinical.referencePmIds=hotspot.referencePmIds;
      this.add_clinical.genomicLocation=hotspot.genomicLocation;
      console.log("this.ClinicalTrialList[i].transcript",hotspot.transcript)
      this.add_clinical.transcript=hotspot.transcript;
      console.log("this.ClinicalTrialList[i].cposition",hotspot.cposition);
      this.add_clinical.cposition=hotspot.cposition;
      console.log("this.ClinicalTrialList[i].pposition",hotspot.pposition);
      this.add_clinical.pposition=hotspot.pposition;
      this.add_clinical.clinicalTrailType=hotspot.clinicalTrailType;
      this.add_clinical.summary=hotspot.summary;
      console.log("add_clinical---->\n",this.add_clinical);

  })
  }
  onItemDeSelect(hotspot:any){
    console.log(hotspot);
    this.ngOnInit();
  }

  getDrugs() {
    this.dataAdminService.getDrugs().subscribe(
      (response: any) => {
        this.drugs = response['payLoad'];
      }
    );
  }

  getCancerTypes(){
    this.dataAdminService.cancerTypeList().subscribe(
      (response: any) => {
        this.cancerTypeList = response['payLoad'];
      }
    );
  }

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  getTherspyList(){
    this.dataAdminService.getTherapyList().subscribe(
      (response: any) => {
        this.therapyList = response['payLoad'];
      }
    );
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/hotspot-clinical-trails']);
    });
  }


}
