<div class="container-fluid px-0">
    <h5 class="mb-0 d-inline-block   mb-3 mt-4">Add Clinical Trial</h5>
   <div class="mx-0 p-4 bg-white cust_shadow rounded w-75">
    <div class="row mx-3">
        <div class="col">
            <form class="form_list" (ngSubmit)="onSubmit()">
                <div class="row mt-5">
                    <div class="col-8">
                        <div style="padding-bottom: 20px!important;">
                            <ng-multiselect-dropdown placeholder="Select Tag"
                                [settings]="hotspotDropdownSettings" [data]="ClinicalTrialList"
                                (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Cancer Type :</label>
                            <div class="col-sm-8">
                                <!-- <select class="form-control" name="cancerType" [(ngModel)]="add_clinical.cancerTypeId">
                                    <option *ngFor="let cancerType of CancerType" value={{cancerType.id}}

                                        [selected]="add_clinical.cancerTypeId == cancerType.id">{{cancerType.name}}
                                    </option>
                                </select> -->
                                <ng-multiselect-dropdown [data]="cancerTypeList" [(ngModel)]="selectedcancertype" name="cancerType"
                                    [settings]="cancerTypeDropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Gene :</label>
                            <div class="col-sm-8">
                                <!-- <select class="form-control" name="gene" [(ngModel)]="add_clinical.geneId">
                                    <option *ngFor="let gene of geneList" value={{gene.id}}>{{gene.name}}</option>
                                </select> -->
                                <ng-multiselect-dropdown [data]="geneList" [(ngModel)]="selectedGene" name="gene"
                                    [settings]="geneDropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>



                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Drugs :</label>
                            <div class="col-sm-8">
                                <ng-multiselect-dropdown [data]="drugs" [(ngModel)]="selectedDrugs" name="drug"
                                    [settings]="drugsDropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Evidence :</label>
                            <div class="col-sm-8">
                                <select type="text" class="form-control" name="evidence"
                                    [(ngModel)]="add_clinical.evidence">
                                    <option value="LEVEL_1">LEVEL_1</option>
                                    <option value="LEVEL_2">LEVEL_2</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Association :</label>
                            <div class="col-sm-8">
                                <select type="text" class="form-control" name="association"
                                    [(ngModel)]="add_clinical.association">
                                    <option value="RESPONSIVE">RESPONSIVE(S)</option>
                                    <option value="RESISTANCE">RESISTANCE(R)</option>
                                    <option value="ONGOING">ONGOING(O)</option>
                                </select>
                            </div>
                        </div>

                        <!-- <div class="form-group row">
                            <label class="col-sm-4 control-label">Reference :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="reference"
                                    [(ngModel)]="add_clinical.reference">
                            </div>
                        </div> -->

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Reference Link :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="referenceLink"
                                    [(ngModel)]="add_clinical.referenceLink">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Reference PmIds :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="referencePmIds"
                                [(ngModel)]="add_clinical.referencePmIds">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Genomic Location :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="genomicLocation"
                                [(ngModel)]="add_clinical.genomicLocation">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Transcript :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="transcript"
                                [(ngModel)]="add_clinical.transcript">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">C. Position :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="cposition"
                                [(ngModel)]="add_clinical.cposition">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">P. Position :</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" name="pposition"
                                [(ngModel)]="add_clinical.pposition">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Trial Type :</label>
                            <div class="col-sm-8">
                                <select type="text" class="form-control" name="clinicalTrailType"
                                    [(ngModel)]="add_clinical.clinicalTrailType">
                                    <option value="FDA">FDA</option>
                                    <option value="NCC">NCCN</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 control-label">Summary :</label>
                            <div class="ml-5">
                                <ckeditor [editor]="editor" name="summary" [(ngModel)]="add_clinical.summary"></ckeditor>
                            </div>
                        </div>


                        <div class="add-emp  mb-5">
                            <div class="col-sm-8 offset-sm-4 px-0 text-right">
                                <button type="submit" class="btn save_btn text-uppercase bg-softblue ml-2"
                                    [disabled]="submitSpinner">
                                    <!-- <span class="delete_clr"  data-toggle="modal" data-target="#deleteDrugModal"> Submit </span> -->
                                    <span *ngIf="!submitSpinner"> Submit </span>
                                    <div *ngIf="submitSpinner" class="spinner-border text-dark">
                                        <span class="sr-only"></span>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </form>
        </div>
    </div>
   </div>
</div>
<!-- <div class="modal fade" id="deleteDrugModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content surgery-modal">

      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Submit</h5>
        <a class="text-softblue" data-dismiss="modal">
            <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4">
              <h6 class=" mb-4">Are you sure want to Submit ?</h6>
            <div class="mx-0 ml-auto d-table">
                <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                (click)="onSubmit()" data-dismiss="modal">Yes</button>
                <button type="button" class="btn bg-softblue text-white px-5"
                data-dismiss="modal">No</button>
            </div>
      </div>

    </div>
  </div>
</div> -->
