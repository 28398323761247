<!DOCTYPE html>
<html lang="en">
  <head>

    <title>page1</title>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
    />
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css"
    />
  </head>

  <div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
    <span class="visually-hidden"></span>
    <div
      class="spinner-border"
      style="width: 8rem; height: 8rem"
      role="status"
    ></div>
  </div>

  <body *ngIf="reportData">
    <button
      [disabled]="downloadSpinner"
      type="button"
      class="btn btn-primary m-2 float-right download-btn"
      (click)="downloadReport()"
    >
      <span *ngIf="!downloadSpinner">
        <i class="zmdi zmdi-download"></i> Download Report
      </span>
      <div *ngIf="downloadSpinner" class="spinner-border text-dark">
        <span class="sr-only"></span>
      </div>
    </button>
    <div id="page" style="margin: 0 auto" class="main_wrapper">
      <img
        src="../../../assets/cover_indiegene.png"
        style="width: 100%"/>
    </div>

    <div id="page1" style="padding: 10px; margin: 0 auto" class="main_wrapper">
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col-7">
            <div class="">
              <div class="w-50" style="min-height: 80px">
                <p style="color: #208dcb; font-weight: 700" class="py-1">
                  SCOPE OF THE TEST
                </p>
                <p>
                  SNVs, InDels, CNAs, Gene Fusions,TMB, MSI and PD-L1 status
                </p>

              </div>
              <img src="../../../assets/BorderLine.png" class="mr-2 w-75" />
            </div>
          </div>
          <div class="col" >
            <div class="">
              <div class="w-100" style="min-height: 80px" *ngIf="report.clinicalSummary != null">
                <p style="color: #208dcb; font-weight: 700" class="py-1">
                Clinical Indication
                </p>
                <p style="color: #208dcb; font-weight: 700" class="py-1">
                  <span style="color: #000 !important">{{
                    report.clinicalSummary
                  }}</span>
                </p>
              </div>
              <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
            </div>
          </div>
        </div>
        <!--  -->
        <div class="row pt-2">
          <div class="col-7">
            <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
            <ul class="list-unstyled">
              <li class="pb-1">
                <span class="clr-cyan">Name : </span
                >{{ report.patient?.name | uppercase }}
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Gender : </span
                >{{ report.patient?.gender | titlecase }}
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Age/DOB : </span
                ><span *ngIf="report.patient.dateOfBirth != null"
                  >{{ report.patient.dateOfBirth | date : "dd/MM/yyyy" }}
                </span>
                <span *ngIf="report.patient.age != null">
                  {{ report.patient.age }} Years
                </span>
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Reporting Date : </span
                >{{ date | date : "dd/MM/yyyy" }}
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-unstyled pt-4 mt-1">
              <li *ngIf="report.cancerSubType != null" class="pb-1">
                <span class="clr-cyan">Cancer Celltype : </span
                >{{ report.cancerSubType }}
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Sample Source : </span>{{ sampleSource }}
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Consulting Clinician : </span
                >{{referringClinician }}
              </li>
              <li class="pb-1">
                <span class="clr-cyan">Hospital : </span
                >{{ report.labDetails?.hospital }}
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div class="row mt-4 py-2">
          <div class="col">
            <h5 class="mb-0 text-uppercase f-weight_700">Results</h5>
          </div>
        </div>
        <h5
          class="py-2 text-center w-100 rounded"
          style="background: #208dcb; color: #fff"
        >
          CLINICALLY ACTIONABLE BIOMARKERS
        </h5>
        <!--  -->
        <div class="row">
          <div class="col-12">
            <table class="page_1 cust_table w-100 mt-3">
              <tr>
                <th class="text-center">Biomarker Category</th>
                <th class="text-center">Result Status</th>
                <th class="text-center">Evidence</th>
                <th class="text-center">Effect</th>
                <th class="text-center">Therapy</th>
              </tr>
              <!-- SNV -->

              <ng-container
                *ngIf="(reportPdfRes?.starClinicalTrailList?.length || 0) > 0"
              >
                <tr
                  *ngFor="
                    let starClinicalTrail of reportPdfRes?.starClinicalTrailList;
                    let i = index
                  "
                >
                  <td
                    *ngIf="i == 0"
                    [attr.rowspan]="reportPdfRes?.starClinicalTrailList?.length"
                  >
                    SNVs/InDels
                  </td>
                  <!-- <td *ngIf="i>0"></td> -->
                  <td>
                    <i>{{ starClinicalTrail?.gene }}</i>
                    <!-- Provide variant detail (p.  ) along with The Gene name (125) -->
                    <p>
                      <span
                        style="
                          font-size: 12px !important;
                          text-align: left !important;
                        "
                        >{{ starClinicalTrail?.snvVarinat }}</span
                      >
                    </p>
                  </td>
                  <td colspan="3" class="border_line">
                    <table style="padding: 0px !important">
                      <tr *ngFor="let item of starClinicalTrail?.requriedData">
                        <td style="width: 144px; padding: 0px !important">
                          <img
                            *ngIf="item?.evidance == 'LEVEL_1'"
                            class="mt-2"
                            src="../../../assets/Level1.png"
                            style="width: 20px"
                          />
                          <img
                            *ngIf="item?.evidance == 'LEVEL_2'"
                            class="mt-2"
                            src="../../../assets/Level2.png"
                            style="width: 20px"
                          />
                          <img
                            *ngIf="item?.evidance == 'LEVEL_3'"
                            class="mt-2"
                            src="../../../assets/Level3.png"
                            style="width: 20px"
                          />
                          <img
                            *ngIf="item?.evidance == 'LEVEL_4'"
                            class="mt-2"
                            src="../../../assets/Level4.png"
                            style="width: 20px"
                          />
                          <span *ngIf="item?.evidance == undefined">NA</span>
                        </td>

                        <td style="width: 144px; padding: 0px !important">
                          <img
                            *ngIf="item?.association == 'RESPONSIVE'"
                            src="../../../assets/responsive.png"
                            style="width: 15px"
                          />
                          <img
                            *ngIf="item?.association == 'RESISTANCE'"
                            src="../../../assets/Resistance.png"
                            style="width: 15px"
                          />
                          <img
                            *ngIf="item?.association == 'ONGOING'"
                            src="../../../assets/ClinicalTrial.png"
                            style="width: 15px"
                          />
                          <span *ngIf="item?.association == undefined">NA</span>
                        </td>

                        <td
                          style="
                            overflow-wrap: anywhere;
                            padding: 0px !important;
                          "
                        >
                          {{ item?.drugName || "NA" }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </ng-container>
              <ng-container
                *ngIf="reportPdfRes?.starClinicalTrailList?.length == 0"
              >
                <tr>
                  <td>SNVs/InDels</td>
                  <td colspan="4" style="text-align: center">
                    Clinically relevant SNV's/InDels were not detected
                  </td>
                </tr>
              </ng-container>

              <!-- CNA -->
              <ng-container
                *ngIf="reportPdfRes.cnaStarClinicalTrailList.length > 0"
              >
                <tr
                  *ngFor="
                    let starClinicalTrail of reportPdfRes.cnaStarClinicalTrailList;
                    let i = index
                  "
                >
                  <td
                    *ngIf="i == 0"
                    [attr.rowspan]="
                      reportPdfRes.cnaStarClinicalTrailList.length
                    "
                  >
                    CNAs
                  </td>
                  <td>
                    <i>{{ starClinicalTrail.gene }}</i>
                    <p>
                      <span
                        style="
                          font-size: 12px !important;
                          text-align: left !important;
                        "
                        >{{ starClinicalTrail.snvVarinat }}</span
                      >
                    </p>
                  </td>
                  <td colspan="3">
                    <table style="padding: 0px !important">
                      <tr *ngFor="let item of starClinicalTrail.requriedData">
                        <td style="width: 144px; padding: 0px !important">
                          <img
                            *ngIf="item.evidance == 'LEVEL_1'"
                            class="mt-2"
                            src="../../../assets/Level1.png"
                            style="width: 20px"
                          />
                          <img
                            *ngIf="item.evidance == 'LEVEL_2'"
                            class="mt-2"
                            src="../../../assets/Level2.png"
                            style="width: 20px"
                          />
                          <img
                            *ngIf="item.evidance == 'LEVEL_3'"
                            class="mt-2"
                            src="../../../assets/Level3.png"
                            style="width: 20px"
                          />
                          <img
                            *ngIf="item.evidance == 'LEVEL_4'"
                            class="mt-2"
                            src="../../../assets/Level4.png"
                            style="width: 20px"
                          />
                          <span *ngIf="item.evidance == undefined">NA</span>
                        </td>

                        <td style="width: 144px; padding: 0px !important">
                          <img
                            *ngIf="item.association == 'RESPONSIVE'"
                            src="../../../assets/responsive.png"
                            style="width: 15px"
                          />
                          <img
                            *ngIf="item.association == 'RESISTANCE'"
                            src="../../../assets/Resistance.png"
                            style="width: 15px"
                          />
                          <img
                            *ngIf="item.association == 'ONGOING'"
                            src="../../../assets/ClinicalTrial.png"
                            style="width: 15px"
                          />
                          <span *ngIf="item.association == undefined">NA</span>
                        </td>

                        <td
                          style="
                            overflow-wrap: anywhere;
                            padding: 0px !important;
                          "
                        >
                          {{ item.drugName || "NA" }}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <!-- <td class="text-center">

                                    <p *ngFor="let evidence of starClinicalTrail.evidenceList">
                                        <img *ngIf="evidence == 'LEVEL_1'" class="mt-2" src="../../../assets/dot-2.png"
                                            style="width: 20px;">
                                    </p>
                                    <p *ngFor="let nctEvidence of starClinicalTrail.nctEvidenceList">
                                        <img *ngIf="nctEvidence == 'LEVEL_3'" src="../../../assets/dot-1.png"
                                            style="width: 15px;">
                                    </p>

                                </td>
                                <td class="text-center">

                                    <p *ngFor="let association of starClinicalTrail.associationList">
                                        <img *ngIf="association == 'RESPONSIVE'" mt-2
                                            src="../../../assets/responsive.png"
                                            style="width: 20px; margin-right: 5px;">
                                        <img *ngIf="association == 'RESISTANCE'" mt-2
                                            src="../../../assets/resistence.png"
                                            style="width: 20px; margin-right: 5px;">
                                        <img *ngIf="association == 'ONGOING'" mt-2
                                            src="../../../assets/ClinicalTrial.png"
                                            style="width: 20px; margin-right: 5px;">
                                    </p>
                                    <p *ngFor="let nctAssociation of starClinicalTrail.nctAssociationList">
                                        <img *ngIf="nctAssociation == 'RESPONSIVE'" src="../../../assets/responsive.png"
                                            style="width: 15px;">
                                        <img *ngIf="nctAssociation == 'RESISTANCE'" src="../../../assets/Resistance.png"
                                            style="width: 15px;">
                                        <img *ngIf="nctAssociation == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                                            style="width: 15px;">

                                    </p>


                                </td>
                                <td>

                                    <div *ngFor="let drugs of starClinicalTrail.drugList">
                                        <span *ngFor="let drug of drugs, let i=index">{{drug.name|titlecase}}
                                            <span *ngIf="drugs.length-1 != i">{{i ? '' : ', '}}</span>
                                        </span>
                                    </div>

                                    <div *ngFor="let drugs of starClinicalTrail.nctDrugList">
                                        <span *ngFor="let drug of drugs, let i=index">
                                            {{drug|titlecase}}<span *ngIf="drugs.length-1 != i">{{i ? '' : ''}},</span>
                                        </span>
                                    </div>


                                </td> -->
                </tr>
              </ng-container>
              <ng-container
                *ngIf="reportPdfRes.cnaStarClinicalTrailList.length == 0"
              >
                <tr>
                  <td>CNAs</td>
                  <td colspan="4" style="text-align: center">
                    Clinically relevant CNAs were not detected
                  </td>
                </tr>
              </ng-container>
              <!-- Fusion -->

              <ng-container
                *ngIf="
                  reportPdfRes.fusionStarClinicalTrailList.length == 0
                ">
                <tr>
                  <td>Gene Fusions</td>
                  <td
                    colspan="4"
                    style="text-align: center"
                    *ngIf="report?.fusionExcelFile != null"
                  >
                    Clinically relevant Gene Fusions were not detected
                  </td>
                  <!-- <td>Gene Fusions</td> -->
                  <td
                    colspan="4"
                    style="text-align: center"
                    *ngIf="report?.fusionExcelFile == null"
                  >
                    Gene fusions were not analysed
                  </td>
                </tr>
              </ng-container>
              <!-- PD-L1 -->
              <tr
                *ngIf="(((this.report.immunoDetails?.pdl1Status ==
                      'NOT_PERFORMED' &&
                      tmb > 20) ||
                      (this.report.immunoDetails?.pdl1Status ==
                        'NOT_PERFORMED' &&
                        msi > 15) ||
                      msi > 15 ||
                      tmb > 20 ||
                      this.report.immunoDetails?.pdl1Status == 'EXPRESSION' ||
                      (this.report.immunoDetails?.pdl1Status ==
                        'NO_EXPRESSION' &&
                        tmb > 20) ||
                      (this.report.immunoDetails?.pdl1Status ==
                        'NO_EXPRESSION' &&
                        msi > 15)))
                "
              >
                <td>PD-L1</td>
                <td>
                  <p
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status == 'EXPRESSION'
                    "
                  >
                    PD-L1 Positive
                  </p>
                  <p
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status == 'NO_EXPRESSION'
                    "
                  >
                    PD-L1 Negative
                  </p>
                  <p
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status == 'NOT_PERFORMED'
                    "
                  >
                    PD-L1 Not Performed
                  </p>
                </td>
                <td class="text-center" style="text-align: left !important">
                  <div
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status ==
                        'NOT_PERFORMED' ||
                      this.report.immunoDetails?.pdl1Status == 'NO_EXPRESSION'
                    "
                  >
                    NA
                  </div>
                  <div
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status == 'EXPRESSION'
                    "
                  >
                    <img src="../../../assets/dot-2.png" style="width: 20px" />
                  </div>
                </td>
                <td class="text-center" style="text-align: left !important">
                  <div
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status ==
                        'NOT_PERFORMED' ||
                      this.report.immunoDetails?.pdl1Status == 'NO_EXPRESSION'
                    "
                  >
                    NA
                  </div>
                  <div
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status == 'EXPRESSION'
                    "
                  >
                    <img
                      src="../../../assets/responsive.png"
                      style="width: 20px; margin-right: 5px"
                    />
                  </div>
                </td>
                <td style="text-align: left !important">
                  <div
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status ==
                        'NOT_PERFORMED' ||
                      this.report.immunoDetails?.pdl1Status == 'NO_EXPRESSION'
                    "
                  >
                    NA
                  </div>
                  <div
                    *ngIf="
                      this.report.immunoDetails?.pdl1Status == 'EXPRESSION'
                    "
                  >
                    <div
                      *ngFor="
                        let pdl1ClinicalTrail of reportPdfRes.pdl1ClinicalTrail
                      "
                    >
                      <span
                        *ngFor="
                          let drugs of pdl1ClinicalTrail.drugList;
                          let di = index
                        "
                      >
                        <span *ngFor="let drug of drugs; let i = index">
                          {{ drug.name }}{{ i ? "" : ""
                          }}<span
                            *ngIf="pdl1ClinicalTrail.drugList.length - 1 != di"
                            >,</span
                          ></span
                        >
                      </span>
                    </div>
                  </div>
                </td>
              </tr>

              <!-- TMB -->
              <tr *ngIf="tmb > 20">
                <td>TMB</td>
                <td>TMB High</td>
                <td class="text-center" style="text-align: left !important">
                  <img src="../../../assets/dot-2.png" style="width: 20px" />
                </td>
                <td class="text-center" style="text-align: left !important">
                  <img
                    src="../../../assets/responsive.png"
                    style="width: 20px; margin-right: 5px"
                  />
                </td>
                <td style="text-align: left !important">
                  <div
                    *ngFor="
                      let highTmbClinicalTrail of reportPdfRes.highTmbClinicalTrail
                    "
                  >
                    <span
                      *ngFor="
                        let drugs of highTmbClinicalTrail.drugList;
                        let di = index
                      "
                    >
                      <span *ngFor="let drug of drugs; let i = index">
                        {{ drug.name
                        }}{{
                          highTmbClinicalTrail.drugList.length - 1 != di
                            ? ","
                            : ""
                        }}
                      </span>
                    </span>
                  </div>
                </td>
              </tr>

              <!-- MSI -->
              <tr
                *ngIf="
                  ( msi > 15) ||
                  (msi > 16)
                "
              >
                <td>MSI</td>
                <td>MSI-H</td>
                <td class="text-center" style="text-align: left !important">
                  <img src="../../../assets/dot-2.png" style="width: 20px" />
                </td>
                <td class="text-center" style="text-align: left !important">
                  <img
                    src="../../../assets/responsive.png"
                    style="width: 20px; margin-right: 5px"
                  />
                </td>
                <td style="text-align: left !important">
                  <div
                    *ngFor="
                      let highMsiClinicalTrail of reportPdfRes.highMsiClinicalTrail
                    "
                  >
                    <span
                      *ngFor="
                        let drugs of highMsiClinicalTrail.drugList;
                        let di = index
                      "
                    >
                      <span *ngFor="let drug of drugs; let i = index"
                        >{{ drug.name }}{{ i ? "" : "" }}
                        <span
                          *ngIf="highMsiClinicalTrail.drugList.length - 1 != di"
                          >,</span
                        >
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
              <tr *ngIf="msi <= 16">
                <td>MSI</td>
                <td
                  colspan="4"
                  *ngIf="msi > 14 && msi <= 16"
                  style="text-align: center !important"
                >
                  Borderline
                </td>
                <td
                  colspan="4"
                  *ngIf="msi <= 14"
                  style="text-align: center !important"
                >
                  MSS
                </td>
              </tr>
              <!-- when all are Negative -->
              <tr
                *ngIf="

                  ((this.report.immunoDetails?.pdl1Status == 'NOT_PERFORMED' &&
                    tmb <= 20 &&
                    msi <= 15) ||
                    (this.report.immunoDetails?.pdl1Status == 'NO_EXPRESSION' &&
                      tmb <= 20 &&
                      msi <= 15) ||
                    (this.report.immunoDetails?.pdl1Status != 'EXPRESSION' &&
                      msi <= 15 &&
                      tmb <= 20)
                    )
                "
              >
                <td>
                  TMB/MSI
                  <span>/PD-L1</span>
                </td>
                <td colspan="4" style="text-align: center !important">
                  Negative
                </td>
              </tr>
            </table>
            <p style="font-size: 15px !important" class="mt-3">
              <i> *NA: Not Applicable </i>
            </p>
          </div>
        </div>

        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="page2" style="padding: 10px; margin: 0 auto" class="main_wrapper">
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row mt-2">
          <div class="col">
            <h5
              class="text-center rounded py-2 font-weight-bold"
              style="border: 1px solid #208dcb; color: #208dcb"
            >
              SCIENTIFIC EVIDENCE BASED SUMMARY OF THE GENOMIC FINDINGS
            </h5>
            <div
              class="py-5"
              [innerHtml]="evidenceSummary"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p class="mb-3">
                        {{report.evidenceSummary}}
                    </p> -->
            <!-- <pre>{{report.evidenceSummary}}</pre> -->
          </div>
        </div>
        <!--  -->
        <!-- <div class="row" *ngIf="reportPdfRes.variantPathwayList.length > 0">
          <div class="col-12 mb-3">
            <h5
              class="mt-3 mb-2 text-center rounded py-2 font-weight-bold"
              style="border: 1px solid #208dcb; color: #208dcb"
            >
              CLINICALLY RELEVANT VARIANTS AND ASSOCIATED PATHWAYS
            </h5>
          </div>
          <div class="col mt-2">
            <table class="Clinical-relavent_table w-100 mt-2">
              <tr>
                <th>Gene Name</th>
                <th>Variant</th>
                <th>Exon Number</th>
                <th>Mutant Allele Burden (%)</th>
                <th>Associated Pathway</th>
              </tr>
              <tr
                *ngFor="let variantPathway of reportPdfRes.variantPathwayList"
              >
                <td>
                  <i>{{ variantPathway.gene }}</i>
                </td>
                <td>
                  <span
                    *ngIf="
                      variantPathway.variant != null ||
                      variantPathway.variant != ' ' ||
                      variantPathway.variant != ''
                    "
                    >{{ variantPathway.variant }}</span
                  >
                  <span
                    *ngIf="
                      variantPathway.variant == null ||
                      variantPathway.variant == ' ' ||
                      variantPathway.variant == ''
                    "
                    >NA</span
                  >
                </td>

                <td>{{ variantPathway.exonNumber || "NA" }}</td>
                <td *ngIf="variantPathway.mutantBurdenPercent != null">
                  {{ variantPathway.mutantBurdenPercent }}
                </td>
                <td *ngIf="variantPathway.mutantBurdenPercent == null">NA</td>
                <td>{{ variantPathway.associatedPathway }}</td>
              </tr>
            </table>
            <p style="font-size: 15px !important" class="mt-3">
              <i> *NA: Not Applicable </i>
            </p>
          </div>
        </div> -->

        <!-- footer -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page3"
      *ngIf="
        report.interpretation != null && report.interpretation != '<p></p>'
      "
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row mt-2">
          <div class="col">
            <h5
              class="mb-4 text-center rounded py-2"
              style="font-weight: 500; background-color: #208dcb; color: #fff"
            >
              RESULT INTERPRETATION
            </h5>
            <div
              [innerHtml]="interpretation0"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p class="mb-3">
                        {{report.interpretation}}
                    </p> -->
            <!-- <pre>{{report.interpretation}}</pre> -->
          </div>
        </div>
        <!--  -->

        <!-- footer -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page3_1"
      *ngIf="interpretation1 != null && interpretation1 != '<p></p>'"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row mt-2">
          <div class="col">
            <h5
              class="mb-4 text-center rounded py-2"
              style="font-weight: 500; background-color: #208dcb; color: #fff"
            >
              RESULT INTERPRETATION
            </h5>
            <!-- <h1>{{interpretation1.length}}</h1> -->
            <div
              [innerHtml]="interpretation1"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p class="mb-3">
                    {{report.interpretation}}
                </p> -->
            <!-- <pre>{{report.interpretation}}</pre> -->
          </div>
        </div>
        <!--  -->

        <!-- footer -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="page4" style="padding: 10px; margin: 0 auto" class="main_wrapper">
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row mt-2">
          <div class="col">
            <h5
              class="mb-2 text-center rounded py-2"
              style="font-weight: 500; background-color: #208dcb; color: #fff"
            >
              IMMUNOTHERAPY HIGHLIGHTS
            </h5>

          </div>
        </div>
        <!--  -->
        <div class="row">
          <div class="col">
            <table class="immuno_table w-100">
              <tr>
                <th>
                  Biomarkers
                  <span
                    style="
                      color: #208dcb;
                      font-weight: bold;
                      font-size: 17px !important;
                    "
                    >(Technique)</span
                  >
                </th>
                <th>Impact</th>
                <th>Status</th>
              </tr>

              <!-- PD-L1 -->
              <tr>
                <td>
                  PD-L1 <br />
                  <span style="color: #208dcb">(IHC)</span>
                </td>
                <td>
                  <span style="color: #208dcb; font-weight: bold"
                    >PD-L1 expression</span
                  >
                  can indicate that a patient will be more likely to respond to
                  immunotherapy
                </td>
                <td *ngIf="report.immunoDetails?.pdl1Status == 'EXPRESSION'">
                  <img
                    src="../../../assets/PD-L1 Expression.png"
                    class="mr-2"
                  />
                  <span style="color: #208dcb; font-weight: bold"
                    >PD-L1 Expression</span
                  >
                </td>
                <td *ngIf="report.immunoDetails?.pdl1Status == 'NO_EXPRESSION'">
                  <img src="../../../assets/STABLE.png" class="mr-2" />
                  PD-L1 No Expression
                </td>
                <td *ngIf="report.immunoDetails?.pdl1Status == 'NOT_PERFORMED'">
                  <img src="../../../assets/STABLE.png" class="mr-2" />
                  PD-L1 Not Performed
                </td>
              </tr>

              <!-- TMB -->
              <tr style="text-align: justify" >
                <td>
                  Tumor Mutational Burden<br />
                  <span style="color: #208dcb">TMB (NGS)</span>
                </td>
                <td>
                  <span style="color: #208dcb; font-weight: bold">TMB</span> is
                  a measure of the mutation load in a tumor sample. High TMB is
                  associated with production of more neo-antigens which may be
                  recognized by the immune system and inciting an anti-tumor
                  response. It is also associated with better response rate with
                  immune-checkpoint inhibitors. A sample is categorized as High
                  TMB if the TMB is found to be >20 mutations/ megabase.
                </td>
                <td *ngIf="tmb > 20">
                  <img
                    src="../../../assets/PD-L1 Expression.png"
                    class="mr-2"
                  />
                  <span style="color: #208dcb; font-weight: bold">HIGH</span>
                </td>
                <td *ngIf="tmb < 20">
                  <img src="../../../assets/LOW.png" class="mr-2" /> LOW
                </td>
                <td *ngIf="tmb == 20">
                  <img src="../../../assets/STABLE.png" class="mr-2" />STABLE
                </td>
              </tr>

              <!-- MSI  -->
              <tr>
                <td>
                  Microsatellite Status<br />
                  <span style="color: #208dcb">MSI-H/MSS (NGS)</span>
                </td>
                <td>
                  <span style="color: #208dcb; font-weight: bold"> MSI-H</span>
                  refers to hypermutable phenotype caused by the loss of DNA
                  mismatch repair activity.
                </td>
                <td *ngIf="msi > 15">
                  <img
                    src="../../../assets/PD-L1 Expression.png"
                    class="mr-2"
                  />
                  <span style="color: #208dcb; font-weight: bold">HIGH</span>
                </td>
                <td *ngIf="msi <= 15">
                  <img src="../../../assets/STABLE.png" class="mr-2" />STABLE
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- MSI Core Report Table -->

        <!-- Images -->
        <div class="row mt-5 pt-5">
          <div
            class="col text-center">
            <img
              *ngIf="
                report.immunoDetails?.pdl1Status == 'EXPRESSION' &&
                tmb <= 20 &&
                msi <= 15
              "
              src="../../../assets/AI-PD-L1-High.png"
              alt=""
              class="w-65"
            />
            <img
              *ngIf="
                report.immunoDetails?.pdl1Status != 'EXPRESSION' &&
                tmb > 20 &&
                msi <= 15
              "
              src="../../../assets/AI-TMB-High.png"
              alt=""
              class="w-65"
            />
            <img
              *ngIf="
                report.immunoDetails?.pdl1Status != 'EXPRESSION' &&
                tmb <= 20 &&
                msi > 15
              "
              src="../../../assets/AI-MSI-High.png"
              alt=""
              class="w-65"
            />
            <img
              *ngIf="
                report.immunoDetails?.pdl1Status == 'EXPRESSION' &&
                tmb > 20 &&
                msi < 15
              "
              src="../../../assets/AI-PD-L1_TMB-High.png"
              alt=""
              class="w-65"
            />
            <img
              *ngIf="
                report.immunoDetails?.pdl1Status == 'EXPRESSION' &&
                tmb <= 20 &&
                msi > 15
              "
              src="../../../assets/AI-PD-L1_MSI-High.png"
              alt=""
              class="w-65"
            />
            <img
              *ngIf="
                report.immunoDetails?.pdl1Status != 'EXPRESSION' &&
                tmb > 20 &&
                msi > 15
              "
              src="../../../assets/AI-TMB_MSI-High.png"
              alt=""
              class="w-65"
            />
            <img
              *ngIf="
                report.immunoDetails?.pdl1Status == 'EXPRESSION' &&
                tmb > 20 &&
                msi > 15
              "
              src="../../../assets/AI-All_High.png"
              alt=""
              class="w-65"
            />
            <img
              *ngIf="
                report.immunoDetails?.pdl1Status != 'EXPRESSION' &&
                tmb <= 20 &&
                msi <= 15
              "
              src="../../../assets/AI-All_Negative.png"
              alt=""
              class="w-65"
            />
          </div>


        </div>

        <!-- footer -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page5"
      *ngIf="
        (
          (report.immunoDetails?.pdl1Status == 'EXPRESSION' ||
            tmb > 20 ||
            msi > 15)) "
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo" sty>
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>

          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES FOR IMMUNOTHERAPY BIOMARKERS
            </h5>
          </div>
        </div>
        <div class="row my-3">
          <div class="col">
            <ul
              class="res_icons list-unstyled d-flex"
              style="float: right; font-style: italic"
            >
              <li><img src="../../../assets/responsive.png" />Responsive</li>
              <li class="pl-4 mr-2">
                <img src="../../../assets/ClinicalTrial.png" />Ongoing Trial
              </li>
              <li class="pl-4 mr-2">
                <img src="../../../assets/resistence.png" />Resistance
              </li>
            </ul>
          </div>
        </div>
        <!--  -->

        <div class="row">
          <div class="col-12">
            <table class="page-5_table rel_immuno_biomarkers w-100 mt-3">
              <tr>
                <th>Markers</th>
                <th>Therapy</th>
                <th>Cancer Type</th>
                <th class="text-center">Effect</th>
                <th class="text-center">Evidence</th>
                <th>Reference(s)</th>
              </tr>

              <!-- <tr *ngFor="let pdl1ClinicalTrail of reportPdfRes.pdl1ClinicalTrail">
                            <td [attr.rowspan]="reportPdfRes.pdl1ClinicalTrail[0].associationList">
                                <h2 style=" font-weight: normal;">PD-L1</h2>
                            </td>
                            <td>
                                <div *ngFor="let drugs of pdl1ClinicalTrail.drugList">
                                    <div *ngFor="let drug of drugs, let i=index">
                                        <span>{{drug.name}}</span>
                                        <span *ngIf="drugs.length-1 != i">,</span>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <div *ngFor="let cancerType of pdl1ClinicalTrail.resultCancerTypeList">
                                    <p>{{cancerType.name}}</p>
                                </div>
                            </td>
                            <td class="text-center">
                                <div *ngFor="let association of pdl1ClinicalTrail.associationList">

                                    <img *ngIf="association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                                        style="width: 15px;">
                                    <img *ngIf="association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                                        style="width: 15px;">
                                    <img *ngIf="association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                                        style="width: 15px;">
                                      </div>
                            </td>
                            <td class="text-center">
                                <div *ngFor="let cancerType of pdl1ClinicalTrail.associationList">
                                    <img src="../../../assets/dot-2.png" style="width: 15px;">
                                </div>
                            </td>
                            <td>
                                <p *ngFor="let cancerType of pdl1ClinicalTrail.associationList">FDA Guidelines</p>
                            </td>
                        </tr> -->

              <!-- Pdl1 expression -->
              <tr *ngFor="let pdl1Expression of pdl1ExpressionList">
                <ng-container *ngIf="pdl1Expression.geneRowCount != 0">
                  <td [attr.rowSpan]="pdl1Expression.geneRowCount">
                    <h2 style="font-weight: normal">
                      {{ pdl1Expression.gene }}
                    </h2>
                  </td>
                </ng-container>
                <td>{{ pdl1Expression.therepy }}</td>
                <td>{{ pdl1Expression.cancerType }}</td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="pdl1Expression.association == 'RESPONSIVE'"
                    src="../../../assets/responsive.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="pdl1Expression.association == 'RESISTANCE'"
                    src="../../../assets/Resistance.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="pdl1Expression.association == 'ONGOING'"
                    src="../../../assets/ClinicalTrial.png"
                    style="width: 15px"
                  />
                </td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="pdl1Expression.evidance == 'LEVEL_1'"
                    src="../../../assets/dot-2.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="pdl1Expression.evidance == 'LEVEL_3'"
                    src="../../../assets/dot-1.png"
                    style="width: 15px"
                  />
                  <p *ngIf="pdl1Expression.evidance == 'LEVEL_2'">NA</p>
                  <p *ngIf="pdl1Expression.evidance == 'LEVEL_4'">NA</p>
                </td>
                <td>{{ pdl1Expression.reference }}</td>
              </tr>
              <!--  -->
              <tr
                *ngFor="
                  let highTmbClinicalTrail of reportPdfRes.highTmbClinicalTrail
                "
              >
                <td
                  [attr.rowspan]="
                    reportPdfRes.highTmbClinicalTrail[0].associationList
                  "
                >
                  <h2 style="font-weight: normal">TMB</h2>
                </td>

                <td>
                  <div *ngFor="let drugs of highTmbClinicalTrail.drugList">
                    <span *ngFor="let drug of drugs; let i = index">
                      {{ drug.name
                      }}<span *ngIf="drugs.length - 1 != i">,</span>
                    </span>
                  </div>
                </td>
                <td>
                  <div
                    *ngFor="
                      let cancerType of highTmbClinicalTrail.resultCancerTypeList
                    "
                  >
                    <p>{{ cancerType.name }}</p>
                  </div>
                </td>
                <td style="text-align: left !important">
                  <div
                    *ngFor="
                      let association of highTmbClinicalTrail.associationList
                    "
                  >
                    <img
                      *ngIf="association == 'RESPONSIVE'"
                      src="../../../assets/responsive.png"
                      style="width: 15px"
                    />
                    <img
                      *ngIf="association == 'RESISTANCE'"
                      src="../../../assets/Resistance.png"
                      style="width: 15px"
                    />
                    <img
                      *ngIf="association == 'ONGOING'"
                      src="../../../assets/ClinicalTrial.png"
                      style="width: 15px"
                    />
                  </div>
                </td>
                <td style="text-align: left !important">
                  <div
                    *ngFor="
                      let cancerType of highTmbClinicalTrail.associationList
                    "
                  >
                    <img src="../../../assets/dot-2.png" style="width: 15px" />
                  </div>
                </td>
                <td>
                  <div
                    *ngFor="
                      let cancerType of highTmbClinicalTrail.associationList
                    "
                  >
                    FDA Guidelines
                  </div>
                </td>
              </tr>
              <!-- MSI -->
              <tr
                *ngFor="
                  let highMsiClinicalTrail of reportPdfRes.highMsiClinicalTrail
                "
              >
                <td
                  [attr.rowspan]="
                    reportPdfRes.highMsiClinicalTrail[0].associationList
                  "
                >
                  <h2 style="font-weight: normal">MSI</h2>
                </td>

                <td>
                  <div *ngFor="let drugs of highMsiClinicalTrail.drugList">
                    <span *ngFor="let drug of drugs; let i = index">
                      {{ drug.name
                      }}<span *ngIf="drugs.length - 1 != i">,</span>
                    </span>
                  </div>
                </td>
                <td>
                  <div
                    *ngFor="
                      let cancerType of highMsiClinicalTrail.resultCancerTypeList
                    "
                  >
                    <p>{{ cancerType.name }}</p>
                  </div>
                </td>
                <td style="text-align: left !important">
                  <div
                    *ngFor="
                      let association of highMsiClinicalTrail.associationList
                    "
                  >
                    <img
                      *ngIf="association == 'RESPONSIVE'"
                      src="../../../assets/responsive.png"
                      style="width: 15px"
                    />
                    <img
                      *ngIf="association == 'RESISTANCE'"
                      src="../../../assets/Resistance.png"
                      style="width: 15px"
                    />
                    <img
                      *ngIf="association == 'ONGOING'"
                      src="../../../assets/ClinicalTrial.png"
                      style="width: 15px"
                    />
                  </div>
                </td>
                <td style="text-align: left !important">
                  <div
                    *ngFor="
                      let cancerType of highMsiClinicalTrail.associationList
                    "
                  >
                    <img src="../../../assets/dot-2.png" style="width: 15px" />
                  </div>
                </td>
                <td>
                  <div
                    *ngFor="
                      let cancerType of highMsiClinicalTrail.associationList
                    "
                  >
                    FDA Guidelines
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- table to be displayed when the same cancer type is > 0 in length -->
    <div
      id="page6"
      *ngIf="
        snvSameReportDataList.length > 0 ||
        cnaSameReportDataList.length > 0 ||
        fusionSameReportDataList.length > 0
      "
      style="overflow-wrap: break-word; padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES IN SAME CANCER TYPE
            </h5>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <div class="row my-3">
          <!-- <div class="col">
                    <h6 class="mb-0 font-weight-bold">Relevant Therapies in same
                        Cancer Type</h6>
                </div> -->
          <div class="col">
            <ul
              class="res_icons list-unstyled d-flex mr-2"
              style="float: right; font-style: italic"
            >
              <li><img src="../../../assets/responsive.png" />Responsive</li>
              <li class="pl-5">
                <img src="../../../assets/ClinicalTrial.png" />Ongoing Trial
              </li>
              <li class="pl-5">
                <img src="../../../assets/resistence.png" />Resistance
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div class="row">
          <div class="col-12">
            <table class="page-6_table w-100 mt-1">
              <tr>
                <th>Gene</th>
                <th>Therapy</th>
                <th>Cancer Type</th>
                <th style="text-align: center">Effect</th>
                <th style="text-align: center">Evidence</th>
                <th>Reference(s)</th>
              </tr>

              <!-- SNV Type -->
              <tr *ngFor="let cnaOtherCancer of snvSameReportDataList">
                <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                  <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                    <i>{{ cnaOtherCancer.gene }}</i>
                    <p
                      style="
                        font-size: 12px !important;
                        text-align: left !important;
                      "
                    >
                      {{ cnaOtherCancer.snvVarinat }}
                    </p>
                  </td>
                </ng-container>
                <td>{{ cnaOtherCancer.therepy }}</td>
                <td>{{ cnaOtherCancer.cancerType }}</td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESPONSIVE'"
                    src="../../../assets/responsive.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESISTANCE'"
                    src="../../../assets/Resistance.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'ONGOING'"
                    src="../../../assets/ClinicalTrial.png"
                    style="width: 15px"
                  />
                </td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'"
                    class="mt-2"
                    src="../../../assets/Level1.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'"
                    class="mt-2"
                    src="../../../assets/Level2.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'"
                    class="mt-2"
                    src="../../../assets/Level3.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'"
                    class="mt-2"
                    src="../../../assets/Level4.png"
                    style="width: 20px"
                  />

                  <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                    <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
                </td>
                <td>{{ cnaOtherCancer.reference }}</td>
              </tr>

              <!-- CNA Type -->
              <tr *ngFor="let cnaOtherCancer of cnaSameReportDataList">
                <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                  <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                    <i>{{ cnaOtherCancer.gene }}</i>
                    <p
                      style="
                        font-size: 12px !important;
                        text-align: left !important;
                      "
                    >
                      {{ cnaOtherCancer.snvVarinat }}
                    </p>
                  </td>
                </ng-container>
                <td>{{ cnaOtherCancer.therepy }}</td>
                <td>{{ cnaOtherCancer.cancerType }}</td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESPONSIVE'"
                    src="../../../assets/responsive.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESISTANCE'"
                    src="../../../assets/Resistance.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'ONGOING'"
                    src="../../../assets/ClinicalTrial.png"
                    style="width: 15px"
                  />
                </td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'"
                    class="mt-2"
                    src="../../../assets/Level1.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'"
                    class="mt-2"
                    src="../../../assets/Level2.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'"
                    class="mt-2"
                    src="../../../assets/Level3.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'"
                    class="mt-2"
                    src="../../../assets/Level4.png"
                    style="width: 20px"
                  />

                  <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                    <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
                </td>
                <td>{{ cnaOtherCancer.reference }}</td>
              </tr>

              <!-- Fusion Type -->
              <tr *ngFor="let cnaOtherCancer of fusionSameReportDataList">
                <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                  <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                    <i>{{ cnaOtherCancer.gene }}</i>
                  </td>
                </ng-container>
                <td>{{ cnaOtherCancer.therepy }}</td>
                <td>{{ cnaOtherCancer.cancerType }}</td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESPONSIVE'"
                    src="../../../assets/responsive.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESISTANCE'"
                    src="../../../assets/Resistance.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'ONGOING'"
                    src="../../../assets/ClinicalTrial.png"
                    style="width: 15px"
                  />
                </td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'"
                    class="mt-2"
                    src="../../../assets/Level1.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'"
                    class="mt-2"
                    src="../../../assets/Level2.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'"
                    class="mt-2"
                    src="../../../assets/Level3.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'"
                    class="mt-2"
                    src="../../../assets/Level4.png"
                    style="width: 20px"
                  />

                </td>
                <td>{{ cnaOtherCancer.reference }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page7"
      *ngIf="
        this.snvReportDataList.length > 0 ||
        this.cnvReportDataList.length > 0 ||
        this.fusionReportDataList.length > 0
      "
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->

        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES IN DIFFERENT CANCER TYPES
            </h5>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <div class="row my-3">
          <!-- <div class="col">
                    <h6 class="mb-0 font-weight-bold">Relevant Therapies in Different
                        Cancer Types</h6>
                </div> -->
          <div class="col">
            <ul
              class="res_icons list-unstyled d-flex mr-2"
              style="float: right; font-style: italic"
            >
              <li><img src="../../../assets/responsive.png" />Responsive</li>
              <li class="pl-5">
                <img src="../../../assets/ClinicalTrial.png" />Ongoing Trial
              </li>
              <li class="pl-5">
                <img src="../../../assets/resistence.png" />Resistance
              </li>
            </ul>
          </div>
        </div>

        <!--  -->
        <div class="row">
          <div class="col-12">
            <table class="page-6_table page-7 w-100 mt-1">
              <tr>
                <th>Gene</th>
                <th>Therapy</th>
                <th>Cancer Type</th>
                <th style="text-align: center">Effect</th>
                <th style="text-align: center">Evidence</th>
                <th>Reference(s)</th>
              </tr>

              <!-- SNV Type -->
              <tr *ngFor="let cnaOtherCancer of snvReportDataList">
                <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                  <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                    <i>{{ cnaOtherCancer.gene }}</i>
                    <p
                      style="
                        font-size: 12px !important;
                        text-align: left !important;
                      "
                    >
                      {{ cnaOtherCancer.snvVarinat }}
                    </p>
                  </td>
                </ng-container>
                <td>{{ cnaOtherCancer.therepy }}</td>
                <td>{{ cnaOtherCancer.cancerType }}</td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESPONSIVE'"
                    src="../../../assets/responsive.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESISTANCE'"
                    src="../../../assets/Resistance.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'ONGOING'"
                    src="../../../assets/ClinicalTrial.png"
                    style="width: 15px"
                  />
                </td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'"
                    class="mt-2"
                    src="../../../assets/Level1.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'"
                    class="mt-2"
                    src="../../../assets/Level2.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'"
                    class="mt-2"
                    src="../../../assets/Level3.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'"
                    class="mt-2"
                    src="../../../assets/Level4.png"
                    style="width: 20px"
                  />

                  <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                        <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
                </td>
                <td>{{ cnaOtherCancer.reference }}</td>
              </tr>

              <!-- CNA Type -->
              <tr *ngFor="let cnaOtherCancer of cnvReportDataList">
                <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                  <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                    <i>{{ cnaOtherCancer.gene }}</i>
                    <p
                      style="
                        font-size: 12px !important;
                        text-align: left !important;
                      "
                    >
                      {{ cnaOtherCancer.snvVarinat }}
                    </p>
                  </td>
                </ng-container>
                <td>{{ cnaOtherCancer.therepy }}</td>
                <td>{{ cnaOtherCancer.cancerType }}</td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESPONSIVE'"
                    src="../../../assets/responsive.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESISTANCE'"
                    src="../../../assets/Resistance.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'ONGOING'"
                    src="../../../assets/ClinicalTrial.png"
                    style="width: 15px"
                  />
                </td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'"
                    class="mt-2"
                    src="../../../assets/Level1.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'"
                    class="mt-2"
                    src="../../../assets/Level2.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'"
                    class="mt-2"
                    src="../../../assets/Level3.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'"
                    class="mt-2"
                    src="../../../assets/Level4.png"
                    style="width: 20px"
                  />

                  <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                        <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p>
                            </td> -->
                </td>

                <td>{{ cnaOtherCancer.reference }}</td>
              </tr>

              <!-- Fusion Type -->
              <tr *ngFor="let cnaOtherCancer of fusionReportDataList">
                <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                  <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                    <i>{{ cnaOtherCancer.gene }}</i>
                  </td>
                </ng-container>
                <td>{{ cnaOtherCancer.therepy }}</td>
                <td>{{ cnaOtherCancer.cancerType }}</td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESPONSIVE'"
                    src="../../../assets/responsive.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'RESISTANCE'"
                    src="../../../assets/Resistance.png"
                    style="width: 15px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.association == 'ONGOING'"
                    src="../../../assets/ClinicalTrial.png"
                    style="width: 15px"
                  />
                </td>
                <td style="text-align: left !important">
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'"
                    class="mt-2"
                    src="../../../assets/Level1.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'"
                    class="mt-2"
                    src="../../../assets/Level2.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'"
                    class="mt-2"
                    src="../../../assets/Level3.png"
                    style="width: 20px"
                  />
                  <img
                    *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'"
                    class="mt-2"
                    src="../../../assets/Level4.png"
                    style="width: 20px"
                  />

                  <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                        <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
                </td>
                <td>{{ cnaOtherCancer.reference }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page8"
      *ngIf="reportPdfRes.starNCTTrailRes.length > 0"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              CLINICAL TRIAL(S)
            </h5>
          </div>
        </div>
        <!--  -->
        <div *ngFor="let starNCTTrail of reportPdfRes.starNCTTrailRes">
          <div class="row mt-3">
            <div class="col-12">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Study Title
              </h4>
              <p class="mb-2">{{ starNCTTrail.studyTitle }}</p>
            </div>
            <div class="col-12 mt-3">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Brief Summary
              </h4>
              <p class="mb-2">{{ starNCTTrail.briefSummary }}</p>
            </div>
          </div>
          <div class="row my-4">
            <div class="col">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Study Phase
              </h4>
              <p class="mb-2">{{ starNCTTrail.studyPhase }}</p>
            </div>
            <div class="col">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                NCT Number
              </h4>
              <p class="mb-2">{{ starNCTTrail.nctNumber }}</p>
            </div>
            <div class="col">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Recruitment Status
              </h4>
              <p class="mb-2">{{ starNCTTrail.recruitmentStatus }}</p>
            </div>
            <div class="w-100"></div>
            <div class="col-12 mt-3">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Intervention
              </h4>
              <h6 class="mb-0 font-weight-bold" style="color: #555">
                Experimental:
                {{
                  starNCTTrail.interventionName
                    .replace("[", "")
                    .replace("]", "")
                }}
              </h6>
              <!-- <p class="mb-2">{{starNCTTrail.interventionDescription}}</p> -->
            </div>
            <div class="w-100"></div>
            <div class="col-4 mt-4">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Last updated
              </h4>
              <p class="mb-2">{{ starNCTTrail.lastUpdated }}</p>
            </div>
            <div class="col-4 mt-4">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Location
              </h4>
              <p class="mb-2">
                {{ starNCTTrail.locationFacility }},
                {{ starNCTTrail.locationCity }},
                {{ starNCTTrail.locationState }},
                {{ starNCTTrail.locationCountry }}
              </p>
            </div>
            <div class="col-12 mt-4">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Biomarker
              </h4>
              <p class="mb-2">{{ starNCTTrail.biomarker }}</p>
            </div>
            <div class="col-12 mt-4">
              <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
                Condition
              </h4>
              <p class="mb-2">{{ starNCTTrail.condition }}</p>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- the table not to be displayed when all the snv varients are of wild type and cna and fusion varients are empty -->
    <div
      id="page9"
      *ngIf="
        (reportPdfRes.selectedReportedVariantList.length > 0 &&
          countOthers != 0) ||
        reportPdfRes.cnaReportedVariantList.length > 0 ||
        reportPdfRes.fusionReportedVariantList.length > 0
      "
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              CLINICALLY RELEVANT GENOMIC ALTERATION(S)
            </h5>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <div class="row mt-3 mb-2">
          <div class="col">
            <h4 class="mb-0 font-weight-bold" style="color: #208dcb">
              Variant Details
            </h4>
          </div>
        </div>
        <!--  -->
        <div class="row">
          <div class="col-12">
            <table class="page-9 w-100">
              <tr>
                <th>Gene</th>
                <th>
                  Genomic Alteration,<br />Transcript ID,<br />Exon Number
                </th>
                <th>cDNA Change, Protein Change, Mutant Allele Burden</th>
                <th>Clinical Significance</th>
                <th>Consequence</th>
                <th>Reference(s)</th>
              </tr>

              <tr
                *ngFor="
                  let selectedReportedVariant of reportPdfRes.selectedReportedVariantList
                "
              >
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                >
                  <i>{{ selectedReportedVariant.refGeneX }}</i>
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                >
                  {{ selectedReportedVariant.genomicAlteration }},<br />
                  {{ selectedReportedVariant.ensembleValue }},<br />
                  Exon {{ selectedReportedVariant.exonNumber }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                >
                  {{
                    selectedReportedVariant.selectedAAChangeknownGene.split(
                      ","
                    )[0]
                  }},
                  {{
                    selectedReportedVariant.selectedAAChangeknownGene.split(
                      ","
                    )[1]
                  }},
                  {{
                    selectedReportedVariant.mutantBurdenPercent * 0.01 | percent
                  }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                >
                  {{ selectedReportedVariant.significance }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                >
                  {{ selectedReportedVariant.exonicFuncknownGene }}
                </td>
                <td
                  *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  "
                >
                  {{ selectedReportedVariant.reference }}
                </td>
              </tr>

              <tr
                *ngFor="
                  let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
                "
              >
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                >
                  <i>{{ cnaReportedVariant.gene }}</i>
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                >
                  chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                    cnaReportedVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                >
                  NA
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                >
                  {{ cnaReportedVariant.significance }}
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                >
                  Copy Number
                  {{ cnaReportedVariant.sample.split(":")[2] | titlecase }} ({{
                    cnaReportedVariant.sample.split("|")[1].split(":")[0]
                  }})
                </td>
                <td
                  *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  "
                >
                  {{ cnaReportedVariant.reference }}
                </td>
              </tr>

              <tr
                *ngFor="
                  let fusionVariant of reportPdfRes.fusionReportedVariantList
                "
              >
                <td
                  *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  "
                >
                  <i>{{ fusionVariant.fusionGene.replace("--", "-") }}</i>
                </td>
                <td
                  *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  "
                >
                  Left break {{ fusionVariant.leftBreakPoint }} ({{
                    fusionVariant.leftExon
                  }})<br />Rigth break {{ fusionVariant.rightBreakPoint }} ({{
                    fusionVariant.rightExon
                  }})
                </td>
                <td
                  *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  "
                >
                  NA
                </td>
                <td
                  *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  "
                >
                  {{ fusionVariant.significance }}
                </td>
                <td
                  *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  "
                >
                  Gene Fusion
                </td>
                <td
                  *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  "
                >
                  {{ fusionVariant.reference }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <p style="font-size: 15px !important" class="mt-3">
          <i> *NA: Not Applicable </i>
        </p>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->

    <div
      id="page10"
      *ngIf="reportPdfRes.genes.length > 0"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="mb-2 py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              GENE DESCRIPTION(S)
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="let gene of reportPdfRes.genes | slice : 0 : 3"
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
              –
              <b>{{ gene.geneName }}</b>
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page10_1"
      *ngIf="reportPdfRes.genes.length > 3"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="mb-2 py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              GENE DESCRIPTION(S)
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="let gene of reportPdfRes.genes | slice : 3 : 6"
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
              –
              <b>{{ gene.geneName }}</b>
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page10_2"
      *ngIf="reportPdfRes.genes.length > 6"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="mb-2 py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              GENE DESCRIPTION(S)
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="let gene of reportPdfRes.genes | slice : 6 : 9"
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
              –
              <b>{{ gene.geneName }}</b>
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div
      id="page10_3"
      *ngIf="reportPdfRes.genes.length > 9"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="mb-2 py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              GENE DESCRIPTION(S)
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="let gene of reportPdfRes.genes | slice : 9 : 12"
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
              –
              <b>{{ gene.geneName }}</b>
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page10_4"
      *ngIf="reportPdfRes.genes.length > 12"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="mb-2 py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              GENE DESCRIPTION(S)
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="let gene of reportPdfRes.genes | slice : 12 : 15"
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
              –
              <b>{{ gene.geneName }}</b>
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page10_5"
      *ngIf="reportPdfRes.genes.length > 15"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="mb-2 py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              GENE DESCRIPTION(S)
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="let gene of reportPdfRes.genes | slice : 15 : 18"
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
              –
              <b>{{ gene.geneName }}</b>
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify"
            ></div>
            <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->

    <div
      id="page11"
      *ngIf="reportPdfRes.pathwayDtoList.length > 0"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              PATHWAY(S) INVOLVED IN CANCER
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-4">
          <div class="col-7">
            <h5 class="mb-1 font-weight-bold py-2">
              {{ reportPdfRes.pathwayDtoList[0]?.name }}
            </h5>
            <div class="path_cancer">
              <img
                [src]="reportPdfRes.pathwayDtoList[0]?.imageUrl"
                class="w-100 h-100"
              />
            </div>
          </div>
          <div
            class="col py-5 mt-5"
            style="font-size: 16px !important"
            [innerHtml]="reportPdfRes.pathwayDtoList[0]?.description"
          >
            <div></div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="reportPdfRes.pathwayDtoList.length > 1">
          <div class="col-7">
            <h5 class="mb-1 font-weight-bold py-2">
              {{ reportPdfRes.pathwayDtoList[1]?.name }}
            </h5>
            <div class="path_cancer">
              <img
                [src]="reportPdfRes.pathwayDtoList[1]?.imageUrl"
                class="w-100 h-100"
              />
            </div>
          </div>
          <div
            class="col py-5 mt-5"
            style="font-size: 16px !important"
            [innerHtml]="reportPdfRes.pathwayDtoList[1]?.description"
          >
            <div></div>
          </div>
        </div>
        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page12"
      *ngIf="reportPdfRes.pathwayDtoList.length > 2"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              PATHWAYS INVOLVED IN CANCER
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-4">
          <div class="col-7">
            <h5 class="mb-1 font-weight-bold py-2">
              {{ reportPdfRes.pathwayDtoList[2]?.name }}
            </h5>
            <div class="path_cancer">
              <img
                [src]="reportPdfRes.pathwayDtoList[2]?.imageUrl"
                class="w-100 h-100"
              />
            </div>
          </div>
          <div
            class="col py-5 mt-5"
            style="font-size: 16px !important"
            [innerHtml]="reportPdfRes.pathwayDtoList[2]?.description"
          >
            <div></div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="reportPdfRes.pathwayDtoList.length > 3">
          <div class="col-7">
            <h5 class="mb-1 font-weight-bold py-2">
              {{ reportPdfRes.pathwayDtoList[3]?.name }}
            </h5>
            <div class="path_cancer">
              <img
                [src]="reportPdfRes.pathwayDtoList[3]?.imageUrl"
                class="w-100 h-100"
              />
            </div>
          </div>
          <div
            class="col py-5 mt-5"
            style="font-size: 16px !important"
            [innerHtml]="reportPdfRes.pathwayDtoList[3]?.description"
          >
            <div></div>
          </div>
        </div>
        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page13"
      *ngIf="reportPdfRes.pathwayDtoList.length > 4"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mar_top"></div>

        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              PATHWAYS INVOLVED IN CANCER
            </h5>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-7">
            <h5 class="mb-1 font-weight-bold py-2">
              {{ reportPdfRes.pathwayDtoList[4]?.name }}
            </h5>
            <div class="path_cancer">
              <img
                [src]="reportPdfRes.pathwayDtoList[4]?.imageUrl"
                class="w-100 h-100"
              />
            </div>
          </div>
          <div
            class="col py-5 mt-5"
            style="font-size: 16px !important"
            [innerHtml]="reportPdfRes.pathwayDtoList[4]?.description"
          >
            <div></div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="reportPdfRes.pathwayDtoList.length > 5">
          <div class="col-7">
            <h5 class="mb-1">{{ reportPdfRes.pathwayDtoList[5]?.name }}</h5>
            <div class="path_cancer">
              <img
                [src]="reportPdfRes.pathwayDtoList[5]?.imageUrl"
                class="w-100 h-100"
              />
            </div>
          </div>
          <div
            class="col pl-5"
            style="font-size: 16px !important"
            [innerHtml]="reportPdfRes.pathwayDtoList[5]?.description"
          >
            <div></div>
          </div>
        </div>

        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page14"
      *ngIf="reportPdfRes.pathwayDtoList.length > 6"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mar_top"></div>

        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              PATHWAYS INVOLVED IN CANCER
            </h5>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-7">
            <h5 class="mb-1">{{ reportPdfRes.pathwayDtoList[6].name }}</h5>
            <div class="path_cancer">
              <img
                [src]="reportPdfRes.pathwayDtoList[6].imageUrl"
                class="w-100 h-100"
              />
            </div>
          </div>
          <div
            class="col pl-5"
            style="font-size: 16px !important"
            [innerHtml]="reportPdfRes.pathwayDtoList[6].description"
          >
            <div></div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="reportPdfRes.pathwayDtoList.length > 7">
          <div class="col-7">
            <h5 class="mb-1">{{ reportPdfRes.pathwayDtoList[7]?.name }}</h5>
            <div class="path_cancer">
              <img
                [src]="reportPdfRes.pathwayDtoList[7]?.imageUrl"
                class="w-100 h-100"
              />
            </div>
          </div>
          <div
            class="col pl-5"
            style="font-size: 16px !important"
            [innerHtml]="reportPdfRes.pathwayDtoList[7]?.description"
          >
            <div></div>
          </div>
        </div>

        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page15"
      *ngIf="reportPdfRes.drugDetails.length > 0"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 0 : 6;
              let i = index
            "
            class="col-12 mb-3"
          >
            <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
            <p [innerHtml]="drug.description"></p>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page15_1"
      *ngIf="reportPdfRes.drugDetails.length > 6"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 6 : 12;
              let i = index
            "
            class="col-12 mb-3"
          >
            <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
            <p class="left_space" [innerHtml]="drug.description"></p>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page15_2"
      *ngIf="reportPdfRes.drugDetails.length > 12"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 12 : 18;
              let i = index
            "
            class="col-12 mb-3"
          >
            <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
            <p [innerHtml]="drug.description"></p>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page15_3"
      *ngIf="reportPdfRes.drugDetails.length > 18"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 18 : 24;
              let i = index
            "
            class="col-12 mb-3"
          >
            <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
            <p [innerHtml]="drug.description"></p>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page15_4"
      *ngIf="reportPdfRes.drugDetails.length > 24"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 24 : 30;
              let i = index
            "
            class="col-12 mb-3"
          >
            <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
            <p [innerHtml]="drug.description"></p>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page15_5"
      *ngIf="reportPdfRes.drugDetails.length > 30"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              RELEVANT THERAPIES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div
            *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 30 : 36;
              let i = index
            "
            class="col-12 mb-3"
          >
            <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
            <p [innerHtml]="drug.description"></p>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="page16" style="padding: 10px; margin: 0 auto" class="main_wrapper">
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 my-4 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              REFERENCES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div class="col-12 mb-3">
            <ul class="pl-4 mt-2 res_art">
              <li
                *ngFor="
                  let referenceArticle of reportPdfRes.referenceArticles
                    | slice : 0 : 15
                "
                style="white-space: pre-wrap"
              >
                {{ referenceArticle }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page16_1"
      *ngIf="(reportPdfRes?.referenceArticles || []).length > 15"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 my-4 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              REFERENCES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div class="col-12 mb-3">
            <ul class="pl-4 mt-2 res_art">
              <li
                *ngFor="
                  let referenceArticle of reportPdfRes.referenceArticles
                    | slice : 15 : 30
                "
                style="white-space: pre-wrap"
              >
                {{ referenceArticle }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page16_2"
      *ngIf="(reportPdfRes?.referenceArticles || []).length > 30"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 my-4 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              REFERENCES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div class="col-12 mb-3">
            <ul class="pl-4 mt-2 res_art">
              <li
                *ngFor="
                  let referenceArticle of reportPdfRes.referenceArticles
                    | slice : 30 : 45
                "
                style="white-space: pre-wrap"
              >
                {{ referenceArticle }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="page17" style="padding: 10px; margin: 0 auto" class="main_wrapper">
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 my-4 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              REFERENCES
            </h5>
          </div>
        </div>
        <!--  -->
        <div class="row mt-3">
          <div class="col-12 mb-3">
            <h4 class="mt-4 mb-2" style="color: #208dcb; font-weight: 600">
              Websites:
            </h4>
            <ul class="pl-4 res_art">
              <!-- <li>COSMIC - Catalogue Of Somatic Mutations in Cancer - https://cancer.sanger.ac.uk/cosmic</li> -->
              <li>ClinVar https://www.ncbi.nlm.nih.gov/clinvar</li>
              <li>NIH- Clinical Trials - https://clinicaltrials.gov/</li>
              <li>NIH- National Cancer Institute - https://www.cancer.gov/</li>
              <li>https://www.mycancergenome.org/</li>
              <li>https://www.drugbank.ca/</li>
              <li>https://www.ncbi.nlm.nih.gov/medgen/</li>
              <li>https://www.cancer.net/cancer-types</li>
              <li>http://www.cancer-genetics.org/</li>
              <li>https://www.genecards.org/</li>

              <span *ngIf="report.referenceWebsites != '<p></p>'" class="">
                <div
                  style="font-size: 16px !important"
                  [innerHtml]="report.referenceWebsites"
                ></div>
              </span>
            </ul>
          </div>
        </div>

        <!--  -->


        <div class="img_signs above-footer">
          <div class="row justify-content-between align-items-center">
            <div class="col-4 text-center">
              <p style="
                    text-align: center;

                    font-size: 15px !important;
                  ">(Electronically signed by)
              </p>
              <p style="
                      text-align: center;
                      font-weight: bolder;
                      font-size: 15px !important;
                    ">
                Vidya H Veldore, PhD<br />Vice President - Clinical Operations
              </p>
            </div>
            <div class="col-4 text-center">            <p style="text-align: center; font-size: 15px !important;">
                (Electronically signed by)</p>
                <p style="
                text-align: center;
                font-weight: bolder;
                font-size: 15px !important;
              ">
              Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
            </div>
            <div class="col-4 text-center">            <p style="
                text-align: center;

                font-size: 15px !important;
              ">(Electronically signed by)
              </p>
              <p style="
                      text-align: center;
                      font-weight: bolder;
                      font-size: 15px !important;
                    ">
                Vyomesh Javle<br />Head - Clinical Bioinformatics
              </p>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="page19" style="padding: 10px; margin: 0 auto" class="main_wrapper">
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <div class="">
              <h5
                class="py-2 text-center px-0 rounded my-4"
                style="background: #208dcb; color: #fff; font-weight: 500"
              >
                TEST DESCRIPTION
              </h5>
              <p class="py-2" >
                TAR<span style="font-weight: bold">GT</span>
                <span style="color: #208dcb; padding-left: 5px">{{
                  report.reportType.name
                }}</span> provides a comprehensive molecular profiling of the 1212 genes by targeting the
                coding region variants and genomic signatures such as SNVs, InDels, CNVs,
                gene fusions and splice variants using advanced NGS technology.
                The alterations detected may have bearing on prognosis and/or therapeutic options and may
                provide relevant information that allows doctor to consider various lines of targeted treatment for the
                patient.
              </p>
            </div>
            <div class="mt-4">
              <h5
                class="py-2 my-4 text-center px-0 rounded mb-2"
                style="background: #208dcb; color: #fff; font-weight: 500"
              >
                TEST METHODOLOGY
              </h5>
              <div>
                <h5 class="pt-2 py-2" style="color: #000; font-weight: bold">
                  Sample preparation and Library preparation :
                </h5>
                <p class="py-2">
                  DNA/RNA isolated from FFPE or any other fresh tumour tissue source was used to perform targeted
                  gene capture using a custom capture kit. The libraries were sequenced to mean >350X coverage on
                  Illumina sequencing platform.
                </p>

                <h5 class="mt-4 mb-3" style="color: #000; font-weight: bold">
                  Bioinformatics Analysis and Reporting :
                </h5>
                <p clsas="py-2">
                  The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was
                  performed using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the 1212 genes were used for clinical
                  interpretation. Silent variations that do not result in any change in amino acid in the coding region are not reported. Clinically relevant mutations were
                  annotated using published variants in literature and a set of databases – ClinVar, COSMIC and dbSNP. Common variants are filtered based on allele
                  frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the absence of a clinically significant reported known variation(s), pathogenicity
                  will be predicted based on in-silico gene prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation taster and prioritized for clinical correlation.
                  <br/>
                  Somatic variants are classified into three tiers based on their level of clinical significance in cancer diagnosis, prognosis , and/or therapeutics as per
                  international guidelines: ACMG, ASCO, AMP, CAP, NCCN, ESMO and FDA.
                </p>
              </div>


            </div>
          </div>
        </div>


        <div class="row">
          <div class="col">
            <h5
              class="py-2 mt-4 mb-5 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              LIMITATIONS AND DISCLAIMER
            </h5>

            <ul class="mt-4 pl-4 res-art">
              <li>
                TAR<span style="font-weight: bold">GT</span>
                <span style="color: #208dcb; padding-left: 5px">{{
                  report.reportType.name
                }}</span> test has been developed, validated, and performed by 4baseCare Genomics Pvt.
                Ltd and has not been cleared or approved by the FDA.
              </li>
              <li>
                TAR<span style="font-weight: bold">GT</span>
                <span style="color: #208dcb; padding-left: 5px">{{
                  report.reportType.name
                }}</span>test should be one of the many aspects used by the healthcare provider to help with a diagnosis and treatment plan. There are
                possible sources of error which can result from trace contamination, rare technical errors, rare genetic variants that interfere with analysis, recent
                scientific developments, and alternative classification systems.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting which is usually the longest coding transcript with strong/multiple supporting evidence.
                However, in rare cases, clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
              </li>
              <li>
                The contents of this test should be carefully assessed by the treating physician and further interpreted along with clinical, histopathological findings,
                contraindications, and guidelines before deciding the course of therapy.
              </li>
              <li>
                The MSI-H/MSS designation by this test is based on genome wide analysis of 95microsatellite loci and not based on the 5 or 7 MSI loci and its clinical
                validity has not been very well established using NGS methods. Reflex testing by alternate methods such as by IHC or PCR is suggested for
                reconfirmation and clinical decision making.
              </li>
              <li>
                Our limit of detection for TARGT Indiegene is 5% for SNVs, 10% for InDels and CNV gain ≥ 6. In addition to this, sequencing quality and coverage is
                dependent on many factors such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant detection.
              </li>
              <li>
                The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
              </li>
              <li>
                The identified pathogenic variant will be correlated with
                observed phenotypic features of the patient and interpreted
                according to standard of care guidelines.
              </li>
              <li>
                The identified pathogenic variant will be correlated with observed phenotypic features of the patient and interpreted according to (ASCO) guidelines.
              </li>
              <li>
                Most recent block is recommended for testing as the mutation profile may change in response to treatment and hence differ at different sampling points.
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot rule out the possibility that the tested
                tumour sample carries mutations not previously associated with cancer and hence not included in the panel.
              </li>
              <li>
                The in-house pipeline for TMB calculation has been developed primarily from Indian/South Asian population.
              </li>
            </ul>
          </div>
        </div>
        <!--  -->


        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>







    <div
      id="page21"
      *ngIf="
        report.immunoDetails?.pdl1Status != 'NOT_PERFORMED' "
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper">
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              ANNEXURE-I
            </h5>
            <h1 class="pt-5 pb-3">
              <span
                style="
                  color: #208dcb;
                  font-size: 40px !important;
                  font-style: italic;
                "
                >PD-L1</span
              >
              {{ report.immunoDetails?.pdl1Status }}
            </h1>

            <div class="img_setup">
              <img [src]="pdl1Images[0]" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <!--  -->

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page22"
      *ngIf="
        report.immunoDetails?.pdl1Status != 'NOT_PERFORMED' "
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              ANNEXURE-I
            </h5>
            <h1 class="pt-5 pb-3">
              <span
                style="
                  color: #208dcb;
                  font-size: 40px !important;
                  font-style: italic;
                "
                >PD-L1</span
              >
              {{ report.immunoDetails?.pdl1Status }}
            </h1>
            <!-- <h3>ImmunoTherapy Biomarkers</h3> -->

            <div class="img_setup">
              <img [src]="pdl1Images[1]" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <!--  -->

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page23"
      *ngIf="
        report.immunoDetails?.pdl1Status != 'NOT_PERFORMED'"
      style="padding: 10px; margin: 0 auto"
      class="main_wrapper"
    >
      <div class="container">
        <div class="row header">
          <div *ngIf="report.clientType != 'client'">
            <div class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/logo.png"
                  alt=""
                  style="width: 140px"
                />
              </div>
            </div>
          </div>
          <div *ngIf="report.clientType == 'client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="patient-detailes ml-auto">
              <ul class="list-unstyled d-flex pt-3">
                <li>
                  Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
                </li>
                <li>
                  Cancer Site<br /><span>{{ report.cancerType }}</span>
                </li>
                <li>
                  SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
                </li>
                <li>
                  Registration Date<br /><span>{{
                    report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="report.clientType == '4basecare_client'">
            <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/oncoquest logo.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
            <div *ngIf="report.clientName == 'path_labs'" class="col-3">
              <div class="logo">
                <img
                  src="../../../assets/pathlab.png"
                  alt=""
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="mar_top"></div>
        <!--  -->
        <div class="row">
          <div class="col">
            <h5
              class="py-2 text-center px-0 rounded"
              style="background: #208dcb; color: #fff; font-weight: 500"
            >
              ANNEXURE-I
            </h5>
            <h1 class="pt-5 pb-3">
              <span
                style="
                  color: #208dcb;
                  font-size: 40px !important;
                  font-style: italic;
                "
                >PD-L1</span
              >
              {{ report.immunoDetails?.pdl1Status }}
            </h1>
            <!-- <h3>ImmunoTherapy Biomarkers</h3> -->

            <div class="img_setup">
              <img [src]="pdl1Images[2]" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <!--  -->

        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
