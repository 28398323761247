<div class="container-fluid px-0">
  <div class="row  py-3 mx-0">
    <div class="col pl-0">
      <h5 class="mb-0 d-inline-block text_color pt-2"> Recurrence Variants List</h5>
    </div>

    <div class="col pr-0">
      <div class="text-right ml-auto d-table">
        <div class="add-new">
          <a class="add_post mr-2" [routerLink]="[ '/super_admin/add-recurrenceVarient' ]">Add Recurrence Variant<i
              class="zmdi zmdi-plus ml-2"></i></a>
        </div>
      </div>
    </div>

      <div class=" justify-content-end d-flex mb-5t" style="padding: 5px!important;">
        <button (click)="generateExcel()" class="btn btn-primary w-md" title="Excel Format Download">
          <i class="zmdi zmdi-download  zmdi-hc-lg"></i></button>
      </div>
    <div class=" justify-content-end d-flex mb-5t" style="padding: 5px!important;">
      <button (click)="fileInput.click()" type="button" class="btn btn-primary w-md">
        <input style="display: none" type="file"
          (change)="onFileChanges($event)" #fileInput />
        Upload file
      </button>
    </div>
  </div>
  <div *ngIf="recurrenceVariantListSpinner" class="row data-spinner-parent">
    <div class="spinner-border data-spinner-child" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
  <div *ngIf="!recurrenceVariantListSpinner" class="bg-white cust_shadow  p-5 rounded">
    <div class="tableFixHead">
      <table class="care_companion table ">
        <thead class="thead-light">
          <tr>
            <th>Sl.No</th>
            <th>Gene</th>
            <th>Cancer Type</th>
            <th>C Position</th>
            <th>P Position</th>
            <th>Genomic Coordinates</th>
            <th style="width: 115px!important;">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let variant of recurrenceVariantList; let i = index">
            <td>{{i+1}}</td>
            <td>{{variant?.gene?.name}}</td>
            <td>{{variant?.cancerType?.name}}</td>
            <td>{{variant?.cposition || "-"}}</td>
            <td>{{variant?.pposition || "-"}}</td>
            <td>{{variant?.genomicCoordinates || "-"}}</td>
            <td>
              <ul class="list-unstyled mb-0 d-flex actions">
                <li><a class="mr-1 delete_clr" [routerLink]="[ '/super_admin/add-recurrenceVarient', variant.id ]"> Edit
                  </a></li>
                <li><a class="delete_clr" data-toggle="modal" data-target="#deleteClinicalModal"
                    (click)="assignRecurrenceVariant(variant)">| Delete</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- The delete Modal -->
  <div class="modal fade" id="deleteClinicalModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content surgery-modal">
        <div class="modal-header px-4 border-bottom-0 bg-light">
          <h5 class="modal-title text-softblue">Delete</h5>
          <a class="text-softblue" data-dismiss="modal">
            <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
          </a>
        </div>
        <div class="modal-body p-4">
          <h6 class=" mb-4">Are you sure want to delete ?</h6>
          <div class="mx-0 ml-auto d-table">
            <button type="button" class="btn bg-softblue text-white px-5 mr-2" data-dismiss="modal"
              (click)="deleteRecurrenceVariant()">Yes</button>
            <button type="button" class="btn bg-softblue text-white px-5" data-dismiss="modal">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
