import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.css']
})
export class AddFilterComponent implements OnInit {
  filters:any
  filterCol:any=[]
  filterId!:number;
  filterTabCols=''
  filterText=""
  filterLoad=false
  statusMsg=''
  name:string=''
  backendData:any
  secondFilterData:any=[]
  geneDropdownSettings={}
  secondFilterDataBackup:any=[]
  geneList:any=[]
  testGeneList:any=[]
  cancerGeneList:any=[]
  testList:any=[]
  cancerSiteList:any=[]
  geneNameList:any=[]
  testNameList:any=[]
  cancerSiteNameList:any=[]
  filterTabName=""
  filtertabs=[{tab:'Consequence',val:['funcknownGene','exonicFuncknownGene']},
  {tab:'Population frequency',val:['esp6500siv2All','exAC_ALL','exAC_SAS','af','afSas','kg2015AugAll','kg2015AugSAS']},
  {tab:'In-Silico Prediction',val:['cancerVar','interVarAutomated','cadd13Phred','siftPred','polyphen2HvarPred','mutationTasterPred','fathmmPred','metaSvmPred','metaLrPred']},
]
filterTypeCad=["equals","Greater than or equal","Greater than"]
  filterType=[
    // "equals","not equal","Greater than or equal","Greater than",
    "less than","less than or equal","equals"]
  filtertab=[{tab:'Consequence',data:[{key:'funcknownGene',webKey:'Region',value:['.','Downstream','Exonic','Exonic;Splicing','Intergenic','Intronic','ncRNA_Exonic','ncRNA_Intronic','Splicing','UTR3','UTR5'],filter:'simple'},
                                      {key:'exonicFuncknownGene',webKey:'Functional consequences',value:['.','Frameshift deletion','Frameshift insertion','Nonframeshift deletion','Nonframeshift insertion',
                                      'Nonsynonymous SNV','Stopgain','Stoploss','Synonymous SNV','Unknown'],filter:'simple'
}]},
{tab:'Population frequency',data:[{key:'esp6500siv2All',webKey:'Exome Sequencing Project 6500 database - ALL',value:[''],filter:'number'}
                                  ,{key:'exAC_ALL',webKey:'ExAC database - ALL',value:[''],filter:'number'}
                                  ,{key:'exAC_SAS',webKey:'ExAC database - SAS (South Asian)',value:[''],filter:'number'}
                                  ,{key:'af',webKey:'AF database - ALL',value:[''],filter:'number'}
                                  ,{key:'afSas',webKey:'AF database - SAS (South Asian)',value:[''],filter:'number'}
                                  ,{key:'kg2015AugAll',webKey:'1000 Genomes Project database - ALL',value:[''],filter:'number'}
                                  ,{key:'kg2015AugSAS',webKey:'1000 Genomes Project database - SAS (South Asian)',value:[''],filter:'number'}
                                  ,{key:'genome_ALL',webKey:'GNOM AD - ALL',value:[''],filter:'number'}
                                  ,{key:'genome_EAS',webKey:'GNOM AD - EAS',value:[''],filter:'number'}

],},
  {tab:'In-Silico Prediction',data:[{key:'cancerVar',webKey:'CancerVar',value:['.','TIER_I','TIER_II','TIER_III','TIER_IV'],filter:'simple'},
                                  {key:'interVarAutomated',webKey:'InterVar',value:['.','Benign','Likely benign','Uncertain significance','Likely pathogenic','Pathogenic'],filter:'simple'},
                                  {key:'clinvar',webKey:'Clinvar',value:['Pathogenic/Likely pathogenic','Conflicting','Benign/Likely benign','VUS','Drug Response','No Information'],filter:'simple',values:''},
                                  {key:'cadd13Phred',webKey:'CADD Score',value:[''],filter:'number'},
                                  {key:'siftPred',webKey:'SIFT score',value:['.','D','T'],filter:'simple'},
                                  {key:'polyphen2HvarPred',webKey:'Polyphen2 score',value:['.','D','B','P'],filter:'simple'},
                                  {key:'mutationTasterPred',webKey:'Mutation Taster score',value:['.','D','N','A','P'],filter:'simple'},
                                  {key:'fathmmPred',webKey:'FATHMM score',value:['.','D','T'],filter:'simple'},
                                  {key:'metaSvmPred',webKey:'MetaSVM score',value:['.','D','T'],filter:'simple'},
                                  {key:'metaLrPred',webKey:'MetaLR score',value:['.','D','T'],filter:'simple'},
]},
{tab:'ClinSig_Inhouse',data:[{key:'clnsig',webKey:'ClinSig_Inhouse',value:['Pathogenic','Likely pathogenic','Rick Factor','Drug Response','VUS','No Information','Uncertain_significance'],filter:'simple'}
]},
{tab:'Gene filter',data:[
  {key:'testName',webKey:'Test Name',value:[],filter:'dropdown'},
  {key:'cansesite',webKey:'Cancer Site',value:[],filter:'dropdown'},
  {key:'refGeneX',webKey:'Gene',value:[],filter:'dropdown'}]},
//                                  ]},
  // {tab:'Clinical Interpretation',val:'clinvar,interVarAutomated,intervarInhouse,cancerVar'},
]

  constructor(private toastr: ToastrService, private dataAdminService: DataAdminService, private router: Router,
    private routerAct: ActivatedRoute) { }

  ngOnInit(): void {
    this.getCancerSiteGeneList();
    this.getTestNames();
    this.getGenes()
    this.geneDropdownSettings = {
      singleSelection: false,
      // idField: 'values',
      // textField: 'values',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.filterId = +this.routerAct.snapshot.params['id'];

    if(this.filterId){
      this.getFilterEngineById()
    }else{
      this.openFilterTab("Consequence")
    }

  }

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList= response['payLoad']

        this.geneList.forEach((element:any)=>{
          this.geneNameList.push(element.name)
         });
      }
    );
  }
  getTestNames(){

    this.dataAdminService.geneTestTypeGeneList().subscribe((res:any)=>{
      this.testList=res["payLoad"];
      console.log('this.testList=>',this.testList)
      this.testList.forEach((element:any)=>{
        this.testNameList.push(element?.testNames)
       });
       console.log('this.testNameList=>',this.testNameList)

    });
  }

  getCancerSiteGeneList(){
    this.dataAdminService.getCancerSiteGeneList().subscribe((res:any)=>{
      this.cancerSiteList=res['payLoad']
      console.log("DATAT ",this.cancerSiteList)
      this.cancerSiteList.forEach((element:any)=>{
        this.cancerSiteNameList.push(element?.cancerSite?.name)
       });
       console.log('this.cancerSiteNameList=>',this.cancerSiteNameList)
    })
  }
  testNameSelected:any;
  isTestNameSelected=false
  isCancerSiteSelected=false
  cancerSiteName:any
  filterByTestType(event:any){
    this.isTestNameSelected=true
    console.log("event==>",event,"\n",this.isTestNameSelected)
    let testData = this.testList.filter( (test:any)=> {
      if(test['testNames']==event){
        return test;
      }
    });
    this.geneList=testData[0].gene;
    this.geneNameList=[];
    this.testGeneList=[]
    this.geneList.forEach((element:any)=>{
      this.testGeneList.push(element.name)
     });
    if(this.isCancerSiteSelected==true){
      this. filterByCancerAndTestType(this.testGeneList, this.cancerGeneList);
    }else{
      this.geneNameList=this.testGeneList;
      console.log("geneNameList TestName==>",this.geneNameList)

    }
  }

  filterByCancerType(event:any){
    this.isCancerSiteSelected=true
    console.log("event==>",event,"\n", this.isCancerSiteSelected)
    let canceData = this.cancerSiteList.filter( (test:any)=> {
      if(test['cancerSite']['name']==event){
        return test;
      }
    });
    this.geneList=canceData[0].genes;
    this.geneNameList=[];
    this.cancerGeneList=[]
    this.geneList.forEach((element:any)=>{
      this.cancerGeneList.push(element.name)
     });
    console.log("geneList==>",this.geneList);
    if(this.isTestNameSelected==true){
      this. filterByCancerAndTestType(this.testGeneList,this.cancerGeneList);
    }else{
      this.geneNameList=this.cancerGeneList;
      console.log("geneNameList Cancer Name==>",this.geneNameList)

    }
  }

  filterByCancerAndTestType(list1:any, list2:any){
    this.geneNameList=[]
    for(let i=0;i<list1.length;i++){
      for(let j=0;j<list2.length;j++){
        if(list1[i]==list2[j]){
          this.geneNameList.push(list1[i])
        }
      }
    }
    console.log("list1==>",list1,"\nlist2==>",list2,"\ngeneList==>",this.geneNameList)
  }

  getFilterEngineById(){
    this.filterLoad=false
    this.dataAdminService.getFilterEngineById(this.filterId).subscribe((res:any)=>{
      console.log("FILTER BY ID ",res['payLoad'])
      this.backendData=res['payLoad']
      this.name=res['payLoad'].name
      this.setFilterValue()
    })
  }
  setFilterValue(){
    this.filtertab.forEach((element:any)=>{
      this.secondFilterData=[]
      element.data.forEach((ele:any)=>{
        console.log(element.tab ," === " ,element.data)
        let data:any=this.backendData[ele.key]
        if(data!=null){
          if(ele.filter=='number'){

            this.secondFilterData.push({
              key:ele.key,
              values: data.split(',')[0],
              value:ele.value,
              filterType: data.split(',')[1],
              filter:ele.filter,
              webKey:ele.webKey,
            })
          }else{
            this.secondFilterData.push({
              key:ele.key,
              values: data.split(','),
              value:ele.value,
              filterType: (ele.filterType)? ele.filterType:'',
              filter:ele.filter,
              webKey:ele.webKey,
            })
            this.filterCol.push( {
            'colName':ele.key,
            'values':data.split(',')})
          }
        }else{
          this.secondFilterData.push({
                    key:ele.key,
                    values:(ele.values)? ele.values:'',
                    value:ele.value,
                    filterType:(ele.filterType)? ele.filterType:'',
                    filter:ele.filter,
                    webKey:ele.webKey,
                  })
        }


      })
      this.filterTabName=element.tab
      this.secondFilterDataBackup.push({
        tab:element.tab,
        filters:JSON.parse(JSON.stringify(this.secondFilterData))
      })
    })
   setTimeout(()=>{
    this.filterLoad=true
    this.openFilterTab("Consequence")
   },1000)
  }


  isChecked(colName:any,data:any):boolean{
    let flag:boolean=false
    this.filterCol.forEach((ele:any) => {
      if(ele.colName==colName){
        let index= ele.values.indexOf(data)
        if(index>=0){
           flag=true;
        }
      }
    })
   return flag
  }
  updateSelectedFilter(event:any){
    let colsvalue:any=[]
    if(event.target.checked){
      if(this.filterCol.length>0){
        let pushFlag=true
        this.filterCol.forEach((ele:any) => {
          if(ele.colName==event.target.name){
            pushFlag=false
            ele.values.push(event.target.value)
          }
        });
        if(pushFlag){
          this.filterCol.push({
            'colName':event.target.name,
            'values':[event.target.value]})
        }
      }else{
        this.filterCol.push({
          'colName':event.target.name,
          'values':[event.target.value]})
      }
    }else{
      this.filterCol.forEach((ele:any) => {
        if(ele.colName==event.target.name){
          let index=  ele.values.indexOf(event.target.value)
          ele.values.splice(index,1)
          if(ele.values.length==0){
            let index=  ele.values.indexOf(ele)
            this.filterCol.splice(index,1)
          }
        }
      })
    }
  }
  getBackup(){
    this.secondFilterDataBackup.forEach((ele:any)=>{
      if(ele.tab==this.filterTabName){
        this.secondFilterData.forEach((data:any)=>{
          this.filterCol.forEach((element:any)=>{
              if(data.key==element.colName)
              {
                data.values=element.values
              }
          })
        })
          ele.filters= JSON.parse(JSON.stringify(this.secondFilterData))
      }

    })
  }

  applyFilter(){
    if(this.name!=''){

      this.secondFilterDataBackup.forEach((ele:any)=>{
        if(ele.tab==this.filterTabName){
          this.filterTabName
          this.getBackup()
        }
        ele.filters.forEach((element:any)=>{
          let flag=false
          if(element.values!='' && element.values!=undefined && element.values!=null){
            this.filterCol.forEach((elementkey:any)=>{
              if(element.key==elementkey.colName){
                flag=true
                elementkey.values=element.values
                elementkey.filterType=element.filterType
              }
            })
           if(!flag){
            this.filterCol.push({
              colName:element.key,
              values:element.values,
              filterType:element.filterType
            })
           }
          }
        })
      })

      let data:any=JSON.parse(JSON.stringify(this.filterCol))
   let finalData:any={}
      data.forEach((ele:any)=>{
      let colname=ele.colName
      let val=ele.values?.toString()
      val=val+((ele.filterType)?','+ele.filterType:'')
        finalData[colname]=val
      })
      finalData['name']=this.name
      if(this.filterId){
        finalData['id']=this.filterId
        this.dataAdminService.addFilterEngine(finalData).subscribe((res:any)=>{
          console.log("SAVE DATA ",finalData ,' ----- ',data )
          this.router.navigate(['/super_admin/filter-engine'])
         })
      }else{
        this.dataAdminService.addFilterEngine(finalData).subscribe((res:any)=>{
          console.log("SAVE DATA ",finalData ,' ----- ',data )
          this.router.navigate(['/super_admin/filter-engine'])
         })
      }


    }else{
      this.statusMsg="Please Enter Filter Name"
      this.showError()
    }


  }
cancelFilter(){

}
openFilterTab(tabName:any){
  this.getBackup()
  if(tabName!=''){
    this.filtertab.forEach((ele:any)=>{
      if(ele.tab==tabName){
        this.filterTabName=tabName
       this.getFilter()
      }
    })
  }else{
    this.filters=[]
  }

}
restroreBackup(){
  this.secondFilterData=[]
  if(this.secondFilterDataBackup.length>0){
    this.secondFilterDataBackup.forEach((ele:any)=>{
      if(ele.tab==this.filterTabName){
        ele.filters.forEach((ele:any)=>{
          this.secondFilterData.push({
            key:ele.key,
            values:(ele.values)? ele.values:'',
            value:ele.value,
            filterType:(ele.filterType)? ele.filterType:'',
            filter:ele.filter,
            webKey:ele.webKey,
          })
         })
      }
    })
  }

}
getFilter(){
   let flag=false

   this.filtertab.forEach((tab:any)=>{
    if(tab.tab==this.filterTabName){
      if(this.secondFilterDataBackup.length>0){
        this.secondFilterDataBackup.forEach((ele:any)=>{
          if(ele.tab==this.filterTabName){
            flag=true
          }
         })
      }

       if(flag){
        this.restroreBackup()
       }else{
        this.secondFilterDataBackup.push({
          tab:this.filterTabName,
          filters:tab.data,
        })
        this.restroreBackup()
       }
    }
   })


}

showSuccess() {
  this.toastr.success(this.statusMsg, '',
    { timeOut: 2000 }
  );
}
showError() {
  this.toastr.error(this.statusMsg, '',
    { timeOut: 2000 }
  );
}
}
