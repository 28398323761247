import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdl1-report-pdf',
  templateUrl: './pdl1-report-pdf.component.html',
  styleUrls: ['./pdl1-report-pdf.component.css']
})
export class Pdl1ReportPdfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
