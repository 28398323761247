<div *ngIf="!cnaTable && !uploadFileInput" class="row mt-4">
	<form id="file-upload-form" class="uploader">
		<input id="file-upload" type="file" name="fileUpload" accept=".xlsx" (change)="onFileChange($event)" [disabled]="uploadFileInput">

		<label for="file-upload" id="file-drag">
			<img id="file-image" src="#" alt="Preview" class="hidden">
			<div id="start">
				<i class="fa fa-download" aria-hidden="true"></i>
				<div>Select a CNA file or drag here</div>
				<div id="notimage" class="hidden">Please select an CNA Excel File</div>
				<span id="file-upload-btn" class="btn btn-primary">Select a CNA file</span>
			</div>
			<div id="response" class="hidden">
				<div id="messages"></div>
				<progress class="progress" id="file-progress" value="0">
					<span>0</span>%
				</progress>
			</div>
		</label>
	</form>
</div>

<div *ngIf="uploadFileInput" class="d-flex justify-content-center">
	<div class="spinner-border" role="status">
	  <span class="visually-hidden"></span>
	</div>
  </div>


  <div *ngIf="cnaTable">
	<div class="row  mb-2 d-flex justify-content-between align-items-center">
		<div class="col-3">
			<h5 class="text-size pl-3 text-info mb-0">CNA Data:-</h5>
		</div>
		<div class="col-3 " *ngIf=" this.organisationName==null ||  this.organisationName==''">
			<p>File Name: {{fileName}}</p>
		</div>
		<div class="col-1 ">
			<button (click)="fileInput.click()" type="button" class="btn bg-softblue text-white">
				<input
              style="display: none"
              type="file"
              accept=".xlsx"
              (change)="onFileChange($event)"
              #fileInput
			  [disabled]="uploadFileInput"
            />
				Replace
			</button>
		</div>
		<div class="col mr-4">
			<!--  -->
			<div class="switch_panel pt-2" *ngIf="spinDiv">
				<div class="switch_main ml-auto mr-5">
					<span class="switch_off">Show All</span>
					<span class="switch_on">Selected Rows</span>
					<label class="switch">
						<input type="checkbox" (click)="switchToggle($event)">
						<span class="slider round"></span>
					</label>
				</div>
			</div>
			<!--  -->
		</div>
	</div>
	<div class="p-4 bg-white cust_shadow mx-3 rounded filter-csv_table">
	<table class="table ">
		<thead class="thead-light">
			<tr>
				<th scope="col"></th>
				<th *ngIf="switchToggleButton" scope="col">Star Select</th>
				<th scope="col" *ngIf="switchToggleButton">Priority Order</th>
				<th scope="col">GENE</th>
				<th scope="col">SAMPLE</th>
				<!-- <th scope="col">Comparison</th> -->
				<th scope="col">Actions</th>
			</tr>
		</thead>
		<div *ngIf="cnaListSpinner">
			<div class="spinner-border" role="status">
				<span></span>
			</div>
		</div>
		<tbody>
			<ng-container *ngFor="let cna of cnaVariants; let i = index">
				<tr>
			<!-- <tr  data-toggle="collapse" data-target="#accordion" (click)="accordionClick(cna)"> -->
				<td>
					<div class="form-check cust_form-ckeck">
						<input class="form-check-input" type="checkbox" (click)="seletedRow($event,cna)"
						[checked]="cna.selectedForReport">
					</div>
				</td>
				<td *ngIf="switchToggleButton">
					<div class="form-check cust_form-ckeck">
						<input class="form-check-input" type="checkbox" (click)="seletedStarRow($event,cna)"
						[checked]="cna.selectedStarForReport">
					</div>
				</td>
				<td *ngIf="switchToggleButton">
					<input type="number"  name="priorityOrderNumber_{{i}}" [(ngModel)]="cna.priorityOrder"
					(change)="priorityOrder(cna.priorityOrder, cna.id)">
				</td>
				<td>{{cna.gene}}</td>
				<td>{{cna.sample}}</td>
				<!-- <td>{{cna.comparison}}</td> -->
				<td>
					<ul class="list-unstyled d-flex mb-0 actions">
						<li>
							<a class="delete_clr" [routerLink]="[ '/report', reportId,'cna_variant' , cna.id]" target="_blank"> <i class="zmdi zmdi-eye mr-2"></i>View Details
							</a>
						</li>
					</ul>

				</td>
			</tr>
			<tr *ngIf="cna.show">
				<td colspan="4">
					<div id="accordion" class="collapse" *ngFor = "let item of comparisionList">{{item}}</div>
				</td>
			</tr>
		</ng-container>
		</tbody>
	</table>
	</div>
</div>
