import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FamilyReqDto } from '../_interfaces.ts/family';

@Injectable({
  providedIn: 'root'
})
export class FamilyHistoryService {

  constructor(
    private httpClient: HttpClient
  ) { }

  addFamilyHistoryDetails(reportId: number, familyReqDtoList: FamilyReqDto[]){
    return this.httpClient.post('/family/' + reportId +'/add_history', familyReqDtoList);
  }

  getAllFamilyDetailsByReportId(reportId: number){
    return this.httpClient.get('/family/' + reportId +'/get_details');
  }

  deleteFamilyHistory(id:number){
    return this.httpClient.get('/family/' + id +'/delete_family_history');
  }
}
