import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CancerType, Gene, GeneVariantReq, InterpretationReqDto } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-interpretation',
  templateUrl: './add-interpretation.component.html',
  styleUrls: ['./add-interpretation.component.css']
})
export class AddInterpretationComponent implements OnInit {

  editor = ClassicEditor;
  add_interpretation = {} as InterpretationReqDto;
  submitSpinner = false;
  cancerTypeList!: CancerType[];
  geneList!: Gene[];
  editorText:string=''
  statusMsg!: string;
  interpretationId!: number;
  cancerTypeDropdownSettings = {};
  selectedcancertype: CancerType[] = [];
  selectedGene: Gene[] = [];

  getSelectedCancerType: CancerType[] = [];
  addGeneVarinats!: {
    addGeneVarinat: GeneVariantReq[];
  };

  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {

    this.addGeneVarinats = {
      addGeneVarinat: [],
    };
    this.add_interpretation.description = "";
    this.interpretationId = +this.routerAct.snapshot.params['id'];
    if(this.interpretationId){
      this.getInterpretation();
    }

    this.getCancerTypes();
    this.getGenes();

    this.cancerTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  textcount(classname:any){
    var dom=document.getElementsByClassName(classname);
      var data:any=dom[0]
      this.editorText= data.outerText
  }

  onSubmit(){

    this.submitSpinner = true;

    if(this.selectedcancertype.length == 1){
      this.add_interpretation.cancerTypeId = this.selectedcancertype[0]['id'];
    }

    console.log(this.add_interpretation);
    this.dataAdminService.addInterpretation(this.add_interpretation).subscribe(
      (response: any) => {
        this.submitSpinner = false;
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );

  }

  addGeneVariant(): void {
    this.addGeneVarinats.addGeneVarinat.push({
      geneId: 0,
      variant: "",
      reference:"",
      referencePmids:""
    });

    this.add_interpretation.geneVariantReqList = this.addGeneVarinats.addGeneVarinat;
    console.log(this.addGeneVarinats.addGeneVarinat);
  }

  removeGeneVariant(i: number) {
    console.log("remove Medication", i);
    this.addGeneVarinats.addGeneVarinat.splice(i, 1);
    this.add_interpretation.geneVariantReqList = this.addGeneVarinats.addGeneVarinat;
  }

  getCancerTypes(){
    this.dataAdminService.cancerTypeList().subscribe(
      (response: any) => {
        this.cancerTypeList = response['payLoad'];
      }
    );
  }

  getGenes(){
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/interpretations']);
    });
  }

  getInterpretation() {
    this.dataAdminService.getInterpretationById(this.interpretationId).subscribe(
      (response: any) => {
        console.log(response);
        this.add_interpretation = response['payLoad'];      
        if(response['payLoad']['typeOfTest']){
          this.add_interpretation.testType=response['payLoad']['typeOfTest']
        }
        console.log(response['payLoad']);
        for(var i=0; i<response['payLoad']['geneVariants'].length;i++){
          console.log("geneVarientId-->",response['payLoad']['geneVariants'][i]['gene']['id']);

          let genevarient={} as GeneVariantReq;
          genevarient.geneId=response['payLoad']['geneVariants'][i]['gene']['id'];
          genevarient.variant=response['payLoad']['geneVariants'][i]['variant'];
          genevarient.referencePmids=response['payLoad']['geneVariants'][i]['referencePmIds'];
          genevarient.reference=response['payLoad']['geneVariants'][i]['referenceLink'];
          this.addGeneVarinats.addGeneVarinat.push(genevarient);
          this.add_interpretation.geneVariantReqList = this.addGeneVarinats.addGeneVarinat;
         
        }


        if(response['payLoad']['cancerType'] != null){
          this.getSelectedCancerType.push(response['payLoad']['cancerType']);
          this.selectedcancertype = this.getSelectedCancerType;
          }
      }
    );
  }
}
