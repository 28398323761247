import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-gene-pattern',
  templateUrl: './gene-pattern.component.html',
  styleUrls: ['./gene-pattern.component.css']
})
export class GenePatternComponent implements OnInit {

  genePatternList:any=[]
  genePatternId!:number;
  statusMsg!:string
  genePatternListSpinner:boolean=true
  constructor( 
     private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.getAllGenePattern()
  }

  getAllGenePattern(){
    this.dataAdminService.genePatternList().subscribe((res:any)=>{
      this.genePatternList=res["payLoad"]
      this.genePatternListSpinner=false
    })
  }

  setGenePattern(genepattern:any){
    this.genePatternId= genepattern['id'];
  }
  showSuccess() {
    this.toastr.success(this.statusMsg,'',
    { timeOut: 2000}
    );
  }

  deleteGenePattern(){
    this.dataAdminService.deleteGenePattern(this.genePatternId).subscribe(
      (response: any) =>{
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
    });
  }
  
  getToString(data:any):string{
    if(data){
      return data.toString()
    }
   return ""
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/genepattern']);
    });
  }

}
