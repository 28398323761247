<div class="variant_view p-4  cust_shadow my-3 d-inline-block w-100 bg-white">
  <div class="variant_title d-flex justify-content-between align-items-center   ">
    <h5 class="mb-0 font-weight-bold">{{hotspotVariant.report?.patient?.ecrfId}}
      <span>[{{hotspotVariant?.geneName}}]</span>
    </h5>

    <div class="ml-auto clinical_trail-title">
      <a *ngIf="this.clinicalTrialResList!=null && this.clinicalTrialResList.length>0"
        [routerLink]="[ '/hotspot_fda_trail_list', hotspotVariantId]" class="drug_title">Select Hotspot Drugs</a>
      <a *ngIf="this.clinicalTrialResList==null || this.clinicalTrialResList.length<=0" class="drug_title">No Clinical
        Trials</a>

    </div>
  </div>

  <div class="row mb-5  mt-3 mx-1 p-4  cust_shadow">
    <h5 class="mb-3 text_color ">Hotspot Variant detail</h5>
    <div class="w-100"></div>
    <div class="col-3 border p-4">
      <p>Gene</p>
      <h6>{{hotspotVariant.geneName}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>Chromosome Number</p>
      <h6>{{hotspotVariant.chrom}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>POS</p>
      <h6>{{hotspotVariant.pos}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>Ref</p>
      <h6>{{hotspotVariant.ref}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>Alt</p>
      <h6>{{hotspotVariant.alt}}</h6>
    </div>

    <div class="col-3 border p-4">
      <p>Qual</p>
      <h6>{{hotspotVariant.qual}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>Sample Id</p>
      <h6>{{hotspotVariant.sampleId}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>Ao</p>
      <h6>{{hotspotVariant.ao}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>Strand Info</p>
      <h6>{{hotspotVariant.strandInfo}}</h6>
    </div>
    <div class="col-3 border p-4">
      <p>Comment</p>
      <h6>{{hotspotVariant.comment}}</h6>
    </div>

  </div>

  <div class="row ml-2 mb-4">
    <div class="col-4">
      <h5 class=" text_color ">*Clinical Siginificance</h5>
      <select class="form-control" name="significance" [(ngModel)]="hotspotVariant.significance"
        (click)="getGeneDescription()">
        <option value="Benign">Benign</option>
        <option value="Pathogenic">Pathogenic</option>
        <option value="Likely Pathogenic">Likely Pathogenic</option>
        <option value="VUS">VUS</option>
        <option value="Not Classified">Not Classified</option>
      </select>
    </div>

    <div class="col-sm-8" *ngIf="hotspotVariant.significance!='NA'">
      <h5 class="text_color ">Reference</h5>
      <input type="text" class="form-control" name="reference" [(ngModel)]="hotspotVariant.reference">
    </div>
  </div>
  <div class="row ml-2 mb-4">

    <div class="col-4" *ngIf="hotspotVariant.significance!='NA'">
      <h5 class="text_color ">Total Depth</h5>
      <input type="text" class="form-control" name="reference" [(ngModel)]="totalDept">
    </div>
    <div class="col-4" *ngIf="hotspotVariant.significance!='NA'">
      <h5 class="text_color ">Alternative Allele Depth</h5>
      <input type="text" class="form-control" name="reference" [(ngModel)]="alternativeAlleleDepth">
    </div>
    <div class="col-4" *ngIf="hotspotVariant.significance!='NA'">
      <h5 class="text_color ">Allelic burden (%)</h5>
      <input type="text" class="form-control" name="reference" [(ngModel)]="allelicBurden">
    </div>
  </div>

  <div class="row ml-2 mb-4">
    <div class="col">
      <h5 class=" text_color ">Gene Description ({{gene?.name}})</h5>
      <p class="py-3" [innerHtml]="gene?.description"></p>
    </div>
  </div>

  <div class="row ml-2 mb-4">
    <div class="col">
      <div class="d-flex justify-content-between align-items-center  my-1">
        <h5 class="text_color mb-0 ">Add Gene Description</h5>
        <div *ngIf="hotspotVariant.addGeneDescription!=null ||hotspotVariant.addGeneDescription!=undefined">
          <P *ngIf="hotspotVariant.addGeneDescription.length"><strong>[{{editorText.length}}/2000]</strong></P>
        </div>
      </div>
      <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
        name="addGeneDescription" [(ngModel)]="hotspotVariant.addGeneDescription"
        (keyup)="textcount('addGeneDescription-editor')"></ckeditor>
      <div class="addGeneDescription-editor" hidden="true" [innerHtml]="hotspotVariant.addGeneDescription"></div>
      <p class="pt-2"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub>
        → {{sub}}<strong>]</strong></p>

    </div>
  </div>

  <div class="form-group row ml-2 mb-4"
    *ngIf="hotspotVariant.report?.reportType?.name != 'First' && hotspotVariant.report.reportType.name != 'First 72 Solid' && hotspotVariant.report.reportType.name != 'First 72 Liquid' && hotspotVariant.report.reportType.name != 'Lung Liquid Basic' && hotspotVariant.report.reportType.name != 'Lung Liquid Advanced' ">
    <div class="col">
      <label>PMIDS:</label>
      <input type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
        [(ngModel)]="pmIds">
    </div>
  </div>

  <div class="row ml-2 mb-4" *ngIf="isFirst72">
    <div class="col-3">
      <h5 class=" text_color ">*Prognostic Siginificance</h5>

      <select class="form-control" name="significance" [(ngModel)]="hotspotVariant.prognasticSignificance"
        (ngModelChange)="getPrognasticTag($event)">
        <option value="Poor">Poor</option>
        <option value="Good">Good</option>
        <option value="NA">NA</option>

      </select>
    </div>

    <div class="col-3">
      <h5 class=" text_color ">*Prognostic Tag</h5>
      <ng-multiselect-dropdown [settings]="prognasticBiomarkerDropdownSettings" [data]="prognasticTagList"
        [(ngModel)]="selectedPrognasticBiomarker" (ngModelChange)="selectPrognasticBiomarker($event)"
        name="prognasticTag">
      </ng-multiselect-dropdown>
    </div>
    <div class="col">
    </div>
  </div>
  <div class="row ml-2 mb-4" *ngIf="isFirst72">
    <div class="col">
      <div class="d-flex justify-content-between align-items-center  my-1">
        <h5 class="text_color">Add Prognostic Description</h5>
        <div *ngIf="hotspotVariant.prognasticDescription!=null ||hotspotVariant.prognasticDescription!=undefined">
          <P *ngIf="hotspotVariant.prognasticDescription.length"><strong>[{{editorText.length}}/2000]</strong></P>
        </div>
      </div>
      <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
        name="addPrognasticDescription" [(ngModel)]="hotspotVariant.prognasticDescription"
        (keyup)="textcount('addPrognasticDescription-editor')"></ckeditor>
      <div class="addPrognasticDescription-editor" hidden="true" [innerHtml]="hotspotVariant.prognasticDescription">
      </div>
      <p class="pt-2"><strong>*[</strong>A<sup>b</sup> → {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub>
        → {{sub}}<strong>]</strong></p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button type="button" [disabled]="isSubmited" class="btn save_btn" style="float:right" (click)="submitDetails()">
        <span *ngIf="!isSubmited"> Save </span>
        <div *ngIf="isSubmited" class="spinner-border text-dark">
          <span class="sr-only"></span>
        </div>
      </button>
    </div>
  </div>
</div>
