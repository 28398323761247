<div>
    <div class="card">
    <div class="card-header">
            Edit Patient
      </div>
      </div>
    <form class="mt-5">
        <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-right">Name : </label>
            <div class="col-sm-4">
                <input type="text" class="form-control" name="name" [(ngModel)]="add_patient.name">
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-right">Date Of Birth : </label>
            <div class="col-sm-4">
                <input type="date" class="form-control" name="dateOfBirth" [(ngModel)]="add_patient.dateOfBirth">
            </div>
        </div>

        <div class="row mb-3">
            <label class="col-sm-2 col-form-label text-right">Gender : </label>
            <div class="col-sm-5">
              <ul class="segmented-control d-flex list-unstyled">
                <li class="segmented-control__item">
                  <a href="#"><input class="segmented-control__input" type="radio" value="MALE" name="gender"
                      [(ngModel)]="add_patient.gender" id="option-1" checked>
                    <label class="segmented-control__label" for="option-1">MALE</label></a>
                </li>
                <li class="segmented-control__item">
                  <a href="#"><input class="segmented-control__input" type="radio" value="FEMALE" name="gender"
                      [(ngModel)]="add_patient.gender" id="option-2">
                    <label class="segmented-control__label" for="option-2">FEMALE</label></a>
                </li>
                <li class="segmented-control__item">
                  <a href="#"><input class="segmented-control__input" type="radio" value="OTHERS" name="gender"
                      [(ngModel)]="add_patient.gender" id="option-3">
                    <label class="segmented-control__label" for="option-3">OTHERS</label></a>
                </li>
              </ul>
            </div>
          </div>

        <div class="d-grid gap-2 col-6 mx-auto " style="max-width: 25%;">
            <button type="submit" class="btn btn-light mr-2" (click)="cancelEditPatient()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="editPatient()">Update</button>
        </div>
    </form>
</div>