import { Injectable } from '@angular/core';
import { rejects } from 'assert';
import { S3 } from 'aws-sdk';
import { resolve } from 'dns';
import { environment } from 'src/environments/environment';
import { PatientDocument } from '../_interfaces.ts/patient';
import { ReportService } from './report.service';

@Injectable({
  providedIn: 'root'
})
export class AWSServiceService {
  key!:string;
  constructor(private reportService: ReportService) { }

  async uploadFile(file: any, data: PatientDocument, ecrfId: string): Promise<any> {

    const formdata: FormData = new FormData();
    formdata.append('file', file);

    //aws statrt
    let fileType = file.name.split('.').pop();
    data.title = file.name
    data.type = fileType
    let date = new Date()
    const bucket = new S3(
      {
        accessKeyId: environment.AWS_ACCESS_KEY_ID,
        secretAccessKey: environment.AWS_SECRET_ACCESS_KEY,
        region: environment.AWS_REGION,
      }
    );
    const params = {
      Bucket: 'oncobench-files',
      Key: "final_report/" + date.getTime() + "-" + file.name,
      Body: file,
      ContentType: fileType
    };
    let response = new Promise<any>((resolve, rejects) => {
      bucket.upload(params, (err: any, res: any) => {
        if (err) {
          console.log('EROOR: ', JSON.stringify(err));
          rejects("Error in file uploading please try again");
          return false;
        }
        this.key=params.Key;

        resolve(res)
        console.log('File Uploaded.', res);
        console.log("=>",this.key);

        data.url = "https://drjcvfmhd5pya.cloudfront.net/" + this.key;
        this.reportService.uploadFinalReport(ecrfId, data).subscribe(
          (response) => {
            resolve("Success")

          }, (error: any) => {
            rejects(error.error.error)
          }
        );
        return true;
      }).on("httpUploadProgress", (progress) => {
        this.getUploadingProgress(progress.loaded, progress.total);
        console.log("progress=>", this.getUploadingProgress(progress.loaded, progress.total))
      })
    });

    // return response.then((res: any) => {
    //   console.log("===>",res);
    //   console.log("=>",res.key);
    //   console.log("->",this.key)

      // let finalPromise = new Promise((resolve, rejects) => {

      // });
      // return finalPromise.then((res) => {
      //   return res
      // }, error => {
      //   return error;
      // })

    // }, rejects => {
    //   debugger;
    //   return rejects;
    // })
  }


  getUploadingProgress(uploadSize: any, totalSize: any) {
    let uploadProgress = (uploadSize / totalSize) * 100;
    return Number(uploadProgress.toFixed(0));
  }
}
