import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule } from './auth/auth.module';
import { PagesModule } from './pages/pages.module';
import { SuperAdminModule } from './super-admin/super-admin.module';
import { AdminGuard } from './_services/admin.guard';
import { AuthGuard } from './_services/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule
  },
  {
    path: 'organisation',
    loadChildren: () => AuthModule
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => PagesModule
  },
  {
    path: 'super_admin',
    canActivate: [AdminGuard],
    loadChildren: () => SuperAdminModule
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
