import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddTherapy, Drug, Therapy } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-therapy',
  templateUrl: './therapy.component.html',
  styleUrls: ['./therapy.component.css']
})
export class TherapyComponent implements OnInit {


  drugs = [];
  drugsId: number[] = [];
  therapyList: Therapy[] = [];
  selectedDrugs: Drug[] = [];

  statusMsg!: string;
  add_therapy = {} as AddTherapy;
  drugsDropdownSettings = {}; 

  constructor(
    private dataAdminService: DataAdminService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {
    this.getTherspyList();
    this.getDrugs();

    this.drugsDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


 
  addTherapy(){

    if(this.selectedDrugs.length > 0){
      for (let i = 0; i < this.selectedDrugs.length; i++) {
        const id = this.selectedDrugs[i]['id'];
        this.drugsId.push(id);
      }
    }

    this.add_therapy.drugIds = this.drugsId;
    this.drugsId = [];
    console.log("add_therapy",this.add_therapy);

    this.dataAdminService.addTherapy(this.add_therapy).subscribe(
      (response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );

  }

  assignTherapy(therapy: Therapy){
    console.log("therapy",therapy.drugs);
    this.add_therapy.id = therapy.id;
    this.add_therapy.name = therapy.name;
    this.selectedDrugs = therapy.drugs;
  }

  getTherspyList(){
    this.dataAdminService.getTherapyList().subscribe(
      (response: any) => {
        this.therapyList = response['payLoad'];
        console.log(this.therapyList);
      }
    );
  }

  deleteTherapy(){
    this.dataAdminService.deleteTherapy(this.add_therapy.id).subscribe(
      (response: any) => {
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  getDrugs() {
    this.dataAdminService.getDrugs().subscribe(
      (response: any) => {
        this.drugs = response['payLoad'];
      }
    );
  }

  closeModal(){
    console.log("close modal")
    this.add_therapy = {} as  AddTherapy;
    this.selectedDrugs = [];
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/therapys']);
    });
  }

}
