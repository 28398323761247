<div class="container-fluid px-0">
    <h5 *ngIf="!cancerSiteGeneId" class="mb-0 d-inline-block position-relative  mb-3 mt-4">Add Cancer Site Genes</h5>
    <h5 *ngIf="cancerSiteGeneId" class="mb-0 d-inline-block position-relative mb-3 mt-4">Edit Cancer Site Genes</h5>
   <div class="mx-0 p-4 bg-white cust_shadow rounded ">
    <div class="row mx-3">
        <div class="col">
            <form class="form_list" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-6 form-group row">
                        <label class="col-sm-5 control-label">Cancer Site:</label>
                        <div class="col-sm-7">
                            <ng-multiselect-dropdown  [settings]="cancerSiteDropdownSettings" [data]="cancerSiteList" [(ngModel)]="cancerSite" name="cancerSite"
                                   >
                                </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-6 form-group row">
                        <label class="col-sm-5 control-label">Gene:</label>
                        <div class="col-sm-7">
                            <ng-multiselect-dropdown  [settings]="geneDropdownSettings" [data]="geneList" [(ngModel)]="gene" name="gene">
                         </ng-multiselect-dropdown>

                        </div>
                    </div>
                    <div class="col-12 add-emp mr-10 mb-5">
                        <div class="col-sm-8 offset-sm-4 px-0 text-right">
                            <button type="submit" class="btn save_btn text-uppercase bg-softblue ml-2"
                                [disabled]="submitSpinner">
                                <!-- <span class="delete_clr"  data-toggle="modal" data-target="#deleteDrugModal"> Submit </span> -->
                                <span *ngIf="!submitSpinner"> Submit </span>
                                <div *ngIf="submitSpinner" class="spinner-border text-dark">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                    </div>
            </div>
            </form>
        </div>
    </div>
   </div>
</div>
