import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Drug } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-drugs',
  templateUrl: './drugs.component.html',
  styleUrls: ['./drugs.component.css']
})
export class DrugsComponent implements OnInit {


  drugs : Drug[] | undefined;
  drugId!: number;
  statusMsg!: string;
  drugsListSpinner = true;

  constructor(
    private dataAdminService: DataAdminService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getDrugs();
  }


  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }


  assignDrug(id: number) {
    this.drugId = id;
    console.log("drugId",this.drugId);
  }


  getDrugs() {
    this.dataAdminService.getDrugs().subscribe(
      (response: any) => {
        this.drugsListSpinner = false;
        this.drugs = response['payLoad'];
        console.log("drugs list =>",this.drugs);
      }
    );
  }

  deleteDrug(){
    console.log("drugId",this.drugId);
    this.dataAdminService.deleteDrug(this.drugId).subscribe(
      (response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      }
    );
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/drugs']);
    });
  }

}
