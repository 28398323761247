import { AddGenePathway } from './../../_interfaces.ts/admin';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClinicalTrialRes, Gene } from 'src/app/_interfaces.ts/admin';
import { FusionVariant } from 'src/app/_interfaces.ts/excel';
import { StudyFieldsResponse } from 'src/app/_interfaces.ts/trails';
import { ClinicalTrailService } from 'src/app/_services/clinical-trail.service';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { FusionService } from 'src/app/_services/fusion.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Title } from '@angular/platform-browser';
import { ReportService } from 'src/app/_services/report.service';


@Component({
  selector: 'app-view-fusion',
  templateUrl: './view-fusion.component.html',
  styleUrls: ['./view-fusion.component.css']
})
export class ViewFusionComponent implements OnInit {

  sup="A{b} → Superscript";
  sub="A~b! → Subscript";
  editor = ClassicEditor;
  pmIds!: string;
  editorText:string=''
  fusionVariantId!: number;
  reportId!: number;
  fusionVariant = {} as FusionVariant;
  studyFieldsResponse = {} as StudyFieldsResponse;
  clinicalTrialResList: ClinicalTrialRes[] = [];

  isSubmited!: boolean;
  statusMsg!: string;
  gene!: Gene;

  constructor(
    private routerAct: ActivatedRoute,
    private router: Router,
    private reportService: ReportService,
    private trailService: ClinicalTrailService,
    private dataAdminService: DataAdminService,
    private fusionService: FusionService,
    private toastr: ToastrService,
    private titleService: Title
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit() {
    this.fusionVariant.significance='Not Classified'

    this.fusionVariantId = this.routerAct.snapshot.params['fid'];
    this.reportId = this.routerAct.snapshot.params['rid'];
    if(this.fusionVariantId){
      this.getFusionVariant();
    }
  }
  textcount(classname:any){
    var dom=document.getElementsByClassName(classname);
      var data:any=dom[0]
      this.editorText= data.outerText
  }
  geneDescriptionMessage!:any;
  previousDescription!:any;
  getGeneDescription(){
    if(this.fusionVariant.significance=='Benign'){this.fusionVariant.addGeneDescription=this.previousDescription;}
    else if(this.fusionVariant.significance=='Pathogenic'){
      this.geneDescriptionMessage=" The break at exon X of (gene1_name) gene leads to the retention of the (domain_name) domains of the (gene1_name) gene and the breakpoint at exon Y of (gene2_name) retains only the (domain_name) domain of the (gene2_name) gene. The fusion protein is essential for the constitutive (activation/autophosphorylation) of (gene2_name) (reference). Hence, the identified (name) gene fusion is classified as Pathogenic. ";
      this.fusionVariant.addGeneDescription = this.previousDescription + `<br/>` + this.geneDescriptionMessage
    }
    else if(this.fusionVariant.significance=='VUS'){
      this.geneDescriptionMessage=" (gene name) fusion has not been reported in the literature yet. However, fusion involving (gene 2) containing the (domain name) domains has been reported in literature in association with (mechanism/pathway name). Most (gene1/2) fusions have their breakpoints around Exon X-Intron X boundary of the gene that fuses with the C-terminal gene partners (reference). Similarly, the fusion identified in the patient is at exon X of the (gene name) gene, retaining/lacking the (domain(s) name) domains of the (name) protein, which are essential for activation of the downstream signalling. However, structural, and functional significance of the fusion partner, (Gene 1) gene is currently not available in literature. Hence, the identified (name) gene fusion is classified as VUS and must be clinically correlated with other findings for any further management. ";
      this.fusionVariant.addGeneDescription = this.previousDescription + `<br/>` + this.geneDescriptionMessage
    }
    else if(this.fusionVariant.significance=='NA' || this.fusionVariant.significance=='Not Classified'){this.fusionVariant.addGeneDescription = this.previousDescription;}
    else if(this.fusionVariant.significance=='Likely Pathogenic'){this.fusionVariant.addGeneDescription = this.previousDescription;}
    }


  submitDetails() {
    this.isSubmited = true;
    console.log("this.fusionVariant.significance", this.fusionVariant.leftExon);
    this.fusionService.addFusionVariantSignificance(this.fusionVariantId, this.fusionVariant).subscribe(
      (response: any) => {
        this.isSubmited = false;
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
      }
    );
    this.isSubmited = true;
    if(this.pmIds.length>0){
      this.pmIds=this.pmIds;
    this.reportService.addPMIds(this.reportId, this.pmIds).subscribe(
      (response: any) => {
        console.log(response);
        this.isSubmited = false;
      }
    );
  }
  }


  nstudiesFound = 0;
  clinicalTrialListCount = 0;
  getFusionVariant() {
    this.fusionService.getFusionVariant(this.fusionVariantId).subscribe(
      (response: any) => {
        this.fusionVariant = response['payLoad'];
        console.log("complete fusionVariant : ",this.fusionVariant);
        if(this.fusionVariant.report.reportStatus == 'REPORTED'){
          this.statusMsg = "Report is already delivered no operations can be done";
          this.showSuccess();
          this.router.navigate(['/reports']);
          return;
        }
        if(this.fusionVariant.significance==null){
          this.fusionVariant.significance='Not Classified'
        }
        if(this.fusionVariant.significance=='NA'){
          this.fusionVariant.significance='Not Classified'
        }
        this.pmIds = this.fusionVariant.report.pmIds.toString();
        this.getreadNameList(this.fusionVariant.readNames);
        this.titleService.setTitle(this.fusionVariant.report.patient.ecrfId + " - fusion - " + this.fusionVariant.fusionGene);



        this.dataAdminService.getGeneByName(this.fusionVariant.fusionGene).subscribe(
          (response: any) => {
            this.gene = response['payLoad'];
            console.log("gene details", this.gene);
            this.previousDescription=this.fusionVariant.addGeneDescription;
            // this is to remove the <p></p> in gene.description the is being displayed above the add gene description
            if (this.gene.description != null) {
              this.gene.description= this.gene.description.replace(/<p>/g,'');
            this.gene.description= this.gene.description.replace(/<\/p>/g, '');
            }
            
          }
        );
        if (this.fusionVariant.significance == null) {
          this.fusionVariant.significance = "Benign";
        }

        if (this.fusionVariant.report.reportType.name != 'First' && this.fusionVariant.report.reportType.name != 'First 72 Solid' && this.fusionVariant.report.reportType.name != 'First 72 Liquid' && this.fusionVariant.report.reportType.name != 'Lung Liquid Basic' && this.fusionVariant.report.reportType.name != 'Lung Liquid Advanced' ) {
        if (response['success']) {

          this.trailService.getTrailsCount(this.fusionVariant.fusionGene).subscribe(
            (res: any) => {
              this.nstudiesFound = res['payLoad'];
              console.log("nstudiesFound", this.nstudiesFound);
            }
          );
        }

        if (response['success']) {
          this.dataAdminService.getAllClinicalTrailsCount(this.fusionVariant.fusionGene).subscribe(
            (response: any) => {
              this.clinicalTrialListCount = response['payLoad'];
              console.log("clinical fda trail db",this.clinicalTrialListCount);
            }
          );
        }
      } else {

        if (response['success']) {
          this.dataAdminService.getAllHotspotClinicalTrailsCount(this.fusionVariant.fusionGene).subscribe(
            (response: any) => {
              this.clinicalTrialListCount = response['payLoad'];
              console.log("clinical fda trail db",this.clinicalTrialListCount);
            }
          );
        }

      }
      setTimeout(()=>{
        this.textcount('addGeneDescription-editor')
      },3000) 
      }
    );
  }

  readNameList : String[] = [] ;
  getreadNameList(readName : string){
    console.log("getreadNameList");
    this.readNameList =  readName.split(";");
  }

}
