import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DisclaimerVariant, DisclaimerVariants } from 'src/app/_interfaces.ts/admin';
import { DataAdminService } from 'src/app/_services/data-admin.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {

  constructor(private toastr: ToastrService,private dataAdminService:DataAdminService) { }
  disclaimerList:DisclaimerVariants[]=[]
  disclaimerListBackup:DisclaimerVariants[]=[]
  deletDisclaimerId!:number
  disclaimerData={} as DisclaimerVariants
  disclaimerListSpinner=true
  statusMsg:string=''
  typeList:any=['SOMATIC','GERMLINE']
  ngOnInit(): void {
    this.getDisclaimerList();
  }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  showError(msg:any) {
    this.toastr.error(msg, '',
      { timeOut: 2000 }
    );
  }

  getDisclaimerList(){
    this.dataAdminService.disclaimerList().subscribe((res:any)=>{
      this.disclaimerList=res["payLoad"]
      this.disclaimerListSpinner=false
      this.disclaimerListBackup=this.disclaimerList
    })
  }

  deleteDisclaimer(){
    if(this.deletDisclaimerId){
      this.dataAdminService.deleteDisclaimer(this.deletDisclaimerId).subscribe((res:any)=>{
        this.statusMsg=res["message"]
        this.showSuccess()
        this.getDisclaimerList();
      })
    }
   
  }

  setDisclaimerDetails(data:any){
    this.disclaimerData.disclaimerDetails=data.disclaimerDetails
    this.disclaimerData.disclaimerTag=data.disclaimerTag
    this.disclaimerData.typeOfTest=data.typeOfTest
  }

  filterByType(item:any){
    let value=item.target.value
    if(!(value=='All')){
      this.disclaimerList=this.disclaimerListBackup.filter((element:any)=>{
        return element.typeOfTest==value
      })
    }else{
      this.getDisclaimerList()
    }
    
  }

  setDisclaimer(data:any){
    this.deletDisclaimerId=data.id
  }

  closeModal(){

  }

}
