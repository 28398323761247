import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { DashReportsComponent } from './dash-reports/dash-reports.component';
import { PagesComponent } from './pages.component';
import { PatientComponent } from './patient/patient.component';
import { FormsModule } from '@angular/forms';
import { ReadExcelComponent } from './read-excel/read-excel.component';
import { TrailsListComponent } from './trails-list/trails-list.component';
import { TruncatePipe } from '../_services/length.pipe';
import { ReportsComponent } from './reports/reports.component';
import { PdfContentComponent } from './pdf-content/pdf-content.component';
import { ViewReportVariantComponent } from './view-report-variant/view-report-variant.component';
import { FdaClinicalTrailsComponent } from './fda-clinical-trails/fda-clinical-trails.component';
import { CancerTypePipe } from '../_services/cancer-type.pipe';
import { CnaExcelComponent } from './cna-excel/cna-excel.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ViewCnaComponent } from './view-cna/view-cna.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FusionExcelComponent } from './fusion-excel/fusion-excel.component';
import { ViewFusionComponent } from './view-fusion/view-fusion.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TargetFirstReportComponent } from './target-first-report/target-first-report.component';
import { PatientDocumentsComponent } from './patient-documents/patient-documents.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GermlineReportComponent } from './germline-report/germline-report.component';
import { CoverageExcelComponent } from './coverage-excel/coverage-excel.component';
import { HrrGermlineReportComponent } from './hrr-germline-report/hrr-germline-report.component';
import { CoreReportComponent } from './core-report/core-report.component';
import { DemoForpdfComponent } from './demo-forpdf/demo-forpdf.component';
import { FamilyTestingReportComponent } from './family-testing-report/family-testing-report.component';
import { HrrSomaticReportComponent } from './hrr-somatic-report/hrr-somatic-report.component';
import { First72ReportComponent } from './first72-report/first72-report.component';
import { First72LiquidComponent } from './first72-liquid/first72-liquid.component';
import { SplitCommaStringPipe } from '../_services/pipe';
import { LungLiquidBasicReportComponent } from './lung-liquid-basic-report/lung-liquid-basic-report.component';
import { LungLiquidAdvancedReportComponent } from './lung-liquid-advanced-report/lung-liquid-advanced-report.component';
import { BrcaReportComponent } from './brca-report/brca-report.component';
import { First72Liquid4bcComponent } from './first72LiquidReports/first72-liquid4bc/first72-liquid4bc.component';
import { First72LiquidHCGComponent } from './first72LiquidReports/first72-liquid-hcg/first72-liquid-hcg.component';
import { First72LiquidTMHComponent } from './first72LiquidReports/first72-liquid-tmh/first72-liquid-tmh.component';
import { First72Solid4bcComponent } from './first72SolidReports/first72-solid4bc/first72-solid4bc.component';
import { First72SolidHCGComponent } from './first72SolidReports/first72-solid-hcg/first72-solid-hcg.component';
import { First72SolidTMHComponent } from './first72SolidReports/first72-solid-tmh/first72-solid-tmh.component';
import { IndiegeneComponent } from './IndiegeneReports/indiegene-dashboard/indiegene/indiegene.component';
import { HotspotExcelComponent } from './hotspot-excel/hotspot-excel.component';
import { ViewHotspotVariantComponent } from './view-hotspot-variant/view-hotspot-variant.component';
import { Pdl1DashboardComponent } from './pdl1Report/pdl1-dashboard/pdl1-dashboard.component';
import { Pdl1ReportPdfComponent } from './pdl1Report/pdl1-report-pdf/pdl1-report-pdf.component';
import { IndiegeneDashboardComponent } from './IndiegeneReports/indiegene-dashboard/indiegene-dashboard.component';



@NgModule({
  declarations: [
    DashReportsComponent,
    PagesComponent,
    PatientComponent,
    ReadExcelComponent,
    TrailsListComponent,
    TruncatePipe,
    SplitCommaStringPipe,
    CancerTypePipe,
    ReportsComponent,
    PdfContentComponent,
    ViewReportVariantComponent,
    FdaClinicalTrailsComponent,
    CnaExcelComponent,
    ViewCnaComponent,
    FusionExcelComponent,
    ViewFusionComponent,
    TargetFirstReportComponent,
    PatientDocumentsComponent,
    GermlineReportComponent,
    CoverageExcelComponent,
    HrrGermlineReportComponent,
    CoreReportComponent,
    DemoForpdfComponent,
    FamilyTestingReportComponent,
    HrrSomaticReportComponent,
    First72ReportComponent,
    First72LiquidComponent,
    LungLiquidBasicReportComponent,
    LungLiquidAdvancedReportComponent,
    BrcaReportComponent,
    First72Liquid4bcComponent,
    First72LiquidHCGComponent,
    First72LiquidTMHComponent,
    First72Solid4bcComponent,
    First72SolidHCGComponent,
    First72SolidTMHComponent,
    IndiegeneComponent,
    HotspotExcelComponent,
    ViewHotspotVariantComponent,
    Pdl1DashboardComponent,
    Pdl1ReportPdfComponent,
    IndiegeneDashboardComponent
    ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    FormsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    CKEditorModule,
    Ng2SearchPipeModule
  ]
})
export class PagesModule { }
