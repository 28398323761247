<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div
    class="spinner-border"
    style="width: 8rem; height: 8rem"
    role="status"
  ></div>
</div>

<div *ngIf="reportData">
  <button
    [disabled]="downloadSpinner"
    type="button"
    class="btn btn-primary m-2 float-right download-btn"
    (click)="downloadReport()"
  >
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i> Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>
  <!-- <div> -->
  <!-- <button type="button" class="btn btn-primary m-2 float-right download-btn">
    <span> <i class="zmdi zmdi-download"></i> Download Report </span>
    <div class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button> -->

  <div>
    <div
      id="page"
      class="container position-relative page"
      style="overflow-y: clip; background-color: white"
    >
      <div class="row header">
        <div class="w-100 overflow-hidden">
          <div class="col px-0">
            <div class="cover_page">
              <!-- <img src="../../../assets/hrr_germline.png" alt="" class="img-fluid w-100"> -->
              <img
                src="../../../assets/FamilyMemberTest_cover.png"
                alt=""
                class="img-fluid w-100"
              />
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>

    <!-- page 1 -->
    <div
      id="page1"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p style="color: #238eca; font-weight: 700" class="py-1">
                SCOPE OF THE TEST
              </p>
              <p>
                SNVs, InDels, CNAs<!--, Gene Fusions-->
                status
              </p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-75" />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p style="color: #238eca; font-weight: 700" class="py-1">
              </p>
              <p></p>
            </div>
            <img src="" class="mr-2 w-75" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan">Name : </span>
              <span class="text-black">{{ report.patient.name }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Gender : </span>
              <span class="text-black" style="text-transform: capitalize">{{
                report.patient.gender | titlecase
              }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Age/DOB : </span>
              <span class="text-black">{{
                report.patient?.dateOfBirth
                  ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                  : report.patient?.age + " Years"
              }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled pt-4 mt-3">
            <li>
              <span class="clr-cyan">Sample Source : </span>
              <span class="text-black">{{ sampleSource }}</span>
            </li>

            <li class="pb-1">
              <span class="clr-cyan">Referning Clinician : </span>
              <span class="text-black">{{ referringClinician }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Hospital : </span>
              <span class="text-black">{{ labHospital }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <div
        class="row mt-3 mb-3"
        *ngIf="
          report.clinicalSummary != null && report.clinicalSummary != '<p></p>'
        "
      >
        <div class="col">
          <h6 class="mb-0 py-2 h6_bg">CLINICAL Summary</h6>
          <div class="mt-2" [innerHtml]="report.clinicalSummary">
            <!-- <p class="py-3">
              The protein encoded by this gene is a member of the RAD51 protein
              family. RAD51 family members are evolutionarily conserved proteins
              essential for DNA repair by homologous recombination. Diseases
              associated with RAD51B include Leiomyoma and Hereditary Breast
              Ovarian Cancer Syndrome. It has an autosomal dominant mode of
              inheritance. RAD51B is altered in 0.42% of all cancers and 0.24%
              of breast carcinoma.
            </p>
            <p class="py-3">
              The variant p.Cys60LeufsTer18 has a total depth of 59X and an
              alternative depth of 25X. The In silico prediction tool,
              MutationTaster predicts this variant to be deleterious. This
              variant is not present in any population databases. The identified
              variant lies in the N-terminal linker of RAD51B protein (Kim YM,
              et al, 2011) and in the 3rd exon of the gene. This frameshift
              variant at codon 60, is expected to result in loss of function by
              premature protein truncation which may result in loss of ATPase
              domain. Germline loss of function variants of the RAD51B gene
              shows a high risk of breast and ovarian cancer (Setton J, et al,
              2021). Based on the available evidence, this variant is classified
              as pathogenic.
            </p> -->
          </div>
        </div>
      </div>
      <!--  -->
      <div class="">
        <h6 class="py-2 h6_bg">Results</h6>
      </div>
      <div class="row mt-2 py-2">
        <div class="tag" id="tag">
          <!--  -->
          <!-- <div  class="mr-2 my-3 ml-2 " *ngIf="isReportDownload">
            <img [src]="tag" alt="" class="img-fluid w-90">
          </div> -->
          <div class="mr-2 my-3">
            <div class="results_gradi mt-3">
              <ul class="d-flex list-unstyled">
                <li [ngClass]="{ zindex: isPathogenic }">
                  <!-- <span *ngIf="!isPathogenic">Pathogenic</span> -->
                  <!-- <span *ngIf="isPathogenic">Pathogenic variant Detected </span> -->
                  <div
                    class="bg"
                    [ngClass]="{
                      marker_left: isPathogenic,
                      resultLine: !isPathogenic
                    }"
                  >
                    <label *ngIf="isPathogenic"
                      >Pathogenic Variant Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isLikelyPathogenic }">
                  <!-- <span>Likely Pathogenic</span> -->
                  <div class="bg" [ngClass]="{ marker: isLikelyPathogenic }">
                    <label *ngIf="isLikelyPathogenic"
                      >Likely Pathogenic Variant Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isVUS }">
                  <!-- <span>Variant of Unknown Significance (VUS)</span> -->
                  <div
                    class="bg"
                    [ngClass]="{ marker_vus: isVUS, resultLine: !isVUS }"
                  >
                    <label *ngIf="isVUS"
                      >Variant of Unknown Significance (VUS) Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isOthers }">
                  <!-- <span>Likely Benign/Benign/No variant</span> -->
                  <div class="bg" [ngClass]="{ marker_right: isOthers }">
                    <label *ngIf="isOthers"
                      >Likely Benign/Benign/No variant Detected</label
                    >
                  </div>
                </li>
              </ul>
            </div>
            <!-- <img src="../../../assets/grad_pic.png" alt="" class="img-fluid w-100"> -->
          </div>
          <p class="py-2">*Please refer to Annexure for further information</p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h5 class="dark-cyan font-weight-bold mb-3">
            Variant Details Associated with Disorder/Gene of interest:
          </h5>

          <!-- table -->
          <!-- <div  *ngIf="isReportDownload">
              <img [src]="tables" alt="" class="img-fluid w-100">
            </div> -->
          <table class="page1_table" id="tables">
            <thead>
              <th>Gene</th>
              <th>Genomic Alteration</th>
              <th>Amino acid change, Exon Number</th>
              <th>Variant Type, Zygosity</th>
              <th>Associated disorder, Inheritance</th>
              <th>Reference</th>
              <th>Clinical Significance</th>
            </thead>
            <tbody
              *ngIf="
                familyTestingReportPdfRes.snvVariants.length == 0 &&
                familyTestingReportPdfRes.cnaVariants.length == 0
              "
            >
              <tr>
                <td></td>
                <td colspan="6" style="text-align: center !important">
                  Clinically relevant genomic alterations associated with
                  therapeutic significance were not detected.
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="varientTableCount <= 10">
              <tr
                *ngFor="
                  let snvStarVariant of familyTestingReportPdfRes.snvVariants
                "
              >
                <td>
                  <b
                    ><i
                      ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                    ></b
                  >
                </td>
                <td>
                  {{ snvStarVariant.genomicAlteration }}
                  {{
                    snvStarVariant.selectedAAChangeknownGene != null
                      ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                      : ""
                  }}, [{{ snvStarVariant.ensembleValue }}]
                </td>
                <td>
                  {{
                    snvStarVariant.selectedAAChangeknownGene != null
                      ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                      : ""
                  }}<br />
                  Exon {{ snvStarVariant.exonNumber }} ,
                  {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
                </td>
                <td>
                  {{ snvStarVariant.exonicFuncknownGene }},
                  {{ snvStarVariant.zygosity }}
                </td>
                <td>
                  {{ snvStarVariant.cancerType }},
                  {{ snvStarVariant.inheritanceMode }}
                </td>
                <td>{{ snvStarVariant.reference }}</td>
                <td>{{ snvStarVariant.significance }}</td>
              </tr>
              <tr
                *ngFor="
                  let cnaStarVariant of familyTestingReportPdfRes.cnaVariants
                "
              >
                <td>
                  <b>{{ cnaStarVariant.gene }}</b>
                </td>
                <td>
                  chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                    cnaStarVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td>NA</td>
                <td>
                  Copy Number
                  {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                    cnaStarVariant.sample.split("|")[1].split(":")[0]
                  }}), {{ cnaStarVariant.zygosity }}
                </td>
                <td>
                  {{ cnaStarVariant.cancerType }},
                  {{ cnaStarVariant.inheritanceMode }}
                </td>
                <td>{{ cnaStarVariant.reference }}</td>
                <td>{{ cnaStarVariant.significance }}</td>
              </tr>
            </tbody>
            <!-- <tbody
              *ngIf="
                varientTableCount >= 4 &&
                familyTestingReportPdfRes.snvVariants.length >= 3
              "
            >
              <tr
                *ngFor="
                  let snvStarVariant of familyTestingReportPdfRes.snvVariants
                "
              >
                <td>
                  <b
                    ><i
                      ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                    ></b
                  >
                </td>
                <td>
                  {{ snvStarVariant.genomicAlteration }}
                  {{
                    snvStarVariant.selectedAAChangeknownGene != null
                      ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                      : ""
                  }}, [{{ snvStarVariant.ensembleValue }}]
                </td>
                <td>
                  {{
                    snvStarVariant.selectedAAChangeknownGene != null
                      ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                      : ""
                  }}<br />
                  Exon {{ snvStarVariant.exonNumber }} ,
                  {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
                </td>
                <td>
                  {{ snvStarVariant.exonicFuncknownGene }},
                  {{ snvStarVariant.zygosity }}
                </td>
                <td>
                  {{ snvStarVariant.cancerType }},
                  {{ snvStarVariant.inheritanceMode }}
                </td>
                <td>{{ snvStarVariant.reference }}</td>
                <td>{{ snvStarVariant.significance }}</td>
              </tr>
            </tbody>
            <tbody
              *ngIf="
                varientTableCount >= 4 &&
                familyTestingReportPdfRes.snvVariants.length == 0
              "
            >
              <tr
                *ngFor="
                  let cnaStarVariant of familyTestingReportPdfRes.cnaVariants
                "
              >
                <td>
                  <b>{{ cnaStarVariant.gene }}</b>
                </td>
                <td>
                  chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                    cnaStarVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td>NA</td>
                <td>
                  Copy Number
                  {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                    cnaStarVariant.sample.split("|")[1].split(":")[0]
                  }}), {{ cnaStarVariant.zygosity }}
                </td>
                <td>
                  {{ cnaStarVariant.cancerType }},
                  {{ cnaStarVariant.inheritanceMode }}
                </td>
                <td>{{ cnaStarVariant.reference }}</td>
                <td>{{ cnaStarVariant.significance }}</td>
              </tr>
            </tbody>
            <tbody
              *ngIf="
                varientTableCount >= 4 &&
                familyTestingReportPdfRes.snvVariants.length == 1
              "
            >
              <tr
                *ngFor="
                  let snvStarVariant of familyTestingReportPdfRes.snvVariants
                "
              >
                <td>
                  <b
                    ><i
                      ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                    ></b
                  >
                </td>
                <td>
                  {{ snvStarVariant.genomicAlteration }}
                  {{
                    snvStarVariant.selectedAAChangeknownGene != null
                      ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                      : ""
                  }}, [{{ snvStarVariant.ensembleValue }}]
                </td>
                <td>
                  {{
                    snvStarVariant.selectedAAChangeknownGene != null
                      ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                      : ""
                  }}<br />
                  Exon {{ snvStarVariant.exonNumber }} ,
                  {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
                </td>
                <td>
                  {{ snvStarVariant.exonicFuncknownGene }},
                  {{ snvStarVariant.zygosity }}
                </td>
                <td>
                  {{ snvStarVariant.cancerType }},
                  {{ snvStarVariant.inheritanceMode }}
                </td>
                <td>{{ snvStarVariant.reference }}</td>
                <td>{{ snvStarVariant.significance }}</td>
              </tr>
              <tr
                *ngFor="
                  let cnaStarVariant of familyTestingReportPdfRes.cnaVariants

                "
              >
                <td>
                  <b>{{ cnaStarVariant.gene }}</b>
                </td>
                <td>
                  chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                    cnaStarVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td>NA</td>
                <td>
                  Copy Number
                  {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                    cnaStarVariant.sample.split("|")[1].split(":")[0]
                  }}), {{ cnaStarVariant.zygosity }}
                </td>
                <td>
                  {{ cnaStarVariant.cancerType }},
                  {{ cnaStarVariant.inheritanceMode }}
                </td>
                <td>{{ cnaStarVariant.reference }}</td>
                <td>{{ cnaStarVariant.significance }}</td>
              </tr>
            </tbody>
            <tbody
              *ngIf="
                varientTableCount >= 4 &&
                familyTestingReportPdfRes.snvVariants.length == 2
              "
            >
              <tr
                *ngFor="
                  let snvStarVariant of familyTestingReportPdfRes.snvVariants
                "
              >
                <td>
                  <b
                    ><i
                      ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                    ></b
                  >
                </td>
                <td>
                  {{ snvStarVariant.genomicAlteration }}
                  {{
                    snvStarVariant.selectedAAChangeknownGene != null
                      ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                      : ""
                  }}, [{{ snvStarVariant.ensembleValue }}]
                </td>
                <td>
                  {{
                    snvStarVariant.selectedAAChangeknownGene != null
                      ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                      : ""
                  }}<br />
                  Exon {{ snvStarVariant.exonNumber }} ,
                  {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
                </td>
                <td>
                  {{ snvStarVariant.exonicFuncknownGene }},
                  {{ snvStarVariant.zygosity }}
                </td>
                <td>
                  {{ snvStarVariant.cancerType }},
                  {{ snvStarVariant.inheritanceMode }}
                </td>
                <td>{{ snvStarVariant.reference }}</td>
                <td>{{ snvStarVariant.significance }}</td>
              </tr>
              <tr
                *ngFor="
                  let cnaStarVariant of familyTestingReportPdfRes.cnaVariants

                "
              >
                <td>
                  <b>{{ cnaStarVariant.gene }}</b>
                </td>
                <td>
                  chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                    cnaStarVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td>NA</td>
                <td>
                  Copy Number
                  {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                    cnaStarVariant.sample.split("|")[1].split(":")[0]
                  }}), {{ cnaStarVariant.zygosity }}
                </td>
                <td>
                  {{ cnaStarVariant.cancerType }},
                  {{ cnaStarVariant.inheritanceMode }}
                </td>
                <td>{{ cnaStarVariant.reference }}</td>
                <td>{{ cnaStarVariant.significance }}</td>
              </tr>
            </tbody> -->
          </table>

          <p class="mt-4">
            Genetic test results are reported based on the recommendations of
            American College of Medical Genetics [1].
          </p>
        </div>
      </div>
      <div
        class="row mt-3 mb-3"
        *ngIf="familyTestingReportPdfRes.reportedGenes.length > 0"
      >
        <div class="col">
          <h6 class="mb-0 py-2 h6_bg">
            VARIANT INTERPRETATION AND CLINICAL CORELATION
          </h6>
          <div *ngIf="varientTableCount < 3">
            <div
              *ngFor="
                let gene of familyTestingReportPdfRes.reportedGenes
                  | slice : 0 : 2
              "
              class="col-12 mb-2"
            >
              <h4
                class="py-2"
                style="
                  color: #000;
                  font-size: 19px !important;
                  font-weight: 400;
                "
              >
                <span
                  class="font-weight-bold"
                  style="font-size: 19px !important"
                  ><i
                    ><b>{{ gene.name }}</b></i
                  ></span
                >
                <!-- <b>{{gene.geneName}}</b> -->
              </h4>
              <p
                [innerHtml]="gene.description"
                style="line-height: 24px; text-align: justify"
              ></p>
            </div>
          </div>

          <div *ngIf="varientTableCount >= 3">
            <div
              *ngFor="
                let gene of familyTestingReportPdfRes.reportedGenes
                  | slice : 0 : 1
              "
              class="col-12 mb-2"
            >
              <h4
                class="py-2"
                style="
                  color: #000;
                  font-size: 19px !important;
                  font-weight: 400;
                "
              >
                <span
                  class="font-weight-bold"
                  style="font-size: 19px !important"
                  ><i
                    ><b>{{ gene.name }}</b></i
                  ></span
                >
                <!-- <b>{{gene.geneName}}</b> -->
              </h4>
              <p
                [innerHtml]="gene.description"
                style="line-height: 24px; text-align: justify"
              ></p>
            </div>
          </div>
        </div>
      </div>

      <!--  -->

      <!-- <div class="row h_300">
          <div class="col">
            <p class="text-center ">Genetic test results are reported based on the recommendations of American College of Medical Genetics [1].</p>
          </div>
        </div> -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- page 2 -->
    <div
      id="page2"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        varientTableCount >= 3 ||
        (familyTestingReportPdfRes.snvStarVariants.length > 0 &&
          familyTestingReportPdfRes.cnaStarVariants.length > 0) ||
        familyTestingReportPdfRes.reportedStarGenes.length > 0
      "
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3 mb-3">
        <div class="col">
          <div *ngIf="varientTableCount < 3">
            <div
              *ngFor="
                let gene of familyTestingReportPdfRes.reportedGenes
                  | slice : 2 : 10
              "
              class="col-12 mb-2"
            >
              <h4
                class="py-2"
                style="
                  color: #000;
                  font-size: 19px !important;
                  font-weight: 400;
                "
              >
                <span
                  class="font-weight-bold"
                  style="font-size: 19px !important"
                  ><i
                    ><b>{{ gene.name }}</b></i
                  ></span
                >
                <!-- <b>{{gene.geneName}}</b> -->
              </h4>
              <p
                [innerHtml]="gene.description"
                style="line-height: 24px; text-align: justify"
              ></p>
            </div>
          </div>
          <div *ngIf="varientTableCount >= 3">
            <div
              *ngFor="
                let gene of familyTestingReportPdfRes.reportedGenes
                  | slice : 1 : 10
              "
              class="col-12 mb-2"
            >
              <h4
                class="py-2"
                style="
                  color: #000;
                  font-size: 19px !important;
                  font-weight: 400;
                "
              >
                <span
                  class="font-weight-bold"
                  style="font-size: 19px !important"
                  ><i
                    ><b>{{ gene.name }}</b></i
                  ></span
                >
                <!-- <b>{{gene.geneName}}</b> -->
              </h4>
              <p
                [innerHtml]="gene.description"
                style="line-height: 24px; text-align: justify"
              ></p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          *ngIf="
            varientTableCount >= 2 ||
            familyTestingReportPdfRes.snvStarVariants.length > 0 ||
            familyTestingReportPdfRes.cnaStarVariants.length > 0
          "
        >
          <h6 class="py-2 mb-3 h6_bg">
            ADDITIONAL VARIANT(S) DETECTED IN THE PROBAND
          </h6>
          <div class="row">
            <div class="col">
              <table class="page1_table">
                <thead>
                  <th>Gene</th>
                  <th>Genomic Alteration</th>
                  <th>Amino acid change, Exon Number, Allele Burden</th>
                  <th>Variant Type, Zygosity</th>
                  <th>Associated disorder, Inheritance</th>
                  <th>Reference</th>
                  <th>Clinical Significance</th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let snvStarVariant of familyTestingReportPdfRes.snvStarVariants
                    "
                  >
                    <td>
                      <i
                        ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                      >
                    </td>
                    <td>
                      {{ snvStarVariant.genomicAlteration }}
                      {{
                        snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                      }}, [{{ snvStarVariant.ensembleValue }}]
                    </td>
                    <td>
                      <span *ngIf="snvStarVariant?.selectedAAChangeknownGene"
                        >{{
                          snvStarVariant.selectedAAChangeknownGene.split(
                            ","
                          )[1]
                        }}, Exon {{ snvStarVariant?.exonNumber }} ,
                        {{
                          snvStarVariant.mutantBurdenPercent * 0.01 | percent
                        }}</span
                      >
                    </td>
                    <td>
                      {{ snvStarVariant.exonicFuncknownGene }},
                      {{ snvStarVariant.zygosity }}
                    </td>
                    <td>
                      {{ snvStarVariant.cancerType }},
                      {{ snvStarVariant.inheritanceMode }}
                    </td>
                    <td>{{ snvStarVariant.reference }}</td>
                    <td>{{ snvStarVariant.significance }}</td>
                  </tr>
                  <tr
                    *ngFor="
                      let cnaStarVariant of familyTestingReportPdfRes.cnaStarVariants
                    "
                  >
                    <td>
                      <i
                        ><strong>{{ cnaStarVariant.gene }}</strong></i
                      >
                    </td>
                    <td>
                      chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                        cnaStarVariant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                      }}
                    </td>
                    <td>NA</td>
                    <td>
                      Copy Number
                      {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                        cnaStarVariant.sample.split("|")[1].split(":")[0]
                      }}), {{ cnaStarVariant.zygosity }}
                    </td>
                    <td>
                      {{ cnaStarVariant.cancerType }},
                      {{ cnaStarVariant.inheritanceMode }}
                    </td>
                    <td>{{ cnaStarVariant.reference }}</td>
                    <td>{{ cnaStarVariant.significance }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="mt-3 mb-5"
          *ngIf="
            varientTableCount <= 2 &&
            familyTestingReportPdfRes.reportedStarGenes &&
            familyTestingReportPdfRes.reportedStarGenes.length > 0
          "
        >
          <h6 class="py-2 h6_bg">
            VARIANT INTERPRETATION AND CLINICAL CORELATION
          </h6>
          <div
            *ngFor="
              let gene of familyTestingReportPdfRes.reportedStarGenes
                | slice : 0 : 3
            "
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify; font-size: 13px"
            ></div>
          </div>
        </div>
        <div
          class="mt-3 mb-5"
          *ngIf="
            varientTableCount == 3 &&
            familyTestingReportPdfRes.reportedStarGenes &&
            familyTestingReportPdfRes.reportedStarGenes.length > 0
          "
        >
          <h6 class="py-2 h6_bg">
            VARIANT INTERPRETATION AND CLINICAL CORRELATION
          </h6>
          <div
            *ngFor="
              let gene of familyTestingReportPdfRes.reportedStarGenes
                | slice : 0 : 1
            "
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify; font-size: 13px"
            ></div>
          </div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 2_1 -->

    <div
      id="page2_1"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        varientTableCount >= 3 ||
        (report.addNotes != null && report.addNotes != '<p></p>') ||
        (familyTestingReportPdfRes.reportedStarGenes &&
          familyTestingReportPdfRes.reportedStarGenes.length > 1) ||
        (report.disclaimer != null && report.disclaimer != '<p></p>')
      "
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div
        class="mt-3 mb-5"
        *ngIf="
          varientTableCount == 3 &&
          familyTestingReportPdfRes.reportedStarGenes &&
          familyTestingReportPdfRes.reportedStarGenes.length > 0
        "
      >
        <div
          *ngFor="
            let gene of familyTestingReportPdfRes.reportedStarGenes
              | slice : 1 : 5
          "
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
          </h4>
          <div
            [innerHtml]="gene.description"
            style="line-height: 28px; text-align: justify; font-size: 13px"
          ></div>
        </div>
      </div>
      <div
        class="mt-3 mb-5"
        *ngIf="
          varientTableCount > 3 &&
          familyTestingReportPdfRes.reportedStarGenes &&
          familyTestingReportPdfRes.reportedStarGenes.length > 0
        "
      >
        <h6 class="py-2 h6_bg">
          VARIANT INTERPRETATION AND CLINICAL CORRELATION
        </h6>
        <div
          *ngFor="
            let gene of familyTestingReportPdfRes.reportedStarGenes
              | slice : 0 : 5
          "
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
          </h4>
          <div
            [innerHtml]="gene.description"
            style="line-height: 28px; text-align: justify; font-size: 13px"
          ></div>
        </div>
      </div>

      <div
        class="row mt-3 mb-3"
        *ngIf="
          report.addNotes != null &&
          report.addNotes != '<p></p>' &&
          varientTableCount <= 3
        "
      >
        <div class="col-12">
          <h6 class="mb-0 py-2 h6_bg">
            <b>ADDITIONAL NOTES</b>
          </h6>
          <div class="py-2" [innerHtml]="report.addNotes"></div>
        </div>
      </div>
      <div
        class="row mt-3 mb-3"
        *ngIf="
          report.addNotes != null &&
          report.addNotes != '<p></p>' &&
          varientTableCount > 3
        "
      >
        <div class="col-12">
          <h6 class="mb-0 py-2 h6_bg">
            <b>ADDITIONAL NOTES</b>
          </h6>
          <div class="py-2" [innerHtml]="report.addNotes"></div>
        </div>
      </div>

      <div
        class="mt-3"
        *ngIf="report.disclaimer != null && report.disclaimer != '<p></p>'"
      >
        <h6 class="py-2 h6_bg">DISCLAIMER</h6>
        <div style="line-height: 28px; text-align: justify">
          <div
            [innerHtml]="report.disclaimer"
            style="
              line-height: 28px;
              text-align: justify;
              overflow-wrap: break-word;
            "
          ></div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--  -->

    <!-- page 2_2 -->

    <div
      id="page2_2"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        report.interpretation != null && report.interpretation != '<p></p>'
      "
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3 mb-3">
        <div class="col">
          <h6 class="mb-0 py-2 h6_bg">RESULT INTERPRETATION</h6>
          <div
            [innerHtml]="report.interpretation"
            style="line-height: 28px; text-align: justify"
            class="interpretationtab"
          ></div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3 -->
    <div
      id="page3"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->

      <div class="row mt-3 mb-3">
        <div class="col">
          <h6 class="mb-0 py-2 h6_bg">References</h6>
        </div>
      </div>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="ref_data mt-4">
            <ul class="break-word">
              <li
                *ngFor="
                  let referenceArticle of familyTestingReportPdfRes.referenceArticles
                "
                style="
                  text-align: justify !important;
                  /* white-space:pre-wrap; */
                  word-break: break-word;
                "
              >
                {{ referenceArticle }}
              </li>
            </ul>
          </div>
        </div>
      </div>

       <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 4 -->
    <div
      id="page4"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->

      <div class="row mt-3 mb-3">
        <div class="col">
          <h6 class="mb-0 py-2 h6_bg">References</h6>
        </div>
      </div>
      <!--  -->
      <div class="row">
        <div class="col">
          <!-- <div class="ref_data mt-4">
            <ul class="break-word pr-3" style="text-align: justify">
              <li>
                elli ML, Hellyer J, Audeh W, Jensen KC, Bose S, Timms KM, Gutin
                A, Abkevich V, Peterson RN, Neff C, Hughes E, Sangale Z, Jones
                J, Hartman AR, Chang PJ, Vinayak S, Wenstrup R, Ford JM.
                Homologous recombination deficiency (HRD) status predicts
                response to standard neoadjuvant chemotherapy in patients with
                triple-negative or BRCA1/2 mutation-associated breast cancer.
                Breast Cancer Res Treat. 2018 Apr;168(3):625-630. doi:
                10.1007/s10549-017-4624-7. Epub 2017 Dec 23. PMID: 29275435.
              </li>
            </ul>
          </div> -->
          <!--  -->
          <div class="ref_data mt-4">
            <h5 class="font-weight-bold pl-4">Websites</h5>
            <ul class="break-word pr-3" style="text-align: justify">
              <li>ClinVar https://www.ncbi.nlm.nih.gov/clinvar</li>
              <li>NIH- National Cancer Institute - https://www.cancer.gov/</li>
              <li>https://www.mycancergenome.org/</li>
              <li>https://www.ncbi.nlm.nih.gov/medgen/</li>
              <li>https://www.cancer.net/cancer-types</li>
              <li>https://www.mayoclinic.org/</li>
              <li>https://www.cancerresearchuk.org/</li>
              <li>http://pfam.xfam.org/</li>
              <li>https://www.uniprot.org/</li>
            </ul>
            <span *ngIf="report.referenceWebsites != '<p></p>'" class="">
              <div
                style="font-size: 14px !important"
                [innerHtml]="report.referenceWebsites"
              ></div>
            </span>
          </div>
        </div>
      </div>
      <!-- <div id="imgsings" class="row">
        <div class="col-9">
          <img src="../../../assets/dr_sarah-signature.png" />
          <p style="text-align: left; font-size: 15px !important">
            <b>Dr. Sarah Bailur</b><br />Medical Geneticist <br />(MBBS, DNB,
            FICG, FSIAMG)
          </p>
        </div>

        <div class="col text-center">
          <img src="../../../assets/dr_vidya-signature.png" alt="" />
          <p style="text-align: center; font-size: 15px !important">
            <b>Dr. Vidya Veldore,</b><br />Clinical Director
          </p>
        </div>
      </div> -->
      <div class="img_signs above-footer">
        <div class="row justify-content-between align-items-center">
          <div class="col-4 text-center">
            <p style="
                  text-align: center;

                  font-size: 15px !important;
                ">(Electronically signed by)
            </p>
            <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
              Vidya H Veldore, PhD<br />Vice President - Clinical Operations
            </p>
          </div>
          <div class="col-4 text-center">            <p style="text-align: center; font-size: 15px !important;">
              (Electronically signed by)</p>
              <p style="
              text-align: center;
              font-weight: bolder;
              font-size: 15px !important;
            ">
            Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
          </div>
          <div class="col-4 text-center">            <p style="
              text-align: center;

              font-size: 15px !important;
            ">(Electronically signed by)
            </p>
            <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
              Vyomesh Javle<br />Head - Clinical Bioinformatics
            </p>
          </div>
          </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 5 -->
    <div
      id="page5"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg">ABOUT THE TESTING</h6>

        <div class="mt-4 px-4 py-3" style="text-align: justify">
          <p class="l_height-38 mb-4">
            Understanding the genetic testing process and its results require
            support of a trained genetic counsellor. We suggest the individual
            to seek genetic counseling prior to consenting for any kind of
            genetic test to understand the purpose of the test recommended by
            clinician and its usefulness to the patient and their family.
          </p>

          <p class="l_height-38 mb-4">
            The patient’s physician may annually wish to re-analyze the results
            or recommend re-testing for any variants that may have been newly
            identified, to associate with the patient’s clinical condition. The
            patient or family members are recommended to consult their physician
            and approach us for testing services accordingly. We, at 4baseCare,
            strive to ensure that every patient and family members are made
            accessible to all possible information without breaching the
            patient’s confidentiality.
          </p>

          <div class="py-5 my-5">
            <img
              src="../../../assets/autosomal_img1.png"
              alt=""
              class="img-fluid w-100"
            />
          </div>

          <!--  -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- page 6 -->
    <div
      id="page6"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg">TEST METHODOLOGY</h6>

        <div class="mt-4 px-4 py-3" style="text-align: justify">
          <p class="l_height-38 mb-2">
            Genomic DNA is isolated from Whole Blood sample for library
            preparation and quantified using Qubit Fluorometer, 50 ng is taken
            for library preparation. The NGS libraries were prepared as per
            standard procedures for Illumina sequencing. The libraries were
            sequenced with mean coverage depth >150X.
          </p>
          <p class="l_height-38 mb-2">
            The sequences obtained are aligned to human reference genome
            (GRCh37/hg19) and variant analysis was performed using set of
            Bioinformatics Pipeline. Only non- synonymous and splice site
            variants found in the exome panel consisting of specific set of
            genes were used for clinical interpretation. Silent variations that
            do not result in any change in amino acid in the coding region are
            not reported.
          </p>
          <p class="l_height-38 mb-2">
            Clinically relevant mutations were annotated using published
            literature and a set of databases – ClinVar (Landrum et al, 2015.),
            cbioportal (Cerami et al, 2012; Gao et al, 2013) and dbSNP. Common
            variants are filtered based on allele frequency in 1000 Genome Phase
            3(Auton et al, 2015), ExAC (Karczewski et al. 2016), dbSNP (Sherry
            et al, 2001), etc. In the absence of a clinically significant
            reported known variation(s), pathogenicity will be predicted based
            on in-silico gene prioritization tools: CADD (Rentzsch et al. 2018),
            SIFT (Ng PC et al, 2003), PolyPhen-2 (Adzhubei et al, 2013) and
            prioritized for clinical correlation. The identified pathogenic
            variant will be correlated with observed phenotypic features of the
            patient and interpreted according to American College of Medical
            Genetics (ACMG) guidelines.
          </p>
        </div>

        <div class="text-center px-4">
          <img
            src="./../../../assets/TestMethodology.png"
            alt=""
            style="width: 100%"
          />
        </div>

        <p class="ml-4 mb-2 py-2">Annexure:</p>
        <p class="ml-4">
          The classification of the variations is done based on American College
          of Medical Genetics as described below
        </p>

        <div class="row ml-4 mt-4 center">
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="first float-right mr-4"></div>
            <!-- </div> -->
            <div
              class="col-11 w-100"
              style="border: 1px solid #238eca; border-radius: 10px 10px 0 0"
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="first-text">Pathogenic</strong> : A disease
                causing variation in a gene which can explain the patient's
                symptoms has been detected. This usually means that a suspected
                disorder for which testing had been requested has been
                confirmed.
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <div class="second float-right mr-4"></div>
            <div
              class="col-11 w-100"
              style="background: #e9f4fa; border: 1px solid #238eca"
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="second-text">Likely Pathogenic</strong> : A
                variant which is very likely to contribute to the development of
                disease. However, the scientific evidence is currently
                insufficient to prove this conclusively. Additional evidence is
                expected to confirm this assertion of pathogenic
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="third float-right mr-4"></div>
            <!-- </div> -->
            <div class="col-11 w-100" style="border: 1px solid #238eca">
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="third-text"
                  >Variant of Uncertain Significance</strong
                >
                : A variant has been detected, but it is difficult to classify
                it as either pathogenic (disease causing) or benign (non-disease
                causing) based on current available scientific evidence. Further
                testing of the patient or family members as recommended by your
                clinician may be needed. It is probable that their significance
                can be assessed only with time, subject to availability of
                scientific evidence.
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="fourth float-right mr-4"></div>
            <!-- </div> -->
            <div
              class="col-11 w-100"
              style="
                background: #e9f4fa;
                border: 1px solid #238eca;
                border-radius: 0 0 10px 10px;
              "
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="fourth-text">Benign/Likely Benign</strong> : A
                variant termed benign has sufficient evidence that it can be
                concluded that the variant is not the cause of the patient's
                disorder. A variant termed likely benign has sufficient evidence
                that it can be concluded that the variant is not the cause of
                the patient's disorder when combined with other information.
              </span>
            </div>
          </div>
          <div class="col"></div>
        </div>

        <!--End -->
        <!-- <table class="table grad_table mt-3">
          <thead>
            <th></th>
            <th></th>
          </thead>
          <tbody>
            <tr>
              <td>Pathogenic</td>
              <td>A disease causing variation in a gene which can explain the patient's symptoms has been detected. This usually means
                that a suspected disorder for which testing had been requested has been confirmed.</td>
            </tr>
            <tr>
              <td>Likely
                Pathogenic</td>
              <td>A variant which is very likely to contribute to the development of disease however, the scientific evidence is currently
                insufficient to prove this conclusively. Additional evidence is expected to confirm this assertion of pathogenic</td>
            </tr>
            <tr>
              <td>Variant of
                Uncertain
                Significance</td>
              <td>A variant has been detected, but it is difficult to classify it as either pathogenic (disease causing) or benign (non-disease
                causing) based on current available scientific evidence. Further testing of the patient or family members as
                recommended by your clinician may be needed. It is probable that their significance can be assessed only with time,
                subject to availability of scientific evidenc</td>
            </tr>
            <tr>
              <td>Benign/
                Likely Benign</td>
              <td>A variant termed benign has sufficient evidence that it can be concluded that the variant is not the cause of the
                patient’s Disorder. A variant termed likely benign has sufficient evidence that it can be concluded that the variant is
                not the cause of the patient’s disorder when combined with other informatin.</td>
            </tr>

          </tbody>
        </table> -->
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- page7 -->
    <div
      id="page11"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg">LIMITATIONS AND DISCLAIMER</h6>

        <!-- <div
          [innerHtml]="report.disclaimer"
          style="line-height: 28px; text-align: justify"
        ></div> -->

        <div class="ref_data pt-2" style="text-align: justify">
          <ul class="px-5">
            <li>
              This test has been developed, validated and performed by 4baseCare
              Oncosolutions Pvt. Ltd., and this test has not been cleared or
              approved by the FDA..
            </li>
            <li>
              A comprehensive risk assessment may include other aspects of the
              patient's personal/family medical history, as well as lifestyle,
              environment and other factors. This is not included in the scope
              of this NGS testing.
            </li>
            <li>
              We are using the canonical transcript for clinical reporting which
              is usually the longest coding transcript with strong/multiple
              supporting clinical evidence. However, in rare cases, clinically
              relevant variants annotated in alternate complete coding
              transcripts could also be reported.
            </li>
            <li>
              Changes in personal/family history or additional data regarding
              specific genes/mutations may affect the cancer risk estimates and
              management recommendations within this report. Personal/family
              history should be updated with a healthcare provider on a regular
              basis
            </li>
            <li>
              Certain genes may not be covered completely, and few mutations
              could be missed. Many factors such as homopolymers, GC-rich
              regions etc. influence the quality of sequencing and coverage.
              This may result in an occasional error in sequence reads or lack
              of detection of a particular genetic alteration.
            </li>
            <li>
              As with any laboratory test, there is a small chance that this
              result may be inaccurate for a preanalytical reasons, such as an
              error during specimen collection and labeling (incorrect patient
              identification).
            </li>
            <li>
              Large insertions, deletions, duplications, inversions, repeat
              expansions and complex rearrangements cannot be characterized
              accurately by NGS as it uses short-read sequencing data. Such
              structural variants have a much higher false-positive and
              false-negative rate than seen for SNVs (single nucleotide
              variant). It is possible that the genomic region where a
              disease-causing variation exists in the proband was not captured
              using the current technologies and therefore was not detected.
            </li>
            <li>
              It is possible that a particular genetic abnormality may not be
              recognized as the underlying cause of the genetic disorder due to
              incomplete scientific knowledge about the function of all genes in
              the human genome and the impact of variants on those genes.
            </li>
            <li>
              Accurate interpretation of this report is dependent on detailed
              clinical history of the patient. In the event of unavailability of
              detailed clinical history, the lab cannot guarantee the accuracy
              of the interpretation.
            </li>
            <li>
              This report is strictly not a medical diagnostic report and shall
              not be construed as the medical certificate or medical laboratory
              report or diagnostic report.
            </li>
            <li>
              The patient’s physician may annually wish to re-analyze the
              results or recommend re-testing for any variants that may have
              been newly identified, to associate with the patient’s clinical
              condition. The patient or family members are recommended to
              consult their physician and approach us for testing services
              accordingly.
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col">
          <img src="../../../assets/clinicalD.png" alt="" style="padding-left:60px;padding-right:60px; width:350px;padding-top: 20px;">
          <p style="text-align:center; font-weight: bolder;font-size: 15px!important;">Vidya H Veldore, PhD<br/>Clinical Director</p>
        </div>
        <div class="col">
          <img src="../../../assets/clinicalH.png" alt="" style="padding-left:60px;padding-right:60px;width:350px">
          <p style="text-align:center; font-weight: bolder;font-size: 15px!important;">Richa Malhotra<br/>Head - Clinical Informatics and Reporting</p>
        </div>
        <div class="col">
          <img src="../../../assets/clinicalL.png" alt="" style="padding-left:60px;padding-right:60px;width:350px">
          <p style="text-align:center; font-weight: bolder;font-size: 15px!important;">Sharanya J<br/>Team Lead - Clinical Reporting</p>
        </div>
      </div> -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
