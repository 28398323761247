import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Documents, PatientDocument } from 'src/app/_interfaces.ts/patient';
import { AWSServiceService } from 'src/app/_services/aws-service.service';
import { ReportService } from 'src/app/_services/report.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-patient-documents',
  templateUrl: './patient-documents.component.html',
  styleUrls: ['./patient-documents.component.css']
})
export class PatientDocumentsComponent implements OnInit {

  ecrfId!: string;
  documentsList: Documents[] = [];
  cancerIdentifier!: string;
  patientData={} as PatientDocument;

  constructor(
    private routerAct: ActivatedRoute,
    private reportService: ReportService,
    private toastr: ToastrService,
    private aswService:AWSServiceService
  ) { }

  statusMsg!: string;
  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  showError() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  ngOnInit(): void {
    this.ecrfId = this.routerAct.snapshot.params['ecrfId'];
    if(this.ecrfId){
      this.getDocuments();
    }
    
  }

  getDocuments() {
    this.reportService.getDocuments(this.ecrfId).subscribe(
      (response: any) => {
        this.documentsList = response['payLoad'];
        this.cancerIdentifier = this.documentsList[0].patient.medical.cancerIdentifier;
        console.log(this.documentsList);
      });
  }

  onFileChange(event: any) {
    const files = event.target.files;
    const file = files[0];
    this.uploadFile(file);
  }

 formdata: FormData = new FormData();
  uploadFile(file: any) {
    this.formdata.set('file', file);
  }

  
  submitReport = false;
  reportStatus:string = "";
  selectedReport(reportStatus: string){
    if(reportStatus.length > 0){
      this.submitReport = true;
    }
  }

  submitFinalReport(){
    this.submitReport = false;
    this.patientData.status=this.reportStatus
   this.aswService.uploadFile(this.formdata.get('file'),this.patientData,this.ecrfId).then((res)=>{
   if(res=="Success")
   {
        this.statusMsg = "Reported Uploaded Successfully";
        this.showSuccess();
        this.submitReport = true;
        this.getDocuments();
   }else{
    this.statusMsg =res
    this.showError()
   }
   });

    // this.reportService.uploadFinalReport(this.formdata,this.ecrfId,this.reportStatus).subscribe(
    //   (response) => {
    //     console.log(response);
    //     this.statusMsg = "Reported Uploaded Successfully";
    //     this.showSuccess();
    //     this.submitReport = true;
    //     this.getDocuments();
    //   }
    // );
  }

  patientDocument = {} as Documents;
  viewComment(documents: Documents){
    this.patientDocument = documents;
    console.log(this.patientDocument);
  }

  downloadDocument(url: string,fileName:any){
    let names:any=[]
    let filename:any
    let fileFormat:any
    if(fileName!=null){
      filename= fileName
    }else{
      names=url.split("/")
      filename=names.pop()
    }
    fileFormat=filename.split('.').pop().toLowerCase() 
    if(fileFormat=='pdf'){
      this.reportService.downloadPdf(url).subscribe((res:any)=>{
        let blob= new Blob([res], { type: 'application/pdf' })
        FileSaver.saveAs(blob,filename)
      })
    }else{
        window.open(url);
    }
   
   
    
  }

}
