import { Component, OnInit } from '@angular/core';
import { Gene, Pathway } from 'src/app/_interfaces.ts/admin';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-pathway',
  templateUrl: './add-pathway.component.html',
  styleUrls: ['./add-pathway.component.css']
})
export class AddPathwayComponent implements OnInit {


  editor = ClassicEditor;
  add_pathway = {} as Pathway;
  statusMsg!: string;
  geneList!: Gene[];
  selectedGenes: Gene[] = [];
  genesId: number[] = [];

  pathwayId!: number;
  addPathwaySpinner = false;
  imageUrl!: string;

  constructor(
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private router: Router,
    private routerAct: ActivatedRoute
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  dropdownSettings = {};

  ngOnInit(): void {

    this.add_pathway.description = "";
    this.pathwayId = +this.routerAct.snapshot.params['id'];


    this.getGenes();
    this.dropdownSetting();


    if (this.pathwayId) {
      this.getPathway();
    }
  }




  
  addPathway() {
    this.addPathwaySpinner = true;

    if (this.selectedGenes.length > 0) {
      this.add_pathway.geneIds = this.getGenesId();
    }

    this.add_pathway.imageUrl = this.imageUrl;
    console.log("add_pathway", this.add_pathway);
    this.dataAdminService.addPathway(this.add_pathway).subscribe(
      (response: any) => {
        console.log("add pathway res", response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.reload();
      });
  }

  getPathway() {
    this.dataAdminService.getPathway(this.pathwayId).subscribe(
      (response: any) => {
        console.log("response get", response);
        this.add_pathway = response['payLoad'];
        this.selectedGenes = response['payLoad']['geneList'];
        this.imageUrl = response['payLoad']['imageUrl'];
      }
    );
  }

  getGenes() {
    this.dataAdminService.geneList().subscribe(
      (response: any) => {
        this.geneList = response['payLoad'];
      }
    );
  }

  getGenesId(): number[] {
    this.selectedGenes.forEach(selectedGene => {
      this.genesId.push(selectedGene.id);
    });
    return this.genesId;
  }

  uploadImage(event: any) {
    const files = event.target.files;
    const file = files[0];
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    this.dataAdminService.uploadImage(formdata).subscribe(
      (response: any) => {
        console.log("upload image ==>", response);
        this.imageUrl = response['payLoad'];
      }
    );
  }


  dropdownSetting() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  reload() {
    console.log("reload");
    this.router.navigateByUrl('/reports', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/super_admin/pathways']);
    });
  }

}
