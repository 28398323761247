<div class="container-fluid px-0">


    <div class="row  py-3 mx-0">
        <div class="col pl-0">
          <h5 class="mb-0 d-inline-block text_color pt-2"> Disclaimer list</h5>
        </div>
        <div class="col pr-0">
          <div class="text-right ml-auto d-table">
            <div class="add-new">
              <a class="add_post mr-2" [routerLink]="[ '/super_admin/add-disclaimer' ]">Add Disclaimer<i class="zmdi zmdi-plus ml-2"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="disclaimerListSpinner" class="row data-spinner-parent">
        <div class="spinner-border data-spinner-child" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
      <div *ngIf="!disclaimerListSpinner" class="bg-white cust_shadow  p-5 rounded">
        <div class="row col " style="padding: 0; margin: 0;">
          <div class="reports_info d-flex ml-auto">
            <label  class="mr-2"> Type:</label>
              <select 
                class="bg-white form-control  dropdown-toggle"
                (change)="filterByType($event)"
              >
                <option selected disabled>Select</option>
                <option value="All">All</option>
                <option *ngFor="let type of typeList" [value]="type">{{type}}</option>
              </select>
          </div>
        </div>        
        <div  class="tableFixHead">
          <table class="care_companion table mt-3">      
            <thead class="thead-light">
              <tr>
                <th>Sl.No</th>
                <th>Disclaimer</th>
                <th>Test Type </th>
                <!-- <th>Cancer</th> -->
                <!-- <th>Discalimer Details</th> -->
                <th>Actions</th>
              </tr>
    
            </thead>
            <tbody>
              <tr *ngFor="let disclaimer of disclaimerList; let i = index">
                <td>{{i+1}}</td>
                <td>{{disclaimer.disclaimerTag}}</td>
                <td>{{disclaimer.typeOfTest}}</td>
                <!-- <td>{{ClinicalTrial.cancerType.name}}</td> -->
                <!-- <td>{{disclaimer.disclaimerDetails}}</td> -->
                <td>
                  <ul class="list-unstyled mb-0 d-flex actions">
                    <li><a class="mr-1 delete_clr" (click)="setDisclaimerDetails(disclaimer)"
                      data-toggle="modal" data-target="#viewdisclaimerModal"> View </a></li>
                    <li><a class="mr-1 delete_clr" [routerLink]="[ '/super_admin/add-disclaimer/', disclaimer.id ]">| Edit </a></li>
                    <li><a class="delete_clr" data-toggle="modal" data-target="#deleteClinicalModal"
                      (click)="setDisclaimer(disclaimer)">| Delete</a></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    
      </div>

      <!-- view Disclaimer Modal -->
<div class="modal fade" id="viewdisclaimerModal">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 900px;">
    <div class="modal-content surgery-modal">

      <div class="modal-header px-4 border-bottom-0 bg-light">
        <h5 class="modal-title text-softblue">Disclaimer Details</h5>
        <a class="text-softblue" data-dismiss="modal" (click)="closeModal()">
          <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
        </a>
      </div>

      <div class="modal-body p-4" style="text-align: left;">
        <div class="row">
          <div class="col">
            <div class="form-group row mb-0">
              <label class="col-sm-2 control-label text-right">Type of Test :</label>
              <div class="col-sm-10">
                <label>{{disclaimerData?.typeOfTest}}</label>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-2 control-label text-right">Disclaimer Tag :</label>
              <div class="col-sm-10">
                <label>{{disclaimerData?.disclaimerTag}}</label>
              </div>
            </div>


            <div class="form-group row mb-0">
              <label class="col-sm-2 control-label text-right">Disclaimer Details :</label>
              <div class="col-sm-10">
                <label style="text-align: justify!important;" [innerHtml]='disclaimerData.disclaimerDetails'></label>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>

      <!-- The delete Modal -->
      <div class="modal fade" id="deleteClinicalModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content surgery-modal">

            <div class="modal-header px-4 border-bottom-0 bg-light">
              <h5 class="modal-title text-softblue">Delete</h5>
              <a class="text-softblue" data-dismiss="modal">
                  <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
              </a>
            </div>

            <div class="modal-body p-4">
                    <h6 class=" mb-4">Are you sure want to delete ?</h6>
                  <div class="mx-0 ml-auto d-table">
                      <button type="button" class="btn bg-softblue text-white px-5 mr-2"
                      data-dismiss="modal" (click)="deleteDisclaimer()">Yes</button>
                      <button type="button" class="btn bg-softblue text-white px-5"
                      data-dismiss="modal">No</button>
                  </div>
            </div>

          </div>
        </div>
      </div>

</div>
