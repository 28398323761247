<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div
    class="spinner-border"
    style="width: 8rem; height: 8rem"
    role="status"
  ></div>
</div>

<div *ngIf="reportData">
  <button
    [disabled]="downloadSpinner"
    type="button"
    class="btn btn-primary m-2 float-right download-btn"
    (click)="downloadReport()"
  >
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i> Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>

  <!-- for 4basecare -->
  <div *ngIf="is4basecare">
    <div
      id="page"
      class="container position-relative page"
      style="overflow-y: clip; background-color: white"
    >
      <div class="row header">
        <div class="w-100 overflow-hidden">
          <div class="col px-0">
            <div class="cover_page">
              <!-- <img src="../../../assets/hrr_germline.png" alt="" class="img-fluid w-100"> -->
              <img
                src="../../../assets/BRCA-Cover-1.png"
                alt=""
                class="img-fluid w-100"
              />
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>

    <!-- page 1 -->
    <div
      id="page1"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p style="color: #238eca; font-weight: 700" class="py-1">
                SCOPE OF THE TEST
              </p>
              <p>
                SNVs, InDels, CNAs<!--, Gene Fusions-->
                status
              </p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-75" />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p style="color: #238eca; font-weight: 700" class="py-1">
                GENES OF INTEREST
              </p>
              <p><i>BRCA1</i> and <i>BRCA2</i></p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-75" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan">Name : </span>
              <span class="text-black">{{ report.patient.name }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Gender : </span>
              <span class="text-black" style="text-transform: capitalize">{{
                report.patient.gender.toLowerCase()
              }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Age/DOB : </span>
              <span class="text-black">{{
                report.patient?.dateOfBirth
                  ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                  : report.patient?.age + " Years"
              }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled pt-4 mt-3">
            <li>
              <span class="clr-cyan">Sample Source : </span>
              <span class="text-black">{{ sampleSource }}</span>
            </li>

            <li class="pb-1">
              <span class="clr-cyan">Referning Clinician : </span>
              <span class="text-black">{{referringClinician}}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Hospital : </span>
              <span class="text-black">{{ labHospital }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3 mb-3">
        <div class="col">
          <h6 class="mb-0 py-2 h6_bg">CLINICAL INDICATIONS / FAMILY HISTORY</h6>
          <!-- <p class="py-3"><b>{{report.clinicalSummary}}</b></p> -->
          <div
            class="py-3"
            [innerHtml]="report.clinicalSummary"
            style="line-height: 28px; text-align: justify; font-size: 13px"
          ></div>

          <div
            class="first_page-table d-flex"
            *ngIf="
              brcaReportPdfRes.familyDetails &&
              brcaReportPdfRes.familyDetails.length > 0
            "
          >
            <div class="box box1">
              <ul class="list-unstyled mb-0">
                <li></li>
                <li>Relationship</li>
                <li>Cancer Type</li>
                <li>Age at diagnosis (in years)</li>
              </ul>
            </div>
            <div
              class="box box2"
              *ngFor="let familyDetails of brcaReportPdfRes.familyDetails"
            >
              <ul class="list-unstyled mb-0">
                <li style="text-align: center">
                  {{ familyDetails.familyType }}
                </li>
                <li style="text-align: center">
                  {{ familyDetails.relationship }}
                </li>
                <li style="text-align: center">
                  {{ familyDetails.cancerType }}
                </li>
                <li style="text-align: center">
                  {{ familyDetails.diagnosisAge }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="">
        <h6 class="py-2 h6_bg">Results</h6>
      </div>
      <div class="row mt-2 py-2">
        <div class="tag" id="tag">
          <!--  -->
          <!-- <div  class="mr-2 my-3 ml-2 " *ngIf="isReportDownload">
                <img [src]="tag" alt="" class="img-fluid w-90">
              </div> -->
          <div class="mr-2 my-3">
            <div class="results_gradi mt-3">
              <ul class="d-flex list-unstyled">
                <li [ngClass]="{ zindex: isPathogenic }">
                  <!-- <span *ngIf="!isPathogenic">Pathogenic</span> -->
                  <!-- <span *ngIf="isPathogenic">Pathogenic variant Detected </span> -->
                  <div
                    class="bg"
                    [ngClass]="{
                      marker_left: isPathogenic,
                      resultLine: !isPathogenic
                    }"
                  >
                    <label *ngIf="isPathogenic"
                      >Pathogenic Variant Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isLikelyPathogenic }">
                  <!-- <span>Likely Pathogenic</span> -->
                  <div class="bg" [ngClass]="{ marker: isLikelyPathogenic }">
                    <label *ngIf="isLikelyPathogenic"
                      >Likely Pathogenic Variant Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isVUS }">
                  <!-- <span>Variant of Unknown Significance (VUS)</span> -->
                  <div
                    class="bg"
                    [ngClass]="{ marker_vus: isVUS, resultLine: !isVUS }"
                  >
                    <label *ngIf="isVUS"
                      >Variant of Unknown Significance (VUS) Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isOthers }">
                  <!-- <span>Likely Benign/Benign/No variant</span> -->
                  <div class="bg" [ngClass]="{ marker_right: isOthers }">
                    <label *ngIf="isOthers"
                      >Likely Benign/Benign/No variant Detected</label
                    >
                  </div>
                </li>
              </ul>
            </div>
            <!-- <img src="../../../assets/grad_pic.png" alt="" class="img-fluid w-100"> -->
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h5 class="dark-cyan font-weight-bold mb-2">Variant Details:</h5>
          <p
            *ngIf="
              brcaReportPdfRes.snvVariants.length == 0 &&
              brcaReportPdfRes.cnaVariants.length == 0
            "
          >
            Clinically relevant genomic alterations associated with therapeutic
            significance were not detected.
          </p>
          <!-- table -->
          <!-- <div  *ngIf="isReportDownload">
                <img [src]="tables" alt="" class="img-fluid w-100">
              </div> -->
          <table
            class="page1_table"
            id="tables"
            *ngIf="
              brcaReportPdfRes.snvVariants.length > 0 ||
              brcaReportPdfRes.cnaVariants.length > 0
            "
          >
            <thead>
              <th>Gene</th>
              <th>Genomic Alteration</th>
              <th>Amino acid change, Exon Number, Allele Burden</th>
              <th>Variant Type, Zygosity</th>
              <th>Associated disorder, Inheritance</th>
              <th>Reference</th>
              <th>Clinical Significance</th>
            </thead>
            <tbody>
              <tr
                *ngFor="let snvStarVariant of brcaReportPdfRes.snvVariants"
              >
                <td>
                  <i
                    ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                  >
                </td>
                <td>
                  {{ snvStarVariant.genomicAlteration }}
                  {{ snvStarVariant.selectedAAChangeknownGene.split(",")[0] }},
                  [{{ snvStarVariant.ensembleValue }}]
                </td>
                <td>
                  <span *ngIf="snvStarVariant?.selectedAAChangeknownGene"
                    >{{ snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                    }}<br />Exon {{ snvStarVariant?.exonNumber }} ,
                    {{
                      snvStarVariant.mutantBurdenPercent * 0.01 | percent
                    }}</span
                  >
                </td>
                <td>
                  {{ snvStarVariant.exonicFuncknownGene }},
                  {{ snvStarVariant.zygosity }}
                </td>
                <td>
                  {{ snvStarVariant.cancerType }},
                  {{ snvStarVariant.inheritanceMode }}
                </td>
                <td>{{ snvStarVariant.reference }}</td>
                <td>{{ snvStarVariant.significance }}</td>
              </tr>
              <tr *ngFor="let cnarVariant of brcaReportPdfRes.cnaVariants">
                <td>
                  <i
                    ><strong>{{ cnarVariant.gene }}</strong></i
                  >
                </td>
                <td>
                  chr{{ cnarVariant.sample.split(":")[0] }}:g.{{
                    cnarVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td>NA</td>
                <td>
                  Copy Number
                  {{ cnarVariant.sample.split(":")[2] | titlecase }} ({{
                    cnarVariant.sample.split("|")[1].split(":")[0]
                  }}), {{ cnarVariant.zygosity }}
                </td>
                <td>
                  {{ cnarVariant.cancerType }},
                  {{ cnarVariant.inheritanceMode }}
                </td>
                <td>{{ cnarVariant.reference }}</td>
                <td>{{ cnarVariant.significance }}</td>
              </tr>
            </tbody>
          </table>

          <p class="mb-2">
            *Genetic test results are reported based on the recommendations of
            American College of Medical Genetics [1]. Please refer to Annexure
            for further information
          </p>
        </div>
      </div>
      <!--  -->

      <!-- <div class="row h_300">
            <div class="col">
              <p class="text-center ">Genetic test results are reported based on the recommendations of American College of Medical Genetics [1].</p>
            </div>
          </div> -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 2 -->


    <div
      id="page2"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        (firstHalfCoverageFileList && firstHalfCoverageFileList.length > 0) ||
        (report.disclaimer && report.disclaimer != '<p></p>') ||
        (secondHalfCoverageFileList && secondHalfCoverageFileList.length > 0) ||
        (report.addNotes && report.addNotes != '<p></p>') ||
        brcaReportPdfRes.reportedGenes.length > 0
      "
    >
      <div class="row header mt-1">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
        </div>
      </div>
      <!--  -->

      <div
        class="mt-3"
        *ngIf="report.disclaimer != null && report.disclaimer != '<p></p>'"
      >
        <h6 class="py-2 h6_bg">DISCLAIMER</h6>
        <div style="line-height: 28px; text-align: justify">
          <div
            [innerHtml]="report.disclaimer"
            style="line-height: 28px; text-align: justify"
          ></div>
        </div>
      </div>

      <div class="mt-3 mb-5">
        <div class="mt-3">
          <h6
            class="py-2 h6_bg"
            *ngIf="
              firstHalfCoverageFileList && firstHalfCoverageFileList.length > 0
            "
          >
            STATUS OF <i style="color:white">BRCA 1/2</i> GENES
          </h6>
          <div
            class="row"
            *ngIf="
              firstHalfCoverageFileList && firstHalfCoverageFileList.length > 0
            "
          >
            <div class="col">
              <table class="page2_table gene_table">
                <thead>
                  <th>Gene Name</th>
                  <th>Detected Mutation</th>
                  <th>Coverage(%)</th>
                </thead>
                <tbody>
                  <tr *ngFor="let coverageFile of firstHalfCoverageFileList">
                    <td>{{ coverageFile.geneName }}</td>
                    <td>
                      <span
                        *ngIf="
                          coverageFile.significance != null &&
                          coverageFile.significance != 'VUS'
                        "
                        style="color: red"
                        >{{ coverageFile.significance }}</span
                      >
                      <span
                        *ngIf="coverageFile.significance == 'VUS'"
                        style="color: #201dd2"
                        >{{ coverageFile.significance }}</span
                      >
                      <span *ngIf="coverageFile.significance == null"
                        >Not Detected</span
                      >
                    </td>
                    <td>
                      {{ coverageFile.coveragePercent | number : "1.2-2" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col offset-1">
              <table class="page2_table gene_table">
                <thead>
                  <th>Gene Name</th>
                  <th>Detected Mutation</th>
                  <th>Coverage(%)</th>
                </thead>
                <tbody>
                  <tr *ngFor="let coverageFile of secondHalfCoverageFileList">
                    <td>{{ coverageFile.geneName }}</td>
                    <td>
                      <span
                        *ngIf="
                          coverageFile.significance != null &&
                          coverageFile.significance != 'VUS'
                        "
                        style="color: red"
                        >{{ coverageFile.significance }}</span
                      >
                      <span
                        *ngIf="coverageFile.significance == 'VUS'"
                        style="color: #201dd2"
                        >{{ coverageFile.significance }}</span
                      >
                      <span *ngIf="coverageFile.significance == null"
                        >Not Detected</span
                      >
                    </td>
                    <td>
                      {{ coverageFile.coveragePercent | number : "1.2-2" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="mt-3 mb-5"
            *ngIf="
              brcaReportPdfRes.reportedGenes &&
              brcaReportPdfRes.reportedGenes.length > 0
            "
          >
            <h6 class="py-2 h6_bg">
              VARIANT INTERPRETATION AND CLINICAL CORRELATION
            </h6>
            <div
              *ngFor="let gene of brcaReportPdfRes.reportedGenes"
              class="col-12 mb-2"
            >
              <h4
                class="py-2"
                style="
                  color: #000;
                  font-size: 19px !important;
                  font-weight: 400;
                "
              >
                <span
                  class="font-weight-bold"
                  style="font-size: 19px !important"
                  ><i
                    ><b>{{ gene.name }}</b></i
                  ></span
                >
                <!-- <b>{{gene.geneName}}</b> -->
              </h4>
              <div
                [innerHtml]="gene.description"
                style="line-height: 28px; text-align: justify; font-size: 13px"
              ></div>
            </div>
          </div>

          <div
            class="mt-3 mb-5 row"
            *ngIf="report.addNotes != null && report.addNotes != '<p></p>'"
          >
            <div class="col-12">
              <h6 class="py-2 mb-3 h6_bg">
                <b>ADDITIONAL NOTES</b>
              </h6>
              <div class="py-2" [innerHtml]="report.addNotes"></div>
            </div>
          </div>
          <!--  -->
          <!-- <div class="hrr_div-main">
            <div class="">
              <p class="pl-3">For all the HRR genes analyzed in the above-mentioned table, variants were classified as :</p>
            </div>
              <div class="row mt-3">
              <div class="col">
                <ul class="list_unstyled list_dots pl-4">
                  <li><span></span>‘Pathogenic/ Likely pathogenic’ - variants for the gene were deleterious or disease-causing</li>
                  <li><span></span>‘VUS’ - Variants for the gene were of uncertain significance</li>
                </ul>
              </div>
              <div class="col">
                <ul class="list_unstyled list_dots">
                  <li><span></span>‘Not detected’ - variants for the gene were not detected in the
                    patient</li>
                </ul>
              </div>
            </div>
           </div> -->
        </div>
        <!--  -->
        <div class="row footer">
          <div class="col-12">
            <div class="pb-2 pl-4 text">
              <div class="d-block">
                <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
              </div>
              <div class="footer_body" [ngClass]="'gene-bg_blue'">
                <div class="footer_list">
                  <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                  <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                  <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                  <span>Ile - I</span>
                </div>
                <div class="footer_list">
                  <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                  <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                  <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                  <span>Val - V</span>
                </div>
              </div>
            </div>
            <div class="col-12 pt-1 ">
              <p style="font-size: 8px!important; line-height: 14px!important;">
                {{footerDiscription}}<br/>{{footerDiscription2}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page2_1"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        (brcaReportPdfRes.snvStarVariants &&
          brcaReportPdfRes.snvStarVariants.length > 0) ||
        (brcaReportPdfRes.cnaStarVariants &&
          brcaReportPdfRes.cnaStarVariants.length > 0) ||
        (brcaReportPdfRes.reportedStarGenes &&
          brcaReportPdfRes.reportedStarGenes.length > 0)
      "
    >
      <div class="row header mt-1">
        <div>
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div></div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-1 mb-5">
        <div
          *ngIf="
            brcaReportPdfRes.snvStarVariants.length > 0 ||
            brcaReportPdfRes.cnaStarVariants.length > 0
          "
          class="mt-5 pt-5"
        >
          <h6 class="py-2 mb-3 h6_bg">
            ADDITIONAL VARIANT(S) DETECTED IN THE PROBAND
          </h6>
          <div class="row">
            <div class="col">
              <table class="page1_table">
                <thead>
                  <th>Gene</th>
                  <th>Genomic Alteration</th>
                  <th>Amino acid change, Exon Number, Allele Burden</th>
                  <th>Variant Type, Zygosity</th>
                  <th>Associated disorder, Inheritance</th>
                  <th>Reference</th>
                  <th>Clinical Significance</th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let snvStarVariant of brcaReportPdfRes.snvStarVariants
                    "
                  >
                    <td>
                      <i
                        ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                      >
                    </td>
                    <td>
                      {{ snvStarVariant.genomicAlteration }}
                      {{
                        snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                      }}, [{{ snvStarVariant.ensembleValue }}]
                    </td>
                    <td>
                      <span *ngIf="snvStarVariant?.selectedAAChangeknownGene"
                        >{{
                          snvStarVariant.selectedAAChangeknownGene.split(
                            ","
                          )[1]
                        }}, Exon {{ snvStarVariant?.exonNumber }} ,
                        {{
                          snvStarVariant.mutantBurdenPercent * 0.01 | percent
                        }}</span
                      >
                    </td>
                    <td>
                      {{ snvStarVariant.exonicFuncknownGene }},
                      {{ snvStarVariant.zygosity }}
                    </td>
                    <td>
                      {{ snvStarVariant.cancerType }},
                      {{ snvStarVariant.inheritanceMode }}
                    </td>
                    <td>{{ snvStarVariant.reference }}</td>
                    <td>{{ snvStarVariant.significance }}</td>
                  </tr>
                  <tr
                    *ngFor="
                      let cnaStarVariant of brcaReportPdfRes.cnaStarVariants
                    "
                  >
                    <td>
                      <i
                        ><strong>{{ cnaStarVariant.gene }}</strong></i
                      >
                    </td>
                    <td>
                      chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                        cnaStarVariant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                      }}
                    </td>
                    <td>NA</td>
                    <td>
                      Copy Number
                      {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                        cnaStarVariant.sample.split("|")[1].split(":")[0]
                      }}), {{ cnaStarVariant.zygosity }}
                    </td>
                    <td>
                      {{ cnaStarVariant.cancerType }},
                      {{ cnaStarVariant.inheritanceMode }}
                    </td>
                    <td>{{ cnaStarVariant.reference }}</td>
                    <td>{{ cnaStarVariant.significance }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="mt-3 mb-5"
          *ngIf="
            brcaReportPdfRes.reportedStarGenes &&
            brcaReportPdfRes.reportedStarGenes.length > 0
          "
        >
          <h6 class="py-2 h6_bg">
            VARIANT INTERPRETATION AND CLINICAL CORRELATION
          </h6>
          <div
            *ngFor="let gene of brcaReportPdfRes?.reportedStarGenes"
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
              <!-- <b>{{gene.geneName}}</b> -->
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify; font-size: 13px"
            ></div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--page 2_2-->
    <div
      id="page2_2"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        report.interpretation != null && report.interpretation != '<p></p>'
      "
    >
      <div class="row header mt-1">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <div
          class="mt-3"
          *ngIf="
            report.interpretation != null && report.interpretation != '<p></p>'
          "
        >
          <h6 class="py-2 h6_bg">RESULT INTERPRETATION</h6>
          <div
            [innerHtml]="report.interpretation"
            class="interpretationtab"
            style="line-height: 28px; text-align: justify"
          ></div>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3 -->
    <div
      id="page3"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg">References</h6>

        <div class="ref_data mt-4">
          <ul class="break-word" style="text-align: justify">
            <li
              *ngFor="
                let referenceArticle of brcaReportPdfRes.referenceArticles
              "
              style="
                text-align: justify;
                /* white-space:pre-wrap; */
                word-break: break-word;
              "
            >
              {{ referenceArticle }}
            </li>
          </ul>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 4 -->
    <div
      id="page4"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg">References</h6>

        <div class="ref_data mt-5 ml-2 pl-4">
          <h5 class="font-weight-bold">Websites</h5>
          <ul class="pl-3">
            <li>ClinVar https://www.ncbi.nlm.nih.gov/clinvar</li>
            <li>NIH- National Cancer Institute - https://www.cancer.gov/</li>
            <li>https://www.mycancergenome.org/</li>
            <li>https://www.ncbi.nlm.nih.gov/medgen/</li>
            <li>https://www.cancer.net/cancer-types</li>
            <li>https://www.mayoclinic.org/</li>
            <li>https://www.cancerresearchuk.org/</li>
            <li>http://pfam.xfam.org/</li>
            <li>https://www.uniprot.org/</li>

            <span *ngIf="report.referenceWebsites != '<p></p>'" class="">
              <div
                style="font-size: 14px !important"
                [innerHtml]="report.referenceWebsites"
              ></div>
            </span>
          </ul>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-between above-footer">
              <div class="ml-3">
                <img src="../../../assets/dr_sarah-signature.png" alt="" class="w-75">
               <p class="font-weight-bold ">Dr. Sarah Bailur</p>
              <p class="">Medical Geneticist</p>
              <p class="">(MBBS, DNB, FICG, FSIAMG)</p>
              </div>
              <div class="">
                <img src="../../../assets/dr_vidya-signature.png" alt=""  class="w-75">
               <p class="font-weight-bold ">Dr. Vidya Veldore,</p>
              <p class=" ">Clinical Director</p>
              </div>
          </div> -->

      <div class="img_signs above-footer">
        <div class="row d-flex justify-content-between">
          <div class="col-4">

            <p style="
                  text-align: center;

                  font-size: 15px !important;
                ">(Electronically signed by)
            </p>
            <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
              Vidya H Veldore, PhD<br />Vice President - Clinical Operations
            </p>
          </div>
          <div class="col-4">
            <p style="text-align: center; font-size: 15px !important;">
              (Electronically signed by)</p>
              <p style="
              text-align: center;
              font-weight: bolder;
              font-size: 15px !important;
            ">
            Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
          </div>
          <div class="col-4">
            <p style="
              text-align: center;

              font-size: 15px !important;
            ">(Electronically signed by)
            </p>
            <p style="
                    text-align: center;
                    font-weight: bolder;
                    font-size: 15px !important;
                  ">
              Vyomesh Javle<br />Head - Clinical Bioinformatics
            </p>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 5 -->
    <div
      id="page5"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg">ABOUT THE TESTING</h6>

        <div class="mt-4 px-4 py-3" style="text-align: justify">
          <p class="l_height-38 mb-4">
            This panel targets <i>BRCA1</i> and <i>BRCA2</i> genes; which are involved in repair of
            damaged DNA. Mutations in these genes can lead to deficiency in repair of damaged DNA.
            It has been established that tumors that are deficient in DNA repair mechanism,
            particularly the Homologous Recombination mechanism are sensitive to PARP inhibitor
            therapy in certain cancer types such as breast and ovarian cancers, while the clinical
            trials are ongoing in other cancer types such as pancreatic, prostate, endometrial and other cancers.
          </p>

          <p class="l_height-38 mb-4">
            Understanding the genetic testing process and its results require
            support of a trained genetic counsellor. We suggest the individual
            to seek genetic counseling prior to consenting for any kind of
            genetic test to understand the purpose of the test recommended by
            clinician and its usefulness to the patient and their family.
          </p>
          <p class="l_height-38">
            Variation (fault/mutation) in certain genes can be inherited, which
            may sometimes cause cancer. The variations found in the genes could
            be pathogenic, likely pathogenic or variant of uncertain
            significance and may attribute to low, moderate or high risk of
            predisposition to cancer.
          </p>

          <div class="p-5 text-center">
            <img
              src="../../../assets/autosomal_img1 brca.png"
              alt=""
              class="img-fluid"
              style="height: 620px; width: 50%;"
            />
          </div>

          <!--  -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 7 -->
    <div
      id="page7"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg">TEST METHODOLOGY</h6>

        <div class="mt-4 px-4 py-3" style="text-align: justify">
          <p class="l_height-38">
            Genomic DNA is isolated from Whole Blood sample for library
            preparation and quantified using Qubit Fluorometer, 50 ng is taken
            for library preparation. The NGS libraries were prepared as per
            standard procedures for Illumina sequencing. The libraries were
            sequenced with mean coverage depth >150X.
          </p><br/>
          <p class="l_height-38">
            The sequences obtained are aligned to human reference genome
            (GRCh37/hg19) and variant analysis was performed using set of
            Bioinformatics Pipeline. Only non- synonymous and splice site
            variants found in the exome panel consisting of specific set of
            genes were used for clinical interpretation. Silent variations that
            do not result in any change in amino acid in the coding region are
            not reported.
          </p><br/>
          <p class="l_height-38">
            Clinically relevant mutations were annotated using published
            literature and a set of databases – ClinVar (Landrum et al, 2015.),
            cbioportal (Cerami et al, 2012; Gao et al, 2013) and dbSNP. Common
            variants are filtered based on allele frequency in 1000 Genome Phase
            3(Auton et al, 2015), ExAC (Karczewski et al. 2016), dbSNP (Sherry
            et al, 2001), etc. In the absence of a clinically significant
            reported known variation(s), pathogenicity will be predicted based
            on in-silico gene prioritization tools: CADD (Rentzsch et al. 2018),
            SIFT (Ng PC et al, 2003), PolyPhen-2 (Adzhubei et al, 2013) and
            prioritized for clinical correlation. The identified pathogenic
            variant will be correlated with observed phenotypic features of the
            patient and interpreted according to American College of Medical
            Genetics (ACMG) guidelines.
          </p>
        </div>

        <div class="text-center px-4">
          <img
            src="./../../../assets/TestMethodology.png"
            alt=""
            style="width: 100%"
          />
        </div>

        <p class="ml-4 mb-2 py-2">Annexure:</p>
        <p class="ml-4">
          The classification of the variations is done based on American College
          of Medical Genetics as described below
        </p>

        <div class="row ml-4 mt-4 center">
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="first float-right mr-4"></div>
            <!-- </div> -->
            <div
              class="col-11 w-100"
              style="border: 1px solid #238eca; border-radius: 10px 10px 0 0"
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="first-text">Pathogenic</strong> : A disease
                causing variation in a gene which can explain the patient's
                symptoms has been detected. This usually means that a suspected
                disorder for which testing had been requested has been
                confirmed.
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <div class="second float-right mr-4"></div>
            <div
              class="col-11 w-100"
              style="background: #e9f4fa; border: 1px solid #238eca"
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="second-text">Likely Pathogenic</strong> : A
                variant which is very likely to contribute to the development of
                disease. However, the scientific evidence is currently
                insufficient to prove this conclusively. Additional evidence is
                expected to confirm this assertion of pathogenic
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="third float-right mr-4"></div>
            <!-- </div> -->
            <div class="col-11 w-100" style="border: 1px solid #238eca">
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="third-text"
                  >Variant of Uncertain Significance</strong
                >
                : A variant has been detected, but it is difficult to classify
                it as either pathogenic (disease causing) or benign (non-disease
                causing) based on current available scientific evidence. Further
                testing of the patient or family members as recommended by your
                clinician may be needed. It is probable that their significance
                can be assessed only with time, subject to availability of
                scientific evidence.
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="fourth float-right mr-4"></div>
            <!-- </div> -->
            <div
              class="col-11 w-100"
              style="
                background: #e9f4fa;
                border: 1px solid #238eca;
                border-radius: 0 0 10px 10px;
              "
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="fourth-text">Benign/Likely Benign</strong> : A
                variant termed benign has sufficient evidence that it can be
                concluded that the variant is not the cause of the patient's
                disorder. A variant termed likely benign has sufficient evidence
                that it can be concluded that the variant is not the cause of
                the patient's disorder when combined with other information.
              </span>
            </div>
          </div>
          <div class="col"></div>
        </div>

        <!--End -->
        <!-- <table class="table grad_table mt-3">
              <thead>
                <th></th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>Pathogenic</td>
                  <td>A disease causing variation in a gene which can explain the patient's symptoms has been detected. This usually means
                    that a suspected disorder for which testing had been requested has been confirmed.</td>
                </tr>
                <tr>
                  <td>Likely
                    Pathogenic</td>
                  <td>A variant which is very likely to contribute to the development of disease however, the scientific evidence is currently
                    insufficient to prove this conclusively. Additional evidence is expected to confirm this assertion of pathogenic</td>
                </tr>
                <tr>
                  <td>Variant of
                    Uncertain
                    Significance</td>
                  <td>A variant has been detected, but it is difficult to classify it as either pathogenic (disease causing) or benign (non-disease
                    causing) based on current available scientific evidence. Further testing of the patient or family members as
                    recommended by your clinician may be needed. It is probable that their significance can be assessed only with time,
                    subject to availability of scientific evidenc</td>
                </tr>
                <tr>
                  <td>Benign/
                    Likely Benign</td>
                  <td>A variant termed benign has sufficient evidence that it can be concluded that the variant is not the cause of the
                    patient’s Disorder. A variant termed likely benign has sufficient evidence that it can be concluded that the variant is
                    not the cause of the patient’s disorder when combined with other informatin.</td>
                </tr>

              </tbody>
            </table> -->
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 11 -->
    <div
      id="page11"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header mt-1">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3 pl-0">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes m-auto">
            <ul
              class="list-unstyled d-flex pt-3 text-uppercase patinet_info justify-content-end"
            >
              <li>
                Patient Id <br />
                <span>{{ report.patient.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name <br />
                <span>{{ report.patient.name }}</span>
              </li>

              <li class="ml-4">
                Registration Date<br />
                <span>{{
                  report.labDetails.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
              </li>
              <li class="ml-4">
                Reporting Date<br />
                <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg">LIMITATIONS AND DISCLAIMER</h6>

        <div class="ref_data pt-2" style="text-align: justify">
          <ul class="px-5">
            <li>
              This test has been developed, validated and performed by 4baseCare
              Oncosolutions Pvt. Ltd., and this test has not been cleared or
              approved by the FDA..
            </li>
            <li>
              A comprehensive risk assessment may include other aspects of the
              patient's personal/family medical history, as well as lifestyle,
              environment and other factors. This is not included in the scope
              of this NGS testing.
            </li>
            <li>
              We are using the canonical transcript for clinical reporting which
              is usually the longest coding transcript with strong/multiple
              supporting clinical evidence. However, in rare cases, clinically
              relevant variants annotated in alternate complete coding
              transcripts could also be reported.
            </li>
            <li>
              Changes in personal/family history or additional data regarding
              specific genes/mutations may affect the cancer risk estimates and
              management recommendations within this report. Personal/family
              history should be updated with a healthcare provider on a regular
              basis
            </li>
            <li>
              Certain genes may not be covered completely, and few mutations
              could be missed. Many factors such as homopolymers, GC-rich
              regions etc. influence the quality of sequencing and coverage.
              This may result in an occasional error in sequence reads or lack
              of detection of a particular genetic alteration.
            </li>
            <li>
              As with any laboratory test, there is a small chance that this
              result may be inaccurate for a preanalytical reasons, such as an
              error during specimen collection and labeling (incorrect patient
              identification).
            </li>
            <li>
              Large insertions, deletions, duplications, inversions, repeat
              expansions and complex rearrangements cannot be characterized
              accurately by NGS as it uses short-read sequencing data. Such
              structural variants have a much higher false-positive and
              false-negative rate than seen for SNVs (single nucleotide
              variant). It is possible that the genomic region where a
              disease-causing variation exists in the proband was not captured
              using the current technologies and therefore was not detected.
            </li>
            <li>
              It is possible that a particular genetic abnormality may not be
              recognized as the underlying cause of the genetic disorder due to
              incomplete scientific knowledge about the function of all genes in
              the human genome and the impact of variants on those genes.
            </li>
            <li>
              Accurate interpretation of this report is dependent on detailed
              clinical history of the patient. In the event of unavailability of
              detailed clinical history, the lab cannot guarantee the accuracy
              of the interpretation.
            </li>
            <li>
              This report is strictly not a medical diagnostic report and shall
              not be construed as the medical certificate or medical laboratory
              report or diagnostic report.
            </li>
            <li>
              The patient’s physician may annually wish to re-analyze the
              results or recommend re-testing for any variants that may have
              been newly identified, to associate with the patient’s clinical
              condition. The patient or family members are recommended to
              consult their physician and approach us for testing services
              accordingly.
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="row">
            <div class="col">
              <img src="../../../assets/clinicalD.png" alt="" style="padding-left:60px;padding-right:60px; width:350px;padding-top: 20px;">
              <p style="text-align:center; font-weight: bolder;font-size: 15px!important;">Vidya H Veldore, PhD<br/>Clinical Director</p>
            </div>
            <div class="col">
              <img src="../../../assets/clinicalH.png" alt="" style="padding-left:60px;padding-right:60px;width:350px">
              <p style="text-align:center; font-weight: bolder;font-size: 15px!important;">Richa Malhotra<br/>Head - Clinical Informatics and Reporting</p>
            </div>
            <div class="col">
              <img src="../../../assets/clinicalL.png" alt="" style="padding-left:60px;padding-right:60px;width:350px">
              <p style="text-align:center; font-weight: bolder;font-size: 15px!important;">Sharanya J<br/>Team Lead - Clinical Reporting</p>
            </div>
          </div> -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gin - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br/>{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- for Lifecell -->
  <div *ngIf="!is4basecare" class="hrrLifeCell">
    <!-- page 1 -->
    <div
      id="page1"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold" [ngClass]="'purple'">
                SCOPE OF THE TEST
              </p>
              <p>SNVs, InDels, CNAs status</p>
            </div>
            <img
              src="../../../assets/targ_first/purple_divider.png"
              class="mr-2 w-75"
            />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold" [ngClass]="'purple'">
                GENES OF INTEREST
              </p>
              <p><i>BRCA1</i> and <i>BRCA2</i></p>
            </div>
            <img
              src="../../../assets/targ_first/purple_divider.png"
              class="mr-2 w-75"
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold" [ngClass]="'purple'">
            Report Details
          </h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'purple'">Name : </span>
              <span class="text-black">{{ report.patient.name }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'purple'">Gender : </span>
              <span class="text-black">{{ report.patient.gender }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'purple'">Patient Id: </span>
              <span class="text-black">{{
                report.patient.orgLabId}}</span>
            </li>
            <li>
              <span class="clr-cyan" [ngClass]="'purple'">CRM No. : </span>
              <span class="text-black">{{report.patient.orgCrmId}}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'purple'">Age/DOB : </span>
              <span class="text-black">{{
                report.patient?.dateOfBirth ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy") : report.patient?.age + " Years"
              }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled pt-4 mt-3">
            <li>
              <span class="clr-cyan" [ngClass]="'purple'"
                >Sample Source :
              </span>
              <span class="text-black">{{ sampleSource }}</span>
            </li>

            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'purple'"
                >Referning Clinician :
              </span>
              <span class="text-black">{{referringClinician}}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'purple'">Hospital : </span>
              <span class="text-black">{{ labHospital }}</span>
            </li>
            <li>
              <span class="clr-cyan" [ngClass]="'purple'"
                >Registration Date :
              </span>
              <span class="text-black">{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li>
              <span class="clr-cyan" [ngClass]="'purple'"
                >Reporting Date :
              </span>
              <span class="text-black">{{
                todayDate | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3 mb-3">
        <div class="col">
          <h6 class="mb-0 py-2 h6_bg" [ngClass]="'bg_purple'">
            CLINICAL INDICATIONS / FAMILY HISTORY
          </h6>
          <!-- <p class="py-3"><b>{{report.clinicalSummary}}</b></p> -->
          <div
            class="py-3"
            [innerHtml]="report.clinicalSummary"
            style="line-height: 28px; text-align: justify; font-size: 13px"
          ></div>
          <!-- <table class="table table_page1 ">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Proband</th>
                      <th>Immediate Relatives
                        (Parents, Siblings)</th>
                      <th>Paternal Relatives</th>
                      <th>Maternal Relatives</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Relationship</td>
                      <td>Self</td>
                      <td>-</td>
                      <td>-</td>
                      <td>Grandmother</td>
                    </tr>
                    <tr>
                      <td>Cancer Type</td>
                      <td>Adenocarcinoma of colon with foci
                        of extracellular mucin with few signet
                        ring cells and lymph node metastasis</td>
                      <td>-</td>
                      <td>-</td>
                      <td>Uterine cancer</td>
                    </tr>
                    <tr>
                      <td>Age at diagnosis
                        (in years)</td>
                      <td>21</td>
                      <td>-</td>
                      <td>-</td>
                      <td>50</td>
                    </tr>
                  </tbody>
                </table> -->

          <div
            class="first_page-table d-flex"
            *ngIf="
              brcaReportPdfRes.familyDetails &&
              brcaReportPdfRes.familyDetails.length > 0
            "
            [ngClass]="'purple-table_1'"
          >
            <div class="box box1">
              <ul class="list-unstyled mb-0">
                <li></li>
                <li>Relationship</li>
                <li>Cancer Type</li>
                <li>Age at diagnosis (in years)</li>
              </ul>
            </div>
            <div
              class="box box2"
              *ngFor="let familyDetails of brcaReportPdfRes.familyDetails"
            >
              <ul class="list-unstyled mb-0">
                <li style="text-align: center">
                  {{ familyDetails.familyType }}
                </li>
                <li style="text-align: center">
                  {{ familyDetails.relationship }}
                </li>
                <li style="text-align: center">
                  {{ familyDetails.cancerType }}
                </li>
                <li style="text-align: center">
                  {{ familyDetails.diagnosisAge }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'bg_purple'">Results</h6>
      </div>
      <div class="row mt-2 py-2">
        <div class="tag" id="tag">
          <!-- <div  class="mr-2 my-3 ml-2 " *ngIf="isReportDownload">
                <img [src]="tag" alt="" class="img-fluid w-90">
              </div> -->
          <div class="mr-2 my-3">
            <div class="results_gradi mt-3">
              <ul class="d-flex list-unstyled">
                <li [ngClass]="{ zindex: isPathogenic }">
                  <!-- <span>Pathogenic Variant detected</span> -->
                  <div
                    class="bg"
                    [ngClass]="{
                      marker_left: isPathogenic,
                      resultLine: !isPathogenic
                    }"
                  >
                    <label *ngIf="isPathogenic"
                      >Pathogenic Variant Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isLikelyPathogenic }">
                  <!-- <span>Likely Pathogenic</span> -->
                  <div class="bg" [ngClass]="{ marker: isLikelyPathogenic }">
                    <label *ngIf="isLikelyPathogenic" for=""
                      >Likely Pathogenic Variant Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isVUS }">
                  <!-- <span>Variant of Unknown Significance (VUS)</span> -->
                  <div
                    class="bg"
                    [ngClass]="{ marker_vus: isVUS, resultLine: !isVUS }"
                  >
                    <label *ngIf="isVUS" for=""
                      >Variant of Unknown Significance (VUS) Detected</label
                    >
                  </div>
                </li>
                <li [ngClass]="{ zindex: isOthers }">
                  <!-- <span>Likely Benign/Benign/No variant</span> -->
                  <div class="bg" [ngClass]="{ marker_right: isOthers }">
                    <label *ngIf="isOthers"
                      >Likely Benign/Benign/No variant Detected</label
                    >
                  </div>
                </li>
              </ul>
            </div>
            <!-- <img src="../../../assets/grad_pic.png" alt="" class="img-fluid w-100"> -->
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h5 class="dark-cyan font-weight-bold mb-2" [ngClass]="'purple'">
            Variant Details:
          </h5>
          <p
            *ngIf="
              brcaReportPdfRes.snvVariants.length == 0 &&
              brcaReportPdfRes.cnaVariants.length == 0
            "
          >
            Clinically relevant genomic alterations associated with therapeutic
            significance were not detected.
          </p>
          <!-- table -->
          <!-- <div  *ngIf="isReportDownload">
                <img [src]="tables" alt="" class="img-fluid w-100">
              </div> -->
          <table
            id="tables"
            class="page1_table purple_page1"
            *ngIf="
              brcaReportPdfRes.snvVariants.length > 0 ||
              brcaReportPdfRes.cnaVariants.length > 0
            "
          >
            <thead>
              <th>Gene</th>
              <th>Genomic Alteration</th>
              <th>Amino acid change, Exon Number , Allele Burden</th>
              <th>Variant type, Zygosity</th>
              <th>Associated disorder, Inheritance</th>
              <th>Reference</th>
              <th>Clinical Significance</th>
            </thead>
            <tbody>
              <tr
                *ngFor="let snvStarVariant of brcaReportPdfRes.snvVariants"
              >
                <td>
                  <i
                    ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                  >
                </td>
                <td>
                  <span *ngIf="snvStarVariant?.selectedAAChangeknownGene"
                    >{{ snvStarVariant.genomicAlteration }}
                    {{
                      snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                    }}, [{{ snvStarVariant?.ensembleValue }}]</span
                  >
                </td>
                <td>
                  <span *ngIf="snvStarVariant?.selectedAAChangeknownGene"
                    >{{
                      snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                    }}, Exon {{ snvStarVariant?.exonNumber }} ,
                    {{
                      snvStarVariant.mutantBurdenPercent * 0.01 | percent
                    }}</span
                  >
                </td>
                <td>
                  {{ snvStarVariant.exonicFuncknownGene }},
                  {{ snvStarVariant.zygosity }}
                </td>
                <td>
                  {{ snvStarVariant.cancerType }},
                  {{ snvStarVariant.inheritanceMode }}
                </td>
                <td>{{ snvStarVariant.reference }}</td>
                <td>{{ snvStarVariant.significance }}</td>
              </tr>
              <tr *ngFor="let cnarVariant of brcaReportPdfRes.cnaVariants">
                <td>
                  <i
                    ><strong>{{ cnarVariant.gene }}</strong></i
                  >
                </td>
                <td>
                  chr{{ cnarVariant.sample.split(":")[0] }}:g.{{
                    cnarVariant.sample
                      .split(":")[1]
                      .replace("-", "_")
                      .split("|")[0]
                  }}
                </td>
                <td>NA</td>
                <td>
                  Copy Number
                  {{ cnarVariant.sample.split(":")[2] | titlecase }} ({{
                    cnarVariant.sample.split("|")[1].split(":")[0]
                  }}), {{ cnarVariant.zygosity }}
                </td>
                <td>
                  {{ cnarVariant.cancerType }},
                  {{ cnarVariant.inheritanceMode }}
                </td>
                <td>{{ cnarVariant.reference }}</td>
                <td>{{ cnarVariant.significance }}</td>
              </tr>
            </tbody>
          </table>
          <p class="mb-2">
            *Genetic test results are reported based on the recommendations of
            American College of Medical Genetics [1]. Please refer to Annexure
            for further information
          </p>
        </div>
      </div>
      <!--  -->

      <!-- <div class="row h_300">
            <div class="col">
              <p class="text-center ">Genetic test results are reported based on the recommendations of American College of Medical Genetics [1].</p>
            </div>
          </div> -->

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!-- page 2 -->
    <div
      id="page2"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        (firstHalfCoverageFileList != null &&
          firstHalfCoverageFileList.length > 0) ||
        (report.disclaimer != null && report.disclaimer != '<p></p>') ||
        (secondHalfCoverageFileList != null &&
          secondHalfCoverageFileList.length > 0) ||
        (report.addNotes != null && report.addNotes != '<p></p>') ||
        brcaReportPdfRes.reportedGenes.length > 0
      "
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mt-3 mb-5">
        <div class="mt-3 pt-5">
          <h6 class="py-2 mb-3 h6_bg" [ngClass]="'bg_purple'">DISCLAIMER</h6>
          <div style="line-height: 28px; text-align: justify">
            <div
              [innerHtml]="report.disclaimer"
              style="line-height: 28px; text-align: justify"
            ></div>
          </div>
        </div>

        <div class="mt-3">
          <h6
            class="py-2 h6_bg"
            [ngClass]="'bg_purple'"
            *ngIf="
              firstHalfCoverageFileList && firstHalfCoverageFileList.length > 0
            "
          >
            STATUS OF <i style="color:white">BRCA 1/2</i> GENES
          </h6>
          <div
            class="row"
            *ngIf="
              firstHalfCoverageFileList && firstHalfCoverageFileList.length > 0
            "
          >
            <div class="col">
              <table class="page2_table gene_table purple_gene-table">
                <thead>
                  <th>Gene Name</th>
                  <th>Detected Mutation</th>
                  <th>Coverage(%)</th>
                </thead>
                <tbody>
                  <tr *ngFor="let coverageFile of firstHalfCoverageFileList">
                    <td>{{ coverageFile.geneName }}</td>
                    <td>
                      <span
                        style="color: red"
                        *ngIf="coverageFile.significance != 'Not Detected'"
                        >{{ coverageFile.significance }}</span
                      >
                      <span
                        *ngIf="
                          coverageFile.significance == null ||
                          coverageFile.significance == 'Not Detected'
                        "
                        >Not Detected</span
                      >
                    </td>
                    <td>
                      {{ coverageFile.coveragePercent | number : "1.2-2" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col offset-1">
              <table class="page2_table gene_table purple_gene-table">
                <thead>
                  <th>Gene Name</th>
                  <th>Detected Mutation</th>
                  <th>Coverage(%)</th>
                </thead>
                <tbody>
                  <tr *ngFor="let coverageFile of secondHalfCoverageFileList">
                    <td>{{ coverageFile.geneName }}</td>
                    <td>
                      <span
                        style="color: red"
                        *ngIf="coverageFile.significance != 'Not Detected'"
                        >{{ coverageFile.significance }}</span
                      >
                      <span
                        *ngIf="
                          coverageFile.significance == null ||
                          coverageFile.significance == 'Not Detected'
                        "
                        >Not Detected</span
                      >
                    </td>
                    <td>
                      {{ coverageFile.coveragePercent | number : "1.2-2" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="mt-3 mb-5"
            *ngIf="
              brcaReportPdfRes.reportedGenes &&
              brcaReportPdfRes.reportedGenes.length > 0
            "
          >
            <h6 class="py-2 h6_bg bg_purple">
              VARIANT INTERPRETATION AND CLINICAL CORRELATION
            </h6>
            <div
              *ngFor="let gene of brcaReportPdfRes.reportedGenes"
              class="col-12 mb-2"
            >
              <h4
                class="py-2"
                style="
                  color: #000;
                  font-size: 19px !important;
                  font-weight: 400;
                "
              >
                <span
                  class="font-weight-bold"
                  style="font-size: 19px !important"
                  ><i
                    ><b>{{ gene.name }}</b></i
                  ></span
                >
                <!-- <b>{{gene.geneName}}</b> -->
              </h4>
              <div
                [innerHtml]="gene.description"
                style="line-height: 28px; text-align: justify; font-size: 13px"
              ></div>
            </div>
          </div>

          <div
            class="mt-3 mb-5 row"
            *ngIf="report.addNotes != null && report.addNotes != '<p></p>'"
          >
            <div class="col-12">
              <h6 class="py-2 mb-3 h6_bg" [ngClass]="'bg_purple'">
                <b>ADDITIONAL NOTES</b>
              </h6>
              <div class="py-2" [innerHtml]="report.addNotes"></div>
            </div>
          </div>
          <!--  -->
          <!-- <div class="hrr_div-main">
            <div class="">
              <p class="pl-3">For all the HRR genes analyzed in the above-mentioned table, variants were classified as :</p>
            </div>
              <div class="row mt-3">
              <div class="col">
                <ul class="list_unstyled list_dots pl-4">
                  <li><span></span>‘Pathogenic/ Likely pathogenic’ - variants for the gene were deleterious or disease-causing</li>
                  <li><span></span>‘VUS’ - Variants for the gene were of uncertain significance</li>
                </ul>
              </div>
              <div class="col">
                <ul class="list_unstyled list_dots">
                  <li><span></span>‘Not detected’ - variants for the gene were not detected in the
                    patient</li>
                </ul>
              </div>
            </div>
           </div> -->
        </div>
        <!--  -->
        <div class="row footer">
          <div class="col">
            <div class="py-2">
              <p>
                <span
                  class="font-weight-bold"
                  style="font-size: 13px !important"
                  >LifeCell International Pvt. Ltd</span
                >
              </p>
              <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
              <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
              <p>CIN: U85196TN2004PTC053577</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right mt-5" style="font-size: 12px">
              <i>*Please note that this is not a medical report</i>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      id="page2_1"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        brcaReportPdfRes.snvStarVariants.length > 0 ||
        brcaReportPdfRes.reportedStarGenes.length > 0
      "
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mb-5">
        <div
          *ngIf="
            brcaReportPdfRes.snvStarVariants.length > 0 ||
            brcaReportPdfRes.cnaStarVariants.length > 0
          "
          class="mt-5 pt-5"
        >
          <h6 class="py-2 mb-3 h6_bg" [ngClass]="'bg_purple'">
            ADDITIONAL VARIANT(S) DETECTED IN THE PROBAND
          </h6>
          <div class="row">
            <div class="col">
              <table class="page1_table" [ngClass]="'purple_page1'">
                <thead>
                  <th>Gene</th>
                  <th>Genomic Alteration</th>
                  <th>Amino acid change, Exon Number, Allele Burden</th>
                  <th>Variant Type, Zygosity</th>
                  <th>Associated disorder, Inheritance</th>
                  <th>Reference</th>
                  <th>Clinical Significance</th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let snvStarVariant of brcaReportPdfRes.snvStarVariants
                    "
                  >
                    <td>
                      <i
                        ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                      >
                    </td>
                    <td>
                      <span *ngIf="snvStarVariant?.selectedAAChangeknownGene"
                        >{{ snvStarVariant?.genomicAlteration }}
                        {{
                          snvStarVariant.selectedAAChangeknownGene.split(
                            ","
                          )[0]
                        }}, [{{ snvStarVariant?.ensembleValue }}]</span
                      >
                    </td>
                    <td>
                      <span *ngIf="snvStarVariant?.selectedAAChangeknownGene"
                        >{{
                          snvStarVariant.selectedAAChangeknownGene.split(
                            ","
                          )[1]
                        }}, Exon {{ snvStarVariant?.exonNumber }},
                        {{
                          snvStarVariant.mutantBurdenPercent * 0.01 | percent
                        }}</span
                      >
                    </td>
                    <td>
                      {{ snvStarVariant.exonicFuncknownGene }},
                      {{ snvStarVariant.zygosity }}
                    </td>
                    <td>
                      {{ snvStarVariant.cancerType }},
                      {{ snvStarVariant.inheritanceMode }}
                    </td>
                    <td>{{ snvStarVariant.reference }}</td>
                    <td>{{ snvStarVariant.significance }}</td>
                  </tr>
                  <tr
                    *ngFor="
                      let cnaStarVariant of brcaReportPdfRes.cnaStarVariants
                    "
                  >
                    <td>
                      <i
                        ><strong>{{ cnaStarVariant.gene }}</strong></i
                      >
                    </td>
                    <td>
                      chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                        cnaStarVariant.sample
                          .split(":")[1]
                          .replace("-", "_")
                          .split("|")[0]
                      }}
                    </td>
                    <td>NA</td>
                    <td>
                      Copy Number
                      {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                        cnaStarVariant.sample.split("|")[1].split(":")[0]
                      }}), {{ cnaStarVariant.zygosity }}
                    </td>
                    <td>
                      {{ cnaStarVariant.cancerType }},
                      {{ cnaStarVariant.inheritanceMode }}
                    </td>
                    <td>{{ cnaStarVariant.reference }}</td>
                    <td>{{ cnaStarVariant.significance }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="mt-3 mb-5"
          *ngIf="
            brcaReportPdfRes.reportedStarGenes &&
            brcaReportPdfRes.reportedStarGenes.length > 0
          "
        >
          <h6 class="py-2 h6_bg" [ngClass]="'bg_purple'">
            VARIANT INTERPRETATION AND CLINICAL CORRELATION
          </h6>
          <div
            *ngFor="let gene of brcaReportPdfRes.reportedStarGenes"
            class="col-12 mb-2"
          >
            <h4
              class="py-2"
              style="color: #000; font-size: 19px !important; font-weight: 400"
            >
              <span class="font-weight-bold" style="font-size: 19px !important"
                ><i
                  ><b>{{ gene.name }}</b></i
                ></span
              >
              <!-- <b>{{gene.geneName}}</b> -->
            </h4>
            <div
              [innerHtml]="gene.description"
              style="line-height: 28px; text-align: justify; font-size: 13px"
            ></div>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col">
            <div class="py-2">
              <p>
                <span
                  class="font-weight-bold"
                  style="font-size: 13px !important"
                  >LifeCell International Pvt. Ltd</span
                >
              </p>
              <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
              <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
              <p>CIN: U85196TN2004PTC053577</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right mt-5" style="font-size: 12px">
              <i>*Please note that this is not a medical report</i>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- page 2_2 -->
    <div
      id="page2_2"
      class="container position-relative page"
      style="background-color: white"
      *ngIf="
        report.interpretation != null && report.interpretation != '<p></p>'
      "
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mt-3 mb-5">
        <div class="mt-3">
          <div class="mt-5 mb-5" *ngIf="report.interpretation">
            <h6 class="py-2 h6_bg" [ngClass]="'bg_purple'">INTERPRETATION</h6>
            <div
              [innerHtml]="report.interpretation"
              class="interpretationtab"
              style="line-height: 28px; text-align: justify"
            ></div>
          </div>
        </div>

        <!--  -->
        <div class="row footer">
          <div class="col">
            <div class="py-2">
              <p>
                <span
                  class="font-weight-bold"
                  style="font-size: 13px !important"
                  >LifeCell International Pvt. Ltd</span
                >
              </p>
              <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
              <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
              <p>CIN: U85196TN2004PTC053577</p>
            </div>
          </div>
          <div class="col">
            <p class="text-right mt-5" style="font-size: 12px">
              <i>*Please note that this is not a medical report</i>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- page 3 -->
    <div
      id="page3"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg" [ngClass]="'bg_purple'">References</h6>

        <div class="ref_data mt-4">
          <ul class="break-word" style="text-align: justify">
            <li
              *ngFor="
                let referenceArticle of brcaReportPdfRes.referenceArticles
              "
              style="
                text-align: justify;
                /* white-space:pre-wrap; */
                word-break: break-word;
              "
            >
              {{ referenceArticle }}
            </li>
          </ul>
        </div>
<!--<div class="col-12 ref_data mt-4">
          <ul class="break-word" style="text-align: justify">
            <li>
              1000 Genomes Project Consortium, Auton A, Brooks LD, et al. A
              global reference for human genetic variation. Nature.
              2015;526(7571):68-74. doi:10.1038/nature15393
            </li>
            <li>
              Adzhubei, I., Jordan, D. M., & Sunyaev, S. R. (2013). Predicting
              functional effect of human missense mutations using PolyPhen-2.
              Current protocols in human genetics, Chapter 7, Unit7.20.
              https://doi.org/10.1002/0471142905.hg0720s76
            </li>
            <li>
              Cerami, E., Gao, J., Dogrusoz, U., Gross, B. E., Sumer, S. O.,
              Aksoy, B. A., Jacobsen, A., Byrne, C. J., Heuer, M. L., Larsson,
              E., Antipin, Y., Reva, B., Goldberg, A. P., Sander, C., & Schultz,
              N. (2012). The cBio cancer genomics portal: an open platform for
              exploring multidimensional cancer genomics data. Cancer discovery,
              2(5), 401–404. https://doi.org/10.1158/2159-8290.CD-12-0095
            </li>
            <li>
              Gao J, Aksoy BA, Dogrusoz U, et al. Integrative analysis of
              complex cancer genomics and clinical profiles using the
              cBioPortal. Sci Signal. 2013;6(269):pl1. Published 2013 Apr 2.
              doi:10.1126/scisignal.2004088
            </li>
            <li>
              Karczewski, K. J., Weisburd, B., Thomas, B., Solomonson, M.,
              Ruderfer, D. M., Kavanagh, D., Hamamsy, T., Lek, M., Samocha, K.
              E., Cummings, B. B., Birnbaum, D., The Exome Aggregation
              Consortium, Daly, M. J., & MacArthur, D. G. (2017). The ExAC
              browser: displaying reference data information from over 60 000
              exomes. Nucleic acids research, 45(D1), D840–D845.
              https://doi.org/10.1093/nar/gkw971
            </li>
            <li>
              Kim YM, Choi BS. Structural and functional characterization of the
              N-terminal domain of human Rad51D. Int J Biochem Cell Biol. 2011
              Mar;43(3):416-22. doi: 10.1016/j.biocel.2010.11.014. Epub 2010 Nov
              24. PMID: 21111057.
            </li>
            <li>
              Kleiblová P, Stolařová L, Křížová K, Lhota F, Hojný J, Zemánková
              P, Havránek O, Vočka M, Černá M, Lhotová K, Borecká M, Janatová M,
              Soukupová J, Ševčík J, Zimovjanová M, Kotlas J, Panczak A, Veselá
              K, Červenková J, Schneiderová M, Burócziová M, Burdová K,
              Stránecký V, Foretová L, Macháčková E, Tavandzis S, Kmoch S,
              Macůrek L, Kleibl Z. Germline CHEK2 Gene Mutations in Hereditary
              Breast Cancer Predisposition - Mutation Types and their Biological
              and Clinical Relevance. Klin Onkol. 2019
              Summer;32(Supplementum2):36-50. English. doi: 10.14735/am
              ko2019S36. PMID: 31409080.
            </li>
            <li>
              Landrum MJ, Lee JM, Benson M, Brown G, Chao C, Chitipiralla S, Gu
              B, Hart J, Hoffman D, Hoover J, Jang W, Katz K, Ovetsky M, Riley
              G, Sethi A, Tully R, Villamarin-Salomon R, Rubinstein W, Maglott
              DR. ClinVar: public archive of interpretations of clinically
              relevant variants. Nucleic Acids Res. 2016 Jan 4;44(D1):D862-8.
              doi: 10.1093/nar/gkv1222. Epub 2015 Nov 17. PMID: 26582918; PMCID:
              PMC4702865
            </li>
            <li>
              Ng, P. C., & Henikoff, S. (2003). SIFT: Predicting amino acid
              changes that affect protein function. Nucleic acids research,
              31(13), 3812–3814. https://doi.org/10.1093/nar/gkg509
            </li>
            <li>
              Rentzsch, P., Witten, D., Cooper, G. M., Shendure, J., & Kircher,
              M. (2019). CADD: predicting the deleteriousness of variants
              throughout the human genome. Nucleic acids research, 47(D1),
              D886–D894. https://doi.org/10.1093/nar/gky1016
            </li>
            <li>
              Richards S, Aziz N, Bale S, et al. Standards and guidelines for
              the interpretation of sequence variants: a joint consensus
              recommendation of the American College of Medical Genetics and
              Genomics and the Association for Molecular Pathology. Genet Med.
              2015;17(5):405-424. doi:10.1038/gim.2015.30
            </li>
            <li>
              Setton, J., Selenica, P., Mukherjee, S. et al. Germline RAD51B
              variants confer susceptibility to breast and ovarian cancers
              deficient in homologous recombination. npj Breast Cancer 7, 135
              (2021). https://doi.org/10.1038/s41523-021-00339-0
            </li>
            <li>
              Sherry, S. T., Ward, M. H., Kholodov, M., Baker, J., Phan, L.,
              Smigielski, E. M., & Sirotkin, K. (2001). dbSNP: the NCBI database
              of genetic variation. Nucleic acids research, 29(1), 308–311.
              https://doi.org/10.1093/nar/29.1.308
            </li>
            <li>
              Telli ML, Hellyer J, Audeh W, Jensen KC, Bose S, Timms KM, Gutin
              A, Abkevich V, Peterson RN, Neff C, Hughes E, Sangale Z, Jones J,
              Hartman AR, Chang PJ, Vinayak S, Wenstrup R, Ford JM. Homologous
              recombination deficiency (HRD) status predicts response to
              standard neoadjuvant chemotherapy in patients with triple-negative
              or BRCA1/2 mutation-associated breast cancer. Breast Cancer Res
              Treat. 2018 Apr;168(3):625-630. doi: 10.1007/s10549-017-4624-7.
              Epub 2017 Dec 23. PMID: 29275435.
            </li>
          </ul>
        </div> -->
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!-- page 4 -->
    <div
      id="page4"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg" [ngClass]="'bg_purple'">References</h6>

        <div class="ref_data mt-5 ml-2 pl-4">
          <h5 class="font-weight-bold">Websites</h5>
          <ul class="pl-3">
            <li>ClinVar https://www.ncbi.nlm.nih.gov/clinvar</li>
            <li>NIH- National Cancer Institute - https://www.cancer.gov/</li>
            <li>https://www.mycancergenome.org/</li>
            <li>https://www.ncbi.nlm.nih.gov/medgen/</li>
            <li>https://www.cancer.net/cancer-types</li>
            <li>https://www.mayoclinic.org/</li>
            <li>https://www.cancerresearchuk.org/</li>
            <li>http://pfam.xfam.org/</li>
            <li>https://www.uniprot.org/</li>

            <span *ngIf="report.referenceWebsites != '<p></p>'" class="">
              <div
                style="font-size: 14px !important"
                [innerHtml]="report.referenceWebsites"
              ></div>
            </span>
          </ul>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-between above-footer">
              <div class="ml-3">
                <img src="../../../assets/dr_sarah-signature.png" alt="" class="w-75">
               <p class="font-weight-bold ">Dr. Sarah Bailur</p>
              <p class="">Medical Geneticist</p>
              <p class="">(MBBS, DNB, FICG, FSIAMG)</p>
              </div>
              <div class="">
                <img src="../../../assets/dr_vidya-signature.png" alt=""  class="w-75">
               <p class="font-weight-bold ">Dr. Vidya Veldore,</p>
              <p class=" ">Clinical Director</p>
              </div>
          </div> -->

      <div class="img_signs">
        <div class="row">
          <div class="col-4">
            <img
              src="../../../assets/targ_first/sign1.png"
              alt=""
              style="padding-left: 60px; padding-right: 60px; width: 60%"
            />
            <p
              style="
                text-align: center;
                font-weight: bolder;
                font-size: 15px !important;
              "
            >
              Dr. Chirayu Padhiar, DCP (G25442)<br />Senior Medical Director
            </p>
          </div>
          <div class="col-4 offset-4 text-center">
            <img
              src="../../../assets/targ_first/sign2.png"
              alt=""
              style="padding-left: 60px; padding-right: 60px; width: 60%"
            />
            <p
              style="
                text-align: center;
                font-weight: bolder;
                font-size: 15px !important;
              "
            >
              Dr. Pragya Guptha MBBS, MD(Pathology)<br />PDF Molecular Genetics
              (TATA MEDICAL) Head - Oncogenomics
            </p>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!-- page 5 -->
    <div
      id="page5"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg" [ngClass]="'bg_purple'">ABOUT THE TESTING</h6>

        <div class="mt-4 px-4 py-3" style="text-align: justify">
          <p class="l_height-38 mb-4">
            HRR Germline is 15 gene panel which targets Homologous recombination
            repair (HRR) genes; which are involved in repair of damaged DNA.
            Mutations in these genes can lead to deficiency in repair of damaged
            DNA. It has been established that tumors that are deficient in DNA
            repair mechanism, particularly the Homologous Recombination
            mechanism are sensitive to PARP inhibitor therapy in certain cancer
            types such as breast and ovarian cancers, while the clinical trials
            are ongoing in other cancer types such as pancreatic, prostate,
            endometrial and other cancers.
          </p>

          <p class="l_height-38 mb-4`">
            Understanding the genetic testing process and its results require
            support of a trained genetic counsellor. We suggest the individual
            to seek genetic counseling prior to consenting for any kind of
            genetic test to understand the purpose of the test recommended by
            clinician and its usefulness to the patient and their family.
          </p>

          <p class="l_height-38">
            Variation (fault/mutation) in certain genes can be inherited, which
            may sometimes cause cancer. The variations found in the genes could
            be pathogenic, likely pathogenic or variant of uncertain
            significance and may attribute to low, moderate or high risk of
            predisposition to cancer.
          </p>

          <div class="p-5">
            <img
              src="../../../assets/Pedigree-Pink.png"
              alt=""
              class="img-fluid w-100"
              style="height: 620px"
            />
          </div>

          <!--  -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!-- page 7 -->
    <div
      id="page7"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg" [ngClass]="'bg_purple'">TEST METHODOLOGY</h6>

        <div class="mt-4 px-4 py-3" style="text-align: justify">
          <p class="l_height-38">
            Genomic DNA is isolated from Whole Blood sample for library
            preparation and quantified using Qubit Fluorometer, 50 ng is taken
            for library preparation. The NGS libraries were prepared as per
            standard procedures for Illumina sequencing. The libraries were
            sequenced with mean coverage depth >150X.
          </p><br/>
          <p class="l_height-38">
            The sequences obtained are aligned to human reference genome
            (GRCh37/hg19) and variant analysis was performed using set of
            Bioinformatics Pipeline. Only non- synonymous and splice site
            variants found in the exome panel consisting of specific set of
            genes were used for clinical interpretation. Silent variations that
            do not result in any change in amino acid in the coding region are
            not reported.
          </p><br/>
          <p class="l_height-38">
            Clinically relevant mutations were annotated using published
            literature and a set of databases – ClinVar (Landrum et al, 2015.),
            cbioportal (Cerami et al, 2012; Gao et al, 2013) and dbSNP. Common
            variants are filtered based on allele frequency in 1000 Genome Phase
            3(Auton et al, 2015), ExAC (Karczewski et al. 2016), dbSNP (Sherry
            et al, 2001), etc. In the absence of a clinically significant
            reported known variation(s), pathogenicity will be predicted based
            on in-silico gene prioritization tools: CADD (Rentzsch et al. 2018),
            SIFT (Ng PC et al, 2003), PolyPhen-2 (Adzhubei et al, 2013) and
            prioritized for clinical correlation. The identified pathogenic
            variant will be correlated with observed phenotypic features of the
            patient and interpreted according to American College of Medical
            Genetics (ACMG) guidelines.
          </p>
        </div>

        <div class="text-center">
          <img
            src="./../../../assets/HRR-Image.png"
            alt=""
            style="width: 50%"
          />
        </div>

        <p class="ml-4 mb-2 py-2">Annexure:</p>
        <p class="ml-4">
          The classification of the variations is done based on American College
          of Medical Genetics as described below
        </p>

        <div class="row ml-4 mt-4 center">
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="first float-right mr-4"></div>
            <!-- </div> -->
            <div
              class="col-11 w-100"
              style="border: 1px solid #a4247a; border-radius: 10px 10px 0 0"
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="first-text">Pathogenic</strong> : A disease
                causing variation in a gene which can explain the patient's
                symptoms has been detected. This usually means that a suspected
                disorder for which testing had been requested has been
                confirmed.
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <div class="second float-right mr-4"></div>
            <div
              class="col-11 w-100"
              style="background: #ffddf4; border: 1px solid #a4247a"
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="second-text">Likely Pathogenic</strong> : A
                variant which is very likely to contribute to the development of
                disease. However, the scientific evidence is currently
                insufficient to prove this conclusively. Additional evidence is
                expected to confirm this assertion of pathogenic
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="third float-right mr-4"></div>
            <!-- </div> -->
            <div class="col-11 w-100" style="border: 1px solid #a4247a">
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="third-text"
                  >Variant of Uncertain Significance</strong
                >
                : A variant has been detected, but it is difficult to classify
                it as either pathogenic (disease causing) or benign (non-disease
                causing) based on current available scientific evidence. Further
                testing of the patient or family members as recommended by your
                clinician may be needed. It is probable that their significance
                can be assessed only with time, subject to availability of
                scientific evidence.
              </span>
            </div>
          </div>
          <div class="d-flex col-12">
            <!-- <div class="col-1"> -->
            <div class="fourth float-right mr-4"></div>
            <!-- </div> -->
            <div
              class="col-11 w-100"
              style="
                background: #ffddf4;
                border: 1px solid #a4247a;
                border-radius: 0 0 10px 10px;
              "
            >
              <span
                class="mt-2 mb-2 annexure-table"
                style="text-align: justify"
              >
                <strong class="fourth-text">Benign/Likely Benign</strong> : A
                variant termed benign has sufficient evidence that it can be
                concluded that the variant is not the cause of the patient's
                disorder. A variant termed likely benign has sufficient evidence
                that it can be concluded that the variant is not the cause of
                the patient's disorder when combined with other information.
              </span>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>

    <!-- page 11 -->
    <div
      id="page11"
      class="container position-relative page"
      style="background-color: white"
    >
      <div class="row header header_height mt-1"></div>
      <!--  -->
      <div class="mt-3 mb-5">
        <h6 class="py-2 h6_bg" [ngClass]="'bg_purple'">
          LIMITATIONS AND DISCLAIMER
        </h6>

        <div class="ref_data pt-2" style="text-align: justify">
          <ul class="px-5">
            <li style="white-space: pre-wrap">
              A comprehensive risk assessment may include other aspects of the
              patient's personal/family medical history, as well as lifestyle,
              environment and other factors. This is not included in the scope
              of this NGS testing.
            </li>
            <li style="white-space: pre-wrap">
              We are using the canonical transcript for clinical reporting which
              is usually the longest coding transcript with strong/multiple
              supporting clinical evidence. However, in rare cases, clinically
              relevant variants annotated in alternate complete coding
              transcripts could also be reported.
            </li>
            <li style="white-space: pre-wrap">
              Changes in personal/family history or additional data regarding
              specific genes/mutations may affect the cancer risk estimates and
              management recommendations within this report. Personal/family
              history should be updated with a healthcare provider on a regular
              basis
            </li>
            <li style="white-space: pre-wrap">
              Certain genes may not be covered completely, and few mutations
              could be missed. Many factors such as homopolymers, GC-rich
              regions etc. influence the quality of sequencing and coverage.
              This may result in an occasional error in sequence reads or lack
              of detection of a particular genetic alteration.
            </li>
            <li style="white-space: pre-wrap">
              As with any laboratory test, there is a small chance that this
              result may be inaccurate for a preanalytical reasons, such as an
              error during specimen collection and labeling (incorrect patient
              identification).
            </li>
            <li style="white-space: pre-wrap">
              Large insertions, deletions, duplications, inversions, repeat
              expansions and complex rearrangements cannot be characterized
              accurately by NGS as it uses short-read sequencing data. Such
              structural variants have a much higher false-positive and
              false-negative rate than seen for SNVs (single nucleotide
              variant). It is possible that the genomic region where a
              disease-causing variation exists in the proband was not captured
              using the current technologies and therefore was not detected.
            </li>
            <li style="white-space: pre-wrap">
              It is possible that a particular genetic abnormality may not be
              recognized as the underlying cause of the genetic disorder due to
              incomplete scientific knowledge about the function of all genes in
              the human genome and the impact of variants on those genes.
            </li>
            <li style="white-space: pre-wrap">
              Accurate interpretation of this report is dependent on detailed
              clinical history of the patient. In the event of unavailability of
              detailed clinical history, the lab cannot guarantee the accuracy
              of the interpretation.
            </li>
            <li style="white-space: pre-wrap">
              This report is strictly not a medical diagnostic report and shall
              not be construed as the medical certificate or medical laboratory
              report or diagnostic report.
            </li>
            <li style="white-space: pre-wrap">
              The patient’s physician may annually wish to re-analyze the
              results or recommend re-testing for any variants that may have
              been newly identified, to associate with the patient’s clinical
              condition. The patient or family members are recommended to
              consult their physician and approach us for testing services
              accordingly.
            </li>
            <!-- <li
              *ngFor="
                let referenceArticle of brcaReportPdfRes.referenceArticles
              "
              style="white-space: pre-wrap"
            >
              {{ referenceArticle }}
            </li> -->
          </ul>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important"
                >LifeCell International Pvt. Ltd</span
              >
            </p>
            <p>#16/9 Vijayaraghava lane, Vijayaraghava Road,</p>
            <p>T.Nagar,Chennai-600017 Tamil Nadu, India</p>
            <p>CIN: U85196TN2004PTC053577</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
